import React, {useEffect, useState, useRef} from 'react';
import {Collapse, InputNumber, Modal, notification, Popconfirm, Radio, Select, Slider, Switch, Tooltip, Button} from 'antd';
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import settings from "../assets/images/settings-icon.svg";
import info from "../assets/images/info.svg";
import config from "../config";

const {Panel} = Collapse;
const BackupInstance = ({props, callback}) => {
    const {
        getData,
        deleteData,
        updateData,
        logout,
        getIcon,
        isWindows,
        isCMDDone,
        defaultOpenBackup,
        setDefaultOpenBackup, userStatus,
        limitExceeded,
        reloadBackups,
        setReloadBackups,
        getUniqueDataCount,
        setPopup500,
        onlyPostDataNoResponse,
        setLoading,
        loading,
        sendDataToMixPanel,
        user,
        setError499,
        setPopup499,
        agentIsRunning,
        loadSyncOnLogin,
        setLoadSyncOnLogin
    } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showTooltips, setshowTooltips] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);

    const [backupInstance, setBackupInstance] = useState([]);


    const [cloudCounts, setcloudCounts] = useState([])

    const [Threads, setThreads] = useState(5);
    const [defaultActive, setDefaultActive] = useState(0)
    const [limitExceed, setLimitExceed] = useState(false);
    const dataFetchedRef1 = useRef(false);
    const checkBackupIntervalCalledFirstTime = useRef(false);

    const onChangeThreads = (newValue) => {
        setThreads(newValue);
    };


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadBackups) {
                setReloadBackups(false)
                loadBackupData(true)
            }
        }
    }, [reloadBackups]);

    function cloudWithCounts(data) {
        var items = [];
        for (const [key, value] of Object.entries(data)) {
            var obj = {};
            obj.cloudType = key;
            obj.count = value;
            items.push(obj)
        }
        setcloudCounts(items);
    }

    const onChange = (key) => {
        if (backupInstance.length > 0) {
            callback({"sync": key.length > 1})
            setDefaultActive(key);
        }

    };

    var time = 2000; // dynamic interval
    var timer; // current timeout id to clear
    function checkBackupInterval() {
        checkBackupIntervalCalledFirstTime.current = true
        time *= 2;
        console.log(time)
        timer = setTimeout(() => {
            checkBackupInterval()
        }, time);

        if (localStorage.getItem('isLoggedInTimer') === null || time === 64000) {
            console.log("timer cleared ----------------")
            time = 2000;
            clearTimeout(timer);
        } else {
            loadBackupData();
        }
    }

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (backupInstance.length === 0) {
                if (agentIsRunning && user.userType !== undefined) {
                    loadBackupData()
                    if (!checkBackupIntervalCalledFirstTime.current) {
                        checkBackupInterval()
                    }
                }
            }
        }
    }, [isCMDDone, agentIsRunning, user]);
    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (defaultOpenBackup) {
                setDefaultActive(1);
                setDefaultOpenBackup(false);
            }
        }
    }, []);

    useEffect(() => {
        if (limitExceeded !== "") {
            if (agentIsRunning) {
                loadBackupData()
            }
        }
    }, [limitExceeded]);

    function loadBackupData(refresh = false) {
        if (dataFetchedRef1.current) return;
        dataFetchedRef1.current = true;
        getData('/Management/cloudbackup')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (data.length > 0) {
                    for (var j = 0; j < data.length; j++) {
                        data[j]["cloudIconType"] = data[j].account.cloudType;
                        data[j]["cloudType"] = data[j].account.cloudType;
                        if (data[j].account.id === limitExceeded) {
                            data[j]["disabled"] = true;
                            setLimitExceed(true)
                            data[j]["active"] = false;
                            actionSettingUpdate(false, data[j], false)
                        }
                        if (data[j].account.internalStorage) {
                            data[j]["cloudIconType"] = "Amove";
                        } else {
                            if (data[j].account.serviceUrl !== null && data[j].account.serviceUrl !== undefined) {
                                if (data[j].account.serviceUrl.indexOf('storjshare.io') > -1) {
                                    data[j]["cloudIconType"] = 'storj';
                                }
                                if (data[j].account.serviceUrl.indexOf('appdomain.cloud') > -1) {
                                    data[j]["cloudIconType"] = 'IBM';
                                }
                                if (data[j].account.serviceUrl.indexOf('ovh.us') > -1) {
                                    data[j]["cloudIconType"] = 'OVH';
                                }
                                if (data[j].account.serviceUrl.indexOf('seagate.com') > -1) {
                                    data[j]["cloudIconType"] = 'Live';
                                }
                                if (data[j].account.serviceUrl.indexOf('oraclecloud.com') > -1) {
                                    data[j]["cloudIconType"] = 'Oracle';
                                }
                                if (data[j].account.serviceUrl.indexOf('digitaloceanspaces.com') > -1) {
                                    data[j]["cloudIconType"] = 'Ocean';
                                }
                                if (data[j].account.serviceUrl.indexOf('backblazeb2.com') > -1) {
                                    data[j]["cloudIconType"] = 'Backblaze';
                                }
                                if (data[j].account.serviceUrl.indexOf("object-matrix.com") > -1) {
                                    data[j]["cloudIconType"] = "Perifery";
                                }
                                if (data[j].account.serviceUrl.indexOf("idrivee2") > -1) {
                                    data[j]["cloudIconType"] = "IDrive";
                                }
                            }
                        }
                    }
                    setBackupInstance(data);
                } else {
                    setBackupInstance([]);
                    setcloudCounts([]);
                    setDefaultActive(0);
                }
                var dataToCheck = [];
                for (var i = 0; i < data.length; i++) {
                    dataToCheck.push(data[i])
                }

                dataFetchedRef1.current = false;
                cloudWithCounts(getUniqueDataCount(dataToCheck, ['cloudIconType']))
            });
    }

    function deleteBackupInstance(id, type, name) {
        setLoadingDel(true)
        deleteData('/Management/cloudbackup/' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                setLoadingDel(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setBackupInstance((current) =>
                        current.filter(
                            (item) => item.id !== id
                        )
                    );
                    api.open({
                        message: 'Your ' + name + ' Backup Instance has been deleted.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('Your ' + name + ' Backup Instance has been deleted.', user.username, {
                        'App': 'Web App'
                    })
                    let newArr = [...cloudCounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].cloudType == type) {
                            newArr[i].count--;
                        }
                    }

                    setcloudCounts(newArr)
                    setIsModalOpen(false);
                }
            });
    }

    const cloudAccount = (
        <div className="connected-accounts-header">
            <h3>Sync</h3>
            {cloudCounts.length > 0 && (
                <ul className="login-accounts">
                    {cloudCounts.map((item, i) => (
                        <li key={i} className={` ${item.count > 0 ? "show" : ""}`}>{getIcon(item.cloudType, item.cloudIconType)}<span className={` ${item.count > 1 ? "show" : ""}`}>{item.count}</span></li>
                    ))}
                </ul>
            )}
        </div>
    );
    const [GBMB, setGBMB] = useState('MB');
    const [chunkSize, setChunkSize] = useState();
    const [modalData, setModalData] = useState({})

    const showModal = (e, data) => {
        setIsModalOpen(true);
        setshowTooltips(true);
        setModalData(data)
        setThreads(data.transferOptions.concurrentFiles)
        setGBMB("MB")
        setChunkSize((data.transferOptions.chunkSize / 1024) / 1024)
        e.preventDefault();
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const chunkSizeChange = (value) => {
        setChunkSize(value)
    };
    const changeGBMB = (e) => {
        setGBMB(e.target.value)
        if (e.target.value === "GB") {
            setChunkSize(1)
        } else {
            setChunkSize(5)
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const saveSettings = (e) => {
        var finalChunkSize = chunkSize * 1024 * 1024;
        if (GBMB === "GB") {
            finalChunkSize = 1024 * 1024 * 1024;
        }
        var dataToPost = {
            "id": modalData.id,
            "active": modalData.active,
            "name": modalData.name,
            "localPath": modalData.localPath,
            "prefix": modalData.prefix,
            "storageName": modalData.storageName,
            "syncIntervalSeconds": modalData.syncIntervalSeconds,
            "transferOptions": {
                "chunkSize": finalChunkSize,
                "retryCount": modalData.transferOptions.retryCount,
                "concurrentChunks": modalData.transferOptions.concurrentChunks,
                "concurrentFiles": Threads
            },
            "account": modalData.account,
            "storageType": modalData.storageType
        }
        setLoading(true);
        updateData('/Management/cloudbackup/' + modalData.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                if (status === 200) {
                    dataToPost.storageType = modalData.storageType;
                    dataToPost.sharedDrive = modalData.sharedDrive;
                    dataToPost.cloudIconType = modalData.cloudIconType;
                    dataToPost.cloudType = modalData.cloudType;
                    let newArr = [...backupInstance];
                    console.log(JSON.stringify(newArr))
                    console.log(JSON.stringify(dataToPost))
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === modalData.id) {
                            newArr[i] = dataToPost;
                        }
                    }
                    setBackupInstance(newArr)

                    api.open({
                        message: 'Your ' + modalData.name + ' Backup Instance has been updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    sendDataToMixPanel('Your ' + modalData.name + ' Backup Instance has been updated.', user.username, {
                        'App': 'Web App'
                    })
                    setIsModalOpen(false);
                    setModalData({})
                }
            });
        e.preventDefault();
    };

    function actionSettingUpdate(e, data, updateCData = true) {
        var msg = 'Your ' + data.name + ' Backup Instance has been deactivated.'
        if (e) {
            msg = 'Your ' + data.name + ' Backup Instance has been activated.'
        }
        var dataToPost = {
            "id": data.id,
            "active": e,
            "name": data.name,
            "localPath": data.localPath,
            "prefix": data.prefix,
            "storageName": data.storageName,
            "syncIntervalSeconds": data.syncIntervalSeconds,
            "transferOptions": {
                "chunkSize": data.transferOptions.chunkSize,
                "retryCount": data.transferOptions.retryCount,
                "concurrentChunks": data.transferOptions.concurrentChunks,
                "concurrentFiles": data.transferOptions.concurrentFiles
            },
            "account": data.account,
            "storageType": data.storageType
        }

        updateData('/Management/cloudbackup/' + data.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                if (status === 200) {
                    if (updateCData) {
                        let newArr = [...backupInstance];
                        for (var i = 0; i < newArr.length; i++) {
                            if (newArr[i].id === dataToPost.id) {
                                newArr[i].active = e;
                            }
                        }

                        setBackupInstance(newArr)
                        api.open({
                            message: msg,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });
                        setIsModalOpen(false);
                        setModalData({})
                    }
                }
            });
    }


    function openCloudBackup(id) {
        onlyPostDataNoResponse('/Management/cloudbackup_open/' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                }
            });
    }

    return (
        <> {contextHolder}
            <div className="connected-accounts">
                <Collapse defaultActiveKey={[defaultActive]} className={`connected-accounts-body-collapse ${backupInstance.length < 1 ? "hide-arrow-icon-panel" : ""}`} activeKey={defaultActive} onChange={onChange}>
                    <Panel header={cloudAccount} key="1">
                        {backupInstance.length > 0 && (
                            <div className="connected-accounts-body">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="col-xl-2 col-md-2 col-2">
                                            <p>Cloud Account</p>
                                        </div>
                                        <div className="col-xl-2 col-md-3 col-4 text-center">
                                            <p>Source Folder</p>
                                        </div>
                                        <div className="col-xl-2 col-md-2 col-2 text-center">
                                            <p>Backup</p>
                                        </div>
                                        <div className="col-xl-3 col-md-3 col-2 text-center">
                                            Bucket
                                        </div>
                                        <div className="col-xl-2 col-md-1 col-1 text-center">
                                            Settings
                                        </div>
                                        <div className="col-xl-1 col-md-1 col-1 text-end last-item-padding">
                                            Off/On
                                        </div>
                                    </div>
                                </div>
                                <div className="table-body">
                                    <ul>
                                        {backupInstance.map(item => (
                                            <div className="table-body-card" key={item.id}>
                                                <div className="row">
                                                    <div className="col-xl-2 col-md-2 col-2 align-self-center">
                                                        <div className="account-detail">
                                                            <div className="img-wrapper">
                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <p className="medium">{item.account.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-md-3 col-4 align-self-center text-center">
                                                        <div title={item.localPath}>
                                                            {isWindows &&
                                                                item.localPath.substring(item.localPath.lastIndexOf("\\") + 1, item.localPath.length)
                                                            }
                                                            {!isWindows &&
                                                                item.localPath.substring(item.localPath.lastIndexOf("/") + 1, item.localPath.length)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`col-xl-2 col-md-2 col-2 align-self-center text-center ${item.disabled ? "disabled" : ""}`}>

                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!limitExceed) {
                                                                openCloudBackup(item.id)
                                                            }
                                                        }} className='btn-style invert smaller open-drive-btn ellipses' title={item.name}>{item.name}</a>
                                                    </div>
                                                    <div className={`col-xl-3 col-md-3 col-2 align-self-center  text-center`}>
                                                        {item.storageName}
                                                    </div>
                                                    <div className={`col-xl-2 col-md-1 col-1 align-self-center text-center ${item.disabled ? "disabled" : ""}`}>
                                                        <a href="#" className="settings-icon" onClick={(e) => {

                                                            if (!limitExceed) {
                                                                showModal(e, item)
                                                            }
                                                        }}>
                                                            <img src={settings} alt="Settings"/>
                                                        </a>
                                                    </div>
                                                    <div className={`col-xl-1 col-md-1 col-1 align-self-center action-wrapper ${item.disabled ? "disabled" : ""}`}>
                                                        <ul className="icons">
                                                            <li>

                                                                {item.active &&
                                                                    <Popconfirm className="switch-confirm"
                                                                                placement="topLeft"
                                                                                title="Are you sure you want to deactivate this cloud backup instance?"
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                onConfirm={() => {
                                                                                    if (!limitExceed) {
                                                                                        actionSettingUpdate(false, item)
                                                                                    }
                                                                                }}
                                                                    >
                                                                        <Switch checked={item.active}/>
                                                                    </Popconfirm>
                                                                }
                                                                {!item.active &&
                                                                    <Switch onChange={(e) => {

                                                                        if (!limitExceed) {
                                                                            actionSettingUpdate(e, item)
                                                                        }
                                                                    }} defaultChecked={item.active}/>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Panel>
                </Collapse>
            </div>
            <Modal title="Basic Modal" centered className="cloud-drive-modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="cloud-drive-modal-wrapper">
                    <h3>{modalData.name} | Backup Instance Settings</h3>
                    <div className="form backup-popup-setting form-spacing-fixed">
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <div className="form-group info-wrapper">
                                    <label htmlFor="threads">Number of concurrent threads
                                        <Tooltip placement="topLeft" title="The number of objects that are transferred in parallel across the network simultaneously.">
                                            <img src={info} alt="Info"/>
                                        </Tooltip></label>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="form-group slider concurrent-threads-slider">
                                    <div className="slider-wrapper">
                                        <InputNumber
                                            className="form-control"
                                            min={1}
                                            max={modalData.cloudIconType === 'storj' ? 20 : 5}
                                            style={{width: 90}}
                                            value={Threads}
                                            onChange={onChangeThreads}/>
                                    </div>
                                    <strong>Max: {modalData.cloudIconType === 'storj' && 20}{modalData.cloudIconType !== 'storj' && 5}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <div className="form-group">
                                    <label htmlFor="chunkSize">Chunk Size</label>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="form-group slider">
                                    <div className="slider-wrapper">
                                        <div className={`form-group no-margin-b `}>
                                            {modalData.cloudIconType !== 'storj' &&
                                                <InputNumber placeholder="5MB to 4GB"
                                                             style={{width: 90}} className="form-control" min={GBMB === 'GB' ? 1 : 5} max={GBMB === 'GB' ? 4 : 4096} value={chunkSize} onChange={chunkSizeChange}/>

                                            }
                                            {modalData.cloudIconType === 'storj' &&
                                                <Select
                                                    defaultValue="64"
                                                    style={{width: 90}}
                                                    onChange={chunkSizeChange}
                                                    value={chunkSize}
                                                    options={[
                                                        {
                                                            value: '64',
                                                            label: '64',
                                                        },
                                                        {
                                                            value: '128',
                                                            label: '128',
                                                        },
                                                        {
                                                            value: '192',
                                                            label: '192',
                                                        },
                                                        {
                                                            value: '256',
                                                            label: '256'
                                                        },
                                                        {
                                                            value: '320',
                                                            label: '320'
                                                        },
                                                        {
                                                            value: '384',
                                                            label: '384'
                                                        },
                                                        {
                                                            value: '448',
                                                            label: '448'
                                                        },
                                                        {
                                                            value: '512',
                                                            label: '512'
                                                        },
                                                        {
                                                            value: '576',
                                                            label: '576'
                                                        },
                                                        {
                                                            value: '640',
                                                            label: '640'
                                                        },
                                                        {
                                                            value: '704',
                                                            label: '704'
                                                        },
                                                        {
                                                            value: '768',
                                                            label: '768'
                                                        },
                                                        {
                                                            value: '832',
                                                            label: '832'
                                                        },
                                                        {
                                                            value: '896',
                                                            label: '896'
                                                        },
                                                        {
                                                            value: '960',
                                                            label: '960'
                                                        }
                                                    ]}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group no-margin-b">
                                        <Radio.Group onChange={changeGBMB} value={GBMB}>
                                            <Radio value="MB">
                                                MB
                                            </Radio>
                                            {modalData.cloudIconType !== 'storj' &&
                                                <Radio value="GB">
                                                    GB
                                                </Radio>
                                            }
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <div className="form-group">
                                    <label htmlFor="backup-folder">Backup Folder Path</label>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="form-group no-margin-b" title={modalData.localPath}>
                                    <div className="ellipses">
                                        {modalData.localPath}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 align-self-end delete-wrapper">
                                <div className="form-group">
                                    <label>
                                        <Popconfirm
                                            placement="topLeft"
                                            title="Are you sure you want to delete this backup instance?"
                                            okText="Yes"
                                            cancelText="No"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onConfirm={() => deleteBackupInstance(modalData.id, modalData.cloudIconType, modalData.name)}
                                        >
                                            <Button loading={loadingDel} className="btn-style small-btn bkp-del-btn">Delete Backup Instance</Button>
                                            {/*Delete {modalData.name} Backup Instance*/}
                                        </Popconfirm>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group submit">
                                    <Button className="btn-style small-btn cancel-btn bkp-cancel-btn"
                                            onClick={handleCancel}>Cancel</Button>
                                    <Button loading={loading} className="btn-style bkp-save-btn small-btn"
                                            onClick={saveSettings}>Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export {BackupInstance}
