import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import editIcon from "../assets/images/icons/edit-red-icon.svg";
import {Button, Modal, Select, notification, Popconfirm} from "antd";
import uploadIcon from "../assets/images/icons/file-upload-icon.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {BackupInstance} from "../components/BackupInstance";
import AgentOffPopup from "../components/AgentOffPopup";

const NewBackupInstance = () => {
    const navigate = useNavigate();
    const {
        cloudAccounts,
        getData,
        logout,
        isWindows,
        getIcon,
        agentIsRunning,
        defaultOpenBackup,
        sendDataToMixPanel,
        user,
        setDefaultOpenBackup,
        postDataNewFolder,
        setPopup500,
        getDataMethodGet,
        setError499,
        setPopup499,
        checkLength
    } = useAuth();
    const [cloudDrive, setCloudDrive] = useState("");
    const [local, setlocal] = useState("");
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isActive, setActive] = useState(null);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [backupName, setBackupName] = useState("");
    const [localDrives, setLocalDrives] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [previousPath, setPreviousPath] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const [bucket, setBucket] = useState("");
    const [prefix, setPrefix] = useState("");
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);

    const [folderName, setFolderName] = useState("");
    const [selectedBuckets, setSelectedBuckets] = useState([]);

    const [cloudDrives, setCloudDrives] = useState([]);
    const [backupInstances, setBackupInstances] = useState([]);
    const [limitExceed, setLimitExceed] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);


    const confirm = (e) => {
        if (folderName !== '') {
            postDataNewFolder('/Management/make_directory', {
                "directoryName": folderName,
                "path": previousPath
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    sendDataToMixPanel('New Folder Created.', user.username, {
                        'App': 'Web App',
                        'Folder Name':folderName
                    })
                    getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(previousPath))
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }
                            setPreviousPath(previousPath)
                            setLocalDir(response)
                            const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                            elements.forEach((element) => {
                                element.classList.remove('active');
                            });

                        })
                });
            setFolderName('');
        }
    };

    function getBuckets(count) {
        postData("/Cloud/list_buckets", cloudAccounts[count])
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setSelectedBuckets(response);
            }
        );
    }



    async function getdrives() {
        getDataMethodGet('/Management/list_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                    setLocalDrives(response)
                })
    }

    function getDirectories(e) {
        setSelectedPath(e.target.value)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setPreviousPath(e.target.value)
                setLocalDir(response)
            })
    }

    async function getDirectoriesMac() {
        getDataMethodGet('/Management/list_directories?path=/')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    function changeDir(e) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        setSelectedPath(val)
        if (e.detail == 2) {
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setPreviousPath(val)
                    setLocalDir(response)
                    const elements = document.querySelectorAll('.directory-list .form-group')
                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                })
        }
    }

    useEffect(() => {
            if (cloudDrives.length === 0) {
                getData('/Management/clouddrive')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                    setCloudDrives(response);
                });
            }
            if (backupInstances.length === 0) {
                getData('/Management/cloudbackup')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                    setBackupInstances(response);
                });
        }
        if (cloudAccounts.length > 0) {
            cloudAccounts.map((item) => {
                if (item.disabled) {
                    setLimitExceed(true);
                }
            })
        }
    }, []);

    function goBack() {
        var val = ""
        if (isWindows) {
            if (previousPath.lastIndexOf("\\") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\") + 1);
            }
        } else {
            if (previousPath.lastIndexOf("/") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("/"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("/") + 1);
            }
        }

        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setPreviousPath(val)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    const showModal = () => {
        setOpen(true);
        if (selectedPath !== '') {
            var p = selectedPath.substring(0, selectedPath.lastIndexOf("\\"));
            if (!isWindows) {
                p = selectedPath.substring(0, selectedPath.lastIndexOf("/"))
            }
            setPreviousPath(p)
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(p))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    setTimeout(function () {
                        const elements = document.querySelectorAll('.directory-list .form-group')
                        elements.forEach((element) => {
                            element.classList.remove('active');
                            var fName = '';
                            if (isWindows) {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                            } else {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                            }
                            if (element.innerText === fName) {
                                element.classList.add('active');
                            }
                        });
                    }, 200)
                })
        } else {
            var dirListPath = '/';
            if (isWindows) {
                setPreviousPath(localDrives[0])
                dirListPath = encodeURIComponent(localDrives[0]);
            } else {
                setPreviousPath('/');
            }
            getDataMethodGet('/Management/list_directories?path=' + dirListPath)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                })
        }
    };
    // run code for dirHandle
    const handleOk = () => {
        setOpen(false);
        setStep(4);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    //
    const selectCloudDrive = async (event) => {
        event.preventDefault();
        if (isWindows) {
            await getdrives();
        } else {
            await getDirectoriesMac()
        }
        const index = event.target.getAttribute('data-index');
        getBuckets(index);
        setSelectedCloudAccount(cloudAccounts[index])
        setStep(2);
    };
    const changeCloudDrive = (event) => {
        event.preventDefault();
        setCloudDrive("");
        setBucket("")
        setPrefix("")
        setStep(1)
    };

    const onChange = (value) => {
        setBucket(value)
        if (value !== '') {
            if (backupName === '' || selectedPath === '') {
                setStep(3)
            } else {
                setStep(4)
            }
        } else {
            setStep(2);
        }
    };
    const onSearch = (value) => {

    };
    const backupInstance = (event) => {
        const name = event.target.value;
        setBackupName(name)
        setShowCloudDriveNameError(false)
        setShowCloudDriveNameLengthError(false)
        if (name.length > 1) {
            var result1 = cloudDrives.filter(item => item.name.toLowerCase() === name.toLowerCase());
            var result2 = backupInstances.filter(item => item.name.toLowerCase() === name.toLowerCase());

            if (result1.length > 0 || result2.length > 0) {
                setActive(false);
                setShowCloudDriveNameError(true)
                setShowCloudDriveNameLengthError(false)
            } else {
                if (!checkLength(name, 32)) {
                    setShowCloudDriveNameLengthError(true)
                } else {
                    setActive(true);
                    if (selectedPath !== '') {
                        setStep(4)
                    }
                }
            }
        } else {
            setShowCloudDriveNameLengthError(false)
            setActive(false);
        }
    }
    const choosePrefix = (event) => {
        const result = event.target.value.replace(/[^a-z0-9.!*)(_'-]/gi, '');
        setPrefix(result);
    }

    const newFolderName = (event) => {
        const folderName = event.target.value;
        setFolderName(folderName);
    }

    async function postData(url = '', data = {}) {
        const response = await fetch(config.apiURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data)
        });
        return response;
    }

    function startBackupInstance() {
        setLoading(true)
        var dataToPost = {
            "name": backupName,
            "localPath": selectedPath,
            "prefix": prefix,
            "storageName": bucket,
            "account": {
                "id": selectedCloudAccount.id,
                "accountId": selectedCloudAccount.accountId,
                "cloudType": selectedCloudAccount.cloudType,
                "name": selectedCloudAccount.name,
                "accessKey": selectedCloudAccount.accessKey,
                "secretKey": selectedCloudAccount.secretKey,
                "credentialsData": selectedCloudAccount.credentialsData,
                "serviceUrl": selectedCloudAccount.serviceUrl,
                "active": selectedCloudAccount.active,
                "internalStorage": selectedCloudAccount.internalStorage,
                "deleted": selectedCloudAccount.deleted
            }
        }

        if (selectedCloudAccount.cloudIconType === 'storj' || selectedCloudAccount.cloudIconType === 'Amove') {
            dataToPost.transferOptions = {
                "chunkSize": 64 * 1024 * 1024,
            }
        }
        postData('/Management/cloudbackup', dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                api.open({
                    message: backupName + ' Sync Instance has been successfully set up.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                sendDataToMixPanel('Sync Instance has been successfully set up.', user.username, {
                    'App': 'Web App',
                    'Sync Name': backupName
                })
                setTimeout(function () {
                    setDefaultOpenBackup(true)
                    setLoading(false)
                }, 3000)
            });
    }

    useEffect(() => {
        if (defaultOpenBackup) {
            navigate("/dashboard");
        }
    }, [defaultOpenBackup]);
    return (
        <>
            {contextHolder}
            <div id="main-content" className={`${!agentIsRunning ? "disabled" : ""}`}>
                <div className="container-fluid">
                    <div className="main-content-body dashboard-home add-cloud-account-page">
                        <div className="cloud-brands-wrapper choose-cloud-wrapper">
                            <div className="left-content">
                                <BackupInstance/>
                                {step === 1 &&
                                    <div className="drive-brands">
                                        <h3 className="mb-41">Setup a New Sync</h3>
                                        <div className="row">
                                            <div className="col-md-4 align-self-center">
                                                <div className="choose-cloud-counter mobile-flex">
                                                    <div className="img-wrapper">
                                                        <div className="counter"><span>1</span></div>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <h3>Choose a Cloud</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-md-end text-start align-self-center">
                                                {/* <Link to="/add-cloud-account" className="btn-style">Add Cloud Account</Link>*/}
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="choose-cloud-card-wrapper">

                                                {cloudAccounts.length > 0 &&
                                                    cloudAccounts.map((item, i) => (
                                                        <div className={`form-group ${item.disabled ? "disabled" : ""}`} key={item.id}>
                                                            <label className="choose-cloud-card">
                                                                <input type="radio" id="choose-cloud-1" value={item.id} data-index={i} data-name={item.name} name="choose-cloud" onChange={selectCloudDrive}/>
                                                                <div className="img-wrapper">
                                                                    {getIcon(item.cloudType, item.cloudIconType)}
                                                                </div>
                                                                <div className="content-wrapper">
                                                                    <p className="medium">{item.name}</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(step === 2 || step > 2) &&
                                    <div className="drive-brands">
                                        <div className="row">
                                            <div className="col-md-4 align-self-center">
                                                <div className="choose-cloud-counter mobile-flex">
                                                    <div className="img-wrapper">
                                                        <div className="counter active"><span>1</span></div>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <h3>Cloud Account Selected</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="change-cloud-wrapper">
                                                    <div className="choose-cloud-card">
                                                        <div className="img-wrapper">
                                                            {getIcon(selectedCloudAccount.cloudType, selectedCloudAccount.cloudIconType)}
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <p className="medium">{selectedCloudAccount.name}</p>
                                                        </div>
                                                    </div>
                                                    <a href="#" className="btn-style transparent icon" onClick={changeCloudDrive}><img src={editIcon} alt="logo"/></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }


                                <div className={`drive-brands step-2  ${step === 1 ? "disabled" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="choose-cloud-counter mobile-flex">
                                                <div className="img-wrapper">
                                                    <div className={`counter  ${step > 3 ? "active" : ""}`}><span>2</span></div>
                                                </div>
                                                <div className="content-wrapper">
                                                    <h3>Complete Your Sync Setup</h3>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-5 align-self-center">

                                        </div>
                                    </div>
                                </div>
                                {(step === 2 || step > 2) &&

                                    <div className="setup-cloud-drive">
                                        <div className="form">

                                            <div className="row">
                                                <div className="col-md-4 align-self-center">
                                                    <p className="medium">Select a bucket</p>
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    <div className={`account-detail radio radio-button ${bucket !== '' ? "selected-select" : ""}`}>
                                                        <Select
                                                            className={`${bucket !== '' ? "selected" : ''}`}
                                                            showSearch
                                                            placeholder="Select a bucket"
                                                            optionFilterProp="children"
                                                            onChange={onChange}
                                                            onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={selectedCloudAccount.buckets.map((item) => ({
                                                                label: item.name,
                                                                value: item.name,
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`row  ${bucket === '' ? "disabled" : ""}`}>
                                                <div className="col-md-4 align-self-center">
                                                    <p className="medium">Add a Prefix (Optional)</p>
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    <div className="account-detail">
                                                        <div className={`form-group ${prefix.length > 0 ? "help-circle" : ''}`}>
                                                            <input type="text" className="form-control" placeholder="Enter prefix" value={prefix} onChange={choosePrefix}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={`row ${showCloudDriveNameError || showCloudDriveNameLengthError ? "has-error" : null} ${bucket === '' ? "disabled" : ""}`}>
                                                <div className="col-md-4 align-self-center">
                                                    <p className="medium">Name your Sync</p>
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    <div className="account-detail">
                                                        <div className={`form-group ${isActive ? "help-circle" : ''}`}>
                                                            <input type="text" className="form-control" placeholder="Sync name" value={backupName}
                                                                   onChange={backupInstance}/>
                                                        </div>

                                                        {showCloudDriveNameError &&
                                                            <span className="red-error cloud-name">
                                                                That Sync name has already been used.
                                                            </span>
                                                        }
                                                        {showCloudDriveNameLengthError && <span className="red-error cloud-name">
                                                                32 characters max allowed
                                                            </span>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className={`row ${!isActive ? "disabled" : ''}`}>
                                                <div className="col-md-4 align-self-center">
                                                    <p className="medium">Choose Sync folder</p>
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    {selectedPath === '' &&
                                                        <div className="file-upload-btn" onClick={showModal}>
                                                            <div className="img-wrapper">
                                                                <img src={`${uploadIcon}`} alt="logo" className="icon"/>
                                                            </div>
                                                            <div className="content-wrapper">
                                                                Select folder
                                                            </div>
                                                        </div>
                                                    }
                                                    {selectedPath !== '' &&
                                                        <div className="change-cloud-wrapper selected-path">
                                                            <div className="choose-cloud-card" onClick={showModal}>
                                                                <div className="content-wrapper">
                                                                    <p className="medium" title={selectedPath}>{selectedPath}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className={`setup-drive-footer  ${backupName.length < 2 || selectedPath.length < 4 || showCloudDriveNameLengthError ? "disabled" : ""}`}>
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-8">
                                                <Button className="btn-style" loading={loading} onClick={startBackupInstance}>Launch New Sync</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                title="Title"
                onOk={handleOk}
                onCancel={handleCancel}
                className={`file-path-modal file-path-modal-wrapper ${!isWindows ? "mac-os" : ""}`}
                footer={[
                    <Popconfirm
                        title="Create a folder"
                        description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                        onConfirm={confirm}
                        cancelButtonProps={false}
                        icon={false}
                        okText="Create"
                        placement="topLeft"
                        getPopupContainer={trigger => trigger.parentNode}
                        className={`new-folder-wrapper ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}
                    >
                        <button className={`btn-style float-start  ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}>
                            New Folder
                        </button>
                    </Popconfirm>,
                    <button className="btn-style" onClick={handleOk}>
                        Select Folder
                    </button>,
                    <button className="btn-style margin-left" onClick={handleCancel}>
                        Cancel
                    </button>
                ]}
            >
                <div className="form dir-path-wrapper">
                    <div className="form-group choose-your-backup-title">
                        <h3>
                            <button className={`btn-style  ${selectedPath.length < 4 ? "disabled" : ""}`} type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                            Choose your Sync folder
                        </h3>
                    </div>

                </div>
                <div className="modal-body-wrapper">
                    <div className={`select-drive-wrapper  ${!isWindows ? "mac-os" : ""}`}>
                        <div className="row">
                            {isWindows &&
                                <div className="col-md-3">
                                    <p className="medium">Drive</p>
                                    <div className="form">

                                        {localDrives.length > 0 &&
                                            localDrives.map((item, i) => (
                                                <div className="form-group" key={i}>
                                                    <input type="radio" id={`select-drive-` + i} value={item} name="select-drive" onChange={getDirectories}/>
                                                    <label htmlFor={`select-drive-` + i}>
                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-9">
                                <div className="form">
                                    <div className={`account-detail radio radio-button  ${isWindows ? "windows-dir-wrapper" : ""}`}>
                                        <div className={`final-selected-cloud directory-list  ${isWindows ? "windows-dir-list-wrapper" : ""}`}>
                                            {localDir.length > 0 &&
                                                localDir.map((item, i) => (
                                                    <div className="form-group">
                                                        <label data-value={item} title={item.substring(item.lastIndexOf("\\") + 1, item.length)} className="account-card" key={i} onClick={changeDir}>
                                                            {isWindows &&
                                                                item.substring(item.lastIndexOf("\\") + 1, item.length)
                                                            }
                                                            {!isWindows &&
                                                                item.substring(item.lastIndexOf("/") + 1, item.length)
                                                            }
                                                            <i key={i} className='d-none'></i>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            {localDir.length < 1 && selectedPath !== "" &&
                                                <h3>There is no sub directory</h3>
                                            }
                                        </div>
                                    </div>

                                    <div className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Path:</div>
                                            <div className="col-9">
                                                <div className="folder-name" title={selectedPath}>
                                                    {selectedPath}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Folder:</div>
                                            <div className="col-9">
                                                <div className="folder-name">
                                                    {isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                                                    }
                                                    {!isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <AgentOffPopup/>
        </>
    )
}

export default NewBackupInstance;

