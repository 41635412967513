import React from "react";
import {Link} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg"
import {useAuth} from "../context/AuthContext";
import pageLogoWhite from "../assets/images/logo-dark.svg";
const ResetPassword = () => {
    const {isDark } = useAuth();
    return (
        <>
            <div className="login email-verification">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="form">
                        <h2 className="h2">Check your email</h2>
                        <p className="p">If an account exists for <br/>
                            {localStorage.getItem('userEmail')}, you will receive
                            an email to reset your password.</p>
                        <div className="form-group submit">
                            <Link to="/" className="btn-style blue max-width-large">Back to Log In</Link>
                        </div>
                        <p className="bottom-text mb-0">Didn't receive the email? <Link to="/forgot-password" className="bold">Click to resend</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;

