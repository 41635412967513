import React, {useEffect, useState, useRef} from "react";
import AiSearch from "../components/AiSearch/AiSearch";

const AiSearchPage = () => {
    return (
        <>
            <div id="main-content">
                <div className="container-fluid">
                    <AiSearch/>
                </div>
            </div>
        </>
    );
};

export default AiSearchPage;



