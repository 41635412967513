import {React} from 'react';
import {Button, Modal} from 'antd';

const DeleteModal = ({title, text, deleteOpen, deleteCancel, deleteSuccess, loading}) => {
    return (
        <><Modal title="Delete Group" className="manual-user-type" centered open={deleteOpen} onCancel={deleteCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>{title}</h3>
                </div>
                <div className="body">
                    <div className="delete-user">
                        <p>{text}</p>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={(e) => {
                        e.preventDefault();
                        deleteCancel()
                    }}>Cancel</a>
                    <Button className="btn-style blue" loading={loading} onClick={(e) => {
                        e.preventDefault();
                        deleteSuccess()
                    }}>Yes, Delete</Button>
                </div>
            </div>
        </Modal>
        </>
    )
}

export {DeleteModal}
