import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal} from 'antd';
import {useAuth} from "../../context/AuthContext";
import config from "../../config";
import searchIcon from "../../assets/images/ai-search/search-icon.svg";
import crossBlackIcon from "../../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../../assets/images/ai-search/info-white-icon.svg";
import fileIcon from "../../assets/images/ai-search/file-icon.svg";
import backArrowIcon from "../../assets/images/icons/back-arrow-black-icon.svg";
import aiCopyIcon from "../../assets/images/icons/ai-copy-icon.svg";
import clipsIcon from "../../assets/images/ai-search/clips-icon.svg";
import taggingIcon from "../../assets/images/ai-search/tagging-icon.svg";
import fileInfoIcon from "../../assets/images/ai-search/file-info-icon.svg";
import commnetIcon from "../../assets/images/ai-search/commnet-icon.svg";
import videoImg from "../../assets/images/ai-search/video-plyer-img.png";
import AvdioImg from "../../assets/images/ai-search/Audio Player.jpg";
import playIcon from "../../assets/images/ai-search/play-circle-icon.svg";
import globeIcon from "../../assets/images/ai-search/globe-gray-icon.svg";
import rightDrawerIcon from "../../assets/images/ai-search/open-drawer-right-icon.svg";
import plusWhiteIcon from "../../assets/images/ai-search/plus-white-icon.svg";
import playBlackIcon from "../../assets/images/ai-search/play-black-icon.svg";
import trashBlackIcon from "../../assets/images/ai-search/trash-black-icon.svg";
import downloadBlackIcon from "../../assets/images/ai-search/download-black-icon.svg";
import editBlackIcon from "../../assets/images/ai-search/edit-black-icon.svg";
import identifiedImg1 from "../../assets/images/ai-search/identified-object-img-1.png";
import identifiedImg2 from "../../assets/images/ai-search/identified-object-img-2.png";
import identifiedImg3 from "../../assets/images/ai-search/identified-object-img-3.png";
import identifiedImg4 from "../../assets/images/ai-search/identified-object-img-4.png";
import identifiedImg5 from "../../assets/images/ai-search/identified-object-img-5.png";
import identifiedImg6 from "../../assets/images/ai-search/identified-object-img-6.png";
import identifiedImg7 from "../../assets/images/ai-search/identified-object-img-7.png";
import identifiedImg8 from "../../assets/images/ai-search/identified-object-img-8.png";
import identifiedImg9 from "../../assets/images/ai-search/identified-object-img-9.png";
import identifiedImg10 from "../../assets/images/ai-search/identified-object-img-10.png";
import identifiedImg11 from "../../assets/images/ai-search/identified-object-img-11.png";
import crossRedCircleIcon from "../../assets/images/ai-search/cross-red-circle-icon.svg";
import userImg1 from "../../assets/images/ai-search/comment-user-img.png";

const {RangePicker} = DatePicker;
const AudioDetail = ({open,callback}) => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults
    } = useAuth();
    const [fileSidebar, setFileSidebar] = useState(false);
    const [selectFile, setSelectFile] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [cloudFilterOpen, setCloudFilterOpen] = useState(false);
    const [fileInfo, setFileInfo] = useState(false);
    const [aiComment, setAiComment] = useState(false);
    const [metaFocused, setMetaFocused] = useState(false);
    const [clipCreated, setClipCreated] = useState(false);
    const [aiTagging, setAiTagging] = useState(false);
    const searchInputRef = useRef(null);
    const metaSearchRef = useRef(null);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [isEditClip, setIsEditClip] = useState(false);
    const [isRightSidebar, setisRightSidebar] = useState(false);
    const [editClipModal, setEditClipModal] = useState(false);
    const [showSmallVideo, setShowSmallVideo] = useState(false);

    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);
    useEffect(() => {
        if (open) {
            setOpenDrawer(true)
        } else {
            setOpenDrawer(false)
        }
    }, [open]);

    useEffect(() => {
        if (openDrawer) {
            document.getElementById('scrollable').addEventListener('scroll', function () {
                var scroll = document.getElementById('scrollable').scrollTop;
                var TranscriptTop = document.getElementById('TranscriptTop').offsetTop;
                if (scroll > TranscriptTop) {
                    setShowSmallVideo(true)
                } else {
                    setShowSmallVideo(false)
                }
            });
        }
    }, [openDrawer]);

    const onCloseDrawer = () => {
        setOpenDrawer(false);
        callback()
    };
    const onEditClip = () => {
        setIsEditClip(true);
        setisRightSidebar(true);
        setAiComment(false);
        setFileInfo(false);
        setAiTagging(false);
    };
    const onEditClipClose = () => {
        setIsEditClip(false);
        setFileInfo(false);
        setisRightSidebar(false);
        setAiComment(false);
        setClipCreated(false);
    };
    const onClipCreated = () => {
        setClipCreated(true);
        setEditClipModal(false);
    };

    const onAiTagging = () => {
        setAiTagging(true);
        setIsEditClip(false);
        setFileInfo(false);
        setAiComment(false);
    };

    const onFileInfo = () => {
        setFileInfo(true);
        setIsEditClip(false);
        setAiTagging(false);
        setAiComment(false);
        setisRightSidebar(true);
    };
    const onAiComment = () => {
        setAiComment(true);
        setFileInfo(false);
        setIsEditClip(false);
        setAiTagging(false);
        setisRightSidebar(true);
    };

    return (
        <>
            <Drawer
                title="Basic Drawer"
                placement={'bottom'}
                closable={false}
                onClose={onCloseDrawer}
                open={openDrawer}
            >
                <div className="ai-drawer-main">
                    <div className={`ai-left-sidebar ${!isRightSidebar ? 'active' : ''}`}>
                        <a href="#" className="open-drawer" onClick={(e) => {
                            e.preventDefault();
                            setisRightSidebar(false)
                        }}>
                            <img src={rightDrawerIcon} alt="icon"/>
                        </a>
                        <div className="ai-sidebar-wrapper">
                            <div className="title-wrapper">
                                <a href="#" className="back-icon" onClick={onCloseDrawer}><img src={backArrowIcon} alt="icon"/></a>
                                <h3>Project_Update_Meeting_Ma...mp3</h3>
                            </div>
                            <div className="form ai-search">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your search"
                                    />
                                    <img src={searchIcon} alt="icon" className="icon"/>
                                </div>
                            </div>
                            <div className="ai-tags-wrapper">
                                <p className="title">Auto-tags</p>
                                <ul className="ai-tags">
                                    <li><a href="#">Vel mattis</a></li>
                                    <li><a href="#">Laculis</a></li>
                                    <li><a href="#">Quam</a></li>
                                    <li><a href="#">Elementum</a></li>
                                    <li><a href="#">Praesent</a></li>
                                    <li><a href="#">Ligula cursus</a></li>
                                    <li><a href="#">Quam</a></li>
                                    <li><a href="#">Molestie</a></li>
                                </ul>
                            </div>
                            <div className="ai-tags-wrapper">
                                <p className="title">Speakers</p>
                                <ul className="ai-tags">
                                    <li><a href="#">Terence Gusikowski</a></li>
                                    <li><a href="#">Joanne Baumbach</a></li>
                                    <li><a href="#">Jaime Watsica</a></li>
                                    <li><a href="#">Floyd Weissnat-Lynch</a></li>
                                    <li><a href="#">Monique Larson</a></li>
                                </ul>
                            </div>
                            <div className="ai-copy-wrapper">
                                <div className="copy-title">
                                    <p className="title">Keywords</p>
                                    <a href="#" className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                </div>
                                <div className="copy-body">
                                    <p>Lorem ipsum, dolor sit amet, consectetur
                                        adipiscind, sed tempor ante, eget tincidunt,
                                        nullam sed, nisi, vestibulum interdum. </p>
                                </div>
                            </div>
                            <div className="ai-copy-wrapper mb-0">
                                <div className="copy-title">
                                    <p className="title">Summary</p>
                                    <a href="#" className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                </div>
                                <div className="copy-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Sed tempor ante eget tincidunt efficitur. Nullam ac est
                                        sed nisi vestibulum interdum. Cras porta quam id nisl
                                        volutpat, vel cursus libero cursus. Integer ultricies auctor
                                        turpis, sed dictum justo faucibus at. Phasellus pretium tortor
                                        in urna fermentum, nec suscipit velit hendrerit. Lorem ipsum
                                        dolor sit amet, consectetur adipiscing elit. Sed tempor ante
                                        eget tincidunt efficitur. Cras porta quam id nisl volutpat,
                                        vel cursus libero cursus. Integer ultricies auctor turpis,
                                        sed dictum justo faucibus at. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`ai-right-sidebar ${isRightSidebar ? 'active' : ''}`}>
                        {isEditClip && (
                            <div className="ai-sidebar-wrapper">
                                <div className="title-header">
                                    <h3 className="title">Clips</h3>
                                    <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                </div>
                                {!clipCreated && (
                                    <div className="no-clip">
                                        <div className="no-clip-wrapper">
                                            <h3>No clips <strong>created</strong> yet.</h3>
                                            <p>Turn your empty space into a thriving oasis.</p>
                                            <a href="#" className="btn-style icon" onClick={() => setEditClipModal(true)}><img src={plusWhiteIcon} alt="icon"/>Create a clip</a>
                                        </div>
                                    </div>
                                )}
                                {clipCreated && (
                                    <div className="cutted-clipped">
                                        <div className="create-clip">
                                            <a href="#"><img src={plusWhiteIcon} alt="icon"/>Create a clip</a>
                                        </div>
                                        <div className="cutted-clipped-wrapper">
                                            <div className="clipped-card">
                                                <div className="clip-detail">
                                                    <div className="img-wrapper">
                                                        <img src={playBlackIcon} alt="icon" className="play-icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p>File Name - Clip 1</p>
                                                        <span className="count">00:06</span>
                                                    </div>
                                                </div>
                                                <div className="clip-options">
                                                    <a href="#"><img src={trashBlackIcon} alt="icon"/></a>
                                                    <a href="#"><img src={downloadBlackIcon} alt="icon"/></a>
                                                </div>
                                            </div>
                                            <div className="clipped-card">
                                                <div className="clip-detail">
                                                    <div className="img-wrapper">
                                                        <img src={playBlackIcon} alt="icon" className="play-icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p>File Name - Clip 1</p>
                                                        <span className="count">00:06</span>
                                                    </div>
                                                </div>
                                                <div className="clip-options">
                                                    <a href="#"><img src={trashBlackIcon} alt="icon"/></a>
                                                    <a href="#"><img src={downloadBlackIcon} alt="icon"/></a>
                                                </div>
                                            </div>
                                            <div className="clipped-card">
                                                <div className="clip-detail">
                                                    <div className="img-wrapper">
                                                        <img src={playBlackIcon} alt="icon" className="play-icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p>File Name - Clip 1</p>
                                                        <span className="count">00:06</span>
                                                    </div>
                                                </div>
                                                <div className="clip-options">
                                                    <a href="#"><img src={trashBlackIcon} alt="icon"/></a>
                                                    <a href="#"><img src={downloadBlackIcon} alt="icon"/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {fileInfo && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">File Name</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <ul className="btn-list">
                                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                                            <li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>
                                        </ul>
                                        <div className="information-wrapper">
                                            <p className="p bold title">General information</p>
                                            <ul className="info">
                                                <li>
                                                    <p className="small semi">Type:</p>
                                                    <p className="small">MP4 video</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Size:</p>
                                                    <p className="small">500 bytes</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Cloud:</p>
                                                    <p className="small">AWS</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Bucket:</p>
                                                    <p className="small">Bucket name</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Created:</p>
                                                    <p className="small">may 11th, 2023 at 09:14</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Modified:</p>
                                                    <p className="small">24 feb 2024 at 13:45 by Barry Allen</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Auto-tags</p>
                                            <ul className="tags">
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Comments</p>
                                            <div className="comment-wrapper">
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {aiComment && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">Comments</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <div className="tags-wrapper">
                                            <div className="select-wrapper">
                                                <Select
                                                    defaultValue="Frame Time"
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Start (0:00 to 2:00)', label: 'Start (0:00 to 2:00)'},
                                                        {value: 'Quarter (2:01 to 4:00)', label: 'Quarter (2:01 to 4:00)'},
                                                        {value: 'Middle (4:01 to 6:00)', label: 'Middle (4:01 to 6:00)'},
                                                        {value: 'Three-quarters (6:01 to 8:00)', label: 'Three-quarters (6:01 to 8:00)'},
                                                        {value: 'End (8:01: to 10:00)', label: 'End (8:01: to 10:00)'},
                                                    ]}
                                                />
                                            </div>
                                            <div className="comment-wrapper">
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="scrollable" className={`ai-main-content  padding-bottom-50  ${isRightSidebar ? 'right-active' : 'left-active'}`}>
                        <ul className="tool-menu">
                            <li><a href="#" onClick={onEditClip} className={`${isEditClip ? 'active' : ''}`}><img src={clipsIcon} alt="icon"/>Clips</a></li>
                            <li><a href="#" onClick={onFileInfo} className={`${fileInfo ? 'active' : ''}`}><img src={fileInfoIcon} alt="icon"/>File information</a></li>
                            <li><a href="#" onClick={onAiComment} className={`${aiComment ? 'active' : ''}`}><img src={commnetIcon} alt="icon"/>Comments</a></li>
                        </ul>
                        <div className="thumbnail-preview">
                            <img src={AvdioImg} className="height-auto" alt="img"/>
                        </div>
                        <div className="transcript-wrapper" id="TranscriptTop">
                            <div className="transcript-header">
                                {!aiTagging && (
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <div className="content">
                                                <p className="title">Transcript</p>
                                                <span>Audio to Text</span>
                                            </div>
                                        </div>
                                        <div className="col-6 align-self-center">
                                            <a href="#" className="translate"><img src={globeIcon} alt="icon"/>Translate</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="transcript-body">
                                {!aiTagging && (
                                    <ul className="transcript">
                                        <li>
                                            <p className="time-tag">00:01</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">00:05</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">00:07</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">00:20</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">00:55</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                        <li>
                                            <p className="time-tag">01:25</p>
                                            <p className="script">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Sed tempor ante eget tincidunt efficitur.
                                                Nullam ac est sed nisi vestibulum interdum. Cras porta quam
                                                id nisl volutpat, vel cursus libero cursus.ante eget tincidunt
                                                efficitur. </p>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default AudioDetail;



