import React, {useEffect, useState} from "react";
import {Checkbox, Select, Input, Spin, Button} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import syncIcon from "../assets/images/icons/sync-icon.svg";
import folderIcon from "../assets/images/icons/file-icon.svg";
import fileIcon from "../assets/images/icons/file-icon-file.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import AgentOffPopup from "../components/AgentOffPopup";

const AmoveSearch = () => {
    const {cloudAccounts, logout, agentIsRunning, setError499, setPopup499, prettySize, postDataNoResponse, setPopup500} = useAuth();
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [buckets, setBuckets] = useState([]);
    const [showPrefix, setShowPrefix] = useState(false);
    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(true);
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [bucket, setBucket] = useState("");
    const [continuationToken, setContinuationToken] = useState("");
    const [previousPath, setPreviousPath] = useState([]);
    const [result, setResult] = useState([]);

    const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;
    const onChangeCloud = (value) => {
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPath('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        var selectedAccount = cloudAccounts.filter(x => x.id === value)[0];
        setSelectedCloudAccount(selectedAccount);
        getBuckets(selectedAccount)
    };


    function getBuckets(selectedAccount) {
        postDataNoResponse("/Cloud/list_buckets", selectedAccount)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                    setBuckets(data);
                }
            );
    }


    const onChange = (value) => {
        setContinuationToken("");
        setPath('');
        setPreviousPath('');
        setBucket(value);
    };

    useEffect(() => {
        setContinuationToken("")
    }, [])
    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])

    useEffect(() => {
        if (path === "") {
            getListObjects()
        }
    }, [bucket])

    useEffect(() => {
        if (continuationToken === "") {
            setLoading(true);
            getListObjects()
        }
    }, [continuationToken])

    function reload() {
        setContinuationToken("");
    }

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }

        if (bucket !== '') {
            var dataToPost = {
                "account": {
                    "id": selectedCloudAccount.id,
                    "accountId": selectedCloudAccount.accountId,
                    "cloudType": selectedCloudAccount.cloudType,
                    "name": selectedCloudAccount.name,
                    "accessKey": selectedCloudAccount.accessKey,
                    "secretKey": selectedCloudAccount.secretKey,
                    "credentialsData": selectedCloudAccount.credentialsData,
                    "serviceUrl": selectedCloudAccount.serviceUrl,
                    "active": selectedCloudAccount.active,
                    "deleted": selectedCloudAccount.deleted
                },
                "request": {
                    "bucketName": bucket,
                    "path": pathName,
                    "continuationToken": ct,
                    "count": 50
                }
            }


            postDataNoResponse('/Cloud/list_objects', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                    }
                    if (ct !== '' && ct !== null) {
                        setResult(old => [...old, ...data.data]);

                    } else {
                        setResult(data.data);
                    }
                    setContinuationToken(data.continuationToken);
                    setLoading(false);
                    setLoadingMore(false)
                    setShowData(true)
                });
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    const onCheckbox = (e) => {
        setShowPrefix(e.target.checked)
        if (e.target.checked === false) {
            setContinuationToken("");
            setPrefix("")
        }
    };

    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');
        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPath(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPath(oldArray => [...oldArray, path]);
                }
                setPath(val)
            }
        }
    }

    function loadMore(e) {
        e.preventDefault()
        getListObjects(true)
    }

    function goBack() {
        setLoading(true)
        setContinuationToken("")
        setPath(previousPath[previousPath.length - 1])
        setPreviousPath((previousArr) => (previousArr.slice(0, -1)));
    }


    const updatePrefix = (e) => {
        setPrefix(e.target.value)
    };
    const listObject = (e) => {
        e.preventDefault();
        setPreviousPath([])
        setContinuationToken("")
        setPath("")
    };


    return (
        <>
            <div id="main-content" className={`activity-page ${!agentIsRunning ? "disabled" : ""}`}>
                <div className="container-fluid mb-4">
                    <h2>Amove Search</h2>
                </div>
                <div className="container-fluid">
                    <div className="search-body-wrapper">
                        <div className="source-account">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="source-account">Source Account</label>
                                        <Select
                                            showSearch
                                            id="source-account"
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            onChange={onChangeCloud}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={cloudAccounts.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="source-account">Source Bucket</label>
                                        <Select
                                            showSearch
                                            id="source-account"
                                            placeholder="Select a bucket"
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            value={bucket || undefined}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={buckets.map((item) => ({
                                                label: item.name,
                                                value: item.name,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="prefix-list">
                                        <div className="form-group">
                                            <Checkbox onChange={onCheckbox}></Checkbox>
                                        </div>

                                        <div className={`form-group prefix-field  ${!showPrefix ? "disabled" : ""}`}>
                                            <Input type="text" className="form-control" value={prefix} id="prefix" placeholder="Enter Prefix to List" onChange={updatePrefix}/>
                                        </div>
                                        <div className={`form-group prefix-field  ${!showPrefix ? "disabled" : ""}`}>
                                            <a href="#" className="btn-style" onClick={listObject}>List Objects</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showData &&
                            <div className="sync-objects-wrapper">
                                <div className="sync-back">
                                    {previousPath.length > 0 &&
                                        <button className="btn-style" type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                                    }
                                    {path.length > 1 &&
                                        <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                    }
                                    {!loading &&
                                        <div className="sync-img">
                                            <img src={`${syncIcon}`} alt="syncIcon" onClick={reload}/>
                                        </div>
                                    }
                                </div>
                                <div className="objects-card-wrapper">
                                    {loading &&
                                        <div className="loading-wrapper">
                                            <Spin indicator={antIcon} loading/>
                                        </div>
                                    }
                                    {!loading && result.length === 0 &&
                                        <div className="loading-error-wrapper">
                                            <span>There are no Objects.</span>
                                        </div>
                                    }
                                    {!loading && result.map((item, i) => (
                                        <div className="objects-card" key={i}>
                                            <label className="row-data" data-value={item.path} data-folder={item.type} onClick={changeObject} role="button"></label>
                                            <div className="img-wrapper">
                                                {item.type === 2 &&
                                                    <img src={`${folderIcon}`} alt="folderIcon"/>
                                                }
                                                {item.type === 1 &&
                                                    <img src={`${fileIcon}`} alt="fileIcon"/>
                                                }
                                            </div>
                                            <p className='title'>{item.name}</p>
                                            {item.type === 1 &&
                                                <p>{prettySize(item.size)}</p>
                                            }
                                        </div>
                                    ))}
                                    {!loading && continuationToken != null && result.length > 0 && bucket !== '' &&

                                        <div className="text-center">
                                            <Button className="btn-style" onClick={loadMore} loading={loadingMore}>
                                                Load More
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <AgentOffPopup/>
        </>
    )
}

export default AmoveSearch;

