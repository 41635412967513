import {React} from 'react';
import {Modal} from "antd";
import pageLogo from "../assets/images/logo.svg";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
const BillingPopup = () => {
    const {
        billingPopupOpen,
        setBillingPopupOpen
    } = useAuth();
    const navigate = useNavigate();
    return (
        <>
            <Modal title="Assign Projects" className="manual-user-type billing-popup" centered open={billingPopupOpen} onOk={() => {
                setBillingPopupOpen(false)
            }} onCancel={() => {
                setBillingPopupOpen(false)
            }}>
                <div className="manual-user-modal-wrapper">
                    <div className="header text-center">
                        <img src={pageLogo} alt="logo"/>
                        <h3 className="justify-content-center not-bold">Give your team access to <br/>
                            exactly what they need</h3>
                        <p>Please choose an Amove Plan and gain <br/>
                            instant access to all Amove features.</p>
                    </div>
                    <div className="footer text-center justify-content-center">

                        <a href="#" className="btn-style  small" onClick={(e) => {
                            e.preventDefault();
                            setBillingPopupOpen(false)
                            navigate("/subscribe");
                        }}>Billing</a>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export {BillingPopup}
