import React, {useEffect, useState, useCallback} from "react";
import {DashboardHeader} from "../components/DashboardHeader";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
import config from "../config";
import awsLogo from "../assets/images/icons/onboard-aws-icon.svg";
import azureLogo from "../assets/images/icons/onboard-azure-icon.svg";
import googleCloudLogo from "../assets/images/icons/onboard-google-cloud-icon.svg";
import ibm from "../assets/images/IBM-icon-new.svg";
import storjLogo from "../assets/images/icons/onboard-storj-icon.svg";
import Backblaze from "../assets/images/blackblaz.svg";
import wasabiCloudLogo from "../assets/images/icons/onboard-wasabi-icon.svg";
import s3Logo from "../assets/images/any-s3-logo.svg";
import ovhLogo from "../assets/images/OVH-icon-new.svg";
import DigitalOcean from "../assets/images/icons/onboard-digital-ocean-icon.svg";
import Oracle from "../assets/images/oracle-corporation-logo.svg";
import lyveLogo from "../assets/images/seagate.svg";
import syncIcon from "../assets/images/icons/sync-icon.svg";
import folderIcon from "../assets/images/icons/file-icon.svg";
import fileIconFile from "../assets/images/icons/file-icon-file.svg";
import uploadNewIcon from "../assets/images/icons/upload-icon-new.svg";
import linkIcon from "../assets/images/icons/link-icon.svg";
import downloadIcon from "../assets/images/icons/download-icon.svg";
import deleteIcon from "../assets/images/icons/delete-icon.svg";
import dotsIcon from "../assets/images/icons/3-dots-icon.svg";

import {Popconfirm, Select, Modal, Tooltip, Checkbox, Spin, Button, notification} from "antd";
import {Link, NavLink, useLocation} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import uploadIcon from "../assets/images/icons/upload-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import pageLogo from "../assets/images/logo.svg";


const {Option} = Select;

const AmoveMigration = () => {
    const {
        cloudAccounts, logout, getIcon,
        getDataWithTokenPOST, agentIsRunning, setError499, setPopup499, prettySize, getFormatedDate, userStatus, getCloudAccounts, setPopup500, user
    } = useAuth();
    const navigate = useNavigate();
    const [sourceAccount, setSourceAccount] = useState('');
    const [destinationAccount, setDestinationAccount] = useState('');
    const [bucketNumber, setBucketNumber] = useState('');
    const [objectNumber, setObjectNumber] = useState('');
    const [objectSize, setObjectSize] = useState('');
    const [totalTbs, setTotalTbs] = useState('');
    const [buckets, setBuckets] = useState([]);
    const [filteredBuckets, setFilteredBuckets] = useState([]);
    const [bucket, setBucket] = useState("");
    const [continuationToken, setContinuationToken] = useState("");
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [previousPathFiles, setPreviousPathFiles] = useState([]);
    const [listView, setListView] = useState(true);
    const [showPrefix, setShowPrefix] = useState(false);
    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingTransferBucket, setLoadingTransferBucket] = useState(false);
    const [loadingTransferSelected, setLoadingTransferSelected] = useState(false);
    const [loadingMore, setLoadingMore] = useState(true);
    const [result, setResult] = useState([]);
    const [tooptipVal, setTooptipVal] = useState(false);
    const [checkedItems, setCheckedItems] = useState(false);
    const [tooptipIndex, setTooptipIndex] = useState(false);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});

    const [sourceAccountDestination, setSourceAccountDestination] = useState('');
    const [destinationAccountDestination, setDestinationAccountDestination] = useState('');
    const [bucketNumberDestination, setBucketNumberDestination] = useState('');
    const [objectNumberDestination, setObjectNumberDestination] = useState('');
    const [objectSizeDestination, setObjectSizeDestination] = useState('');
    const [totalTbsDestination, setTotalTbsDestination] = useState('');
    const [bucketsDestination, setBucketsDestination] = useState([]);
    const [filteredBucketsDestination, setFilteredBucketsDestination] = useState([]);
    const [bucketDestination, setBucketDestination] = useState("");
    const [continuationTokenDestination, setContinuationTokenDestination] = useState("");
    const [prefixDestination, setPrefixDestination] = useState("");
    const [pathDestination, setPathDestination] = useState("");
    const [previousPathFilesDestination, setPreviousPathFilesDestination] = useState([]);
    const [listViewDestination, setListViewDestination] = useState(true);
    const [showPrefixDestination, setShowPrefixDestination] = useState(false);
    const [showDataDestination, setShowDataDestination] = useState(false);
    const [loadingDestination, setLoadingDestination] = useState(true);
    const [loadingMoreDestination, setLoadingMoreDestination] = useState(true);
    const [resultDestination, setResultDestination] = useState([]);
    const [tooptipValDestination, setTooptipValDestination] = useState(false);
    const [tooptipIndexDestination, setTooptipIndexDestination] = useState(false);
    const [transferPopupOpen, setTransferPopupOpen] = useState(false);
    const [migrationPopupOpen, setMigrationPopupOpen] = useState(false);
    const [selectedCloudAccountDestination, setSelectedCloudAccountDestination] = useState({});
    const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;


    const onChange = (value) => {
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setBucket(value);
        setCheckedItems(false)
    };
    const onChangeDestination = (value) => {
        setContinuationTokenDestination("");
        setPathDestination('');
        setPreviousPathFilesDestination('');
        setBucketDestination(value);
    };

    function getBuckets(id, des = false) {
        if (user?.migration) {
            getDataWithTokenPOST("/api/desktop/list_buckets", {
                "cloudAccountId": id,
                "includeRegion": true
            }).then((res) => Promise.all([res.status, res.json()]))
                .then(([status, bucket]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true);
                            setLoading(false);
                            return;
                        }
                        if (status === 499) {
                            var errorType = bucket.type;
                            setError499(bucket.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (des) {
                            setBucketsDestination(bucket);
                            setFilteredBucketsDestination(bucket);
                        } else {
                            setBuckets(bucket);
                            setFilteredBuckets(bucket);
                        }
                    }
                );
        }
    }

    function loadMore(e) {
        e.preventDefault()
        getListObjects(true)
    }

    function loadMoreDestination(e) {
        e.preventDefault()
        getListObjectsDestination(true)
    }

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }
        if (bucket !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccount.id,
                "bucketName": bucket,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }

            if (user?.migration) {
                getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            setLoading(false);
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (ct !== '' && ct !== null) {
                            setResult(old => [...old, ...data.data]);

                        } else {
                            setResult(data.data);
                        }
                        setContinuationToken(data.continuationToken);
                        setLoading(false);
                        setLoadingMore(false)
                        setShowData(true)
                    });
            }
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    function getListObjectsDestination(loadmore = false) {

        var pathName = '/'
        if (prefixDestination !== '' && showPrefixDestination) {
            pathName = prefixDestination;
        }

        if (pathDestination !== '') {
            pathName = pathDestination;
        }
        var ct = ''
        if (continuationTokenDestination !== '' && loadmore) {
            ct = continuationTokenDestination;
            setLoadingMoreDestination(true)
        } else {
            setLoadingDestination(true);
        }

        if (bucketDestination !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccountDestination.id,
                "bucketName": bucketDestination,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }


            if (user?.migration) {
                getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (ct !== '' && ct !== null) {
                            setResultDestination(old => [...old, ...data.data]);
                        } else {
                            setResultDestination(data.data);
                        }

                        setContinuationTokenDestination(data.continuationToken);
                        setLoadingDestination(false);
                        setLoadingMoreDestination(false)
                        setShowDataDestination(true)
                    });
            }
        } else {
            setLoadingDestination(false);
            setLoadingMoreDestination(false)
        }
    }

    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');

        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPathFiles(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFiles(oldArray => [...oldArray, path]);
                }
                setPath(val)
            }
        }
    }

    function changeObjectDestination(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');

        if (e.detail == 2) {
            if (type == 2) {
                if (pathDestination === '') {
                    setPreviousPathFilesDestination(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFilesDestination(oldArray => [...oldArray, pathDestination]);
                }
                setPathDestination(val)
            }
        }
    }

    function goBack() {
        setLoading(true)
        setContinuationToken("")
        setPath(previousPathFiles[previousPathFiles.length - 1])
        setPreviousPathFiles((previousArr) => (previousArr.slice(0, -1)));
    }

    function goBackDestination() {
        setLoadingDestination(true)
        setContinuationTokenDestination("")
        setPathDestination(previousPathFilesDestination[previousPathFilesDestination.length - 1])
        setPreviousPathFilesDestination((previousArr) => (previousArr.slice(0, -1)));
    }

    function reload() {
        setContinuationToken("");
    }

    function reloadDestination() {
        setContinuationTokenDestination("");
    }

    useEffect(() => {
        if (!user?.migration) {
            setMigrationPopupOpen(true)
        }
    }, [user])
    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])

    useEffect(() => {
        if (path === "") {
            getListObjects()
        }
    }, [bucket])

    useEffect(() => {
        if (continuationToken === "") {
            setLoading(true);
            getListObjects()
        }
    }, [continuationToken])

    useEffect(() => {
        if (pathDestination !== "") {
            getListObjectsDestination()
        }
    }, [pathDestination])

    useEffect(() => {
        if (pathDestination === "") {
            getListObjectsDestination()
        }
    }, [bucketDestination])

    useEffect(() => {
        if (continuationTokenDestination === "") {
            setLoadingDestination(true);
            getListObjectsDestination()
        }
    }, [continuationTokenDestination])


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudAccounts.length === 0) {
                if (user?.migration) {
                    getCloudAccounts()
                }
            }
        }
    }, [userStatus]);

    const onSourceAccount = (value) => {
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPathFiles('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        setCheckedItems(false)
        setResult([])
        var selectedAccount = cloudAccounts.filter(x => x.id === value)[0];
        setSelectedCloudAccount(selectedAccount);
        getBuckets(value)
        setSourceAccount(value);
    };
    const onSourceAccountDestination = (value) => {
        setContinuationTokenDestination("");
        setBucketDestination('');
        setPathDestination('');
        setPreviousPathFilesDestination('');
        setResultDestination([])
        setLoadingDestination(false);
        setLoadingMoreDestination(false)
        setShowDataDestination(false)
        var selectedAccount = cloudAccounts.filter(x => x.id === value)[0];
        setSelectedCloudAccountDestination(selectedAccount);
        getBuckets(value, true)
        setSourceAccountDestination(value);
    };
    const onDestinationAccount = (value) => {
        setDestinationAccount(value)
    };

    const onBucketNumber = (event) => {
        setBucketNumber(event.target.value)
    };

    const onObjectNumber = (event) => {
        setObjectNumber(event.target.value)
    };
    const onObjectSize = (event) => {
        setObjectSize(event.target.value)
    };
    const onTotalTbs = (event) => {
        setTotalTbs(event.target.value)
    };

    const updateCheck = (index, checked) => {
        let newArr = [...result];
        newArr[index].checked = !checked
        setResult(newArr);
    }
    const updateCheckAll = (val) => {
        let newArr = [...result];
        newArr.forEach(item => item.checked = val.target.checked)
        setResult(newArr);
        if(val.target.checked){
            setCheckedItems(true)
        } else {
            setCheckedItems(false)
        }
    }
    const transferBucket = () => {
        setLoadingTransferBucket(true)
        var dataToPost = {
            "sourceCloudAccountId": sourceAccount,
            "sourceBucket": bucket,
            "sourcePath": "/",
            "destinationCloudAccountId": sourceAccountDestination,
            "destinationBucket": bucketDestination,
            "destinationPath": pathDestination,
            "allowSkip": true
        }

        if (user?.migration) {
            getDataWithTokenPOST("/api/transfer/transfer", dataToPost)
                .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
                if (res.status === 200) {
                    setLoadingTransferBucket(false)
                    let newArr = [...result];
                    newArr.forEach(item => item.checked = false)
                    setResult(newArr);
                    setTransferPopupOpen(true)
                    setCheckedItems(false)

                    let newArrDes = [...resultDestination];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArrDes.filter((item) => item.name === newArr[i].name).length === 0) {
                            newArrDes.push(newArr[i])
                        }
                    }
                    setResultDestination(newArrDes)
                }
                if (data.status === 401) {
                    // logout();
                    //  return;
                }

            })
        }
    }
    const transferSelected = () => {
        setLoadingTransferSelected(true)
        if (user?.migration) {

            let newArrDes = [...resultDestination];
            let newArr = [...result];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].checked){
                    if (newArrDes.filter((item) => item.name === newArr[i].name).length === 0) {
                        newArrDes.push(newArr[i])
                    }
                    var dataToPost = {
                        "sourceCloudAccountId": sourceAccount,
                        "sourceBucket": bucket,
                        "sourcePath": newArr[i].path,
                        "destinationCloudAccountId": sourceAccountDestination,
                        "destinationBucket": bucketDestination,
                        "destinationPath": pathDestination,
                        "allowSkip": true
                    }
                    getDataWithTokenPOST("/api/transfer/transfer", dataToPost)
                        .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
                        if (res.status === 200) {
                        }
                    })
                }
                newArr[i].checked = false;
                if(i === (newArr.length-1)) {
                    console.log("last item")
                    setResult(newArr);
                    setResultDestination(newArrDes)
                    setTransferPopupOpen(true)
                    setCheckedItems(false)
                    setLoadingTransferSelected(false)
                }
            }
        }
    }

    const submitMigration = () => {
        /*console.log(sourceAccount, "sourceAccount");
        console.log(destinationAccount, "destinationAccount");
        console.log(bucketNumber, "bucketNumber")
        console.log(objectNumber, "objectNumber")
        console.log(objectSize, "objectSize")
        console.log(totalTbs, "totalTbs")*/
    };

    return (
        <>
            <div id="main-content" className={`activity-page c2c-migration-page ${user?.migration ? "" : "disabled"}`}>
                <div className="container-fluid mb-4">
                    <h2>Cloudflyer
                        <Link to="/transfer-status" className="btn-style transfer-status-btn orange-btn">
                            Transfer Status</Link></h2>
                </div>
                <div className="container-fluid">
                    <DragDropContext onDragEnd={(event) => {
                        if (event.destination.droppableId === "destination") {
                            if(result.filter((item)=> item.checked).length > 0) {
                                transferSelected()
                            }else{
                            var dataToPost = {
                                "sourceCloudAccountId": sourceAccount,
                                "sourceBucket": bucket,
                                "sourcePath": result[event.source.index].path,
                                "destinationCloudAccountId": sourceAccountDestination,
                                "destinationBucket": bucketDestination,
                                "destinationPath": pathDestination,
                                "allowSkip": true
                            }
                            console.log(event.destination.index)
                            console.log(result[event.source.index])
                            var newItem = result[event.source.index]
                            delete newItem.checked
                            if (resultDestination.filter((item) => item.name === result[event.source.index].name).length < 1) {
                                const newResultDestination = [
                                    // Items before the insertion point:
                                    ...resultDestination.slice(0, event.destination.index),
                                    // New item:
                                    newItem,
                                    // Items after the insertion point:
                                    ...resultDestination.slice(event.destination.index)
                                ];

                                setResultDestination(newResultDestination);
                            }

                            if (user?.migration) {
                                getDataWithTokenPOST("/api/transfer/transfer", dataToPost)
                                    .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
                                    if (res.status === 200) {
                                        setTransferPopupOpen(true)
                                    }
                                    if (data.status === 401) {
                                        // logout();
                                        //  return;
                                    }

                                })
                            }
                        }
                        }

                    }}>
                        <div className="row migration-page-wrapper">
                            <div className="col-md-6">
                                <h3>Source</h3>
                                <div className="amove-migration-wrapper">
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="sourceAccount">Cloud Account</label>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select an account"
                                                        optionFilterProp="label"
                                                        optionLabelProp="label"
                                                        onChange={onSourceAccount}
                                                        onSearch={onSourceAccount}
                                                    >
                                                        {cloudAccounts.length > 0 &&
                                                            cloudAccounts.map((item, i) => (
                                                                <>
                                                                    {(item.cloudType === 1 || item.cloudType === 2 || item.cloudType === 8) &&
                                                                        <Option key={item.id} value={item.id} label={item.name}>
                                                                            <div className="onboard-brand-select">
                                                                                <div className="icon">
                                                                                    {getIcon(item.cloudType, item.cloudIconType)}
                                                                                </div>
                                                                                <p>{item.name}</p>
                                                                            </div>
                                                                        </Option>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group  ${sourceAccount !== '' ? "" : "disabled"}`}>
                                                    <label htmlFor="sourceAccount">Bucket</label>
                                                    <Select
                                                        className={` ${bucket !== '' ? "selected" : ''}`}
                                                        showSearch
                                                        placeholder="Select a bucket"
                                                        optionFilterProp="children"
                                                        onChange={onChange}
                                                        value={bucket || undefined}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={buckets.map((item) => ({
                                                            label: item.name,
                                                            value: item.name,
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="search-file-wrapper">
                                            <div className="upload-file-detail-wrapper">
                                                <div className="upload-body">
                                                    {
                                                        (listView === true) &&
                                                        <div className="table-wrapper">

                                                            <div className="sync-back">
                                                                {previousPathFiles.length > 0 &&
                                                                    <button className="btn-style" type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                                                                }
                                                                {path.length > 1 &&
                                                                    <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                                                }
                                                                {!loading &&
                                                                    <div className="sync-img">
                                                                        <img src={`${syncIcon}`} alt="syncIcon" onClick={reload}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {result.length > 0 &&
                                                                <div className="table-header migration">
                                                                    <div className="row">
                                                                        <div className='col-md-9 col-9'>
                                                                            <span className="padding-left">{result.length > 0 && <Checkbox checked={checkedItems} onChange={updateCheckAll}></Checkbox>}Name</span></div>
                                                                        <div className="col-md-3 col-3 text-center">Size
                                                                        </div>
                                                                        {/* <div className="col-md-2 text-end"><span className="date-modified">Date Modified</span></div>*/}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="table-data migration-page">

                                                                {loading &&
                                                                    <div className="loading-wrapper">
                                                                        <Spin indicator={antIcon} loading/>
                                                                    </div>
                                                                }
                                                                {!loading && result.length === 0 &&
                                                                    <div className="loading-error-wrapper">
                                                                        <span>There are no Objects.</span>
                                                                    </div>
                                                                }
                                                                <Droppable droppableId="source">
                                                                    {provided => (
                                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                            {!loading && result.map((item, index) => (
                                                                                <Draggable draggableId={'item-' + index} index={index}>
                                                                                    {(provided, snapshot) =>
                                                                                    {
                                                                                        return (
                                                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                                                            <div className={`row ${item.type === 1 ? 'hover-row' : 'folder-row'} ${snapshot.isDragging ? 'dragging' : ''} ${(tooptipVal === true && tooptipIndex === index) ? 'active' : ''} ${(tooptipVal === true && tooptipIndex !== index) ? 'disabled' : ''}`} key={index}>
                                                                                                <div className="col-md-9 col-9 name migration" title={item.name}>
                                                                                                    {item.type === 2 &&
                                                                                                        <label className="row-data" data-value={item.path} data-folder={item.type} onClick={changeObject} role="button"></label>
                                                                                                    }
                                                                                                    <div className="img-wrapper">
                                                                                                        {item.type === 2 &&
                                                                                                            <img src={`${folderIcon}`} alt="folderIcon"/>
                                                                                                        }
                                                                                                        {item.type === 1 &&
                                                                                                            <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                                                                        }

                                                                                                        <Checkbox checked={item.checked} onChange={() => (updateCheck(index, item.checked))}></Checkbox>

                                                                                                    </div>
                                                                                                    <span>{item.name}</span>
                                                                                                </div>
                                                                                                <div className="col-md-3 col-3 text-center align-self-center">
                                                                                                    {item.type === 1 &&
                                                                                                        prettySize(item.size)
                                                                                                    }
                                                                                                    {snapshot.isDragging && result.filter((item) => item.checked).length > 0 &&
                                                                                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange">{result.filter((item) => item.checked).length}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    )}
                                                                                    }
                                                                                </Draggable>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </Droppable>

                                                                <div className="text-center">
                                                                    {!loading && continuationToken != null && result.length > 0 && bucket !== '' &&

                                                                        <div className={`text-center load-more ${tooptipVal === true ? 'disabled' : ''}`}>
                                                                            <Button className="btn-style" onClick={loadMore} loading={loadingMore}>
                                                                                Load More
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            {result.length > 0 &&
                                                                <div className={`table-footer text-center ${bucketDestination === "" ? "disabled" : ""}`}>
                                                                    <Button loading={loadingTransferBucket} className="btn-style orange-btn transparent transfer-status-btn" onClick={transferBucket}>
                                                                        Transfer Bucket</Button>
                                                                   {/* {result.filter((item)=> item.checked).length > 0 &&
                                                                    <Button loading={loadingTransferSelected} className="btn-style orange-btn transparent transfer-status-btn" onClick={transferSelected}>
                                                                        Transfer Selected</Button>
                                                                    }*/}
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                    <div className={`form-group  ${sourceAccount !== '' ? "" : "disabled"}`}>
                                        <label htmlFor="sourceAccount">Destination Account</label>
                                        <Select
                                            showSearch
                                            id="destination-account"
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            onChange={onDestinationAccount}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={cloudAccounts.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                        />
                                    </div>
                                    <div className={`form-group  ${destinationAccount !== '' ? "" : "disabled"}`}>
                                        <label htmlFor="objects">Total number of buckets</label>
                                        <input type="text" className="form-control" onChange={onBucketNumber} placeholder="10 Buckets"/>
                                    </div>
                                    <div className={`form-group  ${bucketNumber !== '' ? "" : "disabled"}`}>
                                        <label htmlFor="objects">Total number of objects</label>
                                        <input type="text" className="form-control" onChange={onObjectNumber} placeholder="1M Objects"/>
                                    </div>
                                    <div className={`form-group  ${objectNumber !== '' ? "" : "disabled"}`}>
                                        <label htmlFor="objectSize">Largest object size</label>
                                        <input type="text" className="form-control" onChange={onObjectSize} placeholder="50 GB"/>
                                    </div>
                                    <div className={`form-group  ${objectSize !== '' ? "" : "disabled"}`}>
                                        <label htmlFor="totalTb">Total TBs</label>
                                        <input type="text" className="form-control" onChange={onTotalTbs} placeholder="500 TB"/>
                                    </div>
                                    <div className={`form-group submit ${totalTbs !== '' ? "" : "disabled"}`}>
                                        <input type="submit" onClick={submitMigration} value="Submit for Migration Review" className="btn-style"/>
                                    </div>
                                    <div className="form-group text-center text-below">
                                        <p>
                                            Next step: Amove will review migration details and deliver project plans <br/>
                                            for final approval from your account Admin.</p>
                                    </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Destination</h3>
                                <div className="amove-migration-wrapper">
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="sourceAccount">Cloud Account</label>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select an account"
                                                        optionFilterProp="label"
                                                        optionLabelProp="label"
                                                        onChange={onSourceAccountDestination}
                                                        onSearch={onSourceAccountDestination}
                                                    >
                                                        {cloudAccounts.length > 0 && user?.migration &&
                                                            cloudAccounts.map((item, i) => (
                                                                <>
                                                                    {(item.cloudType === 2 || item.cloudType === 8 || (selectedCloudAccount.cloudType !== 8 && selectedCloudAccount.cloudType !== 2 && item.cloudType === 1)) &&
                                                                        <Option key={item.id} value={item.id} label={item.name}>
                                                                            <div className="onboard-brand-select">
                                                                                <div className="icon">
                                                                                    {getIcon(item.cloudType, item.cloudIconType)}
                                                                                </div>
                                                                                <p>{item.name}</p>
                                                                            </div>
                                                                        </Option>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group  ${sourceAccountDestination !== '' ? "" : "disabled"}`}>
                                                    <label htmlFor="sourceAccount">Bucket</label>
                                                    <Select
                                                        className={` ${bucketDestination !== '' ? "selected" : ''}`}
                                                        showSearch
                                                        placeholder="Select a bucket"
                                                        optionFilterProp="children"
                                                        onChange={onChangeDestination}
                                                        value={bucketDestination || undefined}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={bucketsDestination.map((item) => ({
                                                            label: item.name,
                                                            value: item.name,
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="search-file-wrapper">
                                            <div className="upload-file-detail-wrapper">
                                                <div className="upload-body">
                                                    {
                                                        (listView === true) &&
                                                        <div className="table-wrapper">
                                                            <div className="sync-back">
                                                                {previousPathFilesDestination.length > 0 &&
                                                                    <button className="btn-style" type="button" onClick={goBackDestination}><i className="fa fa-arrow-left"></i></button>
                                                                }
                                                                {pathDestination.length > 1 &&
                                                                    <b className='searchPath'>Path: <span title={pathDestination}>{pathDestination}</span></b>
                                                                }
                                                                {!loadingDestination &&
                                                                    <div className="sync-img">
                                                                        <img src={`${syncIcon}`} alt="syncIcon" onClick={reloadDestination}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {resultDestination.length > 0 &&
                                                                <div className="table-header migration">
                                                                    <div className="row">
                                                                        <div className='col-md-9'>
                                                                            <span>Name</span></div>
                                                                        <div className="col-md-3 text-center">Size</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="table-data migration-page">

                                                                <Droppable droppableId="destination">
                                                                    {provided => (
                                                                        <div ref={provided.innerRef} {...provided.droppableProps} className="dropzone">
                                                                            {loadingDestination &&
                                                                                <div className="loading-wrapper">
                                                                                    <Spin indicator={antIcon} loading/>
                                                                                </div>
                                                                            }
                                                                            {!loadingDestination && resultDestination.length === 0 &&
                                                                                <div className="loading-error-wrapper">
                                                                                    <span>There are no Objects.</span>
                                                                                </div>
                                                                            }
                                                                            {!loadingDestination && resultDestination.map((item, index) => (
                                                                                <Draggable draggableId={'item2-' + index} index={index} isDragDisabled={true}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                            <div className={`row  ${item.type === 1 ? 'hover-row' : 'folder-row'} ${(tooptipValDestination === true && tooptipIndexDestination === index) ? 'active' : ''} ${(tooptipValDestination === true && tooptipIndexDestination !== index) ? 'disabled' : ''}`} key={index}>
                                                                                                <div className="col-md-9 name migration-right" title={item.name}>
                                                                                                    {item.type === 2 &&
                                                                                                        <label className="row-data" data-value={item.path} data-folder={item.type} onClick={changeObjectDestination} role="button"></label>
                                                                                                    }
                                                                                                    <div className="img-wrapper">
                                                                                                        {item.type === 2 &&
                                                                                                            <img src={`${folderIcon}`} alt="folderIcon"/>
                                                                                                        }
                                                                                                        {item.type === 1 &&
                                                                                                            <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <span>{item.name}</span>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center align-self-center">
                                                                                                    {item.type === 1 &&
                                                                                                        prettySize(item.size)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>

                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </Droppable>

                                                                <div className="text-center">
                                                                    {!loadingDestination && continuationTokenDestination != null && resultDestination.length > 0 && bucketDestination !== '' &&
                                                                        <div className={`text-center load-more ${tooptipValDestination === true ? 'disabled' : ''}`}>
                                                                            <Button className="btn-style" onClick={loadMoreDestination} loading={loadingMoreDestination}>
                                                                                Load More
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </div>

            <Modal title="Assign Projects" className="manual-user-type billing-popup migration-popup" centered open={transferPopupOpen} onOk={() => {
                setTransferPopupOpen(false)
            }} onCancel={() => {
                setTransferPopupOpen(false)
            }}>
                <div className="manual-user-modal-wrapper">
                    <div className="header text-center">
                        <img src={toastIcon} alt="toastIcon"/>
                        <h3 className="justify-content-center not-bold">Transfer Started</h3>
                        <p>Your transfer has successfully started. <br/>
                            Please check the transfer status.</p>
                    </div>
                    <div className="footer text-center justify-content-center">
                        <a href="#" className="btn-style  small" onClick={(e) => {
                            e.preventDefault();
                            setTransferPopupOpen(false)
                            navigate("/transfer-status");
                        }}>Transfer Status</a>
                    </div>
                </div>
            </Modal>

            <Modal title="Cloudflyer Migrations" className="manual-user-type billing-popup migration-popup" centered open={migrationPopupOpen} onOk={() => {
                setMigrationPopupOpen(false)
            }} onCancel={() => {
                setMigrationPopupOpen(false)
            }}>
                <div className="manual-user-modal-wrapper">
                    <div className="header text-center">
                        <h3 className="justify-content-center not-bold">Cloudflyer Migrations</h3>
                        <p>Please contact us to let us know <br/>
                            details about your migration project.</p>
                    </div>
                    <div className="footer text-center justify-content-center">
                        <a href="https://amove.io/contact-us/" className="btn-style small" target={"_blank"}>Contact Us</a>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AmoveMigration;

