import {React} from 'react';
import {NavLink, Link} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
const ConfigurationsMenu = () => {
    const {
        user
    } = useAuth();
    return (
        <>
            <ul className="tabs">
                <li><NavLink to="/users">Users</NavLink></li>
                <li><NavLink to="/teams">Teams</NavLink></li>
                <li><NavLink to="/projects">Projects</NavLink></li>
                <li><NavLink to="/shared-cloud-drive">Drives</NavLink></li>
            </ul>
        </>
    )
}

export {ConfigurationsMenu}
