import React, {useState, useEffect} from "react";
import pageLogo from "../assets/images/logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import popupImage from "../assets/images/popup-image.jpg";
import {Modal} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";

const AgentOffPopup = () => {
    const {agentIsRunning, token, isDark, checkAgent, agentPopupOpen, setAgentPopupOpen,
        setShowAgentOnce,
        isWindows,
        sendDataToMixPanel,
        user
    } = useAuth();
    const [showSingleButton, setShowSingleButton] = useState(false);

    useEffect(() => {
        if (!agentIsRunning) {
            setTimeout(function () {
                setAgentPopupOpen(true);
            }, 400)
        } else {
            setAgentPopupOpen(false);
        }
    }, [agentIsRunning]);
    const handleOk = () => {
        setAgentPopupOpen(false);
        setShowAgentOnce(false)
    };

    const handleCancel = () => {
        setAgentPopupOpen(false);
        setShowAgentOnce(false)
    };

    return (
        <>
            <Modal
                open={agentPopupOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                className="download-client-popup"
                footer={null}
                title={null}
            >
                <div className="cloud-drive-modal-wrapper">
                    <div className="row row-eq-height">
                        <div className="col-md-4 ">
                            <div className="left-side">
                                <div className="logo-img-wrapper">
                                    <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                </div>
                                <div className="download-wrapper">
                                    <a href="https://www.amove.io/download/" target='_blank' className='download-icon'><i className="fa fa-download"></i></a>
                                    If you have not installed the Amove client,
                                    please <a href="https://www.amove.io/download/" target='_blank'><strong>download</strong></a> it here.
                                </div>
                                <div className="content-wrapper">
                                    <h3>Launch the Amove Desktop Client</h3>
                                    <p>If you already have the client installed,<br/>
                                        click the button below to launch.</p>
                                    <h3> Instant Connectivity for Everyone</h3>
                                    <p>Setup unlimited Connections, Drives,<br/>
                                        Projects and manage ALL of your teams<br/>
                                        and content instantly, from anywhere.</p>
                                </div>
                                <div className="text-center launch-amove-btn">

                                    {config.environment === 'POC' && !showSingleButton  &&
                                        <>
                                            {!isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-intel-POC downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })

                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-POC.pkg";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style invert">Download Amove</a>
                                            }
                                            {isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-64bit-POC downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-POC.exe";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style invert">Download Amove</a>
                                            }
                                        </>
                                    }
                                    {config.environment !== 'POC' && !showSingleButton &&
                                        <>
                                            {!isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })

                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style invert">Download Amove</a>
                                            }
                                            {isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);



                                                }} className="btn-style invert">Download Amove</a>
                                            }
                                        </>
                                    }

                                    <a href='#' onClick={(e) => {
                                        e.preventDefault();
                                        checkAgent(true)
                                        sendDataToMixPanel('Launch Amove', user.username, {
                                            'App': 'Web App'
                                        })
                                        window.location.href = `amoveapp://open?token=${token}`
                                    }} className={`btn-style blue ${showSingleButton ? "large-btn" : ""}`}>Launch Amove</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="right-side">
                                <img src={`${popupImage}`} alt="popupImage"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AgentOffPopup;
