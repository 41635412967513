import React, {useEffect, useState} from "react";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import HomeIcon from "../assets/images/icons/team/home-icon.svg";
import googleIcon from "../assets/images/icons/team/google-icon.svg";
import plusIcon from "../assets/images/icons/team/plus-icon.svg";
import messageIcon from "../assets/images/icons/team/message-icon.svg";
import huddleIcon from "../assets/images/icons/team/huddle-icon.svg";
import canvasIcon from "../assets/images/icons/team/canvas-icon.svg";
import channelIcon from "../assets/images/icons/team/channel-icon.svg";
import userIcon from "../assets/images/icons/team/user-icon.svg";
import TeamUserImg from "../assets/images/team-demo-img.png";
import {useAuth} from "../context/AuthContext";
import {Tooltip} from "antd";

const LandingPage = () => {
    const { user} = useAuth();
    const [teamStep1, setTeamStep1] = useState(true);
    const [teamStep2, setTeamStep2] = useState(false);
    const [teamStep3, setTeamStep3] = useState(false);
    const [teamStep4, setTeamStep4] = useState(false);
    const [emails, setEmails] = useState([]);
    const [focused, setFocused] = useState(false);
    const onStep1 = () =>{
        setTeamStep1(false)
        setTeamStep2(true)
    }
    const onStep2 = () =>{
        setTeamStep2(false)
        setTeamStep3(true)
    }
    const onStep3 = () =>{
        setTeamStep3(false)
        setTeamStep4(true)
    }
    return (
        <>
            <div className="team-main-dashboard">
                <div id="team-left-sidebar">
                    <div className="left-sidebar-wrapper">
                        <div className="menu-wrapper">
                            <ul className="menu bottom-menu">
                                <li>
                                    <span className="team-name">WD</span>
                                </li>
                                <li>
                                    <a href="#">
                                        <span className="icon"><img src={HomeIcon} alt="icon"/></span>Home
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <ul className="menu bottom bottom-menu">
                            <li>
                                <Tooltip
                                    getPopupContainer={trigger => trigger.parentNode}
                                    trigger="click"
                                    placement="topRight"
                                    title={
                                        <div className="create-options">
                                            <strong className="title">Create</strong>
                                            <ul className="view-list">
                                                <li>
                                                    <a href="#">
                                                        <span className="icon purple">
                                                            <img src={messageIcon} alt="icon"/>
                                                        </span>
                                                        <span className="content">
                                                            <strong>Shared Drive</strong>
                                                            <p>Instant content access across teams.</p>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span className="icon green">
                                                            <img src={huddleIcon} alt="icon"/>
                                                        </span>
                                                        <span className="content">
                                                            <strong>Project</strong>
                                                            <p>Configure your drives and teams.</p>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span className="icon blue">
                                                            <img src={canvasIcon} alt="icon"/>
                                                        </span>
                                                        <span className="content">
                                                            <strong>Files</strong>
                                                           <p>Share, edit, delete from all silos.</p>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span className="icon gray">
                                                            <img src={channelIcon} alt="icon"/>
                                                        </span>
                                                        <span className="content">
                                                            <strong>Admin</strong>
                                                           <p>Manage everything.</p>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span className="icon">
                                                            <img src={userIcon} alt="icon"/>
                                                        </span>
                                                        <span className="content">
                                                            <strong>Invite people</strong>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <a href="#" className="plus">
                                        <span className="icon"><img src={plusIcon} alt="icon"/></span>
                                    </a>

                                </Tooltip>
                            </li>
                            <li>
                                <div className="user-status">
                                    <img src={TeamUserImg} alt="img"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="team-main-content">
                    <div className="create-team-wrapper">
                        <div className="create-team-steps-wrapper row justify-content-center">
                            {(teamStep1 === true) &&
                                <div className="create-team col-lg-6">
                                    <span className="step-count">Step 1 of 4</span>
                                    <h2>What’s the name of your
                                        company or team?</h2>
                                    <p>This will be the name of your first Amove Project —
                                        choose something that your team will recognize.</p>
                                    <div className="form">
                                        <div className="form-group">
                                            <input type="text" placeholder="Ex: Acme Marketing or Acme Co" className="form-control"/>
                                        </div>
                                    </div>
                                    <a href="#" className="btn-style blue" onClick={onStep1}>Next</a>
                                </div>
                            }
                            {(teamStep2 === true) &&
                                <div className="create-team step-2 col-lg-6">
                                    <span className="step-count">Step 2 of 4</span>
                                    <h2>What’s your name?</h2>
                                    <p>Adding your name and profile photo helps your
                                        teammates recognize and connect with you
                                        more easily.</p>
                                    <div className="form">
                                        <div className="form-group">
                                            <input type="text" placeholder="Enter your full name" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="your-profile-photo">
                                        <strong>Your profile photo <span>(optional)</span></strong>
                                        <span className="help">Help your teammates know they’re talking to the right person.</span>
                                        <div className="user-img">
                                            <img src={TeamUserImg} alt="img"/>
                                        </div>
                                        <a href="#" className="btn-style  blue">Upload Photo</a>
                                    </div>
                                    <a href="#" className="btn-style  blue" onClick={onStep2}>Next</a>
                                </div>
                            }
                            {(teamStep3 === true) &&
                                <div className="create-team col-lg-5">
                                    <span className="step-count">Step 3 of 4</span>
                                    <h2>Who else is on the <br/>
                                        <span>Demo Dex</span> team?</h2>
                                    <div className="multi-email">
                                        <div className="header">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <p>Add coworker by email</p>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <a href="#" className="gmail-contact">
                                                        <img src={googleIcon} alt="icon"/>Add from Google Contacts
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <ReactMultiEmail
                                                placeholder='Input your email'
                                                emails={emails}
                                                onChange={(_emails) => {
                                                    setEmails(_emails);
                                                }}
                                                autoFocus={true}
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                getLabel={(email, index, removeEmail) => {
                                                    return (
                                                        <div data-tag key={index}>
                                                            <div data-tag-item>{email}</div>
                                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <ul className="option">
                                        <li><a href="#" className="btn-style   blue" onClick={onStep3}>Next</a></li>
                                        <li><a href="#" className="btn-style  blue">Copy Invite Link</a></li>
                                        <li><a href="#" className="skip">Skip this step</a></li>
                                    </ul>
                                </div>
                            }
                            {(teamStep4 === true) &&
                                <div className="create-team step-2 col-lg-5">
                                    <span className="step-count">Step 4 of 4</span>
                                    <h2>What’s your team <br/>
                                        working on right now?</h2>
                                    <p>This could be anything: a project, campaign,
                                        event, or the deal you’re trying to close.</p>
                                    <div className="form">
                                        <div className="form-group">
                                            <input type="text" placeholder="Ex: Q4 budget, autumn campaign" className="form-control"/>
                                        </div>
                                    </div>
                                    <a href="#" className="btn-style  blue">Next</a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPage;

