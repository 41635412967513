import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    CheckCircleFilled
} from '@ant-design/icons';
import pageLogo from "../assets/images/page-logo.svg";
import {Button, notification, Tooltip} from "antd";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const Signup = () => {
    const {
        logout,
        token,
        isDark,
        sendDataToWebsocket,
        setPopup500,
        getToken,
        sendDataToWebsocketLogin,
        setJWTToken,
        sendDataToMixPanel,
        setError499,
        setPopup499
    } = useAuth();
    const [userDetail, setuserDetail] = useState({
        name: "",
        lastName: "",
        password: ""
    });
    const [errorText, setErrorText] = useState('');
    const [eye, seteye] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [password, setpassword] = useState("password");
    const [showError, setShowError] = useState(false);
    const [signupStep, setSignupStep] = useState(1);
    const [showErrorAll, setShowErrorAll] = useState(false);
    const [showErrorEmailWrong, setShowErrorEmailWrong] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const eightCharsOrMore = /.{8,}/g; // eight characters or more
    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const [meter, setMeter] = useState(false);
    const passwordTracker = {
        uppercase: userDetail.password.match(atLeastOneUppercase),
        lowercase: userDetail.password.match(atLeastOneLowercase),
        number: userDetail.password.match(atLeastOneNumeric),
        specialChar: userDetail.password.match(atLeastOneSpecialChar),
        eightCharsOrGreater: userDetail.password.match(eightCharsOrMore),
    }
    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;
    useEffect(() => {
        //logout()
    }, []);

    const inputEvent = (event) => {
        setShowError(false)
        setEmailError(false);
        setShowErrorAll(false);
        setShowErrorEmailWrong(false);
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });
    }
    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        } else {
            setpassword("password");
            seteye(true);
        }
    }

    async function postDataLogin(url = "", data = {}) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return response.json();
    }
    async function postData(url = '', data) {
        try {
            const response = await fetch(config.apiURLWeb + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-App-Origin": "WebApp"
                },
                body: JSON.stringify(data)
            });
            sendDataToWebsocket(config.apiURLWeb + url, "POST")
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    const signup = (event) => {
         event.preventDefault();
        if (userDetail.name !== "" &&
            userDetail.lastName !== "" &&
            userDetail.password !== "") {
            if (passwordStrength === 5) {
                setLoading(true);
                const url = window.location.search;
                const userToken = url.split("token=")[1];
                postData('/api/user/signup',
                    {
                        "firstname": userDetail.name,
                        "lastname": userDetail.lastName,
                        "password": userDetail.password,
                        "token": userToken
                    }
                )
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            setLoading(false);
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            setLoading(false);
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        var d = JSON.parse(data)
                        if (status === 499) {
                            console.log(d)
                            if (d.type === 'AUTH') {
                                setErrorText(d.errors.AUTH[0])
                            }
                            if (d.type === 'INACTIVE_USER') {
                                setErrorText(d.errors.INACTIVE_USER[0])
                            }
                            if (d.type === 'SESSION') {
                                setErrorText(d.errors.SESSION[0])
                            }

                            if (d.type === 'SESSION_MAXOUT') {
                                setErrorText(d.errors.SESSION_MAXOUT[0])
                            }

                            if (d.type === 'PENDING') {
                                setErrorText((<>This email is already in use. <br/> Resend activation email.</>))
                            }
                            if (d.type === 'DUPLICATE') {
                                setErrorText(d.errors.DUPLICATE[0])
                            }

                            if (d.type === 'COGNITO') {
                                setErrorText(d.errors.COGNITO[0])
                            }

                            if (d.type === 'INVALID_EMAIL') {
                                setErrorText(d.errors.INVALID_EMAIL[0])
                            }
                            if (d.type === 'INTERNAL_ERROR') {
                                setErrorText(d.errors.INTERNAL_ERROR[0])
                            }
                            if (d.type === 'TOKEN') {
                                setErrorText(d.errors.TOKEN[0])
                            }

                            setLoading(false);
                            setShowErrorAll(true);
                        }
                        if (status === 200) {
                            sendDataToMixPanel('Registration completed for invited user.', d.email, {
                                'App': 'Web App'
                            })
                            login(d.email,userDetail.password)
                            //navigate("/sign-in?registration=true");
                        }

                });

            }
        } else {
            setShowError(true);
        }
    }

    const login = (email,password) => {
        // event.preventDefault();
        if (email !== "" && password !== "") {
            getToken(config.auth + "/api/authentication/request_token")
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, tokenForLogin]) => {
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(tokenForLogin)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    postDataLogin(config.auth + "/api/authentication/login", {
                        username: email,
                        password: password,
                        requestToken: tokenForLogin,
                        type: 112,
                    }).then((data) => {
                        if (data.status === 499 || data.status === 401) {
                            navigate("/sign-in?registration=true");
                        }
                        if (data.status === 500) {
                            navigate("/sign-in?registration=true");
                        } else if (data.challenge === 1) {
                            navigate("/sign-in?registration=true");
                        } else if (data.jwtToken !== undefined) {
                            setJWTToken(data.jwtToken);
                            localStorage.setItem('onboarding', true);
                            navigate("/dashboard");
                            sendDataToWebsocketLogin(config.auth + "/api/authentication/login", "POST", data.jwtToken)
                        }
                    });
                });

        }
    };
    const signupKeyup = (event) => {
        if (event.key === 'Enter') {
            signup();
        }

    }
    const signupKeyup1 = (event) => {
        if (event.key === 'Enter') {
            setSignupStep(2)
        }

    }

    return (
        <>
            {contextHolder}
            <div className="login">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="main-form-wrapper">
                        <div className="form">

                            <div className='fields-wrapper'>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="name" className="form-control" value={userDetail.name}
                                                   onChange={inputEvent} placeholder="First name" autocomplete="off" name="name" onKeyDown={signupKeyup1}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="lastName" className="form-control" value={userDetail.lastName}
                                                   onChange={inputEvent} placeholder="Last name" name="lastName" autocomplete="off" onKeyDown={signupKeyup1}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='fields-wrapper'>
                                <div className={`form-group  ${showError || (passwordStrength < 5 && userDetail.password !== '') ? "" : ""}`}>
                                    <input type={password} className="form-control padding-right" id="password"
                                           placeholder="Choose password" value={userDetail.password} onChange={inputEvent}
                                           onFocus={() => setMeter(true)} name="password" autocomplete="off" onKeyDown={signupKeyup}/>
                                    <i onClick={Eye} className={`fa ${password === "password" ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>

                                <div className="form-group sign-up-page">
                                    <div className="position-relative">

                                        <Tooltip placement="bottom"
                                                 trigger='hover'
                                                 title={
                                                     <div className="security-page error-after-metter">
                                                         <span>Your password must contain:</span>
                                                         <ul className="errors-list">
                                                             <li className={userDetail.password.match(eightCharsOrMore) ? 'active' : ''}><CheckCircleFilled/> Minimum 8 characters</li>
                                                             <li className={userDetail.password.match(atLeastOneUppercase) ? 'active' : ''}><CheckCircleFilled/> 1 upper case letter</li>
                                                             <li className={userDetail.password.match(atLeastOneLowercase) ? 'active' : ''}><CheckCircleFilled/> 1 lower case letter</li>
                                                             <li className={userDetail.password.match(atLeastOneNumeric) ? 'active' : ''}><CheckCircleFilled/> 1 number</li>
                                                             <li className={userDetail.password.match(atLeastOneSpecialChar) ? 'active' : ''}><CheckCircleFilled/> 1 special character (! # $)</li>
                                                         </ul>
                                                     </div>
                                                 }
                                                 getPopupContainer={trigger => trigger.parentNode}>
                                            <div className="security-meter">
                                                <span className={passwordStrength > 0 ? "active" : ""}></span>
                                                <span className={passwordStrength > 1 ? "active" : ""}></span>
                                                <span className={passwordStrength > 2 ? "active" : ""}></span>
                                                <span className={passwordStrength > 3 ? "active" : ""}></span>
                                                <span className={passwordStrength > 4 ? "active" : ""}></span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                {showError &&
                                    <div className="form-group error-after-metter">
                                        <span className="red-error">Must fill out all fields for signup.</span>
                                    </div>
                                }

                                {showErrorAll &&
                                    <div className="form-group error-after-metter">
                                        <span className="red-error">{errorText}</span>
                                    </div>
                                }
                                <div className="form-group text-center submit get-started">
                                    <p>By signing up, I accept Amove's <br/>
                                        <a href="#">Terms of Use</a> and&nbsp;
                                        <a href="#">Privacy Policy</a>.
                                    </p>
                                    <Button className="btn-style full" onClick={signup} loading={loading}>
                                        Sign up
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <p className="text-center bottom-text mb-0">I already have an account. <Link to="/sign-in" className="bold">Sign In</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup;

