import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg";
import {Button, notification, Alert} from "antd";
import {useAuth} from "../context/AuthContext";
import {GoogleLogin} from "@react-oauth/google";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const Login = () => {
    const {
        isAuthenticated,
        setIsAuthenticated,
        setJWTToken,
        setLoginSession,
        cloudAccounts,
        setCloudAccounts,
        setShowHeaderAndSidebar,
        setShowSidebar,
        appLoaded,
        sendDataToMixPanel,
        getToken,
        sendDataToWebsocketLogin,
        isDark,
        delayOnLogin,
        setDelayOnLogin,
        setPopup500,
        setError499,
        setPopup499
    } = useAuth();
    const navigate = useNavigate();
    const controller = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const [userDetail, setuserDetail] = useState({
        email: "",
        password: "",
    });
    const [eye, seteye] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [newRegistration, setNewRegistration] = useState(false);
    const [notVerified, setNotVerified] = useState(false);
    const [SSOError, setSSOError] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [googleBTN, setGoogleBTN] = useState(false);
    const [password, setpassword] = useState("password");
    const inputEvent = (event) => {
        setShowError(false);
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value,
            };
        });
    };

    function removeAllData() {
        let newArr = [...cloudAccounts];
        while (newArr.length > 0) {
            newArr.pop();
        }
        setCloudAccounts(newArr);
    }

    async function postData(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response.json();
    }

    useEffect(() => {
        setShowHeaderAndSidebar(false);
        setShowSidebar(false);
        removeAllData();
        const url = window.location.search;
        const urlParams = new URLSearchParams(window.location.search);
        const registration = urlParams.get('registration')
        const verified = urlParams.get('verified')
        const sso = urlParams.get('sso')
        if (registration === 'true') {
            setNewRegistration(true)
        } else {
            setNewRegistration(false)
        }
        if (verified === 'true') {
            setVerified(true)
        }
        if (verified === 'failed') {
            setNotVerified(true)
        }
        if (sso === 'error') {
            setSSOError(true)
        }

        setTimeout(function () {
            setGoogleBTN(true)
        }, 1500)
    }, []);
    useEffect(() => {
        if (isAuthenticated) {
            // navigate("/dashboard");
            setShowHeaderAndSidebar(true);
            setShowSidebar(true);
        }
    }, [isAuthenticated]);
    useEffect(() => {
        if (delayOnLogin) {
            setTimeout(function () {
                setDelayOnLogin(false)
            }, 4000);
        }
    }, [delayOnLogin]);
    const login = (event) => {
        // event.preventDefault();
        if (userDetail.email !== "" && userDetail.password !== "") {
            setLoading(true);
            removeAllData();
            getToken(config.auth + "/api/authentication/request_token")
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, token]) => {
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(token)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    postData(config.auth + "/api/authentication/login", {
                        username: userDetail.email,
                        password: userDetail.password,
                        requestToken: token,
                        type: 112,
                    }).then((data) => {
                        if (data.status === 499 || data.status === 401) {
                            var d = data;
                            if (d.type === "AUTH") {
                                sendDataToMixPanel(d.errors.AUTH[0], userDetail.email)
                                setErrorText(d.errors.AUTH[0]);
                                setLoading(false);
                            }

                            if (d.type === "INACTIVE_USER") {
                                sendDataToMixPanel(d.errors.INACTIVE_USER[0], userDetail.email)
                                setErrorText(d.errors.INACTIVE_USER[0]);
                                setLoading(false);
                            }

                            if (d.type === "SESSION") {
                                sendDataToMixPanel(d.errors.SESSION[0], userDetail.email)
                                setErrorText(d.errors.SESSION[0]);
                                setLoading(false);
                            }

                            if (d.type === "SESSION_MAXOUT") {
                                sendDataToMixPanel(d.errors.SESSION_MAXOUT[0], userDetail.email)
                                setErrorText(d.errors.SESSION_MAXOUT[0]);
                                setLoading(false);
                            }

                            if (d.type === "DUPLICATE") {
                                sendDataToMixPanel(d.errors.DUPLICATE[0], userDetail.email)
                                setErrorText(d.errors.DUPLICATE[0]);
                                setLoading(false);
                            }

                            if (d.type === "COGNITO") {
                                sendDataToMixPanel(d.errors.COGNITO[0], userDetail.email)
                                setErrorText(d.errors.COGNITO[0]);
                                setLoading(false);
                            }

                            if (d.type === "INVALID_EMAIL") {
                                sendDataToMixPanel(d.errors.INVALID_EMAIL[0], userDetail.email)
                                setErrorText(d.errors.INVALID_EMAIL[0]);
                                setLoading(false);
                            }
                            if (d.type === "INTERNAL_ERROR") {
                                sendDataToMixPanel(d.errors.INTERNAL_ERROR[0], userDetail.email)
                                setErrorText(d.errors.INTERNAL_ERROR[0]);
                            }
                            setLoading(false);
                            setShowError(true);
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (data.status === 500) {
                            setErrorText(data.title);
                            sendDataToMixPanel(data.title)
                            setShowError(true);
                            setLoading(false);
                        } else if (data.challenge === 1) {
                            setLoginSession(data.session);
                            navigate("/mfa");
                            setLoading(false);
                        } else if (data.jwtToken !== undefined) {
                            sendDataToMixPanel("Login With Email Success", userDetail.email)

                            sendDataToWebsocketLogin(config.auth + "/api/authentication/login", "POST", data.jwtToken)
                            setJWTToken(data.jwtToken);
                        }
                    });
                });

        }
    };

    async function postDataGmail(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
        });
        return response;
    }

    const loginWithGoogle = (token) => {
        setLoadingGoogle(true);
        removeAllData();
        var obj = {};
        obj.idToken = token;
        getToken(config.auth + "/api/authentication/request_token")

            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, requestToken]) => {
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(requestToken)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                obj.requestToken = requestToken;
                postDataGmail("/api/desktop/google_signin", obj)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, textData]) => {
                        if (status === 499 || status === 401) {
                            var d = JSON.parse(textData);
                            setLoadingGoogle(false);
                            if (d.type === "AUTH") {
                                sendDataToMixPanel(d.errors.AUTH[0], userDetail.email)
                                setErrorText(d.errors.AUTH[0]);
                            }

                            if (d.type === "INACTIVE_USER") {
                                sendDataToMixPanel(d.errors.INACTIVE_USER[0], userDetail.email)
                                setErrorText(d.errors.INACTIVE_USER[0]);
                            }

                            if (d.type === "SESSION") {
                                sendDataToMixPanel(d.errors.SESSION[0], userDetail.email)
                                setErrorText(d.errors.SESSION[0]);
                            }

                            if (d.type === "SESSION_MAXOUT") {
                                sendDataToMixPanel(d.errors.SESSION_MAXOUT[0], userDetail.email)
                                setErrorText(d.errors.SESSION_MAXOUT[0]);
                            }

                            if (d.type === "DUPLICATE") {
                                sendDataToMixPanel(d.errors.DUPLICATE[0], userDetail.email)
                                setErrorText(d.errors.DUPLICATE[0]);
                            }

                            if (d.type === "COGNITO") {
                                sendDataToMixPanel(d.errors.COGNITO[0], userDetail.email)
                                setErrorText(d.errors.COGNITO[0]);
                            }

                            if (d.type === "INVALID_EMAIL") {
                                sendDataToMixPanel(d.errors.INVALID_EMAIL[0], userDetail.email)
                                setErrorText(d.errors.INVALID_EMAIL[0]);
                            }
                            if (d.type === "INTERNAL_ERROR") {
                                sendDataToMixPanel(d.errors.INTERNAL_ERROR[0], userDetail.email)
                                setErrorText(d.errors.INTERNAL_ERROR[0]);
                            }
                            setShowError(true);
                            setLoadingGoogle(false);
                        }
                        if (status === 200) {
                            sendDataToMixPanel("Login With Google Success")
                            setJWTToken(textData);
                            setIsAuthenticated(true);
                            setLoadingGoogle(false);
                            sendDataToWebsocketLogin(config.auth + "/api/authentication/login", "POST", textData)
                        }
                    });
            });
    }

    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        } else {
            setpassword("password");
            seteye(true);
        }
    };
    const loginKeyup = (event) => {
        if (event.key === "Enter") {
            login();
        }
    };

    return (
        <>
            {contextHolder}
            <div className={`login ${!appLoaded ? "d-none" : ''}`}>
                <div className="login-wrapper no-border">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="main-form-wrapper">
                        <form className="form">
                            {newRegistration &&
                                <div className="success-message-login">
                    <span>Your registration is completed. <br/>
Please log in below.</span>
                                </div>
                            }
                            {verified &&
                                <div className="success-message-login">
                    <span>Your account is now verified. <br/>
Please log in below.</span>
                                </div>
                            }
                            {notVerified &&
                                <div className="success-message-login red">
                                    <span>Verification token is expired.</span>
                                </div>
                            }

                            {SSOError &&
                                <div className="success-message-login red">
                                    <span>SSO is not configured.</span>
                                </div>
                            }
                            <div className='fields-wrapper'>
                                <div className={`form-group  ${showError ? "" : ""}`}>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        value={userDetail.email}
                                        onChange={inputEvent}
                                        placeholder="Email"
                                        name="email"
                                        onKeyDown={loginKeyup}
                                    />
                                    <i className="fa-solid fa-envelope fa"></i>
                                </div>
                                <div className={`form-group  ${showError ? "" : ""}`}>
                                    <input
                                        type={password}
                                        className="form-control padding-right"
                                        id="password"
                                        placeholder="Password"
                                        value={userDetail.password}
                                        onChange={inputEvent}
                                        name="password"
                                        onKeyDown={loginKeyup}
                                    />
                                    <i
                                        onClick={Eye}
                                        className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                    ></i>
                                </div>
                                {showError && (
                                    <div className="form-group">
                                        <span className="red-error">{errorText}</span>
                                    </div>
                                )}
                                <div className="row remember">
                                    <div className="col-md-5 align-self-center">
                                        <div className="form-group styled-check d-none">
                                            <input type="checkbox" id="remember"/>
                                            <label htmlFor="remember">Remember for 30 days</label>
                                        </div>
                                    </div>
                                    <div className="col-md-7 text-end align-self-center">
                                        <Link to="/forgot-password" className="bold text-black">
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group submit get-started">
                                    <Button
                                        className={`btn-style full ${loadingGoogle || delayOnLogin ? "disabled" : ""}`} onClick={login}
                                        loading={loading}>
                                        Sign in
                                    </Button>
                                </div>
                                <div className="or-wrapper"><span>or</span></div>
                                <div className="google-wrapper signin-page">
                                    <div className="form-group submit google-btn-new-wrapper">
                                        <Button
                                            className={`btn-style full google-btn-new ${!googleBTN ? "not-visible" : ""}`}
                                            tabIndex='-1' loading={loadingGoogle}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                                                 className="LgbsSe-Bz112c">
                                                <g>
                                                    <path fill="#EA4335"
                                                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                    <path fill="#4285F4"
                                                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                    <path fill="#FBBC05"
                                                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                    <path fill="#34A853"
                                                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </g>
                                            </svg>
                                            Sign in with Google
                                        </Button>
                                        <GoogleLogin
                                            width="320px"
                                            onSuccess={(credentialResponse) => {
                                                loginWithGoogle(credentialResponse.credential);
                                            }}
                                        />
                                    </div>
                                    {/*<p className='larger-p'>or sign in with</p>*/}
                                    <div className="form-group sso-login-btn ">
                                        <Link to="/sso-sign-in"
                                              className={`btn-style full ${loadingGoogle ? "disabled" : ""}`}>
                                            Sign in with SSO
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p className="text-center bottom-text mb-0">
                            Don’t have an Amove account?{" "}
                            <Link to="/signup" className="bold">
                                Create an account
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Login;
