import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg";
import {Button, notification} from "antd";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const SSOLoginPage = () => {
    const {
        isAuthenticated,
        cloudAccounts,
        setCloudAccounts,
        setShowHeaderAndSidebar,
        setShowSidebar,
        appLoaded,
        isDark
    } = useAuth();
    const navigate = useNavigate();
    const navigateTo = useNavigate();
    const controller = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const [userDetail, setuserDetail] = useState({
        email: ""
    });
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [provider, setProvider] = useState("");
    const [loginURL, setLoginURL] = useState("");
    const [validationError, setValidationError] = useState("");
    const [loading, setLoading] = useState(false);
    const inputEvent = (event) => {
        setShowError(false);
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value,
            };
        });
    };

    function removeAllData() {
        let newArr = [...cloudAccounts];
        while (newArr.length > 0) {
            newArr.pop();
        }
        setCloudAccounts(newArr);
    }

    async function postData(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response;
    }

    useEffect(() => {
        setShowHeaderAndSidebar(false);
        setShowSidebar(false);
        removeAllData();
        const url = window.location.search;
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error')
        if (error !== null) {
            setErrorText(error)
            setShowError(true)
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard");
            setShowHeaderAndSidebar(true);
            setShowSidebar(true);
        }
    }, [isAuthenticated]);

    var checkEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    const login = (event) => {
        if (userDetail.email !== "") {
            checkEmail.test(userDetail.email);
            if (checkEmail.test(userDetail.email) === false) {
                setValidationError('Please add valid email address')
            } else {
                setValidationError('');
            setLoading(true);
            setShowError(false);
            removeAllData();
            postData(config.apiURLWeb + "/api/sso/sso_url", {
                username: userDetail.email,
                callbackUrl: config.callbackURL + "/ssocallback"
            }).then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 400 || status === 499) {
                        setErrorText(data);
                        setShowError(true);
                        setLoading(false);
                        navigate("/sign-in?sso=error");
                    }
                    if (status === 200) {
                        data = JSON.parse(data)
                        window.location.href = data.url;
                    }
                }).catch((errorText) => {
                setLoading(false);
            });
            }
        }
    };


    const loginKeyup = (event) => {
        if (event.key === "Enter") {
            login();
        }
    };

    return (
        <>
            {contextHolder}
            <div className={`login ${!appLoaded ? "d-none" : ''}`}>
                <div className="login-wrapper no-border">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="main-form-wrapper">
                        {provider === "" && loginURL === "" &&
                            <div className="form">
                                <div className='fields-wrapper'>
                                    <div className={`form-group  ${showError ? "" : ""}`}>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control"
                                            value={userDetail.email}
                                            onChange={inputEvent}
                                            placeholder="Email"
                                            name="email"
                                            onKeyDown={loginKeyup}
                                        />
                                        <i className="fa-solid fa-envelope fa"></i>
                                    </div>
                                    {showError && (
                                        <div className="form-group">
                                            <span className="red-error">{errorText}</span>
                                        </div>
                                    )}
                                    {validationError !== "" && (
                                        <div className="form-group">
                                            <span className="red-error">{validationError}</span>
                                        </div>
                                    )}
                                    <div className="form-group submit get-started">
                                        <Button
                                            className={`btn-style full  ${userDetail.email === "" ? "disabled" : ""}`} onClick={login} loading={loading}>
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        {provider !== "" && loginURL !== "" &&
                            <div className="form">
                                <div className='fields-wrapper'>
                                    <div className="form-group">
                                        <a href={loginURL} className={`btn-style full`}>Sign in with Microsoft</a>
                                    </div>
                                </div>
                            </div>
                        }
                        <p className="text-center bottom-text mb-0">
                            <Link to="/sign-in" className="bold">
                                Back to Login
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default SSOLoginPage;
