import React, {useState, useRef, useEffect} from "react";
import {useAuth} from "../context/AuthContext";
import {Button, Modal, notification, Select, Spin, Switch, Tooltip} from "antd";
import logoIcon from "../assets/images/icons/logo-icon.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import {LoadingOutlined} from "@ant-design/icons";
import pageLogo from "../assets/images/logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useNavigate} from "react-router-dom";
const {Option} = Select;
const ProvisionAmoveStorage = ({}) => {
    const {
        isDark,
        regions,
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [api, contextHolder] = notification.useNotification();
    const [regionsFilter, setRegionsFilter] = useState([]);
    const [storageFilter, setStorageFilter] = useState([]);
    const [amoveStorage, setAmoveStorage] = useState([]);
    const [amoveStorageFiltered, setAmoveStorageFiltered] = useState([]);
    const [selectedRegionsFilter, setSelectedRegionsFilter] = useState("All Regions");
    const [selectedStorageFilter, setSelectedStorageFilter] = useState("All Storages");
    const [selectedRegions, setSelectedRegions] = useState();
    const [selectedProvision, setSelectedProvision] = useState("Perform");
    const [createProvision, setCreateProvision] = useState(false);
    const [createRegion, setCreateRegion] = useState(false);
    const [totalBuckets, setTotalBuckets] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [regionToDelete, setRegionToDelete] = useState({});
    const [loadingAmove, setLoadingAmove] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPageData, setShowPageData] = useState(false);
    const [iDriveUsage, setIDriveUsage] = useState(0);
    const [toggleVersioning, setToggleVersioning] = useState(false);
    const [toggleDefaultEncryption, setToggleDefaultEncryption] = useState(false);
    const [toggleObjectLocking, setToggleObjectLocking] = useState(false);
    const [newBucketName, setNewBucketName] = useState("");
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const validBucketName = (bucket) => {
        var regularExp = /^(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9\-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9\-]*[a-z0-9])?)*$)/;
        return regularExp.test(bucket);
    }

    const manageProvisionCancel = () => {
        setCreateProvision(false)
    };
    const onChangeProvisions = (value) => {
        setSelectedProvision(value);
        setSelectedRegions();
        setToggleVersioning(false)
        setToggleDefaultEncryption(false)
        setToggleObjectLocking(false)
        console.log(value)
    };

    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };
    return (
        <>
            {contextHolder}

            <div className="enable-region-wrapper mb-5">
                <div className="new-region-wrapper long-select">
                    <h3>Provision Amove Storage</h3>
                    <Select
                        className="user-select-role"
                        onChange={onChangeProvisions}
                        value={selectedProvision}
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        <Option value="Perform">Perform
                        </Option>
                        <Option value="Global">Global
                        </Option>
                        <Option value="Scale">Scale
                        </Option>
                    </Select>
                    <a href="#" className={`brand-icon ${selectedProvision === undefined ? 'disabled' : ''}`} onClick={(e) => {
                        e.preventDefault();

                        setCreateProvision(true)
                        // setUpNewIDrive()
                    }}>
                        {loadingAmove &&
                            <Spin indicator={<LoadingOutlined style={{fontSize: 22}} spin/>}/>
                        }
                        <img src={isDark ? pageLogo : pageLogoWhite} alt="logo"/></a>
                </div>
            </div>


            <Modal title="Provision Amove Storage" className="bucket-main-modal" centered open={createProvision} onOk={manageProvisionCancel}
                   onCancel={manageProvisionCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-9">
                            <div className="name-wrapper">
                                <img src={logoIcon} alt="icon"/>
                                <h3>Provision Amove Storage</h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    manageProvisionCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Provision Amove Storage</label>
                                    <Select
                                        placeholder="Provision Amove Storage"
                                        className="user-select-role"
                                        onChange={onChangeProvisions}
                                        value={selectedProvision}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        <Option value="Perform">Perform
                                        </Option>
                                        <Option value="Global">Global
                                        </Option>
                                        <Option value="Scale">Scale
                                        </Option>
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Storage Name</label>
                                    <input type="text" id="bucket-name" value={newBucketName} className={`form-control ${showBucketNameError ? "has-error" : ""}`} onChange={(e) => {
                                        if (e.target.value.length > 1) {
                                            var result1 = selectedBuckets.filter(item => item.name.toLowerCase() === e.target.value.toLowerCase());

                                            if (result1.length > 0) {
                                                setBucketNameError(true)
                                            } else {
                                                setBucketNameError(false)
                                            }
                                        } else {
                                            setBucketNameError(false)
                                        }
                                        setNewBucketName(e.target.value);

                                    }} placeholder="Introduce a Name"/>
                                    <p className="small">Storage name can contain lowercase letters, numbers, hyphen (-), and period (.)</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Select a Region</label>
                                    <Select
                                        className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select a Region"
                                        onChange={onChangeRegoin}
                                        value={selectedRegions}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={regions.filter((item) => {
                                            if (selectedProvision === "Scale" && item.name === "Virginia") {
                                                return item
                                            } else if (selectedProvision !== "Scale") {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.code,
                                        }))}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="content">
                                                    <h3>Default encryption</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <Switch checked={toggleDefaultEncryption} onChange={(e) => {
                                                    setToggleDefaultEncryption(e)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className={`row`}>
                                            <div className={`col-md-9`}>
                                                <div className="content">
                                                    <h3>Versioning</h3>
                                                    <p>Retain older versions of the objects
                                                        in your bucket. These versions can be
                                                        accessed and restored.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">

                                                {selectedProvision === "Scale" &&
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="topLeft"
                                                        title="This is supported only in Global and Perform tier"
                                                    >
                                                        <Switch checked={false}/>
                                                    </Tooltip>

                                                }
                                                {selectedProvision !== "Scale" &&
                                                    <Switch checked={toggleVersioning} onChange={(e) => {
                                                        if (!toggleObjectLocking) {
                                                            setToggleVersioning(e)
                                                        }
                                                    }}/>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className={`row`}>
                                            <div className={`col-md-9`}>
                                                <div className="content">
                                                    <h3>Object Locking</h3>
                                                    <p>Prevent modification or deletion of
                                                        objects for a set time duration.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {selectedProvision === "Scale" &&
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="topLeft"
                                                        title="This is supported only in Global and Perform tier"
                                                    >
                                                        <Switch checked={false}/>
                                                    </Tooltip>

                                                }
                                                {selectedProvision !== "Scale" &&
                                                    <Switch checked={toggleObjectLocking} onChange={(e) => {
                                                        setToggleObjectLocking(e)
                                                        setToggleVersioning(e)
                                                    }}/>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button className={`btn-style`}>Create Amove Storage</Button></li>
                        <li>
                            <Button className="btn-style transparent" onClick={manageProvisionCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}
export default ProvisionAmoveStorage;
