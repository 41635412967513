import React, {useEffect, useState, useRef} from 'react';
import settings from "../assets/images/settings-icon.svg";
import {Collapse, notification, Popconfirm, Switch, Select, Input} from 'antd';
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Modal, Alert} from 'antd';
import {Tabs} from 'antd';
import nfsIcon from "../assets/images/icons/network-1.svg";
import config from "../config";

const {TabPane} = Tabs;

const NFS = (props) => {
    const {
        getData,
        deleteData,
        updateData,
        logout,
        getIcon,
        isWindows,
        isCMDDone,
        setDefaultOpenConnection,
        defaultOpenConnection,
        userStatus,
        reloadNFS,
        setReloadNFS,
        getDataMethodGet,
        postDataWebResponseText,
        setPopup500,
        postDataNewFolder,
        sendDataToMixPanel,
        user,
        setError499,
        setPopup499
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const [api, contextHolder] = notification.useNotification();
    const [NFSs, setNFSs] = useState([]);
    const [defaultActive, setDefaultActive] = useState(0)
    const [modalData, setModalData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showTooltips, setshowTooltips] = useState(false);
    const [selectedTab, setSelectedTab] = useState('1');
    const [updatedNFSName, setUpdatedNFSName] = useState(false);
    const [updatedVersion, setUpdatedVersion] = useState(false);
    const [updatedNFSShare, setUpdatedNFSShare] = useState(false);
    const [showNFSNameError, setShowNFSNameError] = useState(false);
    const [selectedAvailableDrive, setSelectedAvailableDrive] = useState("");
    const [availableLocalDrives, setAvailableLocalDrives] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [previousPath, setPreviousPath] = useState("");
    const [localDir, setLocalDir] = useState([]);
    const [selectedPath, setSelectedPath] = useState("");
    const [localDrives, setLocalDrives] = useState([]);
    const [errorText, setErrorText] = useState("");
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (reloadNFS) {
            setReloadNFS(false)
            loadNFS()
        }
    }, [reloadNFS]);
    const onChangeNFSName = (e) => {
        var name = e.target.value;
        setUpdatedNFSName(e.target.value)
        setShowNFSNameError(false)
        if (name.length > 1) {
            var result1 = NFSs.filter(item => item.name.toLowerCase() === name.toLowerCase());
            if (result1.length > 0) {
                setShowNFSNameError(true)
            }
        }
    };

    function getdrives() {
        getDataMethodGet('/Management/list_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDrives(response)
            })
    }

    const newFolderName = (event) => {
        const folderName = event.target.value;
        setFolderName(folderName);
    }

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (isCMDDone) {
                if (NFSs.length === 0) {
                    loadNFS()
                }
            }
        }
    }, [isCMDDone]);

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            loadNFS();
            getdrives()
            getAvailableDrives();
            if (defaultOpenConnection) {
                setDefaultActive(1);
                setDefaultOpenConnection(false);
            }
        }
    }, []);

    function loadNFS(refresh = false) {
        getData('/Management/nfsclient')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setNFSs(response)
            });
    }

    // run code for dirHandle
    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const cloudAccount = (
        <div className="connected-accounts-header">
            <h3>Connections</h3>
            {NFSs.length > 0 && (
                <ul className="login-accounts">
                    <li className={` ${NFSs.length > 0 ? "show" : ""}`}><img src={nfsIcon} className="nfs-icons" alt="NFS"/><span
                        className={` ${NFSs.length > 1 ? "show" : ""}`}>{NFSs.length}</span></li>
                </ul>
            )}
        </div>
    );

    function actionSettingUpdate(e, data) {

        var msg = 'Your ' + data.name + ' Connection has been deactivated.'
        if (e) {
            msg = 'Your ' + data.name + ' Connection has been activated.'
        }
        var dataToPost = {
            "id": data.id,
            "name": data.name,
            "nfsShare": data.nfsShare,
            "version": data.version,
            "localPath": data.localPath,
            "active": e,
        }

        updateData('/Management/nfsclient/' + data.id, dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(res)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                if (status === 200) {
                    let newArr = [...NFSs];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === dataToPost.id) {
                            newArr[i] = dataToPost;
                        }
                    }
                    setNFSs(newArr)

                    api.open({
                        message: msg,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    setIsModalOpen(false);
                    setModalData({});


                }
            });
    }

    function deleteNFS(d) {
        deleteData('/Management/nfsclient/' + d.id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(res)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setNFSs((current) =>
                        current.filter(
                            (item) => item.id !== d.id
                        )
                    );
                    api.open({
                        message: 'Your ' + d.name + ' NFS has been deleted.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    sendDataToMixPanel('Your ' + d.name + ' NFS has been deleted.', user.username, {
                        'App': 'Web App'
                    })
                    setIsModalOpen(false);
                    setModalData({});
                }
            });
    }

    const showModalSettings = (e, data) => {
        setIsModalOpen(true);
        setshowTooltips(true);
        setSelectedTab('1');

        setSelectedAvailableDrive(data.localPath)
        setSelectedPath(data.localPath)
        setUpdatedNFSName(data.name)
        setUpdatedNFSShare(data.nfsShare)
        setUpdatedVersion(data.version)
        setModalData(data)
        e.preventDefault();
    };
    const handleOkSettings = () => {
        setIsModalOpen(false);
        setIsModalOpen(false);
    };

    const handleCancelSettings = () => {
        setIsModalOpen(false);
    };
    const onChangeTab = (key) => {
        setSelectedTab(key);
    };

    function saveSettings(e) {
        //setLoading(true);
        var dataToPost = {
            "id": modalData.id,
            "name": modalData.name,
            "nfsShare": modalData.nfsShare,
            "version": updatedVersion,
            "localPath": selectedAvailableDrive,
            "active": modalData.active,
        }

        if (updatedNFSShare !== '') {
            dataToPost.nfsShare = updatedNFSShare;
        }

        if (updatedNFSName !== '' && !showNFSNameError) {
            dataToPost.name = updatedNFSName;
        }

        if (selectedPath !== '' && !isWindows) {
            dataToPost.localPath = selectedPath;
        }
        var msg = 'Your ' + modalData.name + ' Connection has been updated.'
        updateData('/Management/nfsclient/' + modalData.id, dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(res)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                if (status === 499) {
                    var d = res;
                    if (d.type === "AUTH") {
                        setErrorText(d.errors.AUTH[0]);
                    }

                    if (d.type === "INACTIVE_USER") {
                        setErrorText(d.errors.INACTIVE_USER[0]);
                    }

                    if (d.type === "SESSION") {
                        setErrorText(d.errors.SESSION[0]);
                    }

                    if (d.type === "SESSION_MAXOUT") {
                        setErrorText(d.errors.SESSION_MAXOUT[0]);
                    }

                    if (d.type === "DUPLICATE") {
                        setErrorText(d.errors.DUPLICATE[0]);
                    }

                    if (d.type === "COGNITO") {
                        setErrorText(d.errors.COGNITO[0]);
                    }

                    if (d.type === "INVALID_EMAIL") {
                        setErrorText(d.errors.INVALID_EMAIL[0]);
                    }
                    if (d.type === "INTERNAL_ERROR") {
                        setErrorText(d.errors.INTERNAL_ERROR[0]);
                    }
                    setLoading(false);
                    setShowError(true);
                }
                if (status === 200) {
                    let newArr = [...NFSs];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === dataToPost.id) {
                            newArr[i] = dataToPost;
                        }
                    }
                    setNFSs(newArr)
                    api.open({
                        message: msg,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    sendDataToMixPanel(msg, user.username, {
                        'App': 'Web App'
                    })
                    setLoading(false);
                    setIsModalOpen(false);
                    setShowNFSNameError(false)
                    setSelectedPath('');
                    setSelectedAvailableDrive('')
                    setUpdatedNFSName('')
                    setUpdatedNFSShare('')
                    setUpdatedVersion('')
                    setModalData({});
                }
            });


        e.preventDefault();
    }

    function getAvailableDrives() {
        getDataMethodGet('/Management/list_available_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAvailableLocalDrives(response)
            });
    }

    const onChangeAvailableDrive = (value) => {
        setSelectedAvailableDrive(value)
    };
    const onChangeSelectedVersion = (value) => {
        setUpdatedVersion(value)
    };


    function getDirectories(e) {
        setSelectedPath(e.target.value)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setPreviousPath(e.target.value)
                setLocalDir(response)
            })
    }

    function changeDir(e) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        setSelectedPath(val)
        if (e.detail == 2) {
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setPreviousPath(val)
                    setLocalDir(response)
                    const elements = document.querySelectorAll('.directory-list .form-group')
                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                })
        }
    }

    function goBack() {
        var val = ""
        if (isWindows) {
            if (previousPath.lastIndexOf("\\") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\") + 1);
            }
        } else {
            if (previousPath.lastIndexOf("/") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("/"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("/") + 1);
            }
        }
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setPreviousPath(val)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    const showModal = () => {
        setOpen(true);
        if (selectedPath !== '') {
            var p = selectedPath.substring(0, selectedPath.lastIndexOf("\\"));
            if (!isWindows) {
                p = selectedPath.substring(0, selectedPath.lastIndexOf("/"))
            }
            setPreviousPath(p)
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(p))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);


                    setTimeout(function () {
                        const elements = document.querySelectorAll('.directory-list .form-group')
                        elements.forEach((element) => {
                            element.classList.remove('active');
                            var fName = '';
                            if (isWindows) {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                            } else {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                            }
                            if (element.innerText === fName) {
                                element.classList.add('active');
                            }
                        });
                    }, 200)
                })
        } else {
            var dirListPath = '/';
            if (isWindows) {
                setPreviousPath(localDrives[0])
                dirListPath = encodeURIComponent(localDrives[0]);
            } else {
                setPreviousPath('/');
            }
            getDataMethodGet('/Management/list_directories?path=' + dirListPath)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                })
        }
    };
    const confirm = (e) => {
        if (folderName !== '') {
            postDataNewFolder('/Management/make_directory', {
                "directoryName": folderName,
                "path": previousPath
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, res]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(res)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setFolderName('');
                    sendDataToMixPanel('New Folder Created.', user.username, {
                        'App': 'Web App',
                        'Folder Name':folderName
                    })
                    getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(previousPath))
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }
                            setPreviousPath(previousPath)
                            setLocalDir(response)
                            const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                            elements.forEach((element) => {
                                element.classList.remove('active');
                            });

                        })
                });
            setFolderName('');
        }
    };
    return (

        <> {contextHolder}
            {NFSs.length > 0 &&
                <>
                    <div className="connected-accounts-body">
                        <div className="table-header">
                            <div className="row">
                                <div className="col-md-2">
                                    <p>NFS Name</p>
                                </div>
                                <div className="col-md-2 text-center">
                                    <p>Path</p>
                                </div>
                                <div className={`text-center col-md-2`}>
                                    <p>NFS Share</p>
                                </div>
                                {!isWindows &&
                                    <div className="col-md-1 text-center">
                                        <p>Version</p>
                                    </div>
                                }
                                <div className={`col-md-2 text-center ${isWindows ? 'offset-md-3' : 'offset-md-2'}`}>
                                    Settings
                                </div>
                                <div className={`text-end last-item-padding col-md-1`}>
                                    Action
                                </div>

                            </div>
                        </div>
                        <div className="table-body">
                            {NFSs.length > 0 && (
                                <ul>
                                    {NFSs.map(item => (
                                        <div className="table-body-card" key={item.id}>
                                            <div className="row">
                                                <div className="col-md-2 align-self-center">
                                                    <div className="account-detail">
                                                        <div className="content-wrapper">
                                                            <p className="medium">{item.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 align-self-center text-center ellipses">
                                                    {isWindows ? item.localPath + ':\\' : item.localPath}
                                                </div>
                                                <div className={`align-self-center text-center col-md-2`}>
                                                    {item.nfsShare}
                                                </div>
                                                {!isWindows &&
                                                    <div className="col-md-1 align-self-center text-center">
                                                        {item.version}
                                                    </div>
                                                }

                                                <div className={`col-md-2 align-self-center text-center ${isWindows ? 'offset-md-3' : 'offset-md-2'}`}>
                                                    <a href="#" className="settings-icon" onClick={(e) => {
                                                        showModalSettings(e, item)
                                                    }}>
                                                        <img src={settings} alt="Settings"/>
                                                    </a>
                                                </div>
                                                <div className={`align-self-center text-center action-wrapper col-md-1`}>

                                                    <ul className="icons">
                                                        <li>
                                                            {item.active &&
                                                                <Popconfirm className="switch-confirm"
                                                                            placement="topLeft"
                                                                            title="Are you sure you want to deactivate this connection?"
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            onConfirm={() => {
                                                                                actionSettingUpdate(false, item)
                                                                            }}
                                                                >
                                                                    <Switch checked={item.active}/>
                                                                </Popconfirm>
                                                            }
                                                            {!item.active &&
                                                                <Switch onChange={(e) => {
                                                                    actionSettingUpdate(e, item)
                                                                }} defaultChecked={item.active}/>
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                            )}
                        </div>

                    </div>
                </>
            }

            <Modal title="Basic Modal" centered className={`cloud-drive-modal NFS ${!isWindows ? "mac-os" : ""}`}
                   open={isModalOpen} onOk={handleOkSettings} onCancel={handleCancelSettings}>
                <div className="cloud-drive-modal-wrapper connections-modal">
                    <h3>{modalData.name} | Connection Settings</h3>
                    <Tabs type="card" onChange={onChangeTab} activeKey={selectedTab}>
                        <TabPane tab="General" key="1">
                            <div className="cloud-account-popup-detail ">
                                <ul>
                                    <li>
                                        <strong>NFS Name:</strong>
                                        <div className="form edit-cloud-drive-name">
                                            <div className="form-group">
                                                <Input
                                                    className="form-control"
                                                    value={updatedNFSName}
                                                    onChange={onChangeNFSName}/>
                                            </div>

                                            {showNFSNameError &&
                                                <span className="red-error cloud-name">
                                                                That connection name has already been used.
                                                            </span>
                                            }
                                        </div>
                                    </li>

                                    <li>
                                        <strong>Path:</strong>
                                        <div className="form edit-cloud-drive-name">
                                            <div className="form-group">

                                                {isWindows &&
                                                    <Select
                                                        className=' d-block remove-ant-select-arrow'
                                                        placeholder="Select drive"
                                                        optionFilterProp="children"
                                                        onChange={onChangeAvailableDrive}
                                                        listHeight={160}
                                                        value={selectedAvailableDrive}
                                                        options={availableLocalDrives.map((item) => ({
                                                            label: item,
                                                            value: item,
                                                        }))}
                                                    />
                                                }

                                                {!isWindows &&
                                                    <p className="medium form-control ellipses" onClick={showModal} title={selectedPath}>{selectedPath}</p>
                                                }
                                            </div>

                                        </div>
                                    </li>


                                    {!isWindows &&
                                        <li>
                                            <strong>Version:</strong>
                                            <div className="form edit-cloud-drive-name">
                                                <div className="form-group">
                                                    <Select
                                                        className='d-block remove-ant-select-arrow'
                                                        placeholder="Select version"
                                                        optionFilterProp="children"
                                                        value={updatedVersion}
                                                        onChange={onChangeSelectedVersion}
                                                        listHeight={160}
                                                        options={[
                                                            {value: '3', label: '3'},
                                                            {value: '4', label: '4'},
                                                            {value: '4.1', label: '4.1'},
                                                            {value: '4.2', label: '4.2'}
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    }
                                    {showError &&
                                        <li>
                                            <strong></strong>
                                            <span className="red-error">{errorText}</span>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </TabPane>
                    </Tabs>
                    <div className="form form-footer">
                        <div className="row">
                            <div className="col-md-6 align-self-end delete-wrapper">
                                <div className="form-group">
                                    <label>
                                        <Popconfirm
                                            placement="topLeft"
                                            title="Are you sure you want to delete this connection?"
                                            okText="Yes"
                                            cancelText="No"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onConfirm={() => deleteNFS(modalData)}
                                        >
                                            Delete {modalData.name} Connection
                                        </Popconfirm>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group submit">
                                    <input type="submit" value="Save" className="btn-style small-btn"
                                           onClick={saveSettings}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                open={open}
                title="Title"
                onOk={handleOk}
                onCancel={handleCancel}
                className={`file-path-modal file-path-modal-wrapper ${!isWindows ? "mac-os" : ""}`}
                footer={[
                    <Popconfirm
                        title="Create a folder"
                        description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                        onConfirm={confirm}
                        cancelButtonProps={false}
                        icon={false}
                        okText="Create"
                        placement="topLeft"
                        getPopupContainer={trigger => trigger.parentNode}
                        className={`new-folder-wrapper ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}
                    >
                        <button className={`btn-style float-start  ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}>
                            New Folder
                        </button>
                    </Popconfirm>,
                    <button className="btn-style" onClick={handleOk}>
                        Select Folder
                    </button>,
                    <button className="btn-style margin-left" onClick={handleCancel}>
                        Cancel
                    </button>
                ]}
            >
                <div className="form dir-path-wrapper">
                    <div className="form-group choose-your-backup-title">
                        <h3>
                            <button className={`btn-style  ${selectedPath.length < 4 ? "disabled" : ""}`} type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                            Choose folder
                        </h3>
                    </div>
                </div>
                <div className="modal-body-wrapper">
                    <div className={`select-drive-wrapper  ${!isWindows ? "mac-os" : ""}`}>
                        <div className="row">
                            {isWindows &&
                                <div className="col-md-3">
                                    <p className="medium">Drive</p>
                                    <div className="form">

                                        {localDrives.length > 0 &&
                                            localDrives.map((item, i) => (
                                                <div className="form-group" key={i}>
                                                    <input type="radio" id={`select-drive-` + i} value={item} name="select-drive" onChange={getDirectories}/>
                                                    <label htmlFor={`select-drive-` + i}>
                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-9">
                                <div className="form">
                                    <div className={`account-detail radio radio-button  ${isWindows ? "windows-dir-wrapper" : ""}`}>
                                        <div className={`final-selected-cloud directory-list  ${isWindows ? "windows-dir-list-wrapper" : ""}`}>
                                            {localDir.length > 0 &&
                                                localDir.map((item, i) => (
                                                    <div className="form-group">
                                                        <label data-value={item} title={item.substring(item.lastIndexOf("\\") + 1, item.length)} className="account-card" key={i} onClick={changeDir}>
                                                            {isWindows &&
                                                                item.substring(item.lastIndexOf("\\") + 1, item.length)
                                                            }
                                                            {!isWindows &&
                                                                item.substring(item.lastIndexOf("/") + 1, item.length)
                                                            }
                                                            <i key={i} className='d-none'></i>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            {localDir.length < 1 && selectedPath !== "" &&
                                                <h3>There is no sub directory</h3>
                                            }
                                        </div>
                                    </div>

                                    <div className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Path:</div>
                                            <div className="col-9">
                                                <div className="folder-name" title={selectedPath}>
                                                    {selectedPath}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Folder:</div>
                                            <div className="col-9">
                                                <div className="folder-name">
                                                    {isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                                                    }
                                                    {!isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export {NFS}
