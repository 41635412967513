import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg";
import {Button, Tooltip} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useAuth} from "../context/AuthContext";

const ResetPass = () => {
    const [userDetail, setuserDetail] = useState({
        password: "",
        cpassword: ""
    });
    const {isDark, setPopup500, setError499, setPopup499,checkLength} = useAuth();
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setpassword] = useState("password");
    const [cpassword, setcpassword] = useState("password");
    const [requestPassToken, setRequestPassToken] = useState("password");
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const eightCharsOrMore = /.{8,}/g; // eight characters or more
    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const [meter, setMeter] = useState(false);
    const passwordTracker = {
        uppercase: userDetail.password.match(atLeastOneUppercase),
        lowercase: userDetail.password.match(atLeastOneLowercase),
        number: userDetail.password.match(atLeastOneNumeric),
        specialChar: userDetail.password.match(atLeastOneSpecialChar),
        eightCharsOrGreater: userDetail.password.match(eightCharsOrMore),
    }
    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;

    const inputEvent = (event) => {
        setShowError(false)
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });
    }

    useEffect(() => {
        getRequestToken();
    }, []);

    const Eye = () => {
        if (password == "password") {
            setpassword("text");
        } else {
            setpassword("password");
        }
    }
    const confirmEye = () => {
        if (cpassword == "password") {
            setcpassword("text");
        } else {
            setcpassword("password");
        }
    }

    async function getRequestToken() {
        fetch(config.auth + "/api/authentication/request_token", {
            method: "POST",
        })
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setRequestPassToken(data)
            });
    }

    const reset = (event) => {
        // event.preventDefault();
        if (userDetail.password !== userDetail.cpassword) {
            setErrorText("Must fill out all fields for Reset Password.")
            setShowError(true)
        } else if (!checkLength(userDetail.password, 256)) {
            setErrorText("256 characters max allowed")
            setShowError(true)
        } else if (passwordStrength === 5) {
            setLoading(true);
            fetch(config.auth + '/api/authentication/reset_password', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "requestToken": requestPassToken,
                    "resetToken": window.location.href.split('?token=')[1],
                    "password": userDetail.password
                })
            }).then(res => Promise.all([res.status, res.text()])).then(([status, data]) => {
                if (status === 200) {
                    setLoading(false);
                    setShowSuccess(true)
                } else if (status === 499 || status === 401) {
                    var d = JSON.parse(data)
                    if (d.type === 'AUTH') {
                        setErrorText(d.errors.AUTH[0])
                    }

                    if (d.type === 'INACTIVE_USER') {
                        setErrorText(d.errors.INACTIVE_USER[0])
                    }

                    if (d.type === 'SESSION') {
                        setErrorText(d.errors.SESSION[0])
                    }

                    if (d.type === 'SESSION_MAXOUT') {
                        setErrorText(d.errors.SESSION_MAXOUT[0])
                    }

                    if (d.type === 'DUPLICATE') {
                        setErrorText(d.errors.DUPLICATE[0])
                    }

                    if (d.type === 'COGNITO') {
                        setErrorText(d.errors.COGNITO[0])
                    }

                    if (d.type === 'INVALID_EMAIL') {
                        setErrorText(d.errors.INVALID_EMAIL[0])
                    }
                    if (d.type === 'INTERNAL_ERROR') {
                        setErrorText(d.errors.INTERNAL_ERROR[0])
                    }
                    if (d.type === 'TOKEN') {
                        setErrorText(d.errors.TOKEN[0])
                    }
                    setLoading(false);
                    setShowError(true)
                } else if (status === 500) {
                    setErrorText(data.title)
                    setShowError(true)
                    setLoading(false);
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
            });
        }
    }

    const resetKeyup = (event) => {
        if (event.key === 'Enter') {
            reset();
        }
    }
    return (
        <>
            <div className="login">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    {!showSuccess &&
                        <form className="form">

                            <div className={`form-group  ${showError || (passwordStrength < 5 && userDetail.password !== '') ? "" : ""}`}>
                                <input type={password} className="form-control padding-right" id="password"
                                       placeholder="New Password" value={userDetail.password} onChange={inputEvent}
                                       onFocus={() => setMeter(true)} name="password" onKeyDown={resetKeyup}/>
                                <i onClick={Eye} className={`fa ${password == "password" ? "fa-eye-slash" : "fa-eye"}`}></i>
                            </div>

                            <div className={`form-group  ${showError || (passwordStrength < 5 && userDetail.cpassword !== '') ? "" : ""}`}>
                                <input type={cpassword} className="form-control padding-right" id="cpassword"
                                       placeholder="Confirm Password" value={userDetail.cpassword} onChange={inputEvent}
                                       name="cpassword" onKeyDown={resetKeyup}/>
                                <i onClick={confirmEye} className={`fa ${cpassword == "password" ? "fa-eye-slash" : "fa-eye"}`}></i>
                            </div>
                            <div className="form-group mb-4 mt-4">
                                <div className="position-relative">
                                    <Tooltip placement="bottom"
                                             trigger='hover'
                                             title={
                                                 <div className="security-page error-after-metter">
                                                     <span>Your password must contain:</span>
                                                     <ul className="errors-list">
                                                         <li className={userDetail.password.match(eightCharsOrMore) ? 'active' : ''}><CheckCircleFilled/> Minimum 8 characters</li>
                                                         <li className={userDetail.password.match(atLeastOneUppercase) ? 'active' : ''}><CheckCircleFilled/> 1 upper case letter</li>
                                                         <li className={userDetail.password.match(atLeastOneLowercase) ? 'active' : ''}><CheckCircleFilled/> 1 lower case letter</li>
                                                         <li className={userDetail.password.match(atLeastOneNumeric) ? 'active' : ''}><CheckCircleFilled/> 1 number</li>
                                                         <li className={userDetail.password.match(atLeastOneSpecialChar) ? 'active' : ''}><CheckCircleFilled/> 1 special character (! # $)</li>
                                                     </ul>
                                                 </div>
                                             }
                                             getPopupContainer={trigger => trigger.parentNode}>
                                        <div className="security-meter">
                                            <span className={passwordStrength > 0 ? "active" : ""}></span>
                                            <span className={passwordStrength > 1 ? "active" : ""}></span>
                                            <span className={passwordStrength > 2 ? "active" : ""}></span>
                                            <span className={passwordStrength > 3 ? "active" : ""}></span>
                                            <span className={passwordStrength > 4 ? "active" : ""}></span>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>


                            {showError &&
                                <div className="form-group">
                                    {errorText}
                                </div>
                            }

                            <div className="form-group submit get-started">
                                {/*<input type="submit" className="btn-style" value="Get started" />*/}
                                <Button className="btn-style" onClick={reset} loading={loading}>
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                    }
                    {showSuccess &&
                        <form className="form">
                            <div className="form-group text-center  submit get-started">
                                <span className="success-message mt-0">The password has been changed successfully</span>

                                {/*<input type="submit" className="btn-style" value="Get started" />*/}
                                <Link className="btn-style mt-4" to='/sign-in'>
                                    Back to Sign in
                                </Link>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </>
    )
}

export default ResetPass;

