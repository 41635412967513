import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg"
import {Button, notification} from "antd";
import config from "../config";
import {useAuth} from "../context/AuthContext";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const ForgotPassword = () => {

    const {logout, isDark, getToken,setPopup500,setError499, setPopup499} = useAuth();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorEmpty, setShowErrorEmpty] = useState(false);
    const [userDetail, setuserDetail] = useState({
        email: "",
    });

    const inputEvent = (event) => {
        setShowError(false)
        setShowErrorEmpty(false)
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });

    }

    async function postData(url = '', data = {}) {

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    }

    const reset = (event) => {
        setLoading(true)
        setShowError(false)
        if (userDetail.email !== "") {
            var checkEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (checkEmail.test(userDetail.email)) {
                getToken(config.auth + "/api/authentication/request_token")
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, token]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }

                        if (status === 499) {
                            var data = JSON.parse(token)
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        postData(config.auth + '/api/authentication/forgot_password', {
                            "requestToken": token,
                            "username": userDetail.email
                        })
                            .then((data) => {
                                if (data.status === 401) {
                                    logout()
                                    return;
                                }
                                setLoading(false)
                                localStorage.setItem('userEmail', userDetail.email)
                                navigate("/reset-email")
                            });
                    });
            } else {
                setShowError(true)
                setLoading(false)
            }
        } else {
            setShowErrorEmpty(true)
            setLoading(false)
        }
    }

    const resetKeyup = (event) => {
        if (event.key === 'Enter') {
            reset();
        }

    }

    return (
        <>
            {contextHolder}
            <div className="login">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="form">
                        <h2 className="h2 text-center small-h2">Forgot Password?</h2>
                        <p className="p text-center">Enter your email below and we will send <br/> you a link to reset the password.</p>

                        <div className='fields-wrapper'>
                            <div className={`form-group  ${showError || showErrorEmpty ? "" : ""}`}>
                                <input type="email" id="email" className="form-control" value={userDetail.email}
                                       onChange={inputEvent} onKeyDown={resetKeyup} placeholder="Email" name="email"/>
                            </div>

                            {showError &&
                                <div className="form-group">
                                    <span className="red-error">Incorrect email address</span>
                                </div>
                            }

                            {showErrorEmpty &&
                                <div className="form-group">
                                    <span className="red-error">Please enter your email</span>
                                </div>
                            }
                            <div className="row remember text-center">
                                <div className="col-md-12 bottom-text align-self-center">
                                    Don’t have an Amove account? <Link to="/signup" className="bold">Sign up</Link>
                                </div>
                            </div>
                            <div className="form-group submit get-started">
                                <Button className="btn-style blue" onClick={reset} loading={loading}>
                                    Reset Password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;

