import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg"
import {Button} from "antd";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useAuth} from "../context/AuthContext";

const EmailVerificationNew = () => {
    const {isDark, getToken, setPopup500, logout, setError499, setPopup499} = useAuth();
    const [email, setEmail] = useState();
    const [resendLoading, setResendLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        setEmail(window.localStorage.getItem("signupEmail"))
    }, []);

    async function postData(url = "", data = {}) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        return response.text();
    }

    const handleVerification = () => {
        setResendLoading(true)
        getToken(config.auth + "/api/authentication/request_token")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, token]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(token)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                postData(config.apiURLWeb + "/api/desktop/resend_confirmation_email", {
                    requestToken: token,
                    email: email
                }).then((response) => {
                    setResendLoading(false)
                })
            });
    }
    return (
        <>
            <div className="login email-verification">
                <div className="login-wrapper email-verification-form-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="form new-form">
                        <h2 className="h2">Please check your email.</h2>
                        <p className="p email-verification-text">
                            We have sent an email to <span>{email}</span>. <br/>
                            Check your inbox to activate your account.</p>

                        <div className="p bottom-p">Didn’t receive?</div>
                        <div className="form-group submit">
                            <Button className="btn-style blue max-width-large" onClick={handleVerification} loading={resendLoading}>Resend Email</Button>
                        </div>
                        <div className="form-group submit">
                            <Link to="/" className="arrow">Go Sign In</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmailVerificationNew;

