import React from "react";

import {useAuth} from "../context/AuthContext";

import {Outlet} from 'react-router-dom'
import LoginPage from "../page/LoginPage";

const PrivateRoutes = () => {
    const  {isAuthenticated} = useAuth();
    return(
        isAuthenticated ? <Outlet/> : <LoginPage></LoginPage>
    )
}

export default PrivateRoutes
