import {React, useEffect, useState, useRef} from 'react';
import {Navigate, useSearchParams, useNavigate} from "react-router-dom";
import {Collapse, InputNumber, Modal, notification, Popconfirm, Radio, Select, Pagination, Switch, Tooltip, Button} from 'antd';
import trashIcon from "../assets/images/icons/trash-white-icon.svg";
import settingIcon from "../assets/images/icons/setting-white-icon.svg";
import downArrowIcon from "../assets/images/icons/down-arrow-black-icon.svg";
import keyIcon from "../assets/images/icons/key-icon.svg";
import bucketModalIcon from "../assets/images/icons/bucket-modal-img.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import bucketOrange from "../assets/images/icons/object-bucket-icon.svg";
import copyIcon from "../assets/images/icons/copy-white-icon.svg";

import {useLocation} from 'react-router-dom';
import accessKeyIcon from "../assets/images/icons/access-key-icon.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";

import {useAuth} from "../context/AuthContext";

const AccessKey = () => {
    const {
        cloudAccounts,
        setCloudAccounts,
        deleteDataWeb,
        setEndpoint,
        setShowPopupSupport,
        setPopup500,
        setError499,
        setPopup499,
        user,
        logout,
        prettySize,
        getFormatedDate,
        cloudAccountsDone,
        regions,
        getRegions,
        validBucketName,
        sendDataToWebsocket,
        sendDataToMixPanel,
        getDataWithTokenPOST,
        postDataWebNoResponse,
        getDataWithToken,
        limitExceeded
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const [searchValue, setSearchValue] = useState("");
    const [createBucket, setCreateBucket] = useState(false);
    const [BucketOption, setBucketOption] = useState(false);
    const [deleteBucket, setDeleteBucket] = useState(false);
    const [newAccessKey, setNewAccessKey] = useState(false);
    const [newKey, setNewKey] = useState(false);
    const [secret, setSecretKey] = useState(false);
    const [storageDn, setStorageDn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPopup, setLoadingPopup] = useState(false);
    const [buckets, setBuckets] = useState([]);
    const [selectedBucket, setSelectedBucket] = useState();
    const [selectedRegions, setSelectedRegions] = useState();
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [allBuckets, setAllBuckets] = useState('true');
    const [permissions, setPermissions] = useState("2");
    const [accessKeyName, setAccessKeyName] = useState("");
    const [itemToDelete, setItemToDelete] = useState("");
    const [page, setPage] = useState(1);
    const [pagesize, setPagesize] = useState(30);
    const [totalKeys, setTotalKeys] = useState();
    const [storageKeys, setStorageKeys] = useState([]);
    const [filteredKeys, setFilteredKeys] = useState([]);
    const [pageDataLoaded, setPageDataLoaded] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(limitExceeded){
            navigate("/subscribe");
        }
    }, [limitExceeded]);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getAccessKeys();
    }, [page]);

    const manageBucketCancel = () => {
        setCreateBucket(false);
        setAccessKeyName("");
        setSelectedRegions();
        setSelectedBucket();
        setPermissions("2");
        setAllBuckets('true')
    };
    const manageBucketOpen = () => {
        setCreateBucket(true);
    };

    const bucketOptionCancel = () => {
        setBucketOption(false);
    };

    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };
    const onChangeBucket = (value) => {
        setSelectedBucket(value);
        console.log(value)
    };

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount,
            "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                    if (status === 401) {
                       // logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setBuckets(response)
                    console.log(response)
                }
            );
    }

    const bucketOptionOpen = () => {
        setBucketOption(true);
    };
    const bucketDeleteCancel = () => {
        setDeleteBucket(false);
    };
    const bucketDeleteOpen = () => {
        setDeleteBucket(true);
    };
    const newkeyDeleteCancel = () => {
        setNewAccessKey(false);
    };
    const newkeyOpen = () => {
        setNewAccessKey(true);
    };
    const onToggleSwitch = () => {
        setToggleSwitch(true);
        if (toggleSwitch) {
            setToggleSwitch(false);
        } else {
            setToggleSwitch(true);
        }
    };
    const changeAllBuckets = (e) => {
        setAllBuckets(e.target.value)
        if (e.target.value === "false") {
            getBuckets(selectedRegions)
        } else {
            setSelectedBucket([])
        }
    };
    const changeAllPermissions = (e) => {
        setPermissions(e.target.value)
    };
    const filterAccessKey = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value === '') {
            setFilteredKeys(storageKeys);  // Reset filter if search is empty
        } else {
            const filtered = storageKeys.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredKeys(filtered);
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const createAccessKey = () => {
        var selectedAccount = cloudAccounts.filter(item => item.id === selectedRegions)[0]
        console.log(selectedAccount)
        var dataToPost = {
            "name": accessKeyName,
            "region": selectedAccount.credentialsData,
            "storageDn": selectedAccount.serviceUrl.replace(/(^\w+:|^)\/\//, ''),
            "permission": parseInt(permissions),
            "allBuckets": allBuckets === "true",
            "selectedBuckets": selectedBucket
        }
        console.log(dataToPost)
        setLoading(true)
        postDataWebNoResponse("/api/storage/create_storage_key", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false)
                if (status === 401) {
                    setEndpoint("/api/storage/create_storage_key")
                    setShowPopupSupport(true)
                    // logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    console.log(data)
                    setNewKey(data.accessKey)
                    setSecretKey(data.secretKey)
                    setStorageDn(data.storageDn)
                    manageBucketCancel();
                    getAccessKeys();
                    newkeyOpen();
                    api.open({
                        message: `${accessKeyName} Storage Key has been successfully created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Storage Key has been successfully created.', user.username, {
                        'App': 'Web App',
                        'Storage Key Name': accessKeyName
                    })
                }
            })


    }
    const getAccessKeys = () => {
        setPageDataLoaded(false)
        getDataWithToken(`/api/storage/get_storage_key?page=${page}&pagesize=${pagesize}&sortfield=CreateDate&descending=true`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false)
                if (status === 401) {
                    setEndpoint("/api/storage/get_storage_key")
                    setShowPopupSupport(true)
                    // logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    console.log(data)
                    setFilteredKeys(data.data);
                    setTotalKeys(data.total)
                    setStorageKeys(data.data);
                    dataFetchedRef.current = false;
                    setPageDataLoaded(true)
                }
            })
    }
    const deleteAccessKey = () => {
        setLoading(true)
        deleteDataWeb(`/api/storage/delete_storage_key?id=${itemToDelete}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                setLoading(false)
                bucketDeleteCancel()
                if (status === 401) {
                    setEndpoint("/api/storage/delete_storage_key")
                    setShowPopupSupport(true)
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let newArr = [...storageKeys];
                    newArr = newArr.filter((item) => item.id !== itemToDelete)
                    setStorageKeys(newArr)
                    newArr = [...filteredKeys];
                    newArr = newArr.filter((item) => item.id !== itemToDelete)
                    setFilteredKeys(newArr);
                    api.open({
                        message: 'Access Key removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                }
            });

    }
    const onChangePage = (value) => {
        setPage(value)
        setStorageKeys([]);
    };

    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <>{contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    <div className="storage-dashboard-main bucket-dashboard-main">
                        <div className="storage-region">
                            <h3>Access Key</h3>
                            <a href="#" className="btn-style" onClick={(e)=>{
                                e.preventDefault();
                                manageBucketOpen()
                            }}>Create Access Key</a>
                            {(storageKeys.length > 0 || page > 1) &&
                                <div className="search-wrapper cloud-brands-wrapper">Total Keys: {totalKeys}
                                    <input type="text" className="form-control search-icon right"
                                           value={searchValue}
                                           placeholder="Search Access Key"
                                           onChange={filterAccessKey}/>
                                </div>
                            }
                        </div>
                        <div className="enable-region-wrapper">
                            <div className="table-wrapper">
                                <div className="table-header grid-6">
                                    <p>Name <img src={downArrowIcon} alt="icon"/></p>
                                    <p>Description <img src={downArrowIcon} alt="icon"/></p>
                                    <p>Access key ID <img src={downArrowIcon} alt="icon"/></p>
                                    <p>Region <img src={downArrowIcon} alt="icon"/></p>
                                    <p>Create on <img src={downArrowIcon} alt="icon"/></p>
                                    <p></p>
                                </div>
                                <div className="table-body position-relative">
                                    {pageDataLoaded &&
                                        <>
                                            {filteredKeys.length > 0 && filteredKeys.map((item) => (
                                                <div className="table-row grid-6" key={item.id}>
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={keyIcon} className="bucket-icon" alt="icon"/>
                                                            <p>{item.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <div className="copy-wrapper">
                                                            <p>{item.description.replace("ReadWrite", "Read/Write")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>{item.accessKey}</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>{item.region}</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>{getFormatedDate(item.createDate)}</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li><a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setItemToDelete(item.id);
                                                            }}><img src={trashIcon} alt="icon" onClick={bucketDeleteOpen}/></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                    {!pageDataLoaded &&
                                        <>
                                            <div className="lds-roller small">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {totalKeys > pagesize &&
                                <Pagination current={page} total={totalKeys} pageSize={pagesize}
                                            onChange={onChangePage}/>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Create a Bucket" className="bucket-main-modal" centered open={createBucket} onOk={manageBucketCancel}
                   onCancel={manageBucketCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-9">
                            <div className="name-wrapper">
                                <img src={accessKeyIcon} alt="icon"/>
                                <h3>Create Access Key</h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="cross">
                                <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    manageBucketCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Name</label>
                                    <input type="text" id="bucket-name" value={accessKeyName} className="form-control" onChange={(e) => {
                                        setAccessKeyName(e.target.value);
                                    }} placeholder="Introduce a Name"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Enable Regions</label>
                                    <Select
                                        className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select a Region"
                                        onChange={onChangeRegoin}
                                        value={selectedRegions}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={cloudAccounts.filter((item) => (item.internalStorage)).map((item) => ({
                                            label: item.name.substring(7, item.name.length - 1), value: item.id,
                                        }))}
                                    />
                                </div>
                            </div>
                            {/*<div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="key-expiry">Access Key Expiry</label>
                                    <div className="checkbox-wrapper">
                                        <input type="checkbox" id="set-date" checked={isChecked}
                                               onChange={handleCheckboxChange}  />
                                        <label htmlFor="set-date">Set expiry date and time</label>
                                    </div>
                                    <input type="date" id="key-expiry" className={`form-control ${isChecked ? '' : 'disabled'}`} />
                                </div>
                            </div>*/}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <p className="label">Assign access permission</p>
                                    <Radio.Group onChange={changeAllPermissions} value={permissions}>
                                        <Radio value="0">
                                            Read
                                        </Radio>
                                        <Radio value="1">
                                            Write
                                        </Radio>
                                        <Radio value="2">
                                            Read and write
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className={`col-md-6 ${selectedRegions === undefined ? "disabled" : ""}`}>
                                <div className="form-group">
                                    <p className="label">Assign buckets</p>
                                    <Radio.Group onChange={changeAllBuckets} value={allBuckets}>
                                        <Radio value="true">
                                            All buckets
                                        </Radio>
                                        <Radio value="false">
                                            Select buckets
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {allBuckets === "false" &&
                                    <div className="form-group">
                                        <label htmlFor="bucket-name">Select Buckets</label>
                                        <Select
                                            className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                            showSearch
                                            mode="multiple"
                                            optionFilterProp="children"
                                            placeholder="Choose a buckets"
                                            onChange={onChangeBucket}
                                            value={selectedBucket}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={buckets.map((item) => ({
                                                label: item.name, value: item.name,
                                            }))}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loading} className={`btn-style ${selectedRegions === undefined || accessKeyName === "" ? "disabled" : ""}`} onClick={createAccessKey}>Create Key Access</Button></li>
                        <li>
                            <Button className="btn-style transparent" onClick={manageBucketCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
            <Modal title="Delete Access Key" className="bucket-main-modal" centered open={deleteBucket} onOk={bucketDeleteCancel}
                   onCancel={bucketDeleteCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-8">
                            <div className="name-wrapper">
                                <img src={trashIcon} alt="icon"/>
                                <h3>Delete Access Key</h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cross">
                                <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    bucketDeleteCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="trash-wrapper">
                        <p>Are you sure you want to delete this Access Key?
                            This action cannot be undone. <br/>
                            Please confirm if you wish to proceed.</p>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loading} className="btn-style trash" onClick={deleteAccessKey}>Delete Access Key</Button></li>
                        <li>
                            <Button className="btn-style transparent" onClick={bucketDeleteCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
            <Modal title="New Access Key" className="bucket-main-modal" centered open={newAccessKey} onOk={newkeyDeleteCancel}
                   onCancel={newkeyDeleteCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="name-wrapper">
                                <img src={keyIcon} alt="icon"/>
                                <h3>New Access Key</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cross">
                                <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    newkeyDeleteCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="new-key-wrapper">
                        <p>A new access key has been created. It will only appear
                            here once. <br/> Copy and save the details for future use.</p>
                        <ul className="options">
                            <li>
                                <strong>Endpoint </strong>
                                <p>{storageDn} <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(storageDn);
                                    api.open({
                                        message: 'Endpoint Copied.',
                                        icon: <img src={toastIcon} alt="toastIcon"/>,
                                        duration: 3,
                                    });
                                }}><img src={copyIcon} alt="icon"/></a></p>
                            </li>
                            <li>
                                <strong>Access Key ID </strong>
                                <p>{newKey} <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(newKey);
                                    api.open({
                                        message: 'Access Key ID Copied.',
                                        icon: <img src={toastIcon} alt="toastIcon"/>,
                                        duration: 3,
                                    });
                                }}><img src={copyIcon} alt="icon"/></a></p>
                            </li>
                            <li>
                                <strong>Secret Access Key </strong>
                                <p>{secret} <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(secret);
                                    api.open({
                                        message: 'Secret Access Key Copied.',
                                        icon: <img src={toastIcon} alt="toastIcon"/>,
                                        duration: 3,
                                    });
                                }}><img src={copyIcon} alt="icon"/></a></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loading} className="btn-style" onClick={(e) => {
                                e.preventDefault();
                                download("access-key.txt", `Endpoint: ${storageDn}
Access Key ID: ${newKey}
Secret Access Key: ${secret}`)
                            }}>Download</Button></li>
                        <li>
                            <Button className="btn-style transparent" onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(`Endpoint: ${storageDn}
Access Key ID: ${newKey}
Secret Access Key: ${secret}`);
                                api.open({
                                    message: 'Access Key Copied.',
                                    icon: <img src={toastIcon} alt="toastIcon"/>,
                                    duration: 3,
                                });
                            }}>Copy All</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default AccessKey;

