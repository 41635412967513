import React, {useEffect, useState, useRef} from "react";
import SettingHeader from "../components/Setting/SettingHeader";
import {DrivesSettings} from "../components/DrivesSettings";
const DriveSettings = () => {
    return (
        <>
            <div id="main-content" className={``}>
                <div className="container-fluid activity-page">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                    <div className="main-content-body dashboard-home log-page  add-cloud-account-page">
                        <div className="cloud-brands-wrapper choose-cloud-wrapper">
                            <div className="left-content">
                                <h3 className="mb-41">Drive Settings</h3>
                            </div>
                        </div>
                    </div>
                        <div className="account-detail-body home">
                        <DrivesSettings/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriveSettings;

