import React, {useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg"
import {LoadingOutlined} from '@ant-design/icons';
import {Button, notification, Spin} from "antd";
import warning from "../assets/images/icons/warning-sign.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const VerificationCode = ({length = 4}) => {

    const {postData, logout, isDark,
        sendDataToMixPanel,
        user} = useAuth();
    const navigate = useNavigate();
    const [code, setCode] = useState([...Array(length)].map(() => ""));
    const inputs = useRef([]);
    const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);
    const [showError, setShowError] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num)) return;
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
    };

    const onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
            const newCode = [...code];
            newCode[slot - 1] = "";
            setCode(newCode);
            inputs.current[slot - 1].focus();
        }
    };


    const verifyEmail = (event) => {
        event.preventDefault();
        var finalCode = code.join("");
        setLoading(true);
        postData('/Authentication/confirm_signup', {
            "email": localStorage.getItem("signupEmail"),
            "token": finalCode
        })
            .then((data) => {

                if (data.status === 401) {
                    logout()
                    return;
                }
                if (data.status === 499) {
                    var msg = "";
                    for (var i = 0; i < data.errors.DEFAULT.length; i++) {
                        msg += data.errors.DEFAULT + " ";
                    }
                    setLoading(false);
                    api.open({
                        message: 'Error',
                        description: msg,
                        icon: <img src={warning} alt="warning"/>,
                        duration: 5,
                        placement: "top"
                    });
                }
                if (data.status === 400) {
                    setShowError(true)
                    setLoading(false);
                } else if (data === false) {
                    api.open({
                        message: 'Error',
                        description:
                            `Incorrect Code`,
                        icon: <img src={warning} alt="warning"/>,
                        duration: 5,
                        placement: "top"
                    });
                    setLoading(false);
                } else if (data === true) {
                    navigate("/sign-in")
                }
            });
    }

    // Example POST method implementation:
    async function postEmailData(url = '', data) {
        try {

            const response = await fetch(config.apiURL + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-App-Origin": "WebApp"
                },
                body: JSON.stringify(data)
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    }


    const resendEmail = (event) => {
        event.preventDefault();
        var finalCode = code.join("");
        setLoadingResend(true);
        postEmailData('/Authentication/resend_confirmation_email', {
            "email": localStorage.getItem("signupEmail"),
        })
            .then((data) => {
                if (data.status === 401) {
                    logout()
                    return;
                }

                if (data.status === 200) {
                    api.open({
                        message: 'Verification email resent.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                        placement: "top"
                    });


                    sendDataToMixPanel('Verification email resent.', user.username, {
                        'App': 'Web App',
                        'Email': localStorage.getItem("signupEmail")
                    })
                    setLoadingResend(false);
                } else if (data.status === 499) {
                    api.open({
                        message: 'Your account is already verified.',
                        description:
                            `Please go to the sign in page.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                        placement: "top"
                    });

                    sendDataToMixPanel('Your account is already verified.', user.username, {
                        'App': 'Web App',
                        'Email': localStorage.getItem("signupEmail")
                    })
                    setLoadingResend(false);
                    navigate("/sign-in")
                }
            });
    }

    return (
        <>
            {contextHolder}
            <div className="login email-verification">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="form">
                        <h2 className="h2">Enter 4-Digit Code</h2>
                        <p className="p">We sent it to {localStorage.getItem("signupEmail")}</p>
                        <div className="form-group">
                            <div className="code-input">
                                {code.map((num, idx) => {
                                    return (
                                        <input
                                            key={idx}
                                            type="text"
                                            inputMode="numeric"
                                            maxLength={1}
                                            value={num}
                                            autoFocus={!code[0].length && idx === 0}
                                            readOnly={loading}
                                            onChange={e => processInput(e, idx)}
                                            onKeyUp={e => onKeyUp(e, idx)}
                                            ref={ref => inputs.current.push(ref)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        {showError &&
                            <div className="form-group error-verification-code">
                                <span className="red-error">Please enter correct 4 digit code.</span>
                            </div>
                        }
                        <div className="form-group submit">
                            {/*<Link to="/reset-password" className="btn-style">Verify email</Link>*/}
                            <Button className="btn-style" onClick={verifyEmail} loading={loading}>
                                Verify email
                            </Button>
                        </div>
                        <div className="form-group submit">
                            <div className="p">Didn’t receive the email? <a href="/" onClick={resendEmail} className={`bold ${loadingResend ? "active" : ""}`} id="resendEmail">Click to resend {loadingResend &&
                                <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                        color: "#EA4903"
                                    }}
                                    spin
                                />}/>
                            }</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VerificationCode;

