import React, {useEffect} from "react";
import PageLoading from "../components/PageLoading";
import {useNavigate} from "react-router-dom";

const UserCallBack = () => {
        const navigate = useNavigate();
        useEffect(() => {
                const url = window.location.search;
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code')
                navigate("/SSO?code="+code);
            }, []
        )

        return (
            <>
                <PageLoading></PageLoading>
            </>
        );
    }
;

export default UserCallBack;
