import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Modal, notification, Select, Tooltip} from 'antd';
import azureIcon from "../assets/images/azure-ad-icon.svg";
import oktaIcon from "../assets/images/okta-icon.svg";
import samlIcon from "../assets/images/saml-logo.png";
import settingIcon from "../assets/images/setting-gray-icon.svg";
import leftArrowIcon from "../assets/images/left-arrow-orange-icon.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {useNavigate} from "react-router-dom";
import searchIcon from "../assets/images/icons/search-gray-icon.svg";
import {BillingPopup} from "../components/BillingPopup";
import SettingHeader from "../components/Setting/SettingHeader";
import info from "../assets/images/info.svg";

const SingleSignProvider = () => {
    const {
        user,
        token,
        updateDataWithToken,
        setShowHeaderAndSidebar,
        userStatus,
        setBillingPopupOpen,
        reloadSSO,
        setReloadSSO,
        reloadDeleteSSO,
        setReloadDeleteSSO,
        sendDataToWebsocket,
        postDataWithToken,
        logout,
        setPopup500,
        getDataWithToken,
        sendDataToMixPanel
    } = useAuth();

    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [SSOType, setSSOType] = useState("");
    const [step1, setStep1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [SSOAvailable, setSSOAvailable] = useState(false);
    const [azureAD, setAzureAD] = useState(false);
    const [okta, setOkta] = useState(false);
    const [saml, setSaml] = useState(false);
    const [isOktaUser, setOktaUser] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [importUsersDataWithoutFilter, setImportUsersDataWithoutFilter] = useState([]);
    const [importUsersData, setImportUsersData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [adError, setAdError] = useState('');
    const [isSelectUser, setSelectUser] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [azureADIntegration, setAzureADIntegration] = useState({
        "clientId": "",
        "clientSecret": "",
        "openIdURL": "",
        "active": true
    });
    const [oktaIntegration, setOktaIntegration] = useState({
        "clientId": "",
        "clientSecret": "",
        "openIdURL": "",
        "active": true
    });
    const [samlIntegration, setSamlIntegration] = useState({
        "certificate": "",
        "spEntityId": config.SPEntityID,
        "idPSSOURL": "",
        "active": true
    });

    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (reloadSSO) {
            setReloadSSO(false)
            loadSSO()
        }
    }, [reloadSSO]);

    useEffect(() => {
        if (reloadDeleteSSO) {
            setAzureAD(false);
            var dataToUpdate = {
                "clientId": "",
                "clientSecret": "",
                "openIdURL": "",
                "active": true
            }
            setAzureADIntegration(dataToUpdate);

            setOkta(false)
            var dataToUpdate = {
                "clientId": "",
                "clientSecret": "",
                "openIdURL": "",
                "active": true
            }
            setOktaIntegration(dataToUpdate)
            setSaml(false)
            var dataToUpdate = {
                "certificate": "",
                "spEntityId": "",
                "idPSSOURL": "",
                "active": true
            }
            setSamlIntegration(dataToUpdate)
            setReloadDeleteSSO(false)
            setSSOAvailable(false)
            setSSOType("");
            setStep1(true)
        }
    }, [reloadDeleteSSO]);
    const selectOktaOk = () => {
        setOktaUser(false);
    };

    const selectOktaCancel = () => {
        setOktaUser(false);
    };

    const onSelectUserRoleImport = (e, item) => {

        let newArray = [...importUsersData];
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i].user.id === item.user.id) {
                newArray[i].selected = e.target.checked;
            }
        }
        setImportUsersData(newArray);
    };
    const onSelectUserRoleImportSelectAll = (e) => {
        let newArray = [...importUsersData];
        for (var i = 0; i < newArray.length; i++) {
            newArray[i].selected = e.target.checked;
        }
        setImportUsersData(newArray);
    };


    const importUsers = () => {
        var dataToPost = []

        for (var i = 0; i < importUsersData.length; i++) {
            if (importUsersData[i].selected === true) {
                var data = {
                    user: importUsersData[i].user,
                    userGroups: importUsersData[i].userGroups,
                }
                dataToPost.push(data)
            }

        }

        if (dataToPost.length > 0) {
            postDataResponseText('/api/usergroup/import_users', dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setOktaUser(false);
                    setImportUsersData([])
                    setImportUsersDataWithoutFilter([])
                    api.open({
                        message: `Importing users started in background.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('Importing users started in background.', user.username, {
                        'App': 'Web App'
                    })
                })
        }
    };
    const onChangeUserRoleImport = (value, item) => {
        var userType = 32;
        if (value === "Admin") {
            userType = 16;
        }

        let newArray = [...importUsersData];
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i].user.id === item.user.id) {
                newArray[i].user.userType = userType;
            }
        }
        setImportUsersData(newArray);
    };

    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const filterImportUsers = (e) => {
        setSearchValue(e.target.value)
        let newArray = importUsersDataWithoutFilter.filter((item) => (item.user.email?.toLowerCase().includes(e.target.value) || item.user.firstname?.toLowerCase().includes(e.target.value) || item.user.lastname?.toLowerCase().includes(e.target.value)));

        setImportUsersData(newArray)
    };

    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        loadSSO();
    }, []);

    const loadSSO = () => {
        getDataWithToken("/api/sso/get_entraId")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 200) {
                data = JSON.parse(data)
                var dataToUpdate = {
                    "clientId": data.clientId,
                    "clientSecret": data.clientSecret,
                    "openIdURL": data.openIdURL,
                    "active": data.active,
                    "id": data.id
                }
                setAzureADIntegration(dataToUpdate)
                setAzureAD(true)
                setSSOAvailable(true)
            }
        })
        getDataWithToken("/api/sso/get_okta")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                if (status === 200) {
                data = JSON.parse(data)
                var dataToUpdate = {
                    "clientId": data.clientId,
                    "clientSecret": data.clientSecret,
                    "openIdURL": data.openIdURL,
                    "active": data.active,
                    "id": data.id
                }
                setOktaIntegration(dataToUpdate)
                setOkta(true)
                setSSOAvailable(true)
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');
                window.history.pushState({}, null, "/SSO");
                if (code !== null) {
                    setImportUsersData([])
                    setLoadingData(true);
                    setShowHeaderAndSidebar(true)
                    getDataWithTokenWeb(`/api/sso/get_user_usergroups?authorizationCode=${code}&callbackUrl=${config.callbackURL}/usercallback`)
                            .then((res) => Promise.all([res.status, res.json()]))
                            .then(([status, response]) => {
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    setAdError("Access Denied.")
                            setLoadingData(false);
                                } else {
                                    for (var i = 0; i < response.length; i++) {
                                        response[i].selected = false;
                                    }
                                    setImportUsersDataWithoutFilter(response)
                                    setImportUsersData(response)
                                    setLoadingData(false);
                                }
                    })
                    setOktaUser(true);
                    setSelectUser(false)
                }
            }
        })
        getDataWithToken("/api/sso/get_saml")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                if (status === 200) {
                data = JSON.parse(data)
                setSaml(true)
                setSSOAvailable(true)
                var dataToUpdate = {
                    "certificate": data.certificate,
                    "spEntityId": data.spEntityId,
                    "idPSSOURL": data.idPSSOURL,
                    "active": data.active,
                    "id": data.id
                }
                setSamlIntegration(dataToUpdate)
            }
            setDataLoaded(true)
            setStep1(true)
        })

    }

    async function deleteDataWeb(url) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
        });
        sendDataToWebsocket(config.apiURLWeb + url, "DELETE")
        return response;
    }

    const inputEvent = (event) => {
        setShowError(false);
        const name = event.target.name;
        const value = event.target.value;
        setAzureADIntegration((oldValues) => {
            return {
                ...oldValues,
                [name]: value,
            };
        });
    };

    const inputEvent2 = (event) => {
        setShowError(false);
        const name = event.target.name;
        const value = event.target.value;
        setOktaIntegration((oldValues) => {
            return {
                ...oldValues,
                [name]: value,
            };
        });
    };
    const inputEvent3 = (event) => {
        setShowError(false);
        const name = event.target.name;
        var value = event.target.value;

        if (name === "certificate") {
            value = value.replace(/\n/g, '\\n')
        }
        setSamlIntegration((oldValues) => {
            return {
                ...oldValues,
                [name]: value,
            };
        });
    };

    async function getDataWithTokenWeb(url) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
        };
        return await fetch(config.apiURLWeb + url, requestOptions);
    }

    const showOktaUser = () => {
        postDataWithToken('/api/sso/sso_url_import_user', {
            "callbackUrl": config.callbackURL + "/usercallback"
        })
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                window.location.href = response.url;
            })
    };

    const showADUser = () => {
        setImportUsersData([])
        setLoadingData(true);
        getDataWithToken(`/api/sso/get_user_usergroups`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }

                if (status === 499) {
                    setAdError("Access Denied.")
                    setLoadingData(false);
                } else {
                    for (var i = 0; i < response.length; i++) {
                        response[i].selected = false;
                    }
                    setLoadingData(false);
                    setImportUsersDataWithoutFilter(response)
                    setImportUsersData(response)
                }
            })
        setOktaUser(true);
        setSelectUser(false)
    };

    const setupAzure = () => {
        setLoading(true)
        if (azureAD) {
            updateDataWithToken("/api/sso/update_entraId", azureADIntegration)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    setLoading(false)
                    api.open({
                        message: 'Azure AD Updated Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    setStep1(true)
                    setSSOType("");
                })
        } else {
            postDataWithToken("/api/sso/setup_entraId", azureADIntegration)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    var dataToUpdate = {
                        "clientId": data.clientId,
                        "clientSecret": data.clientSecret,
                        "openIdURL": data.openIdURL,
                        "active": data.active,
                        "id": data.id
                    }
                    setAzureADIntegration(dataToUpdate)
                    setLoading(false)
                    api.open({
                        message: 'Azure AD Connected Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    sendDataToMixPanel('Azure AD Connected Successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setStep1(true)
                    setAzureAD(true)
                    setSSOAvailable(true)
                    setSSOType("")
                })
        }
    }

    const setupSaml = () => {
        setLoading(true)
        if (saml) {
            updateDataWithToken("/api/sso/update_saml", samlIntegration)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    setLoading(false)
                    api.open({
                        message: 'SAML Updated Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('SAML Updated Successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setStep1(true)
                    setSSOType("")
                })
        } else {
            postDataWithToken("/api/sso/setup_saml", samlIntegration)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    var dataToUpdate = {
                        "certificate": data.certificate,
                        "spEntityId": data.spEntityId,
                        "idPSSOURL": data.idPSSOURL,
                        "active": data.active,
                        "id": data.id
                    }
                    setSaml(true)
                    setSamlIntegration(dataToUpdate)
                    setLoading(false)
                    api.open({
                        message: 'SAML Connected Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('SAML Connected Successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setStep1(true)
                    setSSOAvailable(true)
                    setSSOType("")
                })
        }
    }
    const removeAzure = () => {
        deleteDataWeb("/api/sso/delete_entraId")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }

                setAzureAD(false);
                var dataToUpdate = {
                    "clientId": "",
                    "clientSecret": "",
                    "openIdURL": "",
                    "active": true
                }
                setAzureADIntegration(dataToUpdate)
                if (!reloadDeleteSSO) {
                    api.open({
                        message: 'Azure AD removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Azure AD removed successfully.', user.username, {
                        'App': 'Web App'
                    })

                }
                setReloadDeleteSSO(false)
                setSSOAvailable(false)
                setSSOType("");
                setStep1(true)
                onGoBack()
            })
    }
    const removeOkta = () => {
        deleteDataWeb("/api/sso/delete_okta")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }

                setOkta(false)
                var dataToUpdate = {
                    "clientId": "",
                    "clientSecret": "",
                    "openIdURL": "",
                    "active": true
                }
                setOktaIntegration(dataToUpdate)
                if (!reloadDeleteSSO) {
                    api.open({
                        message: 'Okta removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Okta removed successfully.', user.username, {
                        'App': 'Web App'
                    })

                }
                setReloadDeleteSSO(false)
                setSSOAvailable(false)
                setSSOType("");
                setStep1(true)
                onGoBack()
            })
    }
    const removeSaml = () => {
        deleteDataWeb("/api/sso/delete_saml")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }

                setSaml(false)
                var dataToUpdate = {
                    "certificate": "",
                    "spEntityId": "",
                    "idPSSOURL": "",
                    "active": true
                }
                setSamlIntegration(dataToUpdate)
                if (!reloadDeleteSSO) {
                    api.open({
                        message: 'SAML removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('SAML removed successfully.', user.username, {
                        'App': 'Web App'
                    })
                }
                setReloadDeleteSSO(false)
                setSSOAvailable(false)
                setSSOType("");
                setStep1(true)
                onGoBack()
            })
    }
    const setupOkta = () => {
        setLoading(true)
        if (okta) {
            updateDataWithToken("/api/sso/update_okta", oktaIntegration)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    setLoading(false)
                    api.open({
                        message: 'Okta Updated Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Okta Updated Successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setStep1(true)
                    setSSOType("")
                })
        } else {
            postDataWithToken("/api/sso/setup_okta", oktaIntegration)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    setLoading(false);
                    var dataToUpdate = {
                        "clientId": data.clientId,
                        "clientSecret": data.clientSecret,
                        "openIdURL": data.openIdURL,
                        "active": data.active,
                        "id": data.id
                    }
                    setOktaIntegration(dataToUpdate)
                    api.open({
                        message: 'Okta Connected Successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Okta Connected Successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setOkta(true)
                    setStep1(true)
                    setSSOType("")
                    setSSOAvailable(true)
                })
        }
    }
    const onAzureAd = () => {
        setSSOType("azure");
        setStep1(false)
    }
    const onOkta = () => {
        setSSOType("okta")
        setStep1(false)
    }
    const onSaml = () => {
        setSSOType("saml")
        setStep1(false)
    }
    const onGoBack = () => {
        setStep1(true)
        setSSOType("")
    }
    return (
        <>{contextHolder}
            <div id="main-content" className="single-signin-provider">
                <div className="container-fluid">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                        {(step1 === true) &&
                            <div className="sigin-on-steps">
                                <div className="single-signin-wrapper">
                                    <h2>SSO Authentication</h2>
                                    {!SSOAvailable && dataLoaded &&
                                        <div className="single-signin-card-wrapper">
                                            {!azureAD &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={azureIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>Azure AD</h3>
                                                            {azureAD &&
                                                                <Button className={`btn-style mr-2`} onClick={showADUser}>Import Users</Button>
                                                            }
                                                            {azureAD &&
                                                                <a href="#" className="btn-style" onClick={onAzureAd}>Update</a>
                                                            }
                                                            {!azureAD &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onAzureAd()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {!okta &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={oktaIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>Okta</h3>
                                                            {okta &&
                                                                <Button className={`btn-style mr-2`} onClick={showOktaUser}>Import Users</Button>
                                                            }
                                                            {okta &&
                                                                <a href="#" className="btn-style" onClick={onOkta}>Update</a>
                                                            }

                                                            {!okta &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onOkta()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {!saml &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={samlIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>SAML</h3>
                                                            {saml &&
                                                                <Button className={`btn-style mr-2`} onClick={showOktaUser}>Import Users</Button>
                                                            }
                                                            {saml &&
                                                                <a href="#" className="btn-style" onClick={onSaml}>Update</a>
                                                            }

                                                            {!saml &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onSaml()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {SSOAvailable && dataLoaded &&
                                        <div className="single-signin-card-wrapper">
                                            {azureAD &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={azureIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>Azure AD</h3>
                                                            {azureAD &&
                                                                <Button className={`btn-style mr-2`} onClick={showADUser}>Import Users</Button>
                                                            }
                                                            {azureAD &&
                                                                <a href="#" className="btn-style" onClick={onAzureAd}>Update</a>
                                                            }
                                                            {!azureAD &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onAzureAd()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {okta &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={oktaIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>Okta</h3>
                                                            {okta &&
                                                                <Button className={`btn-style mr-2`} onClick={showOktaUser}>Import Users</Button>
                                                            }
                                                            {okta &&
                                                                <a href="#" className="btn-style" onClick={onOkta}>Update</a>
                                                            }

                                                            {!okta &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onOkta()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {saml &&
                                                <div className="single-signin-area">
                                                    <div className="single-signin-card">
                                                        <div className="img-wrapper">
                                                            <img src={samlIcon} alt="icon"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3>SAML</h3>
                                                            {saml &&
                                                                <a href="#" className="btn-style" onClick={onSaml}>Update</a>
                                                            }

                                                            {!saml &&
                                                                <a href="#" className="btn-style" onClick={() => {
                                                                    if (userStatus.status === "active") {
                                                                        onSaml()
                                                                    } else {
                                                                        setBillingPopupOpen(true);
                                                                    }
                                                                }}>Configure</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {(SSOType === "okta") &&
                            <div className="sigin-on-steps">
                                <a href="#" className="back-btn" onClick={onGoBack}><img src={leftArrowIcon} alt="icon"/> Back</a>
                                <div className="single-signin-wrapper">
                                    <h2>Okta Connect</h2>
                                    <div className="form-wrapper">
                                        <div className="form">
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" onChange={inputEvent2} className="form-control" name="clientId" value={oktaIntegration.clientId} placeholder="Client ID"/>
                                            </div>
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" className="form-control" name="clientSecret" value={oktaIntegration.clientSecret}
                                                       onChange={inputEvent2} placeholder="Client Secret"/>
                                            </div>
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" onChange={inputEvent2} className="form-control" name="openIdURL" value={oktaIntegration.openIdURL} placeholder="OpenID URL"/>
                                            </div>
                                            <div className="form-group submit">

                                                {okta &&
                                                    <Button className={`btn-style mr-2`} onClick={removeOkta}>Remove</Button>
                                                }
                                                <Button loading={loading} className={`btn-style ${oktaIntegration.clientId === "" || oktaIntegration.clientSecret === "" || oktaIntegration.openIdURL === "" ? "disabled" : ""}`} onClick={setupOkta}>{okta ? "Save" : "Connect"}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(SSOType === "azure") &&
                            <div className="sigin-on-steps">
                                <a href="#" className="back-btn" onClick={onGoBack}><img src={leftArrowIcon} alt="icon"/> Back</a>
                                <div className="single-signin-wrapper">
                                    <h2>Azure AD Connect</h2>
                                    <div className="form-wrapper">
                                        <div className="form">
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" className="form-control" name="clientId" value={azureADIntegration.clientId}
                                                       onChange={inputEvent} placeholder="Application (Client) ID"/>
                                            </div>
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" className="form-control" name="clientSecret" value={azureADIntegration.clientSecret}
                                                       onChange={inputEvent} placeholder="Client Secret"/>
                                            </div>
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" className="form-control" name="openIdURL" value={azureADIntegration.openIdURL}
                                                       onChange={inputEvent} placeholder="Open Id URL"/>
                                            </div>
                                            <div className="form-group submit">
                                                {azureAD &&
                                                    <Button className={`btn-style mr-2`} onClick={removeAzure}>Remove</Button>
                                                }
                                                <Button loading={loading} className={`btn-style ${azureADIntegration.clientId === "" || azureADIntegration.clientSecret === "" || azureADIntegration.openIdURL === "" ? "disabled" : ""}`} onClick={setupAzure}>{azureAD ? "Save" : "Connect"}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(SSOType === "saml") &&
                            <div className="sigin-on-steps">
                                <a href="#" className="back-btn" onClick={onGoBack}><img src={leftArrowIcon} alt="icon"/> Back</a>
                                <div className="single-signin-wrapper">
                                    <h2>SAML Connect</h2>
                                    <div className="form-wrapper">
                                        <div className="form">
                                            <div className="form-group">
                                                <h3>SAML Configuration: Amove Details for IDP Setup</h3>
                                                <h3 className="info-wrapper">Single sign-on URL
                                                    <Tooltip
                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                        placement="topLeft"
                                                        title={<div>Single Sign-On (SSO) URL: Use this URL in your IDP settings to direct SAML assertions to our application. It serves as the endpoint for your IDP to send authenticated responses.</div>}>
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip></h3>
                                                <p>{config.SSOURL} <a href="#" className="fa-regular fa-copy float-end" onClick={(e) => {
                                                    navigator.clipboard.writeText(config.SSOURL);

                                                    api.open({
                                                        message: 'Single sign-on URL Copied Successfully.',
                                                        icon: <img src={toastIcon} alt="toastIcon"/>,
                                                        duration: 3
                                                    });
                                                    e.preventDefault()
                                                }}></a></p>
                                                <h3 className="info-wrapper">Audience URI (SP Entity ID)
                                                    <Tooltip
                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                        placement="topLeft"
                                                        title={<div>Service Provider Entity ID: This unique identifier for our application is required by your Identity Provider (IDP) to establish a trust relationship. Copy and paste it into your IDP configuration.</div>}>
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip></h3>
                                                <p>{config.SPEntityID} <a href="#" className="fa-regular fa-copy float-end" onClick={(e) => {
                                                    navigator.clipboard.writeText(config.SPEntityID)
                                                    api.open({
                                                        message: 'Audience URI Copied Successfully.',
                                                        icon: <img src={toastIcon} alt="toastIcon"/>,
                                                        duration: 3
                                                    });
                                                    e.preventDefault()
                                                }}></a></p>
                                            </div>
                                            <div className="form-group">
                                                <textarea className="form-control" name="certificate" value={samlIntegration.certificate}
                                                          onChange={inputEvent3} placeholder="Certificate"/>
                                            </div>
                                            <div className="form-group">
                                                <img src={settingIcon} alt="icon" className="icon"/>
                                                <input type="text" className="form-control" name="idPSSOURL" value={samlIntegration.idPSSOURL}
                                                       onChange={inputEvent3} placeholder="IDP SSO URL"/>
                                            </div>
                                            <div className="form-group submit">
                                                {saml &&
                                                    <Button className={`btn-style mr-2`} onClick={removeSaml}>Remove</Button>
                                                }
                                                <Button loading={loading} className={`btn-style ${samlIntegration.certificate === "" || samlIntegration.idPSSOURL === "" ? "disabled" : ""}`} onClick={setupSaml}>{saml ? "Save" : "Connect"}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal title="Import users from AD/Okta" className="okta-user-type" centered open={isOktaUser} onOk={selectOktaOk} onCancel={selectOktaOk}>
                <div className="okta-user-modal-wrapper">
                    <div className="header">
                        {azureAD &&
                            <h3>Import users from AD</h3>
                        }
                        {okta &&
                            <h3>Import users from Okta</h3>
                        }
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Search users by name or email..." value={searchValue} onChange={filterImportUsers}/>
                                    <img src={searchIcon} className="icon" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="search-user-wrapper">
                            <div className="select-all">
                                <Checkbox
                                    className={importUsersData?.length > 0 ? "" : "disabled"}
                                    onChange={onSelectUserRoleImportSelectAll}
                                    checked={importUsersData?.filter((item) => (item.selected === true)).length === importUsersData.length && importUsersData.length > 0}
                                >Select all</Checkbox>
                                <div className="search-user">
                                    {loadingData &&
                                        <div className="loading-wrapper">
                                            <div className="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    }
                                    {adError !== "" &&
                                        <div className="success-message-login red full-width">
                                            <span>{adError}</span>
                                        </div>
                                    }
                                    {importUsersData?.map((item) => (
                                        <div className="search-user-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <Checkbox onChange={(value) => {
                                                        onSelectUserRoleImport(value, item)
                                                    }}
                                                              checked={item.selected}
                                                    >
                                                        <div className="detail">
                                                            <p>{item.user.firstname} {item.user.lastname}</p>
                                                            <span>{item.user.email}</span>
                                                        </div>
                                                    </Checkbox>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="select-role">
                                                        <Select
                                                            defaultValue="Select role"
                                                            value={item.user.userType === 32 || item.user.userType === 64 ? 'Standard User' : 'Admin'}
                                                            onChange={(value) => {
                                                                onChangeUserRoleImport(value, item)
                                                            }}
                                                            style={{width: 196}}
                                                            options={[
                                                                {value: 'Admin', label: 'Admin'},
                                                                {value: 'Standard User', label: 'Standard User'},
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent" onClick={selectOktaCancel}>Cancel</a>
                        <a href="#" className={`btn-style blue ${importUsersData.length > 0 ? "" : "disabled"}`} onClick={importUsers}>Import</a>
                    </div>
                </div>
            </Modal>
            <BillingPopup></BillingPopup>
        </>
    )
}

export default SingleSignProvider;

