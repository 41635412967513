import React, {useEffect} from "react";
import {useAuth} from "../context/AuthContext";
const AutoLogin = () => {
    const {setSignUpWithGoogle,logoutONSignup
    } = useAuth();
    useEffect(() => {
        const url = window.location.search;
        const signup = url.split("token=")[1];
        if (signup !== '') {
            setSignUpWithGoogle(true);
            logoutONSignup(signup)
        }
    }, []);
    return (
        <>
        </>
    );
};

export default AutoLogin;
