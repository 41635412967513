import React, {useEffect, useState, useRef} from "react";
import {notification, Progress, Tooltip} from "antd";
import {useAuth} from "../../context/AuthContext";
import SettingHeader from "../../components/Setting/SettingHeader";
import {Button} from 'antd';
import QRCode from "react-qr-code";
import toastIcon from "../../assets/images/icons/toast-icon.svg";
import config from "../../config";

const MFASetup = () => {
    const {
        user,
        postDataWithToken,
        getUserData,
        getToken,
        setJWTToken,
        logout,
        setPopup500,
        sendDataToWebsocket,
        sendDataToMixPanel
    } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const controller = useRef(null);
    const [secretKey, setSecretKey] = useState("");
    const [qRCode, setQRCode] = useState("");
    const [mfaCode, setMfaCode] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [showError, setShowError] = useState(true);
    const [userNeedLogin, setUserNeedLogin] = useState(false);
    const [request, setRequest] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            if (!user.mfa) {
                postDataWithToken("/api/user/generate_mfa_token")
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, response]) => {
                        if (status === 200) {
                            var issuer = "Amove";
                            var str = "otpauth://totp/" + user.username + "?secret=" + response + "&issuer=" + issuer;
                            setSecretKey(response)
                            setQRCode(str)
                            setUserNeedLogin(false)
                        } else {
                            response = JSON.parse(response)
                            setUserNeedLogin(true)
                        }
                        setRequest(true)
                    })
            }
        }
    }, [user])
    const inputEvent = (event) => {
        setMfaCode(event.target.value);
    }
    const inputEventPassword = (event) => {
        setPassword(event.target.value);
        setErrorText("");
        setShowError(false);
    }
    const setMFA = (val) => {
        var dataToPost = {
            "enabled": val,
            "userCode": mfaCode
        }
        postDataWithToken("/api/user/set_mfa", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                setMfaCode("");
                sendDataToMixPanel(`MFA setup ${val ? "enabled" : "disabled"} successfully.`, user.username, {
                    'App': 'Web App'
                })
                getUserData(true)
            })
    }
    async function postDataNoResponse(url = "", data = {}) {
        controller.current = new AbortController();
        sendDataToWebsocket( url, "POST")
        return await fetch( url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'accept': 'text/plain',
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
    }

    const login = (event) => {
        if (user.username !== "" && password !== "") {
            setLoading(true);
            getToken(config.auth + "/api/authentication/request_token")
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, token]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    postDataNoResponse(config.auth + "/api/authentication/login", {
                        username: user.username,
                        password: password,
                        requestToken: token,
                        type: 48,
                    })
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, data]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var d = data;
                                if (d.type === "AUTH") {
                                    setErrorText(d.errors.AUTH[0]);
                                    setLoading(false);
                                }

                                if (d.type === "INACTIVE_USER") {
                                    setErrorText(d.errors.INACTIVE_USER[0]);
                                    setLoading(false);
                                }

                                if (d.type === "SESSION") {
                                    setErrorText(d.errors.SESSION[0]);
                                    setLoading(false);
                                }

                                if (d.type === "SESSION_MAXOUT") {
                                    setErrorText(d.errors.SESSION_MAXOUT[0]);
                                    setLoading(false);
                                }

                                if (d.type === "DUPLICATE") {
                                    setErrorText(d.errors.DUPLICATE[0]);
                                    setLoading(false);
                                }

                                if (d.type === "COGNITO") {
                                    setErrorText(d.errors.COGNITO[0]);
                                    setLoading(false);
                                }

                                if (d.type === "INVALID_EMAIL") {
                                    setErrorText(d.errors.INVALID_EMAIL[0]);
                                    setLoading(false);
                                }
                                if (d.type === "INTERNAL_ERROR") {
                                    setErrorText(d.errors.INTERNAL_ERROR[0]);
                                }
                                setLoading(false);
                                setShowError(true);
                            }
                            if (status === 500) {
                                setErrorText(data.title);
                                setShowError(true);
                                setLoading(false);
                            }
                            if (data.jwtToken !== undefined) {
                                setJWTToken(data.jwtToken);
                            }
                        });
                });

        }
    };
    return (
        <> {contextHolder}
            <div id="main-content" className={`mfa-page`}>
                <div className="container-fluid mb-4">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                        <h2 className="small-title">MFA Settings</h2>
                        <div className="account-detail-body">
                            <div className="account-detail-card">
                                {Object.keys(user).length !== 0 &&
                                    <div className="info-detail">
                                        {!user.mfa && !userNeedLogin && request &&
                                            <ul>
                                                <li>1. Install a compatible app on your mobile device or computer such as Google Authenticator</li>
                                                <li>2. Please scan this QR code <br/> <br/>
                                                    {secretKey !== "" &&
                                                        <QRCode
                                                            size={1024}
                                                            style={{height: "auto", maxWidth: "100%", width: "160px"}}
                                                            value={qRCode}
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    }
                                                </li>
                                                <li>3. Alternatively, you can type the secret key <br/><br/>
                                                    <span>{secretKey}</span></li>
                                                <li>4. Type MFA code below <br/><br/>
                                                    <div className={`form-group`}>
                                                        <input type="number" value={mfaCode} className="form-control" onChange={inputEvent} placeholder="Enter MFA code"/>
                                                    </div>
                                                    <Button className={`btn-style small-btn ${mfaCode.length < 6 || mfaCode.length > 6 ? "disabled" : ""}`} onClick={() => {
                                                        setMFA(true)
                                                    }}>Enable MFA</Button>
                                                </li>
                                            </ul>
                                        }

                                        {userNeedLogin && request &&
                                            <ul>
                                                <li>Enter your password to access MFA<br/><br/>
                                                    <div className={`form-group`}>
                                                        <input type="password" value={password} className="form-control" onChange={inputEventPassword} placeholder="Password"/>
                                                    </div>
                                                    {showError &&
                                                        <div className={`form-group`}>
                                                            <span className="red-error">{errorText}</span>
                                                        </div>
                                                    }
                                                    <Button className={`btn-style small-btn `} onClick={() => {
                                                        login()
                                                    }}>Verify</Button>
                                                </li>
                                            </ul>
                                        }
                                        {user.mfa &&
                                            <ul>
                                                <li>Type MFA code below <br/><br/>
                                                    <div className={`form-group`}>
                                                        <input type="number" value={mfaCode} className="form-control" onChange={inputEvent} placeholder="Enter MFA code"/>
                                                    </div>
                                                    <Button className={`btn-style small-btn ${mfaCode.length < 6 || mfaCode.length > 6 ? "disabled" : ""}`} onClick={() => {
                                                        setMFA(false)
                                                    }}>Disable MFA</Button>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MFASetup;

