import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {LoadingOutlined} from '@ant-design/icons';
import pageLogo from "../../assets/images/page-logo.svg";
import pageLogoWhite from "../../assets/images/logo-dark.svg";
import {Button, Modal, Radio, Spin} from 'antd';
import {useAuth} from '../../context/AuthContext';

const BillingStatus = () => {
    const navigate = useNavigate();
    const {
        token,
        user,
        isDark,
        setUser,
        checkUserStatus,
        updateDataWithToken,
        userStatus,
        getDataWithTokenPOST,
        loading,
        setLoading,
        logout,
        setPopup500,
        sendDataToMixPanel,
        setError499,
        setPopup499
    } = useAuth();
    const [changeSubscriptionModal, setChangeSubscriptionModal] = useState(false);
    const [subscriptionTypeVal, setSubscriptionTypeVal] = useState("");
    useEffect(() => {
        setLoading(false)
    }, [])
    useEffect(() => {
        setSubscriptionTypeVal(user.subscriptionType)
    }, [user]);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 84,
            }}
            spin
        />
    );

    const convertDate = unix_date => {
        return new Date(unix_date).toDateString();
    }
    const diffDate = (d1, d2) => {
        // To set two dates to two variables
        var date1 = new Date(d1);
        var date2 = new Date(d2);
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return Math.round(Difference_In_Days);
    }
    const handleUnsubscribe = () => {
        setLoading(true);
        getDataWithTokenPOST('/api/desktop/billing_unsubscribe')
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(response)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    checkUserStatus(token)
                    setLoading(false);
                }

                sendDataToMixPanel('User billing package unsubscribed.', user.username, {
                    'App': 'Web App'
                })
                setLoading(false)
            })
    }
    const GoToPayment = () => {
        setLoading(true);
        navigate("/subscribe")
    }
    const showModal = () => {
        setChangeSubscriptionModal(true);
    };
    const closeModal = () => {
        setChangeSubscriptionModal(false);
    };
    const save = () => {
        setLoading(true)
        updateDataWithToken('/api/user/update_subscription', {
            "subscriptionType": subscriptionTypeVal
        })
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(response)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setUser(prevState => ({
                    ...prevState,
                    subscriptionType: subscriptionTypeVal
                }));
                sendDataToMixPanel('User subscription type updated.', user.username, {
                    'App': 'Web App'
                })
                setLoading(false)
                setChangeSubscriptionModal(false);
            })
    };
    const onChangeSubscription = (e) => {
        setSubscriptionTypeVal(e.target.value)
    };

    return (
        <>
            <div className="login billingStatus email-verification">
                {user.owner &&
                    <div className="login-wrapper">
                        <div className="text-center">
                            <Link to="#" className="logo">
                                <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                            </Link>
                        </div>
                        {(userStatus && !loading) ? <>
                            <div className="form">
                                {userStatus.status === "trial" && (
                                    <>
                                        <h2 className="fs-2 fw-semibold text-capitalize">Subscription Status: <br/> {diffDate(userStatus.currentPeriodStart, userStatus.currentPeriodEnd)} Day Free {userStatus.status}</h2>
                                        <p className="mb-4">Start Date: {convertDate(userStatus.currentPeriodStart)} <br/> End Date: {convertDate(userStatus.currentPeriodEnd)}</p>
                                        <p className="mb-0">Payment required at the end of the trial period.</p>
                                        <div className="d-flex unsubscribeButton mx-auto mt-3">
                                            <Button className="btn-style full" loading={loading} disabled={loading} onClick={GoToPayment}>
                                                Go To Payment Plan
                                            </Button>
                                        </div>
                                    </>
                                )
                                }
                                {userStatus.status === "active" && (
                                    <>
                                        <h2 className="fs-2 fw-semibold text-capitalize">Subscription Status: <br/> Paid</h2>
                                        <p className="mb-3"><p className="m-0 text-right">Start Date: {convertDate(userStatus.currentPeriodStart)}</p> Next Payment Date: {convertDate(userStatus.currentPeriodEnd)} <br/></p>
                                        <div className="d-flex unsubscribeButton mx-auto">
                                            <Button className="btn-style full" loading={loading} disabled={loading} onClick={handleUnsubscribe}>
                                                Unsubscribe
                                            </Button>
                                        </div>
                                    </>
                                )
                                }
                                {userStatus.status === "unpaid" && (
                                    <>
                                        <h2 className="fs-2 fw-semibold text-capitalize">Your {diffDate(userStatus.currentPeriodStart, userStatus.currentPeriodEnd)} Day Trial <br/> Has Expired</h2>
                                        <p className="p">Please choose a Payment Plan <br/>
                                            to access your Amove account.</p>
                                        <div className="d-flex unsubscribeButton mx-auto">
                                            <Button className="btn-style full" loading={loading} disabled={loading} onClick={GoToPayment}>
                                                Go To Payment Plan
                                            </Button>
                                        </div>
                                    </>
                                )
                                }

                                {/* <Button className="btn-style change-subscription-type" onClick={showModal}>
                                    Change Subscription Type
                                </Button>*/}

                            </div>
                        </> : <>
                            <div className="form">
                                <Spin indicator={antIcon}/>
                            </div>
                        </>
                        }
                    </div>
                }

                {!user.owner &&
                    <div className="login-wrapper not-owner-text">
                        <div className="text-center">
                            <Link to="#" className="logo">
                                <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                            </Link>
                        </div>
                        <div className="form ">
                            <p>Your subscription is currently inactive. <br/>
                                Please contact the account owner.</p>
                        </div>
                    </div>
                }
            </div>
            {user.owner &&
                <Modal title="Add New User" className="select-user-type" centered open={changeSubscriptionModal} onOk={closeModal} onCancel={closeModal}>
                    <div className="select-user-modal-wrapper">
                        <div className="header  text-center">
                            <h3>Change Subscription Type For Invited Users</h3>
                        </div>
                        <div className="body">
                            <div className="text-center mb-4">
                                <div className="read-write-radio">
                                    <Radio.Group onChange={(e) => {
                                        onChangeSubscription(e)
                                    }} value={subscriptionTypeVal}>
                                        <Radio value={1}>
                                            Monthly
                                        </Radio>
                                        <Radio value={2}>
                                            Annually
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="select-wrapper text-center">
                                <Button loading={loading} className="btn-style blue" onClick={save}>Save</Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default BillingStatus
