import React, {useEffect, useState, useRef} from "react";
import {CheckCircleFilled} from "@ant-design/icons";
import speedIcon from "../assets/images/icons/migration-speed-meter-icon.svg";
import bytesIcon from "../assets/images/icons/migration-bytes-icon.svg";
import syncIcon from "../assets/images/icons/sync-icon-lighter.svg";
import Icon, {CloseOutlined, DatabaseOutlined} from '@ant-design/icons';
import {NavLink, Link} from "react-router-dom";
import {notification, Progress, Pagination,Popconfirm} from "antd";
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import config from "../config";
import moment from 'moment'

const cache = new Map();
const LandingPage = () => {
    const {
        user, getDataWithToken, getFormatedDate, setError499, setPopup499, token, setPopup500, logout, prettySize, toTitleCase, getDataWithTokenPOST,
        migrationsProcessing,
        speed_stat,
        transferred_stat,
        remaining_stat,
        reloadMigrationStatus,
        setReloadMigrationStatus
    } = useAuth();
    const [statusData, setStatusData] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesize, setPagesize] = useState(30);
    const [totalStatus, setTotalStatus] = useState();


    useEffect(() => {
        if (reloadMigrationStatus) {
            setReloadMigrationStatus(false);
            getTransferData()
        }
    }, [reloadMigrationStatus]);
    const onChangePage = (value) => {
        setPage(value)
    };

    useEffect(() => {
        if (user?.migration) {
            getTransferData()
        }
    }, [user, page])

    const getTransferData = () => {
        console.log("getTransferData Called ---------------")
        getDataWithToken(`/api/transfer/get_all?page=${page}&pagesize=${pagesize}&sortfield=RequestDate&descending=true&type=15`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setTotalStatus(data.total)
                    setStatusData(data.data);
                }
            });
    };
    const cancelTransfer = (id) => {
        getDataWithTokenPOST("/api/transfer/cancel_transfer", {
            "id": id
        })
            .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
            if (res.status === 200) {
            }
            if (data.status === 401) {
                // logout();
                //  return;
            }
        })
    };

    function getPercent(b, s) {
        return Math.ceil(((b / s) * 100));
    }

    return (<>
        <div id="main-content" className={`landing-page ${user?.migration ? "" : "disabled"}`}>
            <div className="container-fluid">
                <div className="sec-migration-status">
                    <div className="migration-wrapper">
                        <h2>Migration Status</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="migration-card">
                                    <div className="status-wrapper">
                                        <div className="icon-wrapper">
                                            <img src={speedIcon} alt="icon"/>
                                        </div>
                                        <div className="content">
                                            <p>Speed</p>
                                            <strong>{speed_stat}</strong>
                                        </div>
                                    </div>
                                    <div className="update-status">
                                        <span>
                                            <img src={syncIcon} alt="icon"/>
                                            <p>Updated Now</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="migration-card">
                                    <div className="status-wrapper">
                                        <div className="icon-wrapper">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="content">
                                            <p>Transferred / Skipped</p>
                                            <strong>{transferred_stat}</strong>
                                        </div>
                                    </div>
                                    <div className="update-status">
                                        <span>
                                            <img src={syncIcon} alt="icon"/>
                                            <p>Updated Now</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="migration-card">
                                    <div className="status-wrapper">
                                        <div className="icon-wrapper">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="content">
                                            <p>Remaining</p>
                                            <strong>{remaining_stat}</strong>
                                        </div>
                                    </div>
                                    <div className="update-status">
                                        <span>
                                            <img src={syncIcon} alt="icon"/>
                                            <p>Updated Now</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="speed-wrapper">
                        {migrationsProcessing?.map((item, ind) => {
                            if (item.type === 0 || item.type === 3) {
                                return (<div className="speed-card" key={ind}>
                                    <div className="status-detail">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <p>Path: <strong>{item.sourcePath}</strong></p>
                                            </div>
                                            <div className="col-md-5 progress-wrapper-transfer delete-wrapper">
                                                {item.totalFileSize > 0 && <Progress percent={getPercent((item.skippedSize + item.totalFileTransferedSize), item.totalFileSize)} status={item.status === 4 ? 'exception' : ''}/>}


                                                <Popconfirm
                                                    placement="topLeft"
                                                    title="Are you sure you want to cancel this transfer?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onConfirm={() =>
                                                        cancelTransfer(item.transferID)}
                                                >
                                                    <a href="#" className="close-icon" onClick={(e) => {
                                                        e.preventDefault();
                                                    }}>
                                                        <CloseOutlined/>
                                                    </a>
                                                </Popconfirm>
                                            </div>
                                        </div>

                                        {/* {
                                            "batchTransferID": "3fa85f64-5717-4562-b3fc-5c983f56afa5",
                                            "transferID": "3fa85f64-5717-4562-b3fc-5c983f56afa5",
                                            "totalFileNumber": 100,
                                            "totalSendFileNumber": 40,
                                            "totalFileSize": 9541026946,
                                            "totalFileTransferedSize": 5041026946,
                                            "averageSpeed": 0,
                                            "durationTime": 0,
                                            "comment": "string",
                                            "type": 0,
                                            "sourceCloud": "AWS",
                                            "sourceBucket": "test",
                                            "sourcePath": "test.png",
                                            "destinationCloud": "Wasabi",
                                            "destinationBucket": "test2",
                                            "destinationPath": "test2.png",
                                            "userID": "75f1a33d-c64d-4ebd-973d-fa6a789b59b9",
                                            "statusDateTime": "2024-07-20T17:41:18.573Z",
                                            "succeeded": 25,
                                            "failed": 5,
                                            "percent": 0,
                                            "destinationCloudID": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                            "sourceCloudID": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                            "key": "string",
                                            "skippedSize": 1026946,
                                            "skipped": 10}*/}
                                        <div className="row">
                                            <div className="col-md-7">
                                                <p>{item.sourceBucket} —> {item.destinationBucket}</p>
                                                <p>Total Size: <strong>{item.type === 0 ? "Calculating..." : prettySize(item.totalFileSize)}</strong></p>
                                                <p>Total Transferred size: <strong className="green">{item.type === 0 ? "Calculating..." : prettySize(item.totalFileTransferedSize)}</strong></p>
                                                <p>Total Remaining size: <strong className="green">{item.type === 0 ? "Calculating..." : prettySize(item.totalFileSize - (item.totalFileTransferedSize + item.skippedSize))}</strong></p>
                                                <p>Average Speed: <strong>{item.type === 0 ? "Calculating..." : ((item.averageSpeed > 0 ? toTitleCase(prettySize(item.averageSpeed * 8) + 'ps') : '0') + "/s")}</strong></p>
                                            </div>
                                            <div className="col-md-5">
                                            <p>Total: <strong>{item.type === 0 ? "Calculating..." : item.totalFileNumber}</strong></p>
                                                <p>Transferred: <strong className="green">{item.type === 0 ? "Calculating..." : item.succeeded}</strong></p>
                                                <p>Skipped: <strong className="green">{item.type === 0 ? "Calculating..." : item.skipped}</strong></p>
                                                <p>Failed: <strong className="orange">{item.type === 0 ? "Calculating..." : item.failed}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                    <h2>Messages</h2>
                    <ul>
                        {/* {messages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}*/}
                    </ul>
                    <div className="speed-wrapper">
                        <h3>Transfers speed</h3>
                        {statusData?.map((item, ind) => (<div className="speed-card" key={ind}>
                            <div className="status-wrapper">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="path">
                                            <p>{item.sourceBucket} —> {item.destinationBucket}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="status">
                                            <p>{moment.parseZone(item.requestDate).local().format('DD/MM/YYYY HH:mm a')}&nbsp;Status:&nbsp;
                                                {(item.transferStatus === 1) && <span>Start Transfer</span>}
                                                {(item.transferStatus === 2) && <span>In Progress</span>}
                                                {(item.transferStatus === 4) && <span>Completed</span>}
                                                {(item.transferStatus === 8) && <span>Canceled</span>}
                                                {(item.transferStatus === 16) && <span>Failed</span>}

                                                {(item.transferStatus === 128) && <span>Paused</span>}

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="status-detail">
                                <div className="row">
                                    <div className="col-md-7">
                                        <p>Path: <strong>{item.sourcePath}</strong></p>
                                        <p>Total Size: <strong>{prettySize(item.totalSize)}</strong></p>
                                        <p>Total Transferred size: <strong className="green">{prettySize(item.transferredSize)}</strong></p>
                                        <p>Average Speed: <strong>{prettySize(item.averageSpeed)}/s</strong></p>
                                    </div>
                                    <div className="col-md-5">
                                        <p>Total: <strong>{item.total}</strong></p>
                                        <p>Transferred: <strong className="green">{item.transferred}</strong></p>
                                        <p>Skipped: <strong className="green">{item.skipped}</strong></p>
                                        <p>Failed: <strong className="orange">{item.failed}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>))}

                        {totalStatus > pagesize && <Pagination current={page} total={totalStatus} showSizeChanger={false} pageSize={pagesize}
                                                               onChange={onChangePage}/>}
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default LandingPage;
