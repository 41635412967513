import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import editIcon from "../assets/images/icons/edit-red-icon.svg";
import {Modal, Select, Button, notification, Tooltip, Popconfirm, Switch} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {CloudDrives} from "../components/CloudDrives";
import AgentOffPopup from "../components/AgentOffPopup";

const ChooseClouds = () => {
    const {
        cloudAccounts,
        isWindows,
        getData,
        logout,
        getIcon,
        agentIsRunning,
        defaultOpenCloudDrive,
        setDefaultOpenCloudDrive,
        user,
        token,
        getCloudAccounts,
        userStatus,
        sendDataToMixPanel,
        sendDataToWebsocket,
        sync,
        setPopup500,
        postDataNewFolder,
        getDataMethodGet,
        setPopupSupport,
        setEndpoint,
        setShowPopupSupport,
        setError499,
        setPopup499,
        validBucketName,
        checkLength
    } = useAuth();

    const [local, setlocal] = useState("drive");
    const [localPreCache, setLocalPreCache] = useState("");
    const [localCachePath, setLocalCachePath] = useState("");
    const [step, setStep] = useState(1);
    const [isActive, setActive] = useState(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [openPreCache, setOpenPreCache] = useState(false);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [Objects, setObjects] = useState(10000);
    const [isChecked, setisChecked] = useState(false);
    const [localDrives, setLocalDrives] = useState([]);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [previousPath, setPreviousPath] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const [localDrivesPreCache, setLocalDrivesPreCache] = useState([]);
    const [localDirPreCache, setLocalDirPreCache] = useState([]);
    const [previousPathPreCache, setPreviousPathPreCache] = useState("");
    const [availableLocalDrives, setAvailableLocalDrives] = useState([]);
    const [selectedAvailableDrive, setSelectedAvailableDrive] = useState("Z");
    const [bucket, setBucket] = useState(null);
    const [yourCloudDriveName, setYourCloudDriveName] = useState("");
    const [folderName, setFolderName] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [cloudDrives, setCloudDrives] = useState([]);
    const [backupInstance, setBackupInstance] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [cacheSettings, setcacheSettings] = useState('stream');
    const [directoryType, setDirectoryType] = useState('automatic');
    const [cacheSize, setcacheSize] = useState(50);
    const [objects, setobjects] = useState(10000);
    const [cacheSettingsGBTB, setcacheSettingsGBTB] = useState('GB');
    const [newBucketName, setNewBucketName] = useState("");
    const [limitExceed, setLimitExceed] = useState(false);
    const [newBucketCreated, setNewBucketCreated] = useState(false);
    const [localCacheEncrypted, setLocalCacheEncrypted] = useState(true);
    const [rotationalCache, setRotationalCache] = useState(true);
    const onChangeAllowDelete = (e) => {
        setAllowDelete(false)
    };
    const changeLocal = async (event) => {
        await getdrives();
        await getAvailableDrives()
        setlocal(event.target.value);
        if (event.target.value === 'folder') {
            var p = selectedPath.substring(0, selectedPath.lastIndexOf("\\"))
            setPreviousPath(p)
            showModal()
        }
    };
    const onCheckLocalCacheEncrypted = (e) => {
        setLocalCacheEncrypted(e);
    };


    const showModal = () => {
        setOpen(true);
    };

    // run code for dirHandle
    const handleOkPreCache = () => {
        setOpenPreCache(false);
        setStep(4);
    };

    const handleCancel = () => {
        setOpen(false);
        setOpenPreCache(false);
    };
    const popupClosed = () => {
        setOpen(false);
        setOpenPreCache(false);
        if (localCachePath.length < 4) {
            setDirectoryType("automatic");
        }
    };
    const createBucket = () => {
        var dataToPost = {
            "cloudAccountName": "Amove",
            "bucketName": newBucketName,
            "shared": false
        }
        if (validBucketName(newBucketName) && newBucketName.length > 2 && newBucketName.length < 64) {
            setLoadingCreate(true)
            postRequest("/api/cloudaccount/idrive_create_bucket?bucketName=" + newBucketName + "&cloudAccountId=" + selectedCloudAccount.id)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        setEndpoint("/api/cloudaccount/idrive_create_bucket")
                        setShowPopupSupport(true)

                        // logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 499) {
                        if (data.type === 'DEFAULT') {
                            setErrorText(data.errors.DEFAULT[0])
                            api.open({
                                message: 'Error',
                                description: data.errors.DEFAULT[0],
                                icon: <img src={warning} alt="warning"/>,
                                duration: 3
                            });
                        }
                        setLoadingCreate(false);
                    } else {
                        setNewBucketCreated(true);
                        getBuckets(selectedCloudAccount)

                        api.open({
                            message: `${newBucketName} bucket has been successfully created.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });

                        sendDataToMixPanel('Bucket has been successfully created.', user.username, {
                            'App': 'Web App',
                            'Bucket Name': newBucketName
                        })

                        setNewBucketName("");
                        setLoadingCreate(false);
                    }
                })
        }

    };
    const confirmButton = (e) => {
        setisChecked(true)
        setAllowDelete(true)
    };
    const cancelButton = (e) => {
        setisChecked(false)
    };
    const confirm = (e) => {
        if (folderName !== '') {
            postDataNewFolder('/Management/make_directory', {
                "directoryName": folderName, 
                "path": previousPathPreCache
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    sendDataToMixPanel('New Folder Created.', user.username, {
                        'App': 'Web App',
                        'Folder Name': folderName
                    })

                    getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(previousPathPreCache))
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }
                            setPreviousPathPreCache(previousPathPreCache)
                            setLocalDirPreCache(response)
                            const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                            elements.forEach((element) => {
                                element.classList.remove('active');
                            });
                        })
                });
            setFolderName('');
        }
    };
    const cancel = (e) => {
        setFolderName('');
    };
    const selectCloudDrive = (event) => {
        event.preventDefault();
        if (!limitExceed) {
            const id = event.target.value;
            const index = event.target.getAttribute('data-index');
            const logo = event.target.getAttribute('data-name');
            var newArry = [...cloudAccounts].filter((item) => (item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024))
            getBuckets(newArry[index])
            setSelectedCloudAccount(newArry[index])
            setSelectedAvailableDrive('')
            setStep(2)
        }
    }


    function getBuckets(account) {
        postData("/Cloud/list_buckets", account)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setSelectedBuckets(response);
            });
    }


    const changeCloudDrive = (event) => {
        event.preventDefault();
        setSelectedAvailableDrive('')
        setBucket(null)
        setShowAdvanceSettings(false)
        setStep(1)
    };
    const onChange = async (value) => {
        setBucket(value)
        await getdrives();
        await getAvailableDrives();
    };

    const onSearch = (value) => {
    };


    function getdrives() {
        getDataMethodGet('/Management/list_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setLocalDrives(response)
                setLocalDrivesPreCache(response)
            })
    }

    function getAvailableDrives() {
        getDataMethodGet('/Management/list_available_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                setAvailableLocalDrives(response)
            })
    }

    function getDirectoriesPreCache(e) {
        setLocalCachePath(e.target.value)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setPreviousPath(e.target.value)
                setPreviousPathPreCache(e.target.value)

                const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                elements.forEach((element) => {
                    element.classList.remove('active');
                });
                setLocalDirPreCache(response)
            })
    }

    var singleClickTimer;
    var clickCount = 0;

    function changeDirPreCache(e) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        setLocalCachePath(val)
        clickCount++;
        if (clickCount === 1 && e.detail == 1) {
            singleClickTimer = setTimeout(function () {
                clickCount = 0;
            }, 1000);
        } else if (clickCount === 2 || e.detail == 2) {
            clearTimeout(singleClickTimer);
            clickCount = 0;
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setPreviousPathPreCache(val)
                    setLocalDirPreCache(response)

                    const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')

                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                })
        }
    }

    function goBackPreCache() {
        var val = ""
        if (previousPathPreCache.lastIndexOf("\\") > 2) {
            val = previousPathPreCache.substring(0, previousPathPreCache.lastIndexOf("\\"));
        } else {
            val = previousPathPreCache.substring(0, previousPathPreCache.lastIndexOf("\\") + 1);
        }
        const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setPreviousPathPreCache(val)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setLocalDirPreCache(response)
            })
    }

    const cloudDriveName = (event) => {
        const name = event.target.value;
        setYourCloudDriveName(name)
        setShowCloudDriveNameError(false)
        setShowCloudDriveNameLengthError(false)
        if (name.length > 1) {
            var result1 = cloudDrives.filter(item => item.name.toLowerCase() === name.toLowerCase());
            var result2 = backupInstance.filter(item => item.name.toLowerCase() === name.toLowerCase());

            if (result1.length > 0 || result2.length > 0) {
                setActive(false);
                setShowCloudDriveNameError(true)
                setShowCloudDriveNameLengthError(false)
            } else {
                if (!checkLength(name, 32)) {
                    setShowCloudDriveNameLengthError(true)
                } else {
                    setActive(true);
                }
            }
        } else {
            setActive(false);
            setShowCloudDriveNameLengthError(false)
        }
    }
    const newFolderName = (event) => {
        const folderName = event.target.value;
        setFolderName(folderName);
    }

    // Example POST method implementation:
    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(config.apiURL + url, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'accept': 'text/plain', "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data)
        });
        return response;
    }

    async function postRequest(url = '') {
        // Default options are marked with *
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token, "X-App-Origin": "WebApp"
            },
        });

        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }


    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token, "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }


    const createSharedCloudDrive = () => {
        setLoading(true)
        var selectedBucket = selectedBuckets.filter((item) => (item.name === bucket))[0];
        var dataToPost = {
            "cloudAccountId": selectedCloudAccount.id,
            "name": yourCloudDriveName.trim(),
            "objectsPerFolder": Objects,
            "allowDelete": allowDelete,
            "storageId": selectedBucket.id,
            "storageName": bucket,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
        }
        postDataWeb("/api/sharedclouddrive/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                api.open({
                    message: yourCloudDriveName.trim() + ' Drive has been successfully created.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                });
                setLoading(false)
                sendDataToMixPanel('Drive has been successfully set up.', user.username, {
                    'App': 'Web App', 'Drive Name': yourCloudDriveName.trim()
                })
                AssignUserToDrive(response.id)
                setTimeout(function () {
                    setDefaultOpenCloudDrive(true)
                    setLoading(false)
                }, 3000)
            })
    };


    const AssignUserToDrive = (sharedCloudDriveId) => {
        var dataToPost = [{
            "userId": user.userId, "sharedCloudDriveId": sharedCloudDriveId, "permissionType": 2
        }]
        postDataResponseText("/api/permission/users_sharedclouddrive_permision", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                sendDataToMixPanel('Assign New User To Drive.', user.username, {
                    'App': 'Web App'
                })

                sync('/Management/sync_shareddrive')
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                        }
                    });

            })

    };


    async function postDataWeb(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token, "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const setupCloudDrive = () => {
        var driveType = 1;
        var cSetting = 1;
        var cachePath = localCachePath;

        var cSize = (cacheSize * 1024 * 1024 * 1024);
        if (local === 'folder') {
            driveType = 2;
            cSize = 0;
        }
        if (cacheSettingsGBTB === "TB") {
            cSize = cSize * 1024;
        }
        if (cacheSettings === "stream") {
            cSetting = 1;
        }
        if (cacheSettings === "mirror") {
            cSetting = 2;
        }
        var localPath = '';

        if (driveType === 1) {
            localPath = selectedAvailableDrive;
        }
        if (driveType === 2) {
            localPath = selectedPath;
            cachePath = selectedPath;
        }

        if (directoryType === 'automatic') {
            cachePath = "$$$";
        }

        if (!isWindows) {
            localPath = "A";
            cachePath = "/";
        }

        var selectedBucket = selectedBuckets.filter((item) => (item.name === bucket))[0];

        var dataToPost = {
            "name": yourCloudDriveName.trim(),
            "driveName": localPath,
            "storageId": selectedBucket.id,
            "storageName": bucket,
            "permanentLocalPath": cachePath,
            "driveType": driveType,
            "syncType": cSetting,
            "rotationalCache": rotationalCache,
            "maximumCacheSize": cSize,
            "objectsPerFolder": objects,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
            "account": {
                "id": selectedCloudAccount.id,
                "accountId": selectedCloudAccount.accountId,
                "cloudType": selectedCloudAccount.cloudType,
                "name": selectedCloudAccount.name,
                "accessKey": selectedCloudAccount.accessKey,
                "secretKey": selectedCloudAccount.secretKey,
                "credentialsData": selectedCloudAccount.credentialsData,
                "serviceUrl": selectedCloudAccount.serviceUrl,
                "active": selectedCloudAccount.active,
                "internalStorage": selectedCloudAccount.internalStorage,
                "deleted": selectedCloudAccount.deleted
            },
        }

        if (selectedCloudAccount.cloudIconType === 'storj' || selectedCloudAccount.cloudIconType === 'Amove') {
            dataToPost.transferOptions = {
                "chunkSize": 64 * 1024 * 1024,
            }
        }
        if (!limitExceed) {
            postData('/Management/clouddrive', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    api.open({
                        message: yourCloudDriveName.trim() + ' Drive has been successfully set up.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    setLoading(false)
                    sendDataToMixPanel('Drive has been successfully set up.', user.username, {
                        'App': 'Web App', 'Drive Name': yourCloudDriveName.trim()
                    })
                    setTimeout(function () {
                        setDefaultOpenCloudDrive(true)
                    }, 3000)
                });
        }
    }
    useEffect(() => {
        if (defaultOpenCloudDrive) {
            navigate("/dashboard");
        }
    }, [defaultOpenCloudDrive]);
    useEffect(() => {
        if (cloudAccounts.length > 0) {
            cloudAccounts.map((item) => {
                if (item.disabled) {
                    setLimitExceed(true);
                }
            })
        }
    }, []);

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudDrives.length === 0) {
                getData('/Management/clouddrive')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        setCloudDrives(data);

                    });
            }
            if (backupInstance.length === 0) {
                getData('/Management/cloudbackup')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        setBackupInstance(data);

                    });
            }
            if (cloudAccounts.length === 0) {
                getCloudAccounts()
            }
        }
    }, [userStatus]);


    function accordionCheck(data) {

    }

    return (<>
        {contextHolder}
        <div id="main-content" className={`${!agentIsRunning ? "disabled" : ""}`}>
            <div className="container-fluid">
                <div className="main-content-body dashboard-home  add-cloud-account-page">
                    <div className="cloud-brands-wrapper choose-cloud-wrapper">
                        <div className="left-content">
                            <CloudDrives callback={accordionCheck}/>
                            {step === 1 && <div className="drive-brands">
                                <h3 className="mb-41">Setup a New Drive</h3>
                                <div className="row">
                                    <div className="col-md-4 col-12 align-self-center">
                                        <div className="choose-cloud-counter mobile-flex">
                                            <div className="img-wrapper">
                                                <div className="counter"><span>1</span></div>
                                            </div>
                                            <div className="content-wrapper">
                                                <h3>Choose a Cloud</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12 text-md-end text-start align-self-center">
                                        {/* <Link to="/add-cloud-account" className="btn-style">Add Cloud Account</Link>*/}
                                    </div>
                                </div>
                                <div className="form">
                                    <div className="choose-cloud-card-wrapper">
                                        {cloudAccounts.length > 0 &&
                                            cloudAccounts.filter((item) => (item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024)).map((item, i) => (
                                                <div className={`form-group ${item.disabled ? "disabled" : ""}`} key={item.id}>
                                                    <label className="choose-cloud-card">
                                                        <input type="radio" id="choose-cloud-1" value={item.id} data-index={i} data-name={item.name} name="choose-cloud" onChange={selectCloudDrive}/>
                                                        <div className="img-wrapper">
                                                            {getIcon(item.cloudType, item.cloudIconType)}
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <p className="medium">{item.name}</p>
                                                        </div>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            {(step === 2 || step > 2) &&
                                <div className="drive-brands">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 align-self-center">
                                            <div className="choose-cloud-counter mobile-flex">
                                                <div className="img-wrapper">
                                                    <div className="counter active"><span>1</span></div>
                                                </div>
                                                <div className="content-wrapper">
                                                    <h3>Cloud Account Selected</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-8 align-self-center">
                                            <div className={`quick-bucket-wrapper ${!selectedCloudAccount.internalStorage ? 'no-flex' : ''}`}>
                                                <div className="change-cloud-wrapper">
                                                    <div className="choose-cloud-card">
                                                        <div className="img-wrapper">
                                                            {getIcon(selectedCloudAccount.cloudType, selectedCloudAccount.cloudIconType)}
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <p className="medium">{selectedCloudAccount.name}</p>
                                                        </div>
                                                    </div>
                                                    <a href="#" className="btn-style transparent icon" onClick={changeCloudDrive}><img src={editIcon} alt="logo"/></a>
                                                </div>
                                                {(selectedCloudAccount.internalStorage) &&
                                                    <div className="enter-quick-bucket">
                                                        <div className="form">
                                                            <div className="form-group">
                                                                <input type="text" id="quick-bucket" value={newBucketName} className={`form-control ${showBucketNameError ? "has-error" : ""}`} onChange={(e) => {
                                                                    if (e.target.value.length > 1) {
                                                                        var result1 = selectedBuckets.filter(item => item.name.toLowerCase() === e.target.value.toLowerCase());

                                                                        if (result1.length > 0) {
                                                                            setBucketNameError(true)
                                                                        } else {
                                                                            setBucketNameError(false)
                                                                        }
                                                                    } else {
                                                                        setBucketNameError(false)
                                                                    }
                                                                    setNewBucketName(e.target.value);

                                                                }} placeholder={newBucketCreated ? "" : "myamovebucket"}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <Button loading={loadingCreate} onClick={createBucket} className={`btn-style invert orange-btn ${newBucketName === "" || !validBucketName(newBucketName) || newBucketName.length < 3 || newBucketName.length > 63 ? "disabled" : ""}`}>Create new bucket</Button>
                                                            </div>
                                                            <span className="small-text">Bucket name can contain lowercase letters, numbers, hyphen (-), and period (.)</span>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}


                            <div className={`drive-brands step-2  ${step === 1 ? "disabled" : ""}`}>
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="choose-cloud-counter mobile-flex">
                                            <div className="img-wrapper">
                                                <div className={`counter  ${step > 2 ? "active" : ""}`}><span>2</span></div>
                                            </div>
                                            <div className="content-wrapper">
                                                <h3 className="advance-settings-wrapper">Complete Your Drive Setup
                                                    {!showAdvanceSettings && <a href="#" onClick={(e) => {
                                                        setShowAdvanceSettings(true);
                                                        e.preventDefault();
                                                    }} className='mt-2 d-inline-block'>Advanced settings</a>}</h3>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-5 align-self-center">

                                    </div>
                                </div>

                            </div>
                            {(step === 2 || step > 2) && <div className="setup-cloud-drive">
                                <div className="form">
                                    <div className={`row ${showCloudDriveNameError || showCloudDriveNameLengthError ? "has-error" : null}`}>
                                        <div className="col-md-4 align-self-center">
                                            <p className="medium">Name your drive</p>
                                        </div>
                                        <div className="col-md-8 align-self-center">
                                            <div className="account-detail">
                                                <div className={`form-group max-width-50-percent ${isActive ? "help-circle" : null}`}>
                                                    <input type="text" className="form-control" value={yourCloudDriveName} placeholder="Drive name" onChange={cloudDriveName}/>
                                                </div>
                                                {showCloudDriveNameError && <span className="red-error cloud-name">
                                                                That drive name has already been used.
                                                            </span>}
                                                {showCloudDriveNameLengthError && <span className="red-error cloud-name">
                                                                32 characters max allowed
                                                            </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row  ${!isActive ? "disabled" : ""}`}>
                                        <div className="col-md-4 align-self-center">
                                            <p className="medium">Select a bucket</p>
                                        </div>
                                        <div className="col-md-8 align-self-center">
                                            <div className={`account-detail radio radio-button max-width-50-percent  ${bucket !== '' && bucket !== null ? "selected-select" : ""}`}>
                                                <Select
                                                    className={` ${bucket !== '' && bucket !== null ? "selected" : ''}`}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={onChange}
                                                    onSearch={onSearch}
                                                    placeholder="Select a bucket"
                                                    value={bucket}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    options={selectedBuckets.map((item) => ({
                                                        label: item.name, value: item.name,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {(local !== '' || !isWindows) && <>
                                        {showAdvanceSettings && <>
                                            <div className={`row  ${!isActive || bucket === '' ? "disabled" : ""}`}>
                                                <div className="col-md-4">
                                                    <p className="medium">Enable file deletion in cloud
                                                        account</p>

                                                </div>
                                                <div className="col-md-8">
                                                    <div className="account-detail mounting radio-button">
                                                        <div className="form-wrapper">
                                                            <div className="form-wrapper">

                                                                <div className="form-group switch-btn allow-delete-wrapper">
                                                                    <div className="switch-wrapper">
                                                                        {!allowDelete && <Popconfirm className="switch-confirm"
                                                                                                     placement="topLeft"
                                                                                                     title={<div>IMPORTANT: If you
                                                                                                         enable File
                                                                                                         Deletion
                                                                                                         for <br/> this local shared
                                                                                                         drive,
                                                                                                         any objects deleted <br/> will
                                                                                                         also be
                                                                                                         deleted in
                                                                                                         your corresponding cloud
                                                                                                         bucket. <br/>
                                                                                                         As agreed in the Amove Terms.
                                                                                                     </div>}
                                                                                                     okText="Yes"
                                                                                                     cancelText="No"
                                                                                                     getPopupContainer={trigger => trigger.parentNode}
                                                                                                     onConfirm={confirmButton}
                                                                                                     onCancel={cancelButton}
                                                                        >
                                                                            <Switch checked={allowDelete}/>
                                                                        </Popconfirm>}
                                                                        {allowDelete && <Switch checked={allowDelete}
                                                                                                onChange={onChangeAllowDelete}/>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`row  ${!isActive || bucket === '' ? "disabled" : ""}`}>
                                                <div className="col-md-4">
                                                    <p className="medium">Encrypt Local Cache</p>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="account-detail mounting radio-button">
                                                        <div className="form-wrapper">
                                                            <div className="form-wrapper encryption-tooltip">
                                                                <Tooltip placement="topLeft"
                                                                         trigger="hover"
                                                                         title={<div>Once {localCacheEncrypted ? "disabled" : "enabled"} this Drive setting for <br/>
                                                                             encryption can not be changed later. <br/>
                                                                             You can always create more Drives.</div>}
                                                                         getPopupContainer={trigger => trigger.parentNode}>
                                                                    <Switch checked={localCacheEncrypted}
                                                                            onChange={onCheckLocalCacheEncrypted}/>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </>}
                                </div>
                            </div>}

                            <div className={`row setup-drive-footer  ${!isActive || bucket === '' || bucket === null || (((local !== "drive" && selectedPath === '') || (local === "folder" && selectedPath.length < 4) || (local === "drive" && directoryType !== 'automatic' && localCachePath === '')) || (yourCloudDriveName.trim() === "") && isWindows) || !checkLength(yourCloudDriveName, 32) ? "disabled" : ""}`}>
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-8 align-self-center">
                                    <Button className="btn-style orange-btn" loading={loading} onClick={() => {
                                        if (user.userType !== 32) {
                                            createSharedCloudDrive()
                                        } else {
                                            setupCloudDrive()
                                        }

                                    }}>Setup Drive</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            open={openPreCache}
            title="Title"
            onOk={handleOkPreCache}
            onCancel={handleCancel}
            className="file-path-modal file-path-modal-wrapper"
            afterClose={popupClosed}
            footer={[<Popconfirm
                title="Create a folder"
                description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                onConfirm={confirm}
                cancelButtonProps={false}
                icon={false}
                okText="Create"
                placement="topLeft"
                getPopupContainer={trigger => trigger.parentNode}
                className={`new-folder-wrapper ${localCachePath !== '' ? "" : "d-none"}`}
            >
                <button className={`btn-style float-start  ${localCachePath !== '' ? "" : "d-none"}`}>
                    New Folder
                </button>
            </Popconfirm>, <button className="btn-style" onClick={handleOkPreCache}>
                OK
            </button>, <button className="btn-style margin-left" onClick={handleCancel}>
                Cancel
            </button>]}
        >
            <div className="form dir-path-wrapper">
                <div className="form-group choose-your-backup-title">
                    <h3>
                        <button className={`btn-style  ${previousPathPreCache.length < 4 ? "disabled" : ""}`} type="button" onClick={goBackPreCache}><i className="fa fa-arrow-left"></i></button>
                        Choose your local folder
                    </h3>
                </div>
            </div>
            <div className="modal-body-wrapper">
                <div className="select-drive-wrapper">
                    <div className="row">
                        <div className="col-md-3">
                            <p className="medium">Drive</p>
                            <div className="form">

                                {localDrivesPreCache.length > 0 && localDrivesPreCache.map((item, i) => (<div className="form-group" key={i}>
                                    <input type="radio" id={`select-drive-pre-cache-` + i} value={item} name="select-drive" onChange={getDirectoriesPreCache}/>
                                    <label htmlFor={`select-drive-pre-cache-` + i}>
                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z" stroke="#343434" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                </div>))}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form">
                                <div className="account-detail radio radio-button">
                                    <div className="final-selected-cloud directory-list pre-cache">
                                        {localDirPreCache.length > 0 && localDirPreCache.map((item, i) => (<div className="form-group">
                                            <label data-value={item} title={item.substring(item.lastIndexOf("\\") + 1, item.length)} className="account-card" key={i} onClick={changeDirPreCache}>
                                                {item.substring(item.lastIndexOf("\\") + 1, item.length)}
                                                <i key={i} className='d-none'></i>
                                            </label>
                                        </div>))}
                                        {localDirPreCache.length < 1 && localCachePath !== '' && <h3>There is no sub directory</h3>}
                                    </div>
                                </div>

                                <div className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                    <div className="row">
                                        <div className="col-3">Path:</div>
                                        <div className="col-9">
                                            <div className="folder-name" title={localCachePath}>
                                                {localCachePath}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                    <div className="row">
                                        <div className="col-3">Folder:</div>
                                        <div className="col-9">
                                            <div className="folder-name">
                                                {isWindows && localCachePath.substring(localCachePath.lastIndexOf("\\") + 1, localCachePath.length)}
                                                {!isWindows && localCachePath.substring(localCachePath.lastIndexOf("/") + 1, localCachePath.length)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <AgentOffPopup/>
    </>)
}

export default ChooseClouds;

