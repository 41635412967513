import React, {useState, useRef, useEffect} from "react";
import uploadIcon from "../assets/images/icons/upload-icon.svg";
import {useAuth} from "../context/AuthContext";
import {Popconfirm, Select, Modal, Tooltip, Checkbox, Spin, Button, notification} from "antd";
import syncIcon from "../assets/images/icons/sync-icon.svg";
import folderIcon from "../assets/images/icons/file-icon.svg";
import fileIconFile from "../assets/images/icons/file-icon-file.svg";
import uploadNewIcon from "../assets/images/icons/upload-icon-new.svg";
import plusIcon from "../assets/images/plus-black-icon.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import {LoadingOutlined} from "@ant-design/icons";
import config from "../config";
import linkIcon from "../assets/images/icons/link-icon.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";

const AddMaterials = ({showAddMaterial = false, setShowMaterial, load}) => {
    const {cloudAccounts, logout, postDataWebResponseText, isWindows, setPopup500, getDataWithTokenPOST, postDataReturnText, agentIsRunning, prettySize, getFormatedDate,sendDataToMixPanel, user, setError499, setPopup499} = useAuth();
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(true);
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [bucket, setBucket] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [continuationToken, setContinuationToken] = useState("");
    const [previousPath, setPreviousPath] = useState([]);
    const [previousPathFiles, setPreviousPathFiles] = useState([]);
    const [showData, setShowData] = useState(false);
    const [modalData, setModalData] = useState("");
    const [buckets, setBuckets] = useState([]);
    const [result, setResult] = useState([]);
    const [showPrefix, setShowPrefix] = useState(false);
    const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;
    const [tooptipVal, setTooptipVal] = useState(false);
    const [tooptipIndex, setTooptipIndex] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [title, setTitle] = useState("");
    const [expectedDuration, setExpectedDuration] = useState("");
    const [localDir, setLocalDir] = useState([]);
    const [localDrives, setLocalDrives] = useState([]);
    const [videotypes, setVideoTypes] = useState(["mp4", "mov", "wmv", "avi", "flv", "f4v", "swf", "mkv", "webm", "mpeg", "mpeg-2"]);
    const [api, contextHolder] = notification.useNotification();
    const [error, setError] = useState(false);


    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');

        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPathFiles(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFiles(oldArray => [...oldArray, path]);
                }
                setPath(val)
            }
        }
    }


    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])

    useEffect(() => {
        if (path === "") {
            getListObjects()
        }
    }, [bucket])

    useEffect(() => {
        if (continuationToken === "") {
            setLoading(true);
            getListObjects()
        }
    }, [continuationToken])
    const onChangeCloud = (value) => {
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPathFiles('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        var selectedAccount = cloudAccounts.filter(x => x.id === value)[0];
        setSelectedCloudAccount(selectedAccount);
        getBuckets(selectedAccount)
    };

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount.id,
            "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, bucket]) => {
                setLoading(false);
                if (status === 401) {
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = bucket.type;
                    setError499(bucket.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setBuckets(bucket);
            }
        );
    }

    function goBack() {
        setLoading(true)
        setContinuationToken("")
        setPath(previousPathFiles[previousPathFiles.length - 1])
        setPreviousPathFiles((previousArr) => (previousArr.slice(0, -1)));
    }

    function reload() {
        setContinuationToken("");
    }

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }

        if (bucket !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccount.id,
                "bucketName": bucket,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }


            getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    var videsAndFolder = [];
                    for (var i = 0; i < data.data.length; i++) {
                        if (data.data[i].type === 1) {
                            var ext = data.data[i].name.split('.').pop().split('#')[0].toLowerCase();
                            if (videotypes.includes(ext)) {
                                videsAndFolder.push(data.data[i])
                            }
                        } else {
                            videsAndFolder.push(data.data[i])
                        }
                    }

                    if (ct !== '' && ct !== null) {
                        setResult(old => [...old, ...videsAndFolder]);

                    } else {
                        setResult(videsAndFolder);
                    }
                    setContinuationToken(data.continuationToken);
                    setLoading(false);
                    setLoadingMore(false)
                    setShowData(true)
                });
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    function loadMore(e) {
        e.preventDefault()
        getListObjects(true)
    }

    const showModal = (data) => {
        setModalData(data)
        setIsModalOpen(true);
    };
    const onChange = (value) => {
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setBucket(value);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const onChangeTitle = (event) => {
        const t = event.target.value;
        setTitle(t)
        if (t.length > 0) {
            setError(false)
        } else {
            setError(true)
        }
    }
    const onChangeExpectedDuration = (event) => {
        const t = event.target.value;
        setExpectedDuration(t)
    }
    const addMaterial = () => {
        setLoading(true);
        if (title !== '') {
            setError(false)
            setLoading(true);
            var ed = 0;
            if (expectedDuration !== "" && expectedDuration > 0) {
                ed = expectedDuration * 60;
            }
            var dataToPost = {
                "title": title,
                "cloudAccountId": selectedCloudAccount.id,
                "storageName": bucket,
                "key": modalData.path,
                "expectedDuration": ed
            }
            getDataWithTokenPOST("/api/desktop/add_material", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: data.material.title + ' successfully added.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3,
                        });

                        sendDataToMixPanel('Material successfully added.', user.username, {
                            'App': 'Web App',
                            'Material': data.material.title
                        })
                        setTitle("")
                        setExpectedDuration("")
                        setLoading(false);
                        setIsModalOpen(false);
                        setShowMaterial(false);
                        load();
                    }
                }
            );
        } else {
            setLoading(false);
            setError(true);
        }
    };
    return (
        <>
            {contextHolder}
            {showAddMaterial &&
                <div className="add-material-wrapper">
                    <div className="search-file-wrapper">
                        <div className="search-wrapper">
                            <div className="search-header">
                                <div className="row">
                                    <div className="col-md-6 col-9">
                                        <h3>Add New Material</h3>
                                    </div>
                                    <div className="col-md-6 col-3 text-end">
                                        <a href="#" onClick={() => {
                                            setShowMaterial(false)
                                        }}>

                                            <img src={crossIcon} alt="icon"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="search-group">
                                <div className="form-group">
                                    <Select
                                        showSearch
                                        id="select-cloud"
                                        placeholder="Select Cloud"
                                        optionFilterProp="children"
                                        onChange={onChangeCloud}
                                        value={selectedCloudAccount.id || undefined}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={cloudAccounts.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        }))}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        showSearch
                                        id="select-bucket"
                                        placeholder="Select Bucket"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        value={bucket || undefined}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={buckets.map((item) => ({
                                            label: item.name,
                                            value: item.name,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table-main-wrapper">
                            <div className={`upload-file-detail-wrapper ${!loading && result.length === 0 ? 'no-object' : ''}`}>
                                <div className="upload-body">
                                    <div className="table-wrapper">

                                        <div className="sync-back">
                                            {previousPathFiles.length > 0 &&
                                                <button className="btn-style" type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                                            }
                                            {path.length > 1 &&
                                                <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                            }
                                            {!loading &&
                                                <div className="sync-img">
                                                    <img src={`${syncIcon}`} alt="syncIcon" onClick={reload}/>
                                                </div>
                                            }
                                        </div>
                                        {result.length > 0 &&
                                            <div className="table-header">
                                                <div className="row">

                                                    <div className='col-lg-8 col-md-5 align-self-center col-5'>
                                                        <span>Name</span></div>
                                                    <div className="col-lg-1 col-md-2 align-self-center col-4 text-center">Size</div>
                                                    <div className="col-lg-3 col-md-5 align-self-center col-3 text-end"><span className="date-modified">Date Modified</span></div>
                                                </div>
                                            </div>
                                        }
                                        <div className="table-data match-page">

                                            {loading &&
                                                <div className="loading-wrapper">
                                                    <Spin indicator={antIcon} loading/>
                                                </div>
                                            }
                                            {!loading && result.length === 0 &&
                                                <div className="loading-error-wrapper">
                                                    <span>There are no Objects.</span>
                                                </div>
                                            }
                                            {!loading && result.map((item, index) => (
                                                <div className={`row ${item.type === 1 ? 'hover-row' : 'folder-row'} ${(tooptipVal === true && tooptipIndex === index) ? 'active' : ''} ${(tooptipVal === true && tooptipIndex !== index) ? 'disabled' : ''}`} key={index}>
                                                    <div className="col-lg-8 col-md-5 align-self-center col-5 name">
                                                        {item.type === 2 &&
                                                            <label className="row-data" data-value={item.path} data-folder={item.type} onClick={changeObject} role="button"></label>
                                                        }
                                                        <div className="img-wrapper">
                                                            {item.type === 2 &&
                                                                <img src={`${folderIcon}`} alt="folderIcon"/>
                                                            }
                                                            {item.type === 1 &&
                                                                <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                            }
                                                        </div>
                                                        <span>{item.name}</span>
                                                    </div>
                                                    <div className="col-lg-1 col-md-2 align-self-center col-4 text-center align-self-center">
                                                        {item.type === 1 &&
                                                            prettySize(item.size)
                                                        }
                                                    </div>
                                                    <div className="col-lg-3 col-md-5 align-self-center col-3 align-self-center hover-row-wrapper">
                                                        {item.type === 1 &&
                                                            <p className="date-modified text-end">{getFormatedDate(item.lastModifiedUtc)}</p>
                                                        }

                                                        {item.type === 1 &&
                                                            <div className="hover-wrapper">
                                                                <div className="upload-option">
                                                                    <a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setError(false)
                                                                        setTitle(item.name)
                                                                        showModal(item)
                                                                    }}><img src={plusIcon} alt="icon"/></a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            ))}

                                            <div className="text-center">
                                                {!loading && continuationToken != null && result.length > 0 && bucket !== '' &&

                                                    <div className={`text-center load-more ${tooptipVal === true ? 'disabled' : ''}`}>
                                                        <Button className="btn-style" onClick={loadMore} loading={loadingMore}>
                                                            Load More
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal title="Basic Modal" className="upload-file-popup" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="upload-file-popup-wrapper">
                    <div className="header">
                        <div className="row">
                            <div className="col-9">
                                <div className="uploaded-file-header">
                                    <div className="uploaded-item-image">
                                        <img src={fileIconFile} alt="icon"/>
                                    </div>
                                    <div className="content">
                                        <h3>{modalData.name}</h3>
                                        <p>Only you can access</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <ul className="options">
                                    <li className="cross"><a href="#" onClick={handleCancel}><img src={crossIcon} alt="icon"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <h3>Add this file as Material</h3>
                        <div className="setting">
                        </div>
                        <div className="form">
                            <div className={`form-group ${error === true ? 'error' : ''}`}>
                                <input type="text" className="form-control" placeholder="Title" value={title} onChange={onChangeTitle}/>
                                {(error === true) &&
                                    <span className="red-error">Please add title (required)</span>
                                }

                            </div>
                            <div className="form-group">
                                <input type="number" min={1} className="form-control" placeholder="Expected duration in minutes" value={expectedDuration} onChange={onChangeExpectedDuration}/>
                            </div>

                            <div className="form-group copy-link">
                                <div className="link">
                                </div>
                                <Button type="link" className={`btn-style small ${error ? "disabled" : ""}`} loading={loading} onClick={addMaterial}>Add file</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddMaterials;
//
