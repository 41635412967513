import React from "react";
import {Link} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useAuth} from "../context/AuthContext";
const CancelSubscribe = () => {
    const {isDark } = useAuth();
    return (
        <>
            <div className="login email-verification">
                <div className="login-wrapper">
                    <div className="text-center">
                        <Link to="/" className="logo">
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo" />
                        </Link>
                    </div>
                    <div className="form w-100">
                        <h2 className="h2">There was something wrong with your payment method. <br/> Please go back and re-enter a valid credit card.</h2>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CancelSubscribe;

