import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Modal, Select, Button, notification, Radio, Tooltip, Slider, Col, Row, Space, InputNumber, Popconfirm, Checkbox} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import SettingHeader from "../components/Setting/SettingHeader";

const logOptions = [
    "Upload",
    "Download",
    "Share a file",
    "Delete",
    "Local Drive",
    "Shared Drive",
    "Group",
    "Project",
    "SSO config",
    "NFS & SMB",
    "Users",
    "Copy Link",
    "Connect Storage",
    "Provision Amove Storage",
    "Sync/Backup"]


const LogsPage = () => {
    const {
        cloudAccounts, isWindows, logout, getIcon, agentIsRunning, defaultOpenCloudDrive,
        setDefaultOpenCloudDrive, user, token, getCloudAccounts, userStatus,
        sendDataToMixPanel, updateDataWithToken,
        reloadLog,
        setReloadLog,
        deleteLog,
        setDeleteLog,
        sendDataToWebsocket,
        setPopup500,
        getDataWithToken,
        deleteDataWeb,
        setError499,
        setPopup499
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [step, setStep] = useState(1);
    const [isActive, setActive] = useState(null);
    const [bucket, setBucket] = useState("");
    const [protocol, setProtocol] = useState("TCP");
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [checkedList, setCheckedList] = useState([]);
    const checkAll = logOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < logOptions.length;
    const [serverAddress, setServerAddress] = useState("");
    const [profile, setProfile] = useState("");
    const [sendLogData, setSendLogData] = useState(false);
    const [SSL, setSSL] = useState(false);
    const [DNS, setDNS] = useState('');
    const [IP, setIP] = useState('');
    const [port, setPort] = useState('8088');
    const [sToken, setSToken] = useState('');
    const [ipError, setIPError] = useState(false);
    const [portError, setPortError] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [updateID, setUpdateID] = useState(false);
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? logOptions : []);
    };


    useEffect(() => {
        if (reloadLog) {
            setReloadLog(false)
            loadLogs()
        }
    }, [reloadLog]);

    useEffect(() => {
        if (deleteLog) {
            setDeleteLog(false)
            setDataUpdate(false)
            setSToken("")
            setPort("8088")
            setProfile("")
            setSendLogData(false)
            setIP("")
            setDNS("")
            setServerAddress("")
            setUpdateID("")
            setSSL(false)
        }
    }, [deleteLog]);

    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const onChangeServerAddress = async (value) => {
        setServerAddress(value)
    };

    const onChangeProfile = async (value) => {
        setProfile(value)
    };

    const onSearch = async (value) => {

    };

    const onSave = () => {
        var server = "";
        if (serverAddress === "Name") {
            server = DNS.replace(/(^\w+:|^)\/\//, '');
            if (SSL) {
                server = "https://" + server;
            } else {
                server = "http://" + server;
            }
        } else {
            server = IP;
        }

        var dataToPost = {
            "token": sToken,
            "serverAddress": server,
            "servicePort": port,
            "active": sendLogData,
            "provider": parseInt(profile)
        }

        if (dataUpdate) {
            dataToPost.id = updateID
            updateDataWithToken("/api/desktop/update_accountlogsetting", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    sendDataToMixPanel('Log settings updated successfully.', user.username, {
                        'App': 'Web App'
                    })
                    api.open({
                        message: 'Log settings updated successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                })
        } else {
            postData("/api/desktop/setup_accountlogsetting", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    sendDataToMixPanel('Log settings saved successfully.', user.username, {
                        'App': 'Web App'
                    })
                    setDataUpdate(true)
                    api.open({
                        message: 'Log settings saved successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                })
        }
    };

    const removeLogs = () => {
        deleteDataWeb("/api/desktop/delete_accountlogsetting")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setDataUpdate(false)
                setSToken("")
                setPort("8088")
                setProfile("")
                setSendLogData(false)
                setIP("")
                setDNS("")
                setServerAddress("")
                setUpdateID("")
                setSSL(false)

                sendDataToMixPanel('Log settings removed successfully.', user.username, {
                    'App': 'Web App'
                })
                api.open({
                    message: 'Log settings removed successfully.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 3
                });
            })
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
        loadLogs()
    }, []);
    const loadLogs = () => {
        getDataWithToken(`/api/desktop/get_accountlogsetting`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
            if (status === 200){
                setDataUpdate(true)
                setSToken(response.token)
                setPort(response.servicePort)
                setProfile(response.provider.toString())
                setSendLogData(response.active)
                setUpdateID(response.id)
                if (ValidateIPaddress(response.serverAddress)) {
                    setIP(response.serverAddress)
                    setServerAddress('Server-Address')
                } else {
                    var url = response.serverAddress;
                    if (url.includes("https://")) {
                        setSSL(true)
                    } else {
                        setSSL(false)
                    }
                    setDNS(response.serverAddress.replace(/(^\w+:|^)\/\//, ''))
                    setServerAddress('Name')
                }
            }
        })
    }
    const inputDNS = (event) => {
        const value = event.target.value;
        setDNS(value)
    }
    const inputIP = (event) => {
        const value = event.target.value;
        setIP(value)
        if (ValidateIPaddress(value)) {
            setIPError(false)
        } else {
            setIPError(true)
        }
    }
    const ValidateIPaddress = (ipaddress) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return true;
        }
        return false;
    }
    /* Check if number is a valid port number */
    const checkIfValidPortnumber = (num) => {
        // Regular expression to check if number is a valid port number
        const regexExp = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi;

        return regexExp.test(num);
    }

    const inputPort = (event) => {
        const value = event.target.value;
        setPort(value)
        if (checkIfValidPortnumber(value)) {
            setPortError(false)
        } else {
            setPortError(true)
        }
    }
    const inputToken = (event) => {
        const value = event.target.value;
        setSToken(value)
    }
    const onChangeLog = (e) => {
        setSendLogData(e.target.checked)
    };
    const onChangeSSL = (e) => {
        setSSL(e.target.checked)
    };
    return (
        <>
            {contextHolder}
            <div id="main-content" className={``}>
                <div className="container-fluid">

                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                    </div>
                    <div className="main-content-body dashboard-home log-page add-cloud-account-page">
                        <div className="cloud-brands-wrapper choose-cloud-wrapper">
                            <div className="left-content">
                                <h3 className="mb-41">Log Settings</h3>
                                <div className="setup-cloud-drive">
                                    <div className="form log-form">
                                        <div className={`row`}>
                                            <div className="col-md-4 align-self-center">
                                                <p className="medium">Enable Remote Logging</p>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="account-detail">
                                                    <div className={`form-group ${isActive ? "help-circle" : null}`}>
                                                        <Checkbox checked={sendLogData} onChange={onChangeLog}>
                                                            <div className="detail">
                                                                Send log messages to remote syslog server
                                                            </div>
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-md-4 align-self-center">
                                                <p className="medium">Log server profile</p>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="account-detail">
                                                    <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}`}>
                                                        <Select
                                                            className={` ${bucket !== '' ? "selected" : ''}`}
                                                            showSearch
                                                            placeholder="Select a profile"
                                                            optionFilterProp="children"
                                                            value={profile}
                                                            onChange={onChangeProfile}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Splunk",
                                                                    value: "1",
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-md-4 align-self-center">
                                                <p className="medium">Log Server</p>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="account-detail">
                                                    <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}`}>
                                                        <Select
                                                            className={` ${bucket !== '' ? "selected" : ''}`}
                                                            showSearch
                                                            placeholder="Select (Hostname / IP)"
                                                            optionFilterProp="children"
                                                            onChange={onChangeServerAddress}
                                                            onSearch={onSearch}
                                                            value={serverAddress}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Log Server Endpoint (DNS)",
                                                                    value: "Name",
                                                                },
                                                                {
                                                                    label: "Server IP Address",
                                                                    value: "Server-Address",
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {serverAddress === "Name" &&
                                            <div className={`row`}>
                                                <div className="col-md-4 align-self-center">
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    <div className="account-detail mb-2">
                                                        <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}`}>
                                                            <input type="text" className="form-control" onChange={inputDNS} value={DNS} placeholder="(e.g. logserver.domain.com)"/>
                                                        </div>
                                                    </div>

                                                    <Checkbox checked={SSL} onChange={onChangeSSL}>
                                                        <div className="detail">
                                                            SSL
                                                        </div>
                                                    </Checkbox>
                                                </div>
                                            </div>
                                        }
                                        {serverAddress === "Server-Address" &&
                                            <div className={`row`}>
                                                <div className="col-md-4 align-self-center">
                                                </div>
                                                <div className="col-md-8 align-self-center">
                                                    <div className="account-detail">
                                                        <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}  ${ipError ? "has-error" : ""}`}>
                                                            <input type="text" className="form-control" onChange={inputIP} value={IP} placeholder="IP address (e.g. 192.168.1.1)"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className={`row`}>
                                            <div className="col-md-4 align-self-center">
                                                <p className="medium">Port (TCP)</p>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="account-detail">
                                                    <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}  ${portError ? "has-error" : ""}`}>
                                                        <input type="number" className="form-control" value={port} placeholder="" onChange={inputPort}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className={`row`}>
                                            <div className="col-md-4 align-self-center">
                                                <p className="medium">Secret Token (HTTP Event Collection) </p>
                                            </div>
                                            <div className="col-md-8 align-self-center">
                                                <div className="account-detail">
                                                    <div className={`form-group max-width-50-percent-large ${isActive ? "help-circle" : null}`}>
                                                        <input type="text" className="form-control" placeholder="" value={sToken} onChange={inputToken}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`row setup-drive-footer`}>
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <Button className={`btn-style orange-btn transparent mr-2 ${(sToken === "" || port === "" || profile === "" || (serverAddress === "Name" || (serverAddress === "Server-Address" && ipError) || portError ? DNS : IP) === "") ? "disabled" : ""}`} loading={loading} onClick={onSave}>Save</Button>

                                        {dataUpdate &&
                                            <Button className={`btn-style orange-btn transparent mr-2`} onClick={removeLogs}>Remove</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogsPage;

