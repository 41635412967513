import React, {useEffect, useState} from "react";
import {CheckCircleFilled} from "@ant-design/icons";
import {useAuth} from "../../context/AuthContext";
import {Tooltip} from 'antd';
import SettingHeader from "../../components/Setting/SettingHeader";

const SecurityPage = () => {
    const {postDataWebNoResponse, setPopup500, logout, sendDataToMixPanel, user,checkLength} = useAuth();
    const [userDetail, setuserDetail] = useState({
        password: "",
        cpassword: "",
        currentPassword: ""
    });
    const [eyeCurrent, seteyeCurrent] = useState(true);
    const [eye, seteye] = useState(true);
    const [eyeConfirm, seteyeConfirm] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("password");
    const [password, setpassword] = useState("password");
    const [cpassword, setCpassword] = useState("password");
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorMsgProfile, setErrorMsgProfile] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const eightCharsOrMore = /.{8,}/g; // eight characters or more
    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const [meter, setMeter] = useState(false);
    const passwordTracker = {
        uppercase: userDetail.password.match(atLeastOneUppercase),
        lowercase: userDetail.password.match(atLeastOneLowercase),
        number: userDetail.password.match(atLeastOneNumeric),
        specialChar: userDetail.password.match(atLeastOneSpecialChar),
        eightCharsOrGreater: userDetail.password.match(eightCharsOrMore),
    }
    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;


    const inputEvent = (event) => {
        setShowError(false)
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });
        setSuccessMessage('')
        setErrorMsg('')
        setErrorMsgProfile('')
        if (userDetail.password === userDetail.cpassword) {
            setErrorMsg("");
        }
    }
    const EyeCurrent = () => {
        if (currentPassword == "password") {
            setCurrentPassword("text");
            seteyeCurrent(false);
        } else {
            setCurrentPassword("password");
            seteyeCurrent(true);
        }
    }
    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        } else {
            setpassword("password");
            seteye(true);
        }
    }

    const confirmEye = () => {
        if (cpassword == "password") {
            setCpassword("text");
            seteyeConfirm(false);
        } else {
            setCpassword("password");
            seteyeConfirm(true);
        }
    }
    const onUpdatePassword = (event) => {
        if (userDetail.password !== userDetail.cpassword) {
            setErrorMsg("Your password did not match")
        } else if (!checkLength(userDetail.password, 256)) {
            setErrorMsg("256 characters max allowed")
        } else {
            setErrorMsg("");
            if (passwordStrength === 5) {
                postDataWebNoResponse('/api/user/reset_password', {
                    "password": userDetail.password,
                    "currentPassword": userDetail.currentPassword
                })
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (data !== "") {
                            if (status === 499) {
                                setErrorMsg('Current password is wrong.')
                            }
                        } else {
                            setuserDetail((lastValue) => {
                                return {
                                    ...lastValue,
                                    ['password']: '',
                                    ['cpassword']: '',
                                    ['currentPassword']: ''
                                }
                            });
                            setSuccessMessage('Your password updated');

                            sendDataToMixPanel('User password updated.', user.username, {
                                'App': 'Web App'
                            })
                        }
                    })

            }
        }
    };
    return (
        <>
            <div id="main-content" className={``}>
                <div className="container-fluid">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                        <h2 className="small-title">Change password</h2>
                        <div className="account-detail-body">
                            <div className="account-detail-card ">
                                <div className="form security-page">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className={`form-group security-page`}>
                                                <label htmlFor="objects">Current password</label>
                                                <input type={currentPassword} className="form-control padding-right security-page" id="current-password"
                                                       value={userDetail.currentPassword} onChange={inputEvent}
                                                       autoComplete="current-password" name="currentPassword"/>
                                                <i onClick={EyeCurrent} className={`fa ${eyeCurrent ? "fa-eye-slash" : "fa-eye"}`}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 ">
                                            <div className={`form-group security-page  ${showError || (passwordStrength < 5 && userDetail.password !== '') ? "" : ""}`}>
                                                <label htmlFor="objects">New password</label>
                                                <input type={password} className="form-control padding-right security-page" id="password"
                                                       value={userDetail.password} onChange={inputEvent}
                                                       autoComplete="new-password"
                                                       onFocus={() => setMeter(true)} name="password"/>
                                                <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}></i>
                                            </div>
                                            <div className="position-relative">
                                                <Tooltip placement="bottom"
                                                         trigger='hover'
                                                         title={
                                                             <div className="security-page error-after-metter">
                                                                 <span>Your password must contain:</span>
                                                                 <ul className="errors-list">
                                                                     <li className={userDetail.password.match(eightCharsOrMore) ? 'active' : ''}><CheckCircleFilled/> Minimum 8 characters</li>
                                                                     <li className={userDetail.password.match(atLeastOneUppercase) ? 'active' : ''}><CheckCircleFilled/> 1 upper case letter</li>
                                                                     <li className={userDetail.password.match(atLeastOneLowercase) ? 'active' : ''}><CheckCircleFilled/> 1 lower case letter</li>
                                                                     <li className={userDetail.password.match(atLeastOneNumeric) ? 'active' : ''}><CheckCircleFilled/> 1 number</li>
                                                                     <li className={userDetail.password.match(atLeastOneSpecialChar) ? 'active' : ''}><CheckCircleFilled/> 1 special character (! # $)</li>
                                                                 </ul>
                                                             </div>
                                                         }
                                                         getPopupContainer={trigger => trigger.parentNode}>
                                                    <div className="security-meter">
                                                        <span className={passwordStrength > 0 ? "active" : ""}></span>
                                                        <span className={passwordStrength > 1 ? "active" : ""}></span>
                                                        <span className={passwordStrength > 2 ? "active" : ""}></span>
                                                        <span className={passwordStrength > 3 ? "active" : ""}></span>
                                                        <span className={passwordStrength > 4 ? "active" : ""}></span>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className={`form-group security-page  ${showError || (passwordStrength < 5 && userDetail.cpassword !== '') ? "" : ""}`}>
                                                <label htmlFor="objects">Confirm password</label>
                                                <input type={cpassword} className="form-control padding-right security-page" id="cpassword"
                                                       value={userDetail.cpassword} onChange={inputEvent}
                                                       autoComplete="c-password"
                                                       name="cpassword"/>
                                                <i onClick={confirmEye} className={`fa ${cpassword === "password" ? "fa-eye-slash" : "fa-eye"}`}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group security-page">
                                        {userDetail.currentPassword === userDetail.password && userDetail.currentPassword !== "" && userDetail.password !== "" &&
                                            <span className="red-error">This password is already in use</span>
                                        }
                                        <span className="red-error">{errorMsg}</span>
                                        <span className="green-error">{successMessage}</span>
                                    </div>
                                    <div className={`form-group security-page submit mt-4  ${(userDetail.currentPassword === userDetail.password) ? "disabled" : ""}`}>
                                        <input type="submit" onClick={onUpdatePassword} value="Save Changes" className="btn-style small-btn"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SecurityPage;

