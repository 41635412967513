import React, {useEffect, useRef, useState} from "react";
import {
    Button, Checkbox, InputNumber, Modal, notification, Pagination, Popconfirm, Radio, Select, Switch, Tooltip
} from 'antd';
import plusIcon from '../assets/images/icons/add-user-plus-icon.svg'
import crossIcon from '../assets/images/icons/cross-black-icon.svg'
import searchIcon from '../assets/images/icons/search-gray-icon.svg'
import verticleDotsIcon from '../assets/images/icons/verticle-dots-black-icon.svg'
import downArrowIcon from '../assets/images/icons/down-arrow-icon.svg'
import {ConfigurationsMenu} from '../components/ConfigurationsMenu'
import config from "../config";
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {DeleteModal} from "../components/DeleteModal";
import {useNavigate} from "react-router-dom";
import {BillingPopup} from "../components/BillingPopup";
import AgentOffPopup from "../components/AgentOffPopup";

const SharedCloudDrives = () => {
    const {
        token,
        getDataWithToken,
        updateDataWithToken,
        logout,
        cancelRequests,
        cloudAccounts,
        getDataWithTokenPOST,
        user,
        userStatus,
        agentIsRunning,
        setBillingPopupOpen,
        reloadSharedDrives,
        setReloadSharedDrives,
        sendDataToWebsocket,
        postDataWithToken,
        showAgentOnce,
        setShowAgentOnce,
        sync,
        setPopup500,
        deleteDataWeb,
        sendDataToMixPanel,
        setError499,
        setPopup499,
        checkLength
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isSelectGroup, setSelectGroup] = useState(false);
    const [isOktaGroup, setOktaGroup] = useState(false);
    const [isManualGroup, setManualGroup] = useState(false);
    const [isEditGroup, setEditGroup] = useState(false);
    const [isAssignShare, setAssignProject] = useState(false);
    const [isAssignGroup, setAssignGroup] = useState(false);
    const [isAssignDelete, setAssignDelete] = useState(false);
    const [groupIdToDelete, setGroupIdToDelete] = useState('');
    const [isEditUser, setEditUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const [isChecked, setisChecked] = useState(false);
    const [Objects, setObjects] = useState(10000);
    const [totalGroups, setTotalGroups] = useState("");
    const [cloudDrives, setCloudDrives] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [newProjectsList, setNewProjectsList] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedDrive, setSelectedDrive] = useState("");
    const [projectsData, setProjectsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCloudDrive, setSelectedCloudDrive] = useState([]);
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState('');
    const [selectedCloudAccountID, setSelectedCloudAccountID] = useState('');
    const [buckets, setBuckets] = useState([]);
    const [bucket, setBucket] = useState("");
    const [sharedCloudDriveName, setSharedCloudDriveName] = useState("");
    const [newUserPermissionValue, setNewUserPermissionValue] = useState(2);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [newGroupPermissionValue, setNewGroupPermissionValue] = useState(2);
    const [newGroupList, setNewGroupList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [localCacheEncrypted, setLocalCacheEncrypted] = useState(false);
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);

    var allcloudDrives = [];
    var assignedProjectsCount = 0;
    var assignedUsersCount = 0;

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadSharedDrives) {
                setReloadSharedDrives(false)
                getAllDrives()
            }
        }
    }, [reloadSharedDrives]);
    const onChangeCloud = (value) => {
        setBucket('');
        setSelectedCloudAccount(value)
        setSelectedCloudAccountID(value)
        getBuckets(value)
    };
    const onChangeBucket = (value) => {
        setBucket(value);
    };
    const onChangeObjects = (newValue) => {
        setObjects(newValue);
    };
    const onCheckLocalCacheEncrypted = (e) => {
        setLocalCacheEncrypted(e);
    };

    async function postDataReturnText(url = "") {
        const response = await fetch(config.apiURL + url, {
            method: "POST", headers: {
                "accept": "text/plain",
                "X-App-Origin": "WebApp"
            }
        })
        return response;
    }

    const confirm = (e) => {
        setisChecked(true)
        setAllowDelete(true)
    };
    const cancel = (e) => {
        setisChecked(false)
    };
    const onChange = (value) => {
        let newArr = [...newProjectsList];
        for (var i = 0; i < projectsData.length; i++) {
            if (projectsData[i].id === value) {
                newArr.push({projectName: projectsData[i].name, projectId: value, permissionType: 1})
            }
        }
        setNewProjectsList(newArr);
    };
    const onChangeSort = () => {
        if (!loading) {
            setDescending(!descending);
            setTimeout(function () {
                getAllDrives();
            }, 800)
        }
    };
    const onChangePage = (value) => {
        setCurrentPage(value)
        setCloudDrives([])
        setTimeout(function () {
            getAllDrives();
        }, 800)
    };
    const onChangeAllowDelete = (e) => {
        setAllowDelete(false)
    };

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount, "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = bucket.type;
                    setError499(bucket.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setBuckets(bucket);
            });
    }

    const onChangePermissionUsers = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newUsersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].user.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setUsersList(newArr)
        }
    };

    const removeUserFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newUsersList];
            newArr.splice(index, 1);
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            newArr.splice(index, 1);
            setUsersList(newArr)
            deleteDataWeb('/api/permission/users_sharedclouddrive_permision?id=' + permissionId).then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'User removed successfully.',
                            description: `User has been removed from project.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('User has been removed from project.', user.username, {
                            'App': 'Web App'
                        })
                        if (agentIsRunning) {
                            postDataReturnText('/Management/sync_shareddrive')
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, bucket]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                    if (status === 499) {
                                        var data = JSON.parse(bucket)
                                        var errorType = data.type;
                                        setError499(data.errors[errorType][0])
                                        setPopup499(true)
                                        return;
                                    }
                                });
                        }
                        let newArrG = [...cloudDrives];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedDrive.id) {
                                newArrG[i].usersData.splice(index, 1);
                                break;
                            }
                        }
                        setCloudDrives(newArrG);
                    }
                });
        }

    };

    const AssignUserToDrive = () => {
        setLoading(true)
        if (newUsersList.length > 0) {
            var dataToPost = [];
            var newArray = [...newUsersList];
            var assignedHimSelf = false;
            for (var i = 0; i < newArray.length; i++) {
                if (newArray[i].userId === user.userId) {
                    assignedHimSelf = true;
                }
                dataToPost.push({
                    "userId": newArray[i].userId,
                    "sharedCloudDriveId": selectedDrive.id,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataWithToken("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(bucket)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setEditUser(false);
                    api.open({
                        message: 'User successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    sendDataToMixPanel('User successfully assigned to Drive.', user.username, {
                        'App': 'Web App'
                    })

                    if (agentIsRunning) {
                        if (assignedHimSelf) {
                            postDataReturnText('/Management/sync_shareddrive')
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, bucket]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                    if (status === 499) {
                                        var data = JSON.parse(bucket)
                                        var errorType = data.type;
                                        setError499(data.errors[errorType][0])
                                        setPopup499(true)
                                        return;
                                    }
                                });
                        }
                    }
                    setNewUsersList([]);
                    setSelectedDrive("")
                    if (usersList.length < 1) {
                        allcloudDrives = cloudDrives;
                        getAssignedUsers(selectedDrive.id, true);
                    }
                })
        }
        if (usersList.length > 0) {
            dataToPost = [];
            newArray = [...usersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userId": newArray[i].user.id,
                    "sharedCloudDriveId": selectedDrive.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(bucket)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    setLoading(false)
                    setEditUser(false);
                    api.open({
                        message: 'User successfully updated.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    sendDataToMixPanel('User successfully updated.', user.username, {
                        'App': 'Web App'
                    })
                    setUsersList([]);
                    setSelectedDrive("");
                    allcloudDrives = cloudDrives;
                    getAssignedUsers(selectedDrive.id, true);
                })
        }

    };

    const removeProjectFromList = (index, project, newProject = false) => {
        if (newProject) {
            let newArr = [...newProjectsList];
            newArr.splice(index, 1);
            setNewProjectsList(newArr)
        } else {
            let newArr = [...projectsList];
            newArr.splice(index, 1);
            setProjectsList(newArr)
            deleteDataWeb('/api/project/unassign_sharedclouddrive?id=' + project)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Project removed successfully.',
                            description: `Your project has been removed from group.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Your project has been removed from group.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...cloudDrives];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedDrive.id) {
                                newArrG[i].projectsData.splice(index, 1);
                                break;
                            }
                        }
                        setCloudDrives(newArrG);
                    }
                });
        }

    };


    const selectUserCancel = () => {
        setSelectGroup(false);
    };

    // Okta User Modal
    const showOktaUser = () => {
        setOktaGroup(true);
    };


    const selectOktaCancel = () => {
        setOktaGroup(false);
    };


    // Manual Group Modal
    const showManualUser = () => {
        setManualGroup(true);
    };

    const selectManualCancel = () => {
        setManualGroup(false);
        setSelectedCloudDrive("")
        setAllowDelete(false)
        setSharedCloudDriveName("")
        setBucket("")
        setSelectedCloudAccount('');
        setSelectedCloudAccountID('');
        setObjects(10000);
    };


    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }


    const createSharedCloudDrive = () => {
        var dataToPost = {
            "cloudAccountId": selectedCloudAccount,
            "name": sharedCloudDriveName.trim(),
            "objectsPerFolder": Objects,
            "allowDelete": allowDelete,
            "storageName": bucket,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
        }
        if(sharedCloudDriveName.trim().length > 0){
            setLoading(true)
            postData("/api/sharedclouddrive/insert", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setManualGroup(false);
                    api.open({
                        message: sharedCloudDriveName.trim() + ' Drive has been successfully created.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Drive has been successfully created.', user.username, {
                        'App': 'Web App',
                        'Drive Name' : sharedCloudDriveName.trim()
                    })
                    setSelectedCloudDrive("")
                    setAllowDelete(false)
                    setSharedCloudDriveName("")
                    setBucket("")
                    setSelectedCloudAccount('');
                    setSelectedCloudAccountID('');
                    setObjects(10000);
                    AssignCurrentUserToDrive(response.id)
                })
        }
    };


    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const AssignCurrentUserToDrive = (sharedCloudDriveId) => {
        var dataToPost = [{
            "userId": user.userId,
            "sharedCloudDriveId": sharedCloudDriveId,
            "permissionType": 2
        }]
        postDataResponseText("/api/permission/users_sharedclouddrive_permision", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(bucket)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                sendDataToMixPanel('Assign user to Drive.', user.username, {
                    'App': 'Web App'
                })
                getAllDrives();
                sync('/Management/sync_shareddrive')
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                    });
            })
    };

    const updateCloudDrive = () => {
        setLoading(true)
        var dataToPost = {
            "id": selectedCloudDrive.id,
            "cloudAccountId": selectedCloudAccount,
            "name": sharedCloudDriveName.trim(),
            "objectsPerFolder": Objects,
            "allowDelete": allowDelete,
            "storageName": bucket,
        }
        updateDataWithToken("/api/sharedclouddrive/update", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(bucket)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoading(false)
                setManualGroup(false);
                setSelectGroup(false);
                setEditGroup(false);
                let newArr = [...cloudDrives];
                for (var i = 0; i < newArr.length; i++) {
                    if (newArr[i].id === selectedCloudDrive.id) {
                        newArr[i].cloudAccountId = selectedCloudAccount;
                        newArr[i].name = sharedCloudDriveName.trim();
                        newArr[i].objectsPerFolder = Objects;
                        newArr[i].allowDelete = allowDelete;
                        newArr[i].storageName = bucket;
                    }

                }
                setCloudDrives(newArr);

                sendDataToMixPanel('Drive has been successfully updated.', user.username, {
                    'App': 'Web App',
                    'Drive Name' : sharedCloudDriveName.trim()
                })
                api.open({
                    message: sharedCloudDriveName.trim() + ' Drive has been successfully updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
            });
    }

    // Assign Project Modal
    const showAssignProjects = (drive) => {
        setAssignProject(true);
        setSelectedDrive(drive);
        setProjectsList(drive.projectsData);
    };

    const AssignShareCancel = () => {
        setAssignProject(false);
        setProjectsList([]);
        setNewProjectsList([])
    };
    const AssignProjectsToDrive = () => {
        setLoading(true)
        if (newProjectsList.length > 0) {
            var dataToPost = [];
            var newArray = [...newProjectsList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "sharedCloudDriveId": selectedDrive.id, "projectId": newArray[i].projectId
                })
            }

            postDataWithToken("/api/project/assign_sharedclouddrive", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignProject(false);
                    api.open({
                        message: 'Project successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });

                    sendDataToMixPanel('Project successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewProjectsList([]);
                    setSelectedProject("");
                    allcloudDrives = cloudDrives;
                    getAssignedProjects(selectedDrive.id, true);
                })
        } else {
            setLoading(false)
            setAssignProject(false);
        }
    };

    // Assign Group Modal
    const showAssignGroup = (drive) => {
        setAssignGroup(true);
        setSelectedDrive(drive);
        setGroupList(drive.groupsData)
    };


    const AssignGroupCancel = () => {
        setAssignGroup(false);
        setGroupList([])
        setNewGroupList([])
    };

    const handleChangeGroup = (value) => {
        let newArr = [...newGroupList];
        for (var i = 0; i < groupsData.length; i++) {
            if (groupsData[i].id === value) {
                newArr.push({
                    userGroupId: groupsData[i].id, name: groupsData[i].name, permissionType: newGroupPermissionValue
                })
            }
        }
        setNewGroupList(newArr);
    };


    const onChangePermissionGroup = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newGroupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroupId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroup.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setGroupList(newArr)
        }
    };
    const removeGroupFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newGroupList];
            newArr.splice(index, 1);
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            newArr.splice(index, 1);
            setGroupList(newArr);
            deleteDataWeb('/api/permission/usergroup_sharedclouddrive_permission?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'User removed successfully.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });

                        sendDataToMixPanel('User removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...cloudDrives];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedDrive.id) {
                                newArrG[i].groupsData.splice(index, 1);
                                break;
                            }
                        }
                        setCloudDrives(newArrG);
                    }
                });
        }

    };

    const AssignGroupToDrive = () => {
        setLoading(true)
        if (newGroupList.length > 0) {
            var dataToPost = [];
            var newArray = [...newGroupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": newArray[i].userGroupId,
                    "sharedCloudDriveId": selectedDrive.id,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataWithToken("/api/permission/usergroup_sharedclouddrive_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignGroup(false);
                    api.open({
                        message: 'Group successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    sendDataToMixPanel('Group successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewGroupList([]);
                    setSelectedDrive("")
                    if (groupList.length < 1) {
                        allcloudDrives = cloudDrives;
                        getAssignedGroups(selectedDrive.id, true);
                    }
                })
        }
        if (groupList.length > 0) {
            dataToPost = [];
            newArray = [...groupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userGroupId": newArray[i].userGroup.id,
                    "sharedCloudDriveId": selectedDrive.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/usergroup_sharedclouddrive_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignGroup(false);
                    api.open({
                        message: 'Group successfully updated.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });

                    sendDataToMixPanel('Group successfully updated.', user.username, {
                        'App': 'Web App'
                    })
                    setGroupList([]);
                    setSelectedDrive("");
                    allcloudDrives = cloudDrives;
                    getAssignedGroups(selectedDrive.id, true);
                })
        }

    };
    // Delete Group Modal
    const showAssignDelete = (id) => {
        setAssignDelete(true);
        setGroupIdToDelete(id)
    };

    const assignDeleteOk = () => {
        setLoading(true)
        deleteDrive(groupIdToDelete)
    };

    const AssignDeleteCancel = () => {
        setAssignDelete(false);
        setGroupIdToDelete('')
    };


    const selectEditCancel = () => {
        setEditGroup(false);
    };


    const showEditMembers = (drive) => {
        setEditUser(true);
        setSelectedDrive(drive);
        setUsersList(drive.usersData);
    };

    const manageUserCancel = () => {
        setEditUser(false);
        setUsersList("");
        setUsersList([])
        setNewUsersList([])
    };

    const getAllDrives = () => {
        setDataLoaded(false)
        setCloudDrives([])
        if (user.userType === 16) {
            console.log("/api/sharedclouddrive/get_all")
            console.log(user)
        getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            var data = response.data;
            for (var i = 0; i < data.length; i++) {
                data[i].projectsData = [];
                data[i].usersData = [];
                data[i].groupsData = [];
            }
            if (data.length < 1) {
                setDataLoaded(true);
            }
            let newArr = [...cloudDrives]; // copying the old datas array
            while (newArr.length > 0) {
                newArr.pop();
            }
            for (var i = 0; i < data.length; i++) {
                newArr.push(data[i]); // replace e.target.value with whatever you want to change it to
            }
            setCloudDrives(newArr)
            allcloudDrives = data;
            setTotalGroups(response.total)
            assignedProjectsCount = 0
            getAssignedProjects(0)
        })
        }
    };

    const getAssignedProjects = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allcloudDrives[count].id;
        }

        getDataWithToken(`/api/project/get_assigned_projects?sharedCloudDriveId=${id}&page=1&pagesize=1000000&sortfield=Project.Name&descending=true`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            var res = JSON.parse(response);
            if (single) {
                let newArr = [...cloudDrives]; // copying the old datas array
                for (var i = 0; i < newArr.length; i++) {
                    if (newArr[i].id === count) {
                        newArr[i].projectsData = res.data;
                    }
                }
                setCloudDrives(newArr)
                setDataLoaded(true);
            } else {
                allcloudDrives[count].projectsData = res.data;
                assignedProjectsCount++;
                if (assignedProjectsCount < allcloudDrives.length) {
                    getAssignedProjects(assignedProjectsCount, single);
                } else {
                    let newArr = [...cloudDrives]; // copying the old datas array
                    while (newArr.length > 0) {
                        newArr.pop();
                    }
                    for (var i = 0; i < allcloudDrives.length; i++) {
                        newArr[i] = allcloudDrives[i]; // replace e.target.value with whatever you want to change it to
                    }
                    setCloudDrives(newArr);

                    allcloudDrives = newArr;
                    assignedProjectsCount = 0
                    getAssignedUsers(0);
                }
            }
        })
    };
    const getAssignedUsers = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allcloudDrives[count].id;
        }

        getDataWithToken(`/api/permission/get_users_assigned_to_sharedclouddrive?sharedCloudDriveId=${id}&page=1&pagesize=1000000&sortfield=User.Username&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            var res = JSON.parse(response);
            if (single) {
                let newArr = [...cloudDrives]; // copying the old datas array
                for (var i = 0; i < newArr.length; i++) {
                    if (newArr[i].id === count) {
                        newArr[i].usersData = res.data;
                    }
                }
                setCloudDrives(newArr)
                setDataLoaded(true);
            } else {

                allcloudDrives[count].usersData = res.data;
                assignedUsersCount++;
                if (assignedUsersCount < allcloudDrives.length) {
                    getAssignedUsers(assignedUsersCount, single);
                } else {
                    let newArr = [...cloudDrives]; // copying the old datas array
                    while (newArr.length > 0) {
                        newArr.pop();
                    }
                    for (var i = 0; i < allcloudDrives.length; i++) {
                        newArr[i] = allcloudDrives[i]; // replace e.target.value with whatever you want to change it to
                    }
                    setCloudDrives(newArr);

                    allcloudDrives = newArr;
                    assignedUsersCount = 0
                    getAssignedGroups(0);
                }
            }

        })
    };
    const getAssignedGroups = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allcloudDrives[count].id;
        }

        getDataWithToken(`/api/permission/get_usergroups_assigned_to_sharedclouddrive?sharedCloudDrive=${id}&page=1&pagesize=1000000&sortfield=UserGroup.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            var res = JSON.parse(response);
            if (single) {
                let newArr = [...cloudDrives]; // copying the old datas array
                for (var i = 0; i < newArr.length; i++) {
                    if (newArr[i].id === count) {
                        newArr[i].groupsData = res.data;
                    }
                }
                setCloudDrives(newArr)
                setDataLoaded(true);
            } else {
                allcloudDrives[count].groupsData = res.data;
                assignedUsersCount++;
                if (assignedUsersCount < allcloudDrives.length) {
                    getAssignedGroups(assignedUsersCount, single);
                } else {
                    let newArr = [...cloudDrives]; // copying the old datas array
                    while (newArr.length > 0) {
                        newArr.pop();
                    }
                    for (var i = 0; i < allcloudDrives.length; i++) {
                        newArr[i] = allcloudDrives[i]; // replace e.target.value with whatever you want to change it to
                    }
                    setCloudDrives(newArr);
                    setDataLoaded(true)
                }
            }
        })
    };
    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (user.userType === 16) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getAllDrives();
            getAllProjects();
            getAllUsers();
            getAllGroups();
        }
    }, [user]);

    const getAllProjects = () => {
        getDataWithToken("/api/project/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            setProjectsData(response.data)
        })
    };
    const getAllUsers = () => {
        //&userStatus=1
        getDataWithToken("/api/user/get_all_users?page=1&pagesize=10000000&sortfield=CreateDate&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            setUsersData(response.data)
        })
    };
    const getAllGroups = () => {
        getDataWithToken("/api/usergroup/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
            setGroupsData(response.data)
        })
    };


    function deleteDrive(id) {
        deleteDataWeb('/api/sharedclouddrive/delete?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }

                if (status === 200) {
                    setLoading(false)
                    setAssignDelete(false);
                    api.open({
                        message: 'Drive removed successfully.',
                        description: `Your Drive has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    var newArray = [...cloudDrives];
                    var driveNameRemoved = newArray.filter((item) => item.id === id);
                    setCloudDrives((current) => current.filter((item) => item.id !== id));
                    sendDataToMixPanel('Drive removed successfully.', user.username, {
                        'App': 'Web App',
                        'Team Name': driveNameRemoved[0].name
                    })

                }
            });
    }

    const handleChangeUser = (value) => {
        let newArr = [...newUsersList];
        for (var i = 0; i < usersData.length; i++) {
            if (usersData[i].id === value) {
                newArr.push({name: usersData[i].email, userId: value, permissionType: newUserPermissionValue})
            }
        }
        setNewUsersList(newArr);
    };

    return (<> {contextHolder}
        <div id="main-content">
            <div className="container-fluid mb-4">
                <div className="add-new-user-wrapper">
                    <div className="add-user-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="content-wrapper">
                                    <h2>Drive</h2>
                                    <p>Use Drive to give multiple users <br/>
                                        the same permissions to drive.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ConfigurationsMenu/>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="btn-wrapper">
                                    <a href="#" className="btn-style small-btn icon" onClick={() => {

                                        if (agentIsRunning) {
                                            if (userStatus.status === "active" || userStatus.status === "trial") {
                                                showManualUser()
                                            } else {
                                                setBillingPopupOpen(true);
                                            }
                                        } else {
                                            setShowAgentOnce(true)
                                        }
                                    }}>
                                        <img src={plusIcon} alt="icon"/>
                                        Create Drive
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-body">
                        <div className="user-table-wrapper">
                            <div className="user-table-header">
                                <ul className={`filter ${!dataLoaded ? "disabled" : ""}`}>
                                    <li>
                                        <div className="form">
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={(e) => {
                                                    e.preventDefault()
                                                    setSearchValForSearch(e.target.value)
                                                }} value={searchValForSearch} placeholder="Search by Drive Name"/>
                                                <a href="#" className="icon" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (userStatus.status === "active") {
                                                        cancelRequests();
                                                        setCurrentPage(1);
                                                        setDescending(false)
                                                        getAllDrives();
                                                    }
                                                }}> <img src={searchIcon} alt="icon"/></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="user-table-body">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th className="flex">Drives <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                onChangeSort();
                                            }}
                                                                           className={`${descending ? "arrow-down" : "arrow-up"} ${!dataLoaded ? "disabled" : ""}`}><img
                                                src={downArrowIcon} alt="icon"/></a></th>
                                            <th>Projects</th>
                                            <th>Members</th>
                                            <th>Teams</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody className={cloudDrives.length > 4 ? "more-rows" : ""}>
                                        {cloudDrives.map(drive => (<tr>
                                            <td className="width-300">
                                                <div className="group-title">
                                                    <p>{drive.name}</p>
                                                    <span>{drive.description}</span>
                                                </div>
                                            </td>
                                            <td className="more-tooltip width-200">
                                                <p>{drive.projectsData.length > 0 && <>
                                                                <span>{drive.projectsData[0].project.name}
                                                                </span>
                                                    {drive.projectsData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {drive.projectsData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.project.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{drive.projectsData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="more-tooltip width-300">
                                                <p>{drive.usersData.length > 0 && <>
                                                                <span>{drive.usersData[0].user.email}
                                                                </span>
                                                    {drive.usersData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {drive.usersData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.user.email}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{drive.usersData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="more-tooltip width-200">
                                                <p>{drive.groupsData.length > 0 && <>
                                                                <span>{drive.groupsData[0].userGroup.name}
                                                                </span>
                                                    {drive.groupsData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {drive.groupsData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.userGroup.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{drive.groupsData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className={!dataLoaded ? "disabled" : ""}>
                                                {(userStatus.status === "active" || userStatus.status === "trial") && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                    title={<ul className="view-list">
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showEditMembers(drive)
                                                        }}>Manage members</a></li>
                                                        {userStatus.status === "active" &&
                                                            <>
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showAssignProjects(drive)
                                                                }}>Manage Project</a></li>
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showAssignGroup(drive)
                                                                }}>Add to Team</a></li>
                                                            </>
                                                        }
                                                        <li><a href="#" className="red-color"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   showAssignDelete(drive.id)
                                                               }}>Delete Drive</a></li>
                                                    </ul>}
                                                >
                                                    <a href="#" className="toltip-btn">
                                                        <img src={verticleDotsIcon} alt="icon"/>
                                                    </a>
                                                </Tooltip>}
                                            </td>
                                        </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={!dataLoaded ? "disabled" : ""}>
                                {totalGroups > perPage &&
                                    <Pagination current={currentPage} total={totalGroups} pageSize={perPage}
                                                onChange={onChangePage}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal title="Create new group" className="select-user-type" centered open={isSelectGroup}
               onCancel={selectUserCancel}>
            <div className="select-user-modal-wrapper">
                <div className="header">
                    <h3>Create new group</h3>
                </div>
                <div className="body">
                    <div className="select-wrapper">
                        <a href="#" className="btn-style" onClick={showOktaUser}>Import teams from AD/Okta</a>
                        <a href="#" className="btn-style" onClick={showManualUser}>Create teams manually</a>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectUserCancel}>Cancel</a>
                    <a href="#" className="btn-style blue">Proceed</a>
                </div>
            </div>
        </Modal>

        <Modal title="Import users from AD/Okta" className="okta-user-type" centered open={isOktaGroup}
               onOk={selectOktaCancel} onCancel={selectOktaCancel}>
            <div className="okta-user-modal-wrapper">
                <div className="header">
                    <h3>Import teams from AD/Okta</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Search teams by name..."/>
                                <img src={searchIcon} className="icon" alt="icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="search-user-wrapper">
                        <div className="select-all">
                            <Checkbox>Select all</Checkbox>
                            <div className="search-user">
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A group for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectOktaCancel}>Cancel</a>
                    <a href="#" className="btn-style blue">Import</a>
                </div>
            </div>
        </Modal>


        <Modal title="Create new group" className="manual-user-type" centered open={isManualGroup}
               onOk={selectManualCancel} onCancel={selectManualCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Create new drive</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="search-wrapper">
                                <div className="search-group">
                                    <div className="form-group">
                                        <p className="label">Drive name</p>
                                        <input type="text" className="form-control" onChange={(e) => {
                                            setSharedCloudDriveName(e.target.value);

                                            if (!checkLength(e.target.value, 32)) {
                                                setShowCloudDriveNameLengthError(true)
                                            } else {
                                                setShowCloudDriveNameLengthError(false)
                                            }
                                        }} value={sharedCloudDriveName} placeholder="Enter Drive Name"/>

                                        {showCloudDriveNameLengthError && <span className="error">
                                                                32 characters max allowed
                                                            </span>}
                                    </div>
                                    <div className="form-group">
                                        <p className="label">Select Cloud Account</p>
                                        <Select
                                            showSearch
                                            id="select-cloud-1"
                                            placeholder="Select Cloud"
                                            optionFilterProp="children"
                                            onChange={onChangeCloud}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            value={selectedCloudAccountID || undefined}
                                            options={cloudAccounts?.filter((item)=>(item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024)).filter(item => !item.disabled).map((item) => ({
                                                label: item.name, value: item.id,
                                            }))}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <p className="label">Select Bucket</p>
                                        <Select
                                            showSearch
                                            id="select-bucket"
                                            placeholder="Select Bucket"
                                            optionFilterProp="children"
                                            onChange={onChangeBucket}
                                            value={bucket || undefined}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={buckets.map((item) => ({
                                                label: item.name, value: item.name,
                                            }))}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 align-self-center">
                                            <div className="form-group">
                                                <label htmlFor="filesPerFolder">Number of files per folder</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 align-self-center">
                                            <div className="form-group slider">
                                                <div className="slider-wrapper">
                                                    <InputNumber
                                                        className="form-control"
                                                        min={5000}
                                                        max={100000}
                                                        style={{width: 90}}
                                                        value={Objects}
                                                        onChange={onChangeObjects}/>
                                                </div>
                                                <strong>Max: 100K</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor="deletion">Enable file deletion in cloud
                                                    account</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper shared-drive-switch">
                                                    {!allowDelete && <Popconfirm className="switch-confirm"
                                                                                 placement="topLeft"
                                                                                 title={<div>IMPORTANT: If you
                                                                                     enable File
                                                                                     Deletion
                                                                                     for <br/> this local shared
                                                                                     drive,
                                                                                     any objects deleted <br/> will
                                                                                     also be
                                                                                     deleted in
                                                                                     your corresponding cloud
                                                                                     bucket. <br/>
                                                                                     As agreed in the Amove Terms.
                                                                                 </div>}
                                                                                 okText="Yes"
                                                                                 cancelText="No"
                                                                                 getPopupContainer={trigger => trigger.parentNode}
                                                                                 onConfirm={confirm}
                                                                                 onCancel={cancel}
                                                    >
                                                        <Switch checked={allowDelete}/>
                                                    </Popconfirm>}
                                                    {allowDelete && <Switch checked={allowDelete}
                                                                            onChange={onChangeAllowDelete}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor="deletion">Encrypt Local Cache</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper">

                                                    <Tooltip placement="topLeft"
                                                             title={`Once ${localCacheEncrypted ? "disabled" : "enabled"}, this setting cannot be changed later.`}
                                                             getPopupContainer={trigger => trigger.parentNode}>
                                                        <Switch checked={localCacheEncrypted}
                                                                onChange={onCheckLocalCacheEncrypted}/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectManualCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue ${sharedCloudDriveName.length < 1 || showCloudDriveNameLengthError || sharedCloudDriveName.trim() === "" || (selectedCloudAccountID.length < 1 && bucket.length < 1) || bucket === "" ? 'disabled' : ''}`}
                        loading={loading} onClick={createSharedCloudDrive}>Create Drive</Button>
                </div>
            </div>

        </Modal>

        <Modal title="Edit Drive" className="manual-user-type" centered open={isEditGroup}
               onOk={selectEditCancel} onCancel={selectEditCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Edit Drive</h3>
                </div>
                <div className="body">
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="search-wrapper">
                                    <div className="search-group">
                                        <div className="form-group">
                                            <p className="label">Drive name</p>
                                            <input type="text" className="form-control" onChange={(e) => {
                                                setSharedCloudDriveName(e.target.value)
                                            }} value={sharedCloudDriveName} placeholder="Enter Drive Name"/>
                                        </div>
                                        <div className="form-group">
                                            <p className="label">Select Cloud Account</p>
                                            <Select
                                                showSearch
                                                id="select-cloud"
                                                placeholder="Select Cloud"
                                                optionFilterProp="children"
                                                onChange={onChangeCloud}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                value={selectedCloudAccount}
                                                options={cloudAccounts?.filter((item)=>(item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024)).map((item) => ({
                                                    label: item.name, value: item.id,
                                                }))}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <p className="label">Select Bucket</p>
                                            <Select
                                                showSearch
                                                id="select-bucket"
                                                placeholder="Select Bucket"
                                                optionFilterProp="children"
                                                onChange={onChangeBucket}
                                                value={bucket || undefined}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={buckets?.map((item) => ({
                                                    label: item.name, value: item.name,
                                                }))}
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 align-self-center">
                                                <div className="form-group">
                                                    <label htmlFor="filesPerFolder">Number of files per
                                                        folder</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <div className="form-group slider">
                                                    <div className="slider-wrapper">
                                                        <InputNumber
                                                            className="form-control"
                                                            min={5000}
                                                            max={100000}
                                                            style={{width: 90}}
                                                            value={Objects}
                                                            onChange={onChangeObjects}/>
                                                    </div>
                                                    <strong>Max: 100K</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="form-group">
                                                    <label htmlFor="deletion">Enable file deletion in cloud
                                                        account</label>
                                                </div>
                                            </div>
                                            <div className="col-md-5 align-self-center">
                                                <div className="form-group switch-btn allow-delete-wrapper">
                                                    <div className="switch-wrapper">
                                                        {!allowDelete && <Popconfirm className="switch-confirm"
                                                                                     placement="topLeft"
                                                                                     title={<div>IMPORTANT: If you
                                                                                         enable File
                                                                                         Deletion
                                                                                         for <br/> this local shared
                                                                                         drive,
                                                                                         any objects
                                                                                         deleted <br/> will also be
                                                                                         deleted in
                                                                                         your corresponding cloud
                                                                                         bucket. <br/>
                                                                                         As agreed in the Amove
                                                                                         Terms.</div>}
                                                                                     okText="Yes"
                                                                                     cancelText="No"
                                                                                     getPopupContainer={trigger => trigger.parentNode}
                                                                                     onConfirm={confirm}
                                                                                     onCancel={cancel}
                                                        >
                                                            <Switch checked={allowDelete}/>
                                                        </Popconfirm>}
                                                        {allowDelete && <Switch checked={allowDelete}
                                                                                onChange={onChangeAllowDelete}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectEditCancel}>Cancel</a>
                    <Button className={`btn-style blue ${sharedCloudDriveName.length < 1 || sharedCloudDriveName.trim() === "" ? 'disabled' : ''}`}
                            loading={loading} onClick={updateCloudDrive}>Save Changes</Button>
                </div>
            </div>

        </Modal>


        <Modal title="Assign Project" className="manual-user-type" centered open={isAssignShare}
               onOk={AssignShareCancel} onCancel={AssignShareCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Assign Project</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Projects</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a project"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        value={selectedProject}
                                        options={projectsData.filter((item) => {
                                            if (projectsList.filter((a) => a.project.id === item.id).length < 1 && newProjectsList.filter((a) => a.projectId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(projectsList.length > 0 || newProjectsList.length > 0) &&
                                    <div className="added-users">
                                        {projectsList.length > 0 && projectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.project.name} <a href="#"
                                                                                                                onClick={() => {
                                                                                                                    removeProjectFromList(i, project.projectSharedCloudDrive.id)
                                                                                                                }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        {newProjectsList.length > 0 && newProjectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.projectName} <a href="#"
                                                                                                               onClick={(e) => {
                                                                                                                   e.preventDefault();
                                                                                                                   removeProjectFromList(i, project.id, true)
                                                                                                               }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={AssignShareCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue ${newProjectsList.length > 0 || projectsList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignProjectsToDrive}>Save Changes</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Add to Team" className="manual-user-type" centered open={isAssignGroup}
               onOk={AssignGroupCancel} onCancel={AssignGroupCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Add to Team</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Teams</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a group"
                                        optionFilterProp="children"
                                        onChange={handleChangeGroup}
                                        value={selectedGroup}
                                        options={groupsData.filter((item) => {
                                            if (groupList.filter((a) => a.userGroup.id === item.id).length < 1 && newGroupList.filter((a) => a.userGroupId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(groupList.length > 0 || newGroupList.length > 0) && <div className="added-users">
                                    {groupList.length > 0 && groupList.map((group, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                               <span className="tag">{group.userGroup.name} <a href="#"
                                                                                                               onClick={(e) => {
                                                                                                                   e.preventDefault();
                                                                                                                   removeGroupFromList(i, group.permission.id)
                                                                                                               }}><img
                                                                   src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionGroup(e, group.userGroup.id)
                                                        }} value={group.permission.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newGroupList.length > 0 && newGroupList.map((group, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{group.name} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeGroupFromList(i, group, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionGroup(e, group.userGroupId, true)
                                                        }} value={group.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={AssignGroupCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue ${newGroupList.length > 0 || groupList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignGroupToDrive}>Assign</Button>
                </div>
            </div>
        </Modal>

        <DeleteModal title="Delete Drive" text="Are you sure you want to delete this drive?"
                     deleteOpen={isAssignDelete} deleteCancel={AssignDeleteCancel} deleteSuccess={assignDeleteOk}
                     loading={loading}/>

        <Modal title="Manage Users" className="manual-user-type" centered open={isEditUser} onOk={manageUserCancel}
               onCancel={manageUserCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Manage Users</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Users</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a user"
                                        optionFilterProp="children"
                                        onChange={handleChangeUser}
                                        value={selectedUsers}
                                        options={usersData.filter((item) => {
                                            if (usersList.filter((a) => a.user.id === item.id).length < 1 && newUsersList.filter((a) => a.userId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.email, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(usersList.length > 0 || newUsersList.length > 0) && <div className="added-users">
                                    {usersList.length > 0 && usersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                               <span className="tag">{user.user.email} <a href="#"
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              removeUserFromList(i, user.permission.id)
                                                                                                          }}><img
                                                                   src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionUsers(e, user.user.id)
                                                        }} value={user.permission.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newUsersList.length > 0 && newUsersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{user.name}<a href="#"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        removeUserFromList(i, user.userId, true)
                                                                                                    }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionUsers(e, user.userId, true)
                                                        }} value={user.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={manageUserCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue ${newUsersList.length > 0 || usersList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignUserToDrive}>Assign</Button>
                </div>
            </div>
        </Modal>


        {showAgentOnce &&
            <AgentOffPopup/>
        }

        <BillingPopup></BillingPopup>

    </>)
}

export default SharedCloudDrives;

