import React from 'react';
import pageLogo from "../assets/images/page-logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useAuth} from "../context/AuthContext";
const PageLoading = () => {
    const {isDark} = useAuth();
    return (
        <>
            <div className="login-wrapper no-border  text-center page-loading">
                <div className="text-center">
                    <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                </div>
                <div className="main-form-wrapper text-center">
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageLoading;
