import React, {useEffect,useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "antd";
import pageLogo from "../../assets/images/page-logo.svg";
import {useAuth} from "../../context/AuthContext";
import pageLogoWhite from "../../assets/images/logo-dark.svg";

const SubscriptionSuccess = () => {
    const navigate = useNavigate();
    const {setShowSidebar, setShowHeaderAndSidebar, isDark,sendDataToMixPanel,user} = useAuth();
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setShowSidebar(true);
        setShowHeaderAndSidebar(true);
        sendDataToMixPanel('Your subscription is successful.', user.username, {
            'App': 'Web App'
        })
    }, [])
    return (
        <>
            <div className="login email-verification">
                <div className="login-wrapper">
                    <div className="text-center">
                        <Link to="/" className="logo">
                            <img src={isDark ? pageLogoWhite : pageLogo} className="large-logo" alt="logo"/>
                        </Link>
                    </div>
                    <div className="form w-100">
                        <h2 className="h2">Your subscription is successful. <br/> Go to the dashboard to keep using Amove.</h2>
                    </div>
                    <div className="form mx-auto mt-4 w-25">
                        <Button className="btn-style full" onClick={() => navigate("/dashboard")}>
                            Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionSuccess;

