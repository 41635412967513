import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Modal, notification, Pagination, Radio, Select, Tooltip} from 'antd';
import plusIcon from '../assets/images/icons/add-user-plus-icon.svg'
import crossIcon from '../assets/images/icons/cross-black-icon.svg'
import searchIcon from '../assets/images/icons/search-gray-icon.svg'
import verticleDotsIcon from '../assets/images/icons/verticle-dots-black-icon.svg'
import downArrowIcon from '../assets/images/icons/down-arrow-icon.svg'
import pluscon from '../assets/images/icons/plus-white-icon.svg'
import {ConfigurationsMenu} from '../components/ConfigurationsMenu'
import config from "../config";
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {DeleteModal} from "../components/DeleteModal";
import {useNavigate} from "react-router-dom";
import {BillingPopup} from "../components/BillingPopup";

const Groups = () => {
    const {
        token,
        updateDataWithToken,
        logout,
        getDataWithToken,
        sendDataToMixPanel,
        cancelRequests,
        user,
        userStatus,
        deleteDataWeb,
        setBillingPopupOpen,
        reloadTeams,
        setReloadTeams,
        sendDataToWebsocket,
        postDataWithToken,
        setPopup500,
        setError499,
        setPopup499
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isSelectGroup, setSelectGroup] = useState(false);
    const [isOktaGroup, setOktaGroup] = useState(false);
    const [isManualGroup, setManualGroup] = useState(false);
    const [isEditGroup, setEditGroup] = useState(false);
    const [isAssignShare, setAssignProject] = useState(false);
    const [isAssignDrive, setAssignDrive] = useState(false);
    const [isAssignProjects, setAssignProjects] = useState(false);
    const [isAssignDelete, setAssignDelete] = useState(false);
    const [isAssignDeleteUser, setAssignDeleteUser] = useState(false);
    const [groupIdToDelete, setGroupIdToDelete] = useState('');
    const [isEditUser, setEditUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createGroupStep1, setCreateGroupStep1] = useState(false);
    const [addEmail, setAddEmail] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [addEmailEdit, setAddEmailEdit] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [totalGroups, setTotalGroups] = useState("");
    const [groupDescription, setGroupDescription] = useState("");
    const [selectedUserValueCreateGroup, setSelectedUserValueCreateGroup] = useState("");
    const [groupsData, setGroupsData] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [newProjectsList, setNewProjectsList] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");
    const [newProjectPermissionValue, setNewProjectPermissionValue] = useState(2);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [projectsData, setProjectsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [drivesData, setDrivesData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [driveList, setDriveList] = useState([]);
    const [newDriveList, setNewDriveList] = useState([]);
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [selectedCloudDrive, setSelectedCloudDrive] = useState("");
    const [newDrivePermissionValue, setNewDrivePermissionValue] = useState(2);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    var allGroupsData = [];
    var assignedProjectsCount = 0;
    var assignedUsersCount = 0;

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadTeams) {
                setReloadTeams(false)
                getAllGroups()
            }
        }
    }, [reloadTeams]);
    const onChange = (value) => {
        let newArr = [...newProjectsList];
        for (var i = 0; i < projectsData.length; i++) {
            if (projectsData[i].id === value) {
                newArr.push({
                    projectName: projectsData[i].name, projectId: value, permissionType: newProjectPermissionValue
                })
            }
        }
        setNewProjectsList(newArr);
    };

    const onChangeSort = () => {
        if (!loading) {
            setDescending(!descending);
            setTimeout(function () {
                getAllGroups()
            }, 800)
        }
    };
    const onChangePage = (value) => {
        setCurrentPage(value)
        setGroupsData([]);
        setTimeout(function () {
            getAllGroups()
        }, 800)
    };


    const onChangeCloudDrive = (value) => {
        let newArr = [...newDriveList];
        for (var i = 0; i < drivesData.length; i++) {
            if (drivesData[i].id === value) {
                drivesData[i].permissionType = newDrivePermissionValue;
                newArr.push(drivesData[i])
            }
        }
        setNewDriveList(newArr);
    };

    const onChangePermissionDrive = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newDriveList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].id === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewDriveList(newArr)
        } else {
            let newArr = [...driveList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].id === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setDriveList(newArr)
        }
    };
    const AssignCloudDrive = () => {
        setLoading(true)
        if (newDriveList.length > 0) {
            var dataToPost = [];
            var newArray = [...newDriveList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": selectedGroup.id,
                    "sharedCloudDriveId": newArray[i].id,
                    "permissionType": newArray[i].permissionType
                })
            }

            postDataWithToken("/api/permission/usergroup_sharedclouddrive_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setAssignDrive(false);
                    api.open({
                        message: 'Drive successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });

                    sendDataToMixPanel('Drive successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewDriveList([]);
                    setSelectedCloudDrive("");
                    if (driveList.length < 1) {
                        allGroupsData = groupsData
                        getAssignedCloudDrives(selectedGroup.id, true);
                    }
                })
        }
        if (driveList.length > 0) {
            dataToPost = [];
            newArray = [...driveList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": selectedGroup.id,
                    "sharedCloudDriveId": newArray[i].sharedCloudDriveId,
                    "permissionType": newArray[i].permissionType,
                    "id": newArray[i].id
                })
            }
            updateDataWithToken("/api/permission/usergroup_sharedclouddrive_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setAssignDrive(false);
                    if (newProjectsList.length < 1) {
                        api.open({
                            message: 'Drive successfully updated.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5,
                        });
                        sendDataToMixPanel('Drive successfully updated.', user.username, {
                            'App': 'Web App'
                        })
                    }
                    setDriveList([]);
                    setSelectedCloudDrive("");
                    allGroupsData = groupsData
                    getAssignedCloudDrives(selectedGroup.id, true);
                })
        }
    };
    const removeDriveFromList = (index, drive, newDrive = false) => {

        if (newDrive) {
            let newArr = [...newDriveList];
            newArr.splice(index, 1);
            setNewDriveList(newArr)
        } else {
            let newArr = [...driveList];
            newArr.splice(index, 1);
            setDriveList(newArr)
            deleteDataWeb('/api/permission/usergroup_sharedclouddrive_permission?id=' + drive)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Drive removed successfully.',
                            description: `Your Drive has been removed from team.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Drive removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...groupsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedGroup.id) {
                                newArrG[i].drivesData.splice(index, 1);
                                break;
                            }
                        }
                        setGroupsData(newArrG);
                    }
                });
        }

    };
    const onChangePermission = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newProjectsList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].projectId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewProjectsList(newArr)
        } else {
            let newArr = [...projectsList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].projectId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setProjectsList(newArr)
        }
    };


    const removeProjectFromList = (index, project, newProject = false) => {
        if (newProject) {
            let newArr = [...newProjectsList];
            newArr.splice(index, 1);
            setNewProjectsList(newArr)
        } else {
            let newArr = [...projectsList];
            newArr.splice(index, 1);
            setProjectsList(newArr)
            deleteDataWeb('/api/permission/usergroups_project_permission?id=' + project.permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Project removed successfully.',
                            description: `Your project has been removed from team.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Project removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...groupsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedGroup.id) {
                                newArrG[i].projectsData.splice(index, 1);
                                break;
                            }
                        }
                        setGroupsData(newArrG);
                    }
                });
        }

    };
    const removeUserFromList = (index, project, newProject = false) => {
        if (newProject) {
            let newArr = [...newUsersList];
            newArr.splice(index, 1);
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            newArr.splice(index, 1);
            setUsersList(newArr)
            deleteDataWeb('/api/usergroup/unassign_user?id=' + project)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'User removed successfully.',
                            description: `Your user has been removed from team.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('User removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...groupsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedGroup.id) {
                                newArrG[i].usersData.splice(index, 1);
                                break;
                            }
                        }
                        setGroupsData(newArrG);
                    }
                });
        }

    };
    // Select User Modal
    const showSelectUser = () => {
        setSelectGroup(true);
    };

    const selectUserCancel = () => {
        setSelectGroup(false);
    };

    // Okta User Modal
    const showOktaUser = () => {
        setOktaGroup(true);
    };

    const selectOktaOk = () => {
        setOktaGroup(false);
    };

    const selectOktaCancel = () => {
        setOktaGroup(false);
    };


    // Manual Team Modal
    const showManualUser = () => {
        setManualGroup(true);
    };

    const selectManualOk = () => {
        setManualGroup(false);
    };

    const selectManualCancel = () => {
        setManualGroup(false);
    };


    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });

        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const createGroup = () => {
        setLoading(true)
        var dataToPost = {
            "name": groupName.trim(), "description": groupDescription, "active": true, "deleted": false
        }

        postData("/api/usergroup/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setLoading(false)
                setManualGroup(false);
                setSelectGroup(false);
                api.open({
                    message: groupName.trim() + ' Team has been successfully created.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                sendDataToMixPanel('Team has been successfully created.', user.username, {
                    'App': 'Web App',
                    'Team Name': groupName.trim()
                })
                setGroupDescription("")
                setGroupName("")
                getAllGroups();
            })
    };
    const updateGroup = () => {
        setLoading(true)
        var dataToPost = {
            "id": selectedGroup.id, "name": groupName.trim(), "description": groupDescription, "active": true, "deleted": false
        }
        updateDataWithToken("/api/usergroup/update", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setLoading(false)
                setManualGroup(false);
                setSelectGroup(false);
                setEditGroup(false);

                let newArr = [...groupsData];
                for (var i = 0; i < newArr.length; i++) {
                    if (newArr[i].id === selectedGroup.id) {
                        newArr[i].name = groupName.trim();
                        newArr[i].description = groupDescription;
                    }
                }
                setGroupsData(newArr);

                sendDataToMixPanel('Team has been successfully updated.', user.username, {
                    'App': 'Web App',
                    'Team Name': groupName.trim()
                })
                api.open({
                    message: groupName.trim() + ' Team has been successfully updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                setGroupDescription("")
                setGroupName("")
                setSelectedGroup("")
            })
    };


    // Assign Project Modal
    const showAssignProjects = (group) => {
        setAssignProject(true);
        setSelectedGroup(group);
        var newArray = [...projectsList];
        newArray = [];

        for (var i = 0; i < group.projectsData.length; i++) {
            newArray.push({
                "projectName": group.projectsData[i].project.name,
                "projectId": group.projectsData[i].permission.projectId,
                "permissionType": group.projectsData[i].permission.permissionType,
                "permissionId": group.projectsData[i].permission.id,
            })
        }
        setProjectsList(newArray);
    };


    const AssignShareCancel = () => {
        setAssignProject(false);
        setProjectsList([]);
        setNewProjectsList([])
    };
    const AssignProjectsToGroup = () => {
        setLoading(true)
        if (newProjectsList.length > 0) {
            var dataToPost = [];
            var newArray = [...newProjectsList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": selectedGroup.id,
                    "projectId": newArray[i].projectId,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataWithToken("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    setLoading(false)
                    setAssignProject(false);
                    api.open({
                        message: 'Project successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Project successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewProjectsList([]);
                    setSelectedProject("");
                    if (projectsList.length < 1) {
                        allGroupsData = groupsData
                        getAssignedProjects(selectedGroup.id, true);
                    }
                })
        }
        if (projectsList.length > 0) {
            dataToPost = [];
            newArray = [...projectsList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": selectedGroup.id,
                    "projectId": newArray[i].projectId,
                    "permissionType": newArray[i].permissionType,
                    "id": newArray[i].permissionId
                })
            }
            updateDataWithToken("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignProject(false);

                    if (newProjectsList.length < 1) {
                        api.open({
                            message: 'Projects successfully updated.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5,
                        });
                        sendDataToMixPanel('Projects successfully updated.', user.username, {
                            'App': 'Web App'
                        })
                    }
                    setProjectsList([]);
                    setSelectedProject("");
                    allGroupsData = groupsData
                    getAssignedProjects(selectedGroup.id, true);
                })
        }
    };
    const AssignUsersToGroup = () => {
        setLoading(true)
        if (newUsersList.length > 0) {
            var dataToPost = [];
            var newArray = [...newUsersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userId": newArray[i].id, "userGroupId": selectedGroup.id,
                })
            }
            postDataWithToken("/api/usergroup/assign_users", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                    api.open({
                        message: 'User successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    sendDataToMixPanel('User successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewUsersList([]);
                    setSelectGroup("");
                    setLoading(false)
                    setEditUser(false);
                    allGroupsData = groupsData
                    getAssignedUsers(selectedGroup.id, true);
                })
        } else {
            setLoading(false)
            setEditUser(false);
        }

    };

    // Assign Team Modal
    const showAssignGroup = (group) => {
        setAssignDrive(true);
        setSelectedGroup(group);
        var newArr = []
        for (var i = 0; i < group.drivesData.length; i++) {
            newArr.push({
                id: group.drivesData[i].permission.id,
                name: group.drivesData[i].sharedCloudDrive.name,
                permissionType: group.drivesData[i].permission.permissionType,
                sharedCloudDriveId: group.drivesData[i].sharedCloudDrive.id
            })
        }
        setDriveList(newArr)
    };

    const AssignDriveCancel = () => {
        setAssignDrive(false);
        setSelectGroup("")
        setDriveList([])
        setNewDriveList([])
    };


    // Delete Team Modal
    const showAssignDelete = (id) => {
        setAssignDelete(true);
        setGroupIdToDelete(id)
    };

    const assignDeleteOk = () => {
        setLoading(true)
        deleteGroup(groupIdToDelete)
    };

    const AssignDeleteCancel = () => {
        setAssignDelete(false);
        setGroupIdToDelete('')
    };

    // Edit Team Modal
    const showEditGroup = (data) => {
        setEditGroup(true);
        setSelectedGroup(data)
        setGroupName(data.name);
        setGroupDescription(data.description);
    };

    const selectEditOk = () => {
        setEditGroup(false);
    };

    const selectEditCancel = () => {
        setEditGroup(false);
    };


    const showEditMembers = (group) => {
        setEditUser(true);
        setSelectedGroup(group);
        setUsersList(group.usersData)
    };

    const EditUserCancel = () => {
        setEditUser(false);
        setSelectedUsers("");
        setUsersList([])
        setNewUsersList([])
    };

    const getAssignedProjects = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allGroupsData[count].id;
        }
        getDataWithToken(`/api/permission/get_projects_assigned_to_usergroup?userGroupId=${id}&page=1&pagesize=10000000&sortfield=Project.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...groupsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].projectsData = res.data;
                        }
                    }
                    setGroupsData(newArr)
                    setDataLoaded(true);
                } else {
                    allGroupsData[count].projectsData = res.data;
                    assignedProjectsCount++;
                    if (assignedProjectsCount < allGroupsData.length) {
                        getAssignedProjects(assignedProjectsCount, single);
                    } else {
                        let newArr = [...groupsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allGroupsData.length; i++) {
                            newArr[i] = allGroupsData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setGroupsData(newArr);
                        allGroupsData = newArr;
                        getAssignedUsers(0);
                    }
                }

            })
    };
    const getAssignedUsers = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allGroupsData[count].id;
        }
        getDataWithToken(`/api/usergroup/get_assigned_users?userGroupId=${id}&page=1&pagesize=10000000&sortfield=User.Username&descending=true`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...groupsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].usersData = res.data;
                        }
                    }
                    setGroupsData(newArr)
                    setDataLoaded(true);
                } else {
                    allGroupsData[count].usersData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allGroupsData.length) {
                        getAssignedUsers(assignedUsersCount, single);
                    } else {
                        let newArr = [...groupsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allGroupsData.length; i++) {
                            newArr[i] = allGroupsData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setGroupsData(newArr);
                        allGroupsData = newArr;
                        assignedUsersCount = 0
                        getAssignedCloudDrives(0)
                    }
                }

            })
    };
    const getAssignedCloudDrives = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allGroupsData[count].id;
        }
        getDataWithToken(`/api/permission/get_sharedclouddrive_assigned_to_usergroup?userGroupId=${id}&page=1&pagesize=10000000&sortfield=SharedCloudDrive.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...groupsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].drivesData = res.data;
                        }
                    }
                    setGroupsData(newArr)
                    setDataLoaded(true);
                } else {
                    allGroupsData[count].drivesData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allGroupsData.length) {
                        getAssignedCloudDrives(assignedUsersCount, single);
                    } else {
                        let newArr = [...groupsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allGroupsData.length; i++) {
                            newArr[i] = allGroupsData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        allGroupsData = newArr;
                        setGroupsData(newArr);
                        setDataLoaded(true)
                    }
                }

            })
    };
    const getAllGroups = () => {
        cancelRequests()
        setDataLoaded(false)
        setGroupsData([])
        getDataWithToken(`/api/usergroup/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                assignedProjectsCount = 0;
                var data = response.data;
                for (var i = 0; i < data.length; i++) {
                    data[i].projectsData = [];
                    data[i].usersData = [];
                    data[i].drivesData = [];
                }
                let newArr = [...groupsData]; // copying the old datas array
                while (newArr.length > 0) {
                    newArr.pop();
                }
                for (var i = 0; i < data.length; i++) {
                    newArr.push(data[i]); // replace e.target.value with whatever you want to change it to
                }
                setGroupsData(newArr);
                setTotalGroups(response.total);
                if (data.length < 1) {
                    setDataLoaded(true)
                } else {
                    allGroupsData = data;
                    getAssignedProjects(0);
                }
            })
    };

    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (user.userType === 16) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getAllGroups();
            getAllProjects();
            getAllUsers();
            getAllDrives();
        }
    }, [user]);

    const getAllProjects = () => {
        getDataWithToken("/api/project/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setProjectsData(response.data)
            })
    };
    const getAllUsers = () => {
        getDataWithToken("/api/user/get_all_users?page=1&pagesize=10000000&sortfield=CreateDate&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                setUsersData(response.data)
            })
    };
    const getAllDrives = () => {
        if (user.userType === 16) {
            console.log("/api/sharedclouddrive/get_all")
            console.log(user)
            getDataWithToken(`/api/sharedclouddrive/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false`)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }
                    setDrivesData(response.data)
                })
        }
    };

    function deleteGroup(id) {
        deleteDataWeb('/api/usergroup/delete?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setLoading(false);
                    setPopup500(true);
                    return;
                }

                if (status === 200) {
                    var newArray = [...groupsData];
                    var teamNameRemoved = newArray.filter((item) => item.id === id);
                    setGroupsData((current) => current.filter((item) => item.id !== id));
                    setAssignDelete(false);
                    sendDataToMixPanel('Team removed successfully.', user.username, {
                        'App': 'Web App',
                        'Team Name': teamNameRemoved[0].name
                    })
                    api.open({
                        message: 'Team removed successfully.',
                        description: `Your team has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                }

                setLoading(false)
            });
    }

    const handleChangeUser = (value) => {
        let newArr = [...newUsersList];
        for (var i = 0; i < usersData.length; i++) {
            if (usersData[i].id === value) {
                newArr.push(usersData[i])
            }
        }
        setNewUsersList(newArr);
    };


    return (<> {contextHolder}
        <div id="main-content">
            <div className="container-fluid mb-4">
                <div className="add-new-user-wrapper">
                    <div className="add-user-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="content-wrapper">
                                    <h2>Teams</h2>
                                    <p>Use Teams to give multiple users the <br/>
                                        same permissions and access.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ConfigurationsMenu/>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="btn-wrapper">
                                    <a href="#" className="btn-style small-btn icon" onClick={() => {
                                        if (userStatus.status === "active") {
                                            showManualUser()
                                        } else {
                                            setBillingPopupOpen(true);
                                        }
                                    }}>
                                        <img src={plusIcon} alt="icon"/>
                                        Create Team
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-body">
                        <div className="user-table-wrapper">
                            <div className="user-table-header">
                                <ul className={`filter ${!dataLoaded ? "disabled" : ""}`}>
                                    <li>
                                        <div className="form">
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={(e) => {
                                                    e.preventDefault()
                                                    setSearchValForSearch(e.target.value)
                                                }} value={searchValForSearch} placeholder="Search by Team Name"/>
                                                <a href="#" className="icon" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (userStatus.status === "active") {
                                                        cancelRequests();
                                                        setCurrentPage(1);
                                                        setDescending(false)
                                                        setGroupsData([])
                                                        getAllGroups();
                                                    }
                                                }}> <img src={searchIcon} alt="icon"/></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="user-table-body">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th className="flex">Teams <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                onChangeSort();
                                            }} className={`${descending ? "arrow-down" : "arrow-up"} ${!dataLoaded ? "disabled" : ""}`}><img
                                                src={downArrowIcon} alt="icon"/></a></th>
                                            <th>Projects</th>
                                            <th>Members</th>
                                            <th>Drive</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody className={groupsData.length > 4 ? "more-rows" : ""}>
                                        {groupsData.map(group => (<tr>
                                            <td className="width-400">
                                                <div className="group-title">
                                                    <p>{group.name}</p>
                                                    <span>{group.description}</span>
                                                </div>
                                            </td>
                                            <td className="more-tooltip width-200">
                                                <p>{group.projectsData.length > 0 && <>
                                                                <span>{group.projectsData[0].project.name}
                                                                </span>
                                                    {group.projectsData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {group.projectsData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.project.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{group.projectsData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="width-300"><p>{group.usersData.length > 0 && <>
                                                                <span>{group.usersData[0].user.email}
                                                                </span>
                                                {group.usersData.length > 1 && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="hover"
                                                    placement="bottomRight"
                                                    title={<ul className="view-list scroll">
                                                        {group.usersData.map((item, index) => (<>
                                                            {index > 0 && <li>{item.user.email}</li>}
                                                        </>))}
                                                    </ul>}
                                                ><a href="#">+{group.usersData.length - 1}</a>
                                                </Tooltip>}
                                            </>

                                            }
                                            </p></td>
                                            <td className="width-150"><p>{group.drivesData.length > 0 && <>
                                                                <span>{group.drivesData[0].sharedCloudDrive.name}
                                                                </span>
                                                {group.drivesData.length > 1 && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="hover"
                                                    placement="bottomRight"
                                                    title={<ul className="view-list scroll">
                                                        {group.drivesData.map((item, index) => (<>
                                                            {index > 0 && <li>{item.sharedCloudDrive.name}</li>}
                                                        </>))}
                                                    </ul>}
                                                ><a href="#">+{group.drivesData.length - 1}</a>
                                                </Tooltip>}
                                            </>

                                            }
                                            </p></td>
                                            <td className={!dataLoaded ? "disabled" : ""}>
                                                {userStatus.status === "active" && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                    title={<ul className="view-list">
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showEditGroup(group)
                                                        }}>Edit team</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showEditMembers(group)
                                                        }}>Manage members</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showAssignProjects(group)
                                                        }}>Manage Project</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showAssignGroup(group)
                                                        }}>Manage Share</a></li>

                                                        <li><a href="#" className="red-color"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   showAssignDelete(group.id)
                                                               }}>Delete Team</a></li>
                                                    </ul>}
                                                >
                                                    <a href="#" className="toltip-btn">
                                                        <img src={verticleDotsIcon} alt="icon"/>
                                                    </a>
                                                </Tooltip>}
                                            </td>
                                        </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={!dataLoaded ? "disabled" : ""}>
                                {totalGroups > perPage &&
                                    <Pagination current={currentPage} total={totalGroups} pageSize={perPage}
                                                onChange={onChangePage}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal title="Create new team" className="select-user-type" centered open={isSelectGroup}
               onCancel={selectUserCancel}>
            <div className="select-user-modal-wrapper">
                <div className="header">
                    <h3>Create new team</h3>
                </div>
                <div className="body">
                    <div className="select-wrapper">
                        <a href="#" className="btn-style" onClick={showOktaUser}>Import teams from AD/Okta</a>
                        <a href="#" className="btn-style" onClick={showManualUser}>Create teams manually</a>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectUserCancel}>Cancel</a>
                    <a href="#" className="btn-style blue">Proceed</a>
                </div>
            </div>
        </Modal>

        <Modal title="Import users from AD/Okta" className="okta-user-type" centered open={isOktaGroup}
               onOk={selectOktaOk} onCancel={selectOktaOk}>
            <div className="okta-user-modal-wrapper">
                <div className="header">
                    <h3>Import teams from AD/Okta</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Search teams by name..."/>
                                <img src={searchIcon} className="icon" alt="icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="search-user-wrapper">
                        <div className="select-all">
                            <Checkbox>Select all</Checkbox>
                            <div className="search-user">
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="search-user-card">
                                    <Checkbox>
                                        <div className="detail">
                                            <p>Programming Enthusiasts</p>
                                            <span>A team for those who love coding and want to discuss programming languages.</span>
                                        </div>
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectOktaCancel}>Cancel</a>
                    <a href="#" className="btn-style blue">Import</a>
                </div>
            </div>
        </Modal>


        <Modal title="Create new team" className="manual-user-type" centered open={isManualGroup}
               onOk={selectManualOk} onCancel={selectManualOk}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Create new team</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Team name</p>
                                <input type="text" className="form-control" onChange={(e) => {
                                    setGroupName(e.target.value)
                                }} value={groupName} placeholder="Enter team name"/>
                            </div>
                            <div className="form-group">
                                <p className="label">Team’s description</p>
                                <textarea className="form-control" onChange={(e) => {
                                    setGroupDescription(e.target.value)
                                }} value={groupDescription} name="description"
                                          placeholder="Enter team description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectManualCancel}>Cancel</a>
                    <Button className={`btn-style blue ${groupName.length < 1 || groupName.trim() === "" ? 'disabled' : ''}`} loading={loading}
                            onClick={createGroup}>Create team</Button>
                </div>
            </div>

        </Modal>

        <Modal title="Edit team" className="manual-user-type" centered open={isEditGroup} onOk={selectEditOk}
               onCancel={selectEditOk}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Edit team</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Team name</p>
                                <input type="text" className="form-control" onChange={(e) => {
                                    setGroupName(e.target.value)
                                }} value={groupName} placeholder="Enter team name"/>
                            </div>
                            <div className="form-group">
                                <p className="label">Team’s description</p>
                                <textarea className="form-control" onChange={(e) => {
                                    setGroupDescription(e.target.value)
                                }} value={groupDescription} name="description"
                                          placeholder="Enter team description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={selectEditCancel}>Cancel</a>
                    <Button className={`btn-style blue ${groupName.length < 1 || groupName.trim() === "" ? 'disabled' : ''}`} loading={loading}
                            onClick={updateGroup}>Save Changes</Button>
                </div>
            </div>

        </Modal>


        <Modal title="Assign Project" className="manual-user-type" centered open={isAssignShare}
               onOk={AssignShareCancel} onCancel={AssignShareCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Assign Project</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Projects</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        value={selectedProject}
                                        options={projectsData.filter((item) => {
                                            if (projectsList.filter((a) => a.projectId === item.id).length < 1 && newProjectsList.filter((a) => a.projectId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(projectsList.length > 0 || newProjectsList.length > 0) &&

                                    <div className="added-users">
                                        {projectsList.length > 0 && projectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.projectName} <a href="#"
                                                                                                               onClick={() => {
                                                                                                                   removeProjectFromList(i, project)
                                                                                                               }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermission(e, project.projectId)
                                                            }} value={project.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        {newProjectsList.length > 0 && newProjectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.projectName} <a href="#"
                                                                                                               onClick={(e) => {
                                                                                                                   e.preventDefault();
                                                                                                                   removeProjectFromList(i, project, true)
                                                                                                               }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermission(e, project.projectId, true)
                                                            }} value={project.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={AssignShareCancel}>Cancel</a>
                    <Button loading={loading}
                            className={`btn-style blue ${newProjectsList.length > 0 || projectsList.length > 0 ? "" : "disabled"}`}
                            onClick={AssignProjectsToGroup}>Save Changes</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Assign Drive" className="manual-user-type" centered open={isAssignDrive}
               onOk={AssignDriveCancel} onCancel={AssignDriveCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Assign Shared Drive</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Drive</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a Drive"
                                        onChange={onChangeCloudDrive}
                                        value={selectedCloudDrive}
                                        options={drivesData.filter((item) => {
                                            if (driveList.filter((a) => a.sharedCloudDriveId === item.id).length < 1 && newDriveList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(driveList.length > 0 || newDriveList.length > 0) && <div className="added-users">
                                    {driveList.length > 0 && driveList.map((drive, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#"
                                                                                                      onClick={() => {
                                                                                                          removeDriveFromList(i, drive.id)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionDrive(e, drive.id)
                                                        }} value={drive.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newDriveList.length > 0 && newDriveList.map((drive, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeDriveFromList(i, drive, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionDrive(e, drive.id, true)
                                                        }} value={drive.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={AssignDriveCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue ${newDriveList.length > 0 || driveList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignCloudDrive}>Assign</Button>
                </div>
            </div>
        </Modal>
        <DeleteModal title="Delete Team" text="Are you sure you want to delete this team?"
                     deleteOpen={isAssignDelete} deleteCancel={AssignDeleteCancel} deleteSuccess={assignDeleteOk}
                     loading={loading}/>
        <Modal title="Manage Users" className="manual-user-type" centered open={isEditUser} onOk={EditUserCancel}
               onCancel={EditUserCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Manage Users</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Users</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a User"
                                        onChange={handleChangeUser}
                                        value={selectedUsers}
                                        options={usersData.filter((item) => {
                                            if (usersList.filter((a) => a.user.id === item.id).length < 1 && newUsersList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.email, value: item.id,
                                        }))}
                                    />
                                </div>

                                {(usersList.length > 0 || newUsersList.length > 0) && <div className="added-users">
                                    {usersList.length > 0 && usersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{user.user.email} <a href="#"
                                                                                                           onClick={() => {
                                                                                                               removeUserFromList(i, user.userUserGroup.id)
                                                                                                           }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newUsersList.length > 0 && newUsersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{user.email} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeUserFromList(i, user.id, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent" onClick={EditUserCancel}>Cancel</a>
                    <Button loading={loading}
                            className={`btn-style blue ${newUsersList.length > 0 || usersList.length > 0 ? "" : "disabled"}`}
                            onClick={AssignUsersToGroup}>Save Changes</Button>
                </div>

            </div>
        </Modal>
        <BillingPopup></BillingPopup>
    </>)
}

export default Groups;

