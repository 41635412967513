import {React, useEffect, useState, useRef} from 'react';
import {Link, NavLink} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {Tooltip, Switch, Popconfirm} from 'antd';
import pageLogo from "../assets/images/logo-dark.svg";
import homeIcon from "../assets/images/icons/home-icon-new.svg";
import keyIcon from "../assets/images/icons/key-icon.svg";
import bucketIcon from "../assets/images/icons/bucket.svg";
import drawerIcon from "../assets/images/icons/sidebar-drawer-new-icon.svg";
import Gear from "../assets/images/icons/gear.svg";
import plane from "../assets/images/plane.svg";
import userIcon from "../assets/images/icons/team/user-icon.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import launchIcon from "../assets/images/icons/launch-amove-white-icon.svg";
import {useLocation} from "react-router-dom";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";
import lightModeIcon from "../assets/images/icons/light-mode-icon.svg";
import darkModeIcon from "../assets/images/icons/dark-mode-icon.svg";
import amoveStorageIcon from "../assets/images/icons/amove-storage-icon.svg";
import administratorIcon from "../assets/images/icons/administrator-icon.svg";
import awsIcon from "../assets/images/icons/aws-small-icon.svg";
import azureIcon from "../assets/images/icons/azure-small-icon.svg";
import googleIcon from "../assets/images/icons/google-cloud-icon.svg";
import wasabiIcon from "../assets/images/icons/wasabi-small-icon.svg";
import infoIcon from "../assets/images/icons/info-orange-icon.svg";
import logoutIcon from "../assets/images/icons/logout-switch-icon.svg";
import sunIcon from "../assets/images/icons/theme-sun-icon.svg";
import moonIcon from "../assets/images/icons/theme-moon-icon.svg";
import plusIcon from "../assets/images/icons/plus-white-icon.svg";
import trashIcon from "../assets/images/icons/trash-icon.svg";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


const Sidebar = ({open}) => {
    const {
        userStatus,
        user,
        isDark,
        setIsDark,
        hideBTNs,
        checkAgent,
        token,
        agentIsRunning,
        setOpenInviteUserPopup,
        showInvitePopup,
        setShowInvitePopup,
        backButton,
        logout,
        cloudAccounts,
        getIcon
    } = useAuth();
    const navigate = useNavigate();
    const [isOpenSidebar, setOpenSidebar] = useState(false);
    const wrapperRef = useRef(null);
    const location = useLocation();
    const onOpenSidebar = () => {
        setOpenSidebar(true)
        document.body.classList.add('sidebar-open');
        if (isOpenSidebar === true) {
            setOpenSidebar(false)
            document.body.classList.remove('sidebar-open');
        }
    }
    useEffect(() => {
        // Cleanup the class on route change
        return () => {
            // document.body.classList.remove('sidebar-open');
        };
    }, [location]);
    const closeSideBar = () => {
        //setOpenSidebar(false)
        //document.body.classList.remove('sidebar-open');
    }
    useOutsideAlerter(wrapperRef, closeSideBar);

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const toggleDarkMode = () => {
        var isCheckDark = localStorage.getItem('darkMode');
        console.log("isCheckDark-----------------")
        console.log(isCheckDark)
        if (isCheckDark === 'true') {
            document.body.classList.remove('dark-theme-active');
            localStorage.setItem('darkMode', "false")
            setIsDark(false);
        } else {
            document.body.classList.add('dark-theme-active');
            localStorage.setItem('darkMode', "true");
            setIsDark(true);
        }
    }

    const onThemeChange = (checked) => {
        console.log(`switch to ${checked}`);
        toggleDarkMode();
    };

    return (<>
        <div id="left-sidebar" ref={wrapperRef} className={`dark ${isOpenSidebar ? "active" : ""} `}>
            <div className={`left-sidebar-wrapper  ${userStatus.status === "unpaid" ? "disabled" : ""}`}>
                <div className="navbar-menu">
                    <div className="drawer-wrapper">
                        <Tooltip placement="right"
                                 trigger='hover'
                                 title={isOpenSidebar ? "Hide navigation" : "Show navigation"}>
                            <img src={drawerIcon} className="drawer" alt="icon" onClick={onOpenSidebar}/>
                        </Tooltip>
                        <Link to="/dashboard" className="logo">
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/></Link>
                    </div>

                    <div className="menu-wrapper-main">
                        {(location.pathname !== '/onboarding') && !hideBTNs &&
                            <ul className="menu">
                                <li>
                                    {!isOpenSidebar && <Tooltip placement="right"
                                                                trigger='hover'
                                                                title="Home">
                                        <NavLink to="/dashboard"
                                                 className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                 activeclassname="active">
                                            <img src={homeIcon} alt="dashboardNewIcon" className="dashboardNewIcon"/>
                                            <span>Home</span>
                                        </NavLink>
                                    </Tooltip>}
                                    {isOpenSidebar && <NavLink to="/dashboard"
                                                               className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                               activeclassname="active">
                                        <img src={homeIcon} alt="dashboardNewIcon" className="dashboardNewIcon"/>
                                        <span>Home</span>
                                    </NavLink>}
                                </li>


                                {user.userType !== 64 &&
                                    (location.pathname === '/storage-dashboard' || location.pathname === '/buckets' || location.pathname === '/access-key' || backButton) &&
                                    <li>
                                        {!isOpenSidebar && <Tooltip placement="right"
                                                                    trigger='hover'
                                                                    title="Buckets">
                                            <NavLink to="/buckets"
                                                     className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={bucketIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Buckets</span></NavLink>
                                        </Tooltip>}
                                        {isOpenSidebar &&
                                            <NavLink to="/buckets" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={bucketIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Buckets</span></NavLink>}
                                    </li>
                                }
                                {user.userType !== 64 &&
                                    (location.pathname === '/storage-dashboard' || location.pathname === '/buckets' || location.pathname === '/access-key' || backButton) &&
                                    <li>
                                        {!isOpenSidebar && <Tooltip placement="right"
                                                                    trigger='hover'
                                                                    title="Access Key">
                                            <NavLink to="/access-key"
                                                     className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={keyIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Access Key</span></NavLink>
                                        </Tooltip>}
                                        {isOpenSidebar &&
                                            <NavLink to="/access-key" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={keyIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Access Key</span></NavLink>}
                                    </li>
                                }

                                {/*{user.userType === 16 && (location.pathname !== '/storage-dashboard' && location.pathname !== '/buckets' && location.pathname !== '/access-key' && !backButton) &&
                                <li>
                                    {!isOpenSidebar && <Tooltip placement="right"
                                                                trigger='hover'
                                                                title="Cloudflyer">
                                        <NavLink to="/migration"
                                                 className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                 activeclassname="active">
                                            <img src={plane} alt="Cloudflyer Icon" className="confIconWhite"/>
                                            <span>Cloudflyer </span></NavLink>
                                    </Tooltip>}
                                    {isOpenSidebar &&
                                        <NavLink to="/migration" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                 activeclassname="active">
                                            <img src={plane} alt="Cloudflyer Icon" className="confIconWhite"/>
                                            <span>Cloudflyer</span></NavLink>}
                                </li>
                            }*/}
                                {user.userType === 16 && (location.pathname !== '/storage-dashboard' && location.pathname !== '/buckets' && location.pathname !== '/access-key' && !backButton) &&
                                    <li>
                                        {!isOpenSidebar && <Tooltip placement="right"
                                                                    trigger='hover'
                                                                    title="Cloudflyer">
                                            <NavLink to="/migration"
                                                     className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={amoveStorageIcon} alt="Cloudflyer Icon" className="confIconWhite"/>
                                                <span>Amove Storage </span></NavLink>
                                        </Tooltip>}
                                        {isOpenSidebar &&
                                            <NavLink to="/migration" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={amoveStorageIcon} alt="Cloudflyer Icon" className="confIconWhite"/>
                                                <span>Amove Storage</span></NavLink>}
                                    </li>
                                }
                                {/*{user.userType === 16 &&(location.pathname !== '/storage-dashboard' && location.pathname !== '/buckets' && location.pathname !== '/access-key' && !backButton) &&
                                    }
                                </li>
                            }*/}
                                {user.userType === 16 && (location.pathname !== '/storage-dashboard' && location.pathname !== '/buckets' && location.pathname !== '/access-key' && !backButton) &&
                                    <li>
                                        {!isOpenSidebar && <Tooltip placement="right"
                                                                    trigger='hover'
                                                                    title="Admin">
                                            <NavLink to="/users"
                                                     className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={administratorIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Administrator</span></NavLink>
                                        </Tooltip>}
                                        {isOpenSidebar &&
                                            <NavLink to="/users" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                     activeclassname="active">
                                                <img src={administratorIcon} alt="NFS Icon" className="confIconWhite"/>
                                                <span>Administrator</span></NavLink>}
                                    </li>
                                }
                            </ul>
                        }

                        <div className="sidebar-clouds">
                            {isOpenSidebar && (
                                <div className="heading-wrapper">
                                    <p>Clouds</p>
                                    <NavLink to="/add-cloud-account" className="add-icon">
                                        <img src={plusIcon} alt="icon"/></NavLink>
                                </div>
                            )}
                            {!isOpenSidebar && (
                                <div className={`heading-wrapper ${!isOpenSidebar ? 'small-wrapper' : ''}`}>
                                    {/*<div className="heading-wrapper">*/}
                                    <Tooltip placement="right"
                                             trigger='hover'
                                             title="Connect your Cloud">

                                        <NavLink to="/add-cloud-account" className={`add-icon ${isTooltipVisible ? 'tooltip-open' : ''}`}>
                                            <img src={plusIcon} alt="icon"/></NavLink>
                                    </Tooltip>
                                </div>
                            )}

                            <ul className="menu">
                                {cloudAccounts.length > 0 && (<>


                                        <div className="table-body">
                                            <ul>
                                                {cloudAccounts.map((item, accountIndex) => (
                                                    <li key={item.id}>
                                                        {!isOpenSidebar && <Tooltip placement="right"
                                                                                    trigger='hover'
                                                                                    title={item.name}>
                                                            <a href="#"
                                                               className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                               activeclassname="active">
                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                                <span>{item.name}</span></a>
                                                        </Tooltip>}
                                                        {isOpenSidebar &&
                                                            <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`}
                                                               activeclassname="active">
                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                                <span>{item.name}</span></a>}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>

                    {(location.pathname !== '/onboarding') && !hideBTNs &&

                        <ul className="menu bottom-menu">

                            {/*{user.userType === 16 &&(location.pathname !== '/storage-dashboard' && location.pathname !== '/buckets' && location.pathname !== '/access-key' && !backButton) &&
                                <li className="invie-people-icon-left-sidebar">
                                    {!isOpenSidebar && <Tooltip placement="right"
                                                                trigger='hover'
                                                                title="Invite people">
                                        <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                            e.preventDefault();
                                            setIsTooltipVisible(false);
                                            setOpenInviteUserPopup(true)
                                            navigate("/users");
                                        }}>
                                            <img src={userIcon} alt="activityIcon" className="activityIcon make-white-image"/>
                                            <span>Invite people</span>
                                        </a>

                                    </Tooltip>}
                                    {isOpenSidebar &&
                                        <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                            e.preventDefault();
                                            setIsTooltipVisible(false);
                                            setOpenInviteUserPopup(true)
                                            navigate("/users");
                                        }}>
                                            <img src={userIcon} alt="activityIcon" className="activityIcon make-white-image"/>
                                            <span>Invite people</span>
                                        </a>
                                    }
                                </li>
                            }*/}
                            <li className="no-menu-orange-hover">
                                {!isOpenSidebar && <Tooltip placement="right"
                                                            trigger='hover'
                                                            title="Information">
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <img src={infoIcon} alt="activityIcon" className="activityIcon"/>
                                        <span>Information</span>
                                    </a>

                                </Tooltip>}
                                {isOpenSidebar &&
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <img src={infoIcon} alt="activityIcon" className="activityIcon"/>
                                        <span>Information</span>
                                    </a>
                                }
                            </li>
                            <li className="no-menu-orange-hover">
                                {!isOpenSidebar && <Tooltip placement="right"
                                                            trigger='hover'
                                                            title="Log out">
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                    }}>
                                        <img src={logoutIcon} alt="activityIcon" className="activityIcon"/>
                                        <span>Log out</span>
                                    </a>

                                </Tooltip>}
                                {isOpenSidebar &&
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                    }}>
                                        <img src={logoutIcon} alt="activityIcon" className="activityIcon"/>
                                        <span>Log out</span>
                                    </a>
                                }
                            </li>
                            <li className="no-menu-orange-hover theme-switch-main">
                                {!isOpenSidebar && <Tooltip placement="right"
                                                            trigger='hover'
                                                            title={isDark ? 'Dark' : 'Light'}>
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <Switch defaultChecked onChange={onThemeChange}/>
                                        <span>{isDark ? 'Dark' : 'Light'}</span>
                                    </a>

                                </Tooltip>}
                                {isOpenSidebar &&
                                    <a href="#" className={`medium ${isTooltipVisible ? 'tooltip-open' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <Switch defaultChecked onChange={onThemeChange}/>
                                        <span>{isDark ? 'Dark' : 'Light'}</span>
                                    </a>
                                }
                            </li>
                            {/*{(isDark === false) &&
                                <li>
                                    {!isOpenSidebar &&
                                        <Tooltip placement="right"
                                                 trigger='hover'
                                                 title="Dark Mode">
                                            <a href="#" className="medium" onClick={toggleDarkMode}>
                                                <img src={lightModeIcon} alt="themeIcon" className="themeIcon"/>
                                                <span>Dark Mode</span></a>
                                        </Tooltip>
                                    }
                                    {isOpenSidebar &&
                                        <a href="#" className="medium" onClick={toggleDarkMode}>
                                            <img src={lightModeIcon} alt="themeIcon" className="themeIcon"/>
                                            <span>Dark Mode</span></a>
                                    }
                                </li>
                            }
                            {(isDark === true) &&
                                <li>
                                    {!isOpenSidebar &&
                                        <Tooltip placement="right"
                                                 trigger='hover'
                                                 title="Light Mode">
                                            <a href="#" className="medium" onClick={toggleDarkMode}>
                                                <img src={darkModeIcon} alt="themeIcon" className="themeIcon"/>
                                                <span>Light Mode</span></a>
                                        </Tooltip>
                                    }
                                    {isOpenSidebar &&
                                        <a href="#" className="medium" onClick={toggleDarkMode}>
                                            <img src={darkModeIcon} alt="themeIcon" className="themeIcon"/>
                                            <span>Light Mode</span></a>
                                    }
                                </li>
                            }*/}

                            {/*<li>
                                {!isOpenSidebar && <Tooltip placement="right"
                                                            trigger='hover'
                                                            title={agentIsRunning ? "App is Launched" : "Launch Amove"}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (!agentIsRunning) {
                                            checkAgent(true)
                                            window.location.href = `amoveapp://open?token=${token}`
                                        }
                                    }}
                                       className={`medium ${isTooltipVisible ? 'tooltip-open' : ''} ${agentIsRunning ? "disabled-new" : ""}`}
                                       activeclassname="active">
                                        <img src={launchIcon} alt="activityIcon" className="activityIcon"/>
                                        <span>Launch Amove</span></a>
                                </Tooltip>}
                                {isOpenSidebar && <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    if (!agentIsRunning) {
                                        checkAgent(true)
                                        window.location.href = `amoveapp://open?token=${token}`
                                    }
                                }}
                                                     className={`medium ${isTooltipVisible ? 'tooltip-open' : ''} ${agentIsRunning ? "disabled-new" : ""}`}
                                                     activeclassname="active">
                                    <img src={launchIcon} alt="activityIcon" className="activityIcon"/>
                                    <span>{agentIsRunning ? "App is Launched" : "Launch Amove"}</span></a>}
                            </li>*/}

                        </ul>
                    }

                </div>
            </div>
        </div>

        {user.userType === 16 &&
            <div className={`tour-tooltip tour-add-user ${showInvitePopup ? "active" : ""} ${isOpenSidebar ? "sidebar-opened" : ""}`}>
                <div className="tour-tooltip-wrapper">
                    <a href="#" className="cross" onClick={(e) => {
                        e.preventDefault();
                        setShowInvitePopup(false)
                        localStorage.setItem('shorNextTour', "true")
                        window.history.pushState({}, null, "/");
                    }}>
                        <img src={crossBalckIcon} alt="icon"/>
                    </a>

                    <h3>Invite New Users</h3>
                    <p className="p mb-0">Add your team and assign instant Drive access.
                        Or manage onboarding through SSO <NavLink to="/SSO" onClick={() => {
                            setShowInvitePopup(false)
                            localStorage.setItem('shorNextTour', "true")
                        }}>here</NavLink>.</p>
                </div>
            </div>
        }
        <div id="left-sidebar-tooltip-wraper" className={`${isOpenSidebar ? "active" : ""}`}></div>
    </>)
}

export {Sidebar}
