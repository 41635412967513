import {React, useEffect, useState, useRef} from 'react';
import {CloudAccounts} from "../components/CloudAccounts";
import {Navigate, useSearchParams, useNavigate, NavLink, useLocation} from "react-router-dom";

import {Button, Checkbox, Dropdown, Modal, Popconfirm, Popover, Radio, Select, Switch, Tooltip, Tour} from "antd";
import {useAuth} from "../context/AuthContext";
import {CloudDrives} from "../components/CloudDrives";
import {BackupInstance} from "../components/BackupInstance";
import {ProjectsAccordion} from "../components/ProjectsAccordion";
import Icon, {CloseOutlined} from '@ant-design/icons';
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";
import config from "../config";
import popupImage from "../assets/images/amove-setup-min.jpg";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";
import fileIcon1 from "../assets/images/ai-search/file-icon-1.svg";
import fileIcon2 from "../assets/images/ai-search/file-icon-2.svg";
import fileIcon3 from "../assets/images/ai-search/file-icon-3.svg";
import downArrowIcon from "../assets/images/ai-search/angle-arrow-down-icon.svg";
import searchIcon from "../assets/images/ai-search/search-icon.svg";
import calendarIcon from "../assets/images/ai-search/calendar-icon.svg";
import timeIcon from "../assets/images/ai-search/time-icon.svg";
import guideIcon1 from "../assets/images/ai-search/guide-icon-1.svg";
import guideIcon2 from "../assets/images/ai-search/guide-icon-2.svg";
import guideIcon3 from "../assets/images/ai-search/guide-icon-3.svg";
import guideIcon4 from "../assets/images/ai-search/guide-icon-4.svg";
import {DateRange} from "react-date-range";
import filterIcon from "../assets/images/ai-search/filter-icon.svg";
import searchOrangeIcon from "../assets/images/ai-search/orange-search-icon.svg";
import searchGrayIcon from "../assets/images/ai-search/search-icon.svg";
import paginationLeftIcon from "../assets/images/ai-search/pagination-left-arrow.svg";
import paginationRightIcon from "../assets/images/ai-search/pagination-right-arrow.svg";
import azureIcon from "../assets/images/icons/azure-small-icon.svg";
import AiSearch from "../components/AiSearch/AiSearch";

const Dashboard = () => {
    const [searchParams] = useSearchParams();
    const subscriptionStatus = searchParams.get('subscription');
    const {
        getData,
        user,
        userStatus,
        amovePkg,
        showAgentOnce,
        setShowAgentOnce,
        isDark,
        checkAgent,
        setShowHeaderAndSidebar,
        isWindows,
        showTourDashboard,
        setShowTourDashboard,
        totalDrives,
        tourStep,
        setTourStep,
        agentIsRunning,
        showStartedBtn,
        setShowStartedBtn,
        sendDataToMixPanel,
        token,
        cloudAccounts,
        defaultOpenCloudDrive,
        isAuthenticated,
        setShowDrivePopup2,
        showLaunchPopup,
        setShowLaunchPopup,
        getDataWithTokenPOST,
        setPopup500,
        setError499,
        setPopup499,
        getIcon
    } = useAuth();

    //creating a reference object
    const countRef = useRef(agentIsRunning);
    countRef.current = agentIsRunning;

    const location = useLocation();
    const navigate = useNavigate();
    const [firstPopup, setFirstPopup] = useState(false);
    const dataFetchedRef = useRef(false);
    const ticking = useRef(false);
    const lastScrollTop = useRef(0);


    const urlParams = new URLSearchParams(window.location.search);
    const amoveDrive = urlParams.get('amove')
    const onboarding = urlParams.get('onboarding')
    const accounts = urlParams.get('accounts')
    const lastid = urlParams.get('lastid');

    const [showButtons, setShowButtons] = useState(false);
    const [driveCount, setDriveCount] = useState(0);
    const [showSingleButton, setShowSingleButton] = useState(false);
    const [drivesOpen, setDrivesOpen] = useState(false);
    const [accountsOpen, setAccountsOpen] = useState(false);
    const [syncOpen, setSyncOpen] = useState(false);
    const [standardUserTour, setStandardUserTour] = useState(false);
    const searchInputRef = useRef(null);
    const metaSearchRef = useRef(null);
    const [aISearchSection, setAISearchSection] = useState(false);
    const [aISearchSectionActive, setAISearchSectionActive] = useState(false);
    const [modalActivateAi, setModalActivateAi] = useState(false);
    const [loading, setLoading] = useState(false);

    const [buckets, setBuckets] = useState([]);
    const [bucket, setBucket] = useState("");
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [selectedCloudAccountID, setSelectedCloudAccountID] = useState('');

    const OnboardingAgentOk = () => {
        setShowAgentOnce(false);
        if (amoveDrive === "true") {
            localStorage.setItem("dashboardTourStep", 1)
            setShowTourDashboard(true)
        }
    };
    const loadCloudDrives = () => {
        getData('/Management/clouddrive')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setDriveCount(response.length)
                if (response.length > 0) {
                    setShowStartedBtn(false)
                } else {
                    setShowStartedBtn(true)
                }
            }).catch((error) => {
            setShowStartedBtn(true)
        })
    }
    useEffect(() => {
        if (agentIsRunning) {
            loadCloudDrives();
            setStandardUserTour(false)
        } else if (user.userType === 64) {
            localStorage.setItem('shorNextTour', false)
        }
        if (!agentIsRunning) {
            setShowStartedBtn(true)
        }
    }, [agentIsRunning]);

    function mouseScroll(event) {
        console.log('scrolled')
        clearTimeout(ticking.current)
        ticking.current = setTimeout(() => {
            console.log('scrolled')
            console.log(event)
            console.log(JSON.stringify(event))
            console.log(event.deltaY)
            console.log(window.scrollY)
            console.log(aISearchSection)
            if (window.scrollY === 0 && event.deltaY === -100) {
                setAISearchSection(true)
                setTimeout(function () {
                    setAISearchSectionActive(true)
                }, 100)
            }
            console.log("aISearchSection")
            if (window.scrollY > 0 && event.deltaY === 100) {
                console.log("setAISearchSection False")
                setAISearchSectionActive(false)
                setTimeout(function () {
                    setAISearchSection(false)
                }, 1000)
            }

        }, 100)
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setShowHeaderAndSidebar(true);
        setTimeout(function () {
            if (amoveDrive === "true") {
                localStorage.setItem("dashboardTourStep", 1)
                setShowTourDashboard(true)
            }
        }, 2000)
        checkAgent()
        checkAgentInterval()
        document.addEventListener('wheel', mouseScroll);

    }, []);


    var agentCallCount = 0

    function checkAgentInterval() {
        console.log("callAgentAgain")
        console.log(countRef.current)
        agentCallCount++
        var callAgentAgain = setTimeout(() => {
            checkAgentInterval()
        }, 2000);

        if (localStorage.getItem('isLoggedInTimer') === null || agentCallCount === 10 || countRef.current) {
            console.log("timer cleared checkAgentInterval ----------------")
            agentCallCount = 0
            clearTimeout(callAgentAgain);
        } else {
            checkAgent();
        }
    }


    useEffect(() => {
        if (user.userType === 64) {
            if (!agentIsRunning) {
                setStandardUserTour(true)
            }
        }
    }, [user]);
    if (subscriptionStatus === 'unsuccess') {
        return <Navigate to="/cancel-subscribe"/>;
    }

    function startTourAgain() {
        setShowTourDashboard(true);
        setShowStartedBtn(true);
    }

    function accordionCheck(data) {
        Object.entries(data).find(([key, val]) => {
            if (key === 'drives') {
                setDrivesOpen(val)
            }
            if (key === 'accounts') {
                setAccountsOpen(val)
            }
            if (key === 'sync') {
                setSyncOpen(val)
            }
        })
    }


    const onCloudChange = (checked) => {
        console.log(`switch to ${checked}`);
    };
    const onChangeCloud = (value) => {
        setBuckets([]);
        setSelectedCloudAccount(cloudAccounts.filter(account => account.id === value))
        console.log(cloudAccounts.filter(account => account.id === value))
        setSelectedCloudAccountID(value)
        getBuckets(value)
    };

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount, "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = bucket.type;
                    setError499(bucket.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                console.log(bucket)
                setBuckets(bucket);
            });
    }

    return (
        <>
            <Modal
                title="Vertically centered modal dialog"
                centered
                open={modalActivateAi}
                onOk={() => setModalActivateAi(false)}
                onCancel={() => setModalActivateAi(false)}
                className="activate-ai-modal"
            >
                <div className="activate-ai-wrapper">
                    <h3 className="h3">Activate AI</h3>
                    <div className="form">
                        <div className="form-group">
                            <p className="label">Select Cloud Account</p>
                            <Select
                                showSearch
                                id="select-cloud-1"
                                placeholder="Select Cloud"
                                optionFilterProp="children"
                                onChange={onChangeCloud}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                value={selectedCloudAccountID || undefined}
                                options={cloudAccounts?.filter((item) => (item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024)).filter(item => !item.disabled).map((item) => ({
                                    label: item.name, value: item.id,
                                }))}
                            />
                        </div>
                    </div>
                    <div className="activate-cloud-main">
                        {buckets.length > 0 &&
                            <div className="activate-cloud-wrapper">
                                <div className="cloud-item header">
                                    <p>Cloud</p>
                                    <p>Bucket</p>
                                    <p>On/Off</p>
                                </div>

                                <div className="cloud-item-body-wrapper">
                                    {buckets.map((item) => (
                                        <div className="cloud-item">
                                            <div className="cloud-name">
                                                {getIcon(selectedCloudAccount[0].cloudType, selectedCloudAccount[0].cloudIconType)}
                                                <p>{selectedCloudAccount[0].name}</p>
                                            </div>
                                            <p>{item.name}</p>
                                            <div className="switch-wrapper">
                                                <Popover
                                                    content={
                                                        <div className="ai-popover">
                                                            <h3 className="">Enable AI Processing</h3>
                                                            <p className="">Files will be processed with Amove AI. <br/>
                                                                Once activated AI features will be applied.</p>
                                                            <div className="popover-footer">
                                                                <Button className="btn-style transparent orange">Cancel</Button>
                                                                <Button className="btn-style orange-btn">Activate AI</Button>
                                                            </div>
                                                        </div>}
                                                    trigger="click"
                                                    placement="left"
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    <Switch/>
                                                </Popover>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                    {buckets.length > 0 &&
                        <div className="ai-footer">
                            <div className="row">
                                <div className="col-md-8 align-self-center">
                                    {/* <ul className="ai-pagination">
                                    <li><a href="#"><img src={paginationLeftIcon} alt="icon"/></a></li>
                                    <li><p className="p">Page</p></li>
                                    <li>
                                        <div className="count"><p>1</p></div>
                                    </li>
                                    <li><p className="p">of</p></li>
                                    <li><p className="p">4</p></li>
                                    <li><a href="#"><img src={paginationRightIcon} alt="icon"/></a></li>
                                </ul>*/}
                                </div>
                                <div className="col-md-4 align-self-center">
                                    <div className="btn-wrapper">
                                        <Popover
                                            content={
                                                <div className="ai-popover">
                                                    <h3 className="h3">Changes not saved.</h3>
                                                    <p className="p">Please save your changes before applying bulk activation.</p>
                                                    <div className="popover-footer">
                                                        <Button className="btn-style transparent black">Cancel</Button>
                                                        <Button className="btn-style orange-btn">Save</Button>
                                                    </div>
                                                </div>}
                                            trigger="click"
                                            placement="left"
                                            getPopupContainer={trigger => trigger.parentNode}
                                        >
                                            <a href="#" className="btn-style orange-btn">Save Changes</a>
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            <div id="main-content" className={`${showTourDashboard ? "tour-index" : ""} ${tourStep === 5 && showTourDashboard ? "step-5" : ""}`}>
                <div className="container-fluid">
                    {aISearchSection &&
                        <div className={`ai-search-scrolled-section ${aISearchSectionActive ? "active" : ""}`}>
                            <AiSearch/>
                        </div>
                    }
                    {(userStatus.status === "trial" || userStatus.status === "active") &&
                        <div className="main-content-body home dashboard-page">
                            <div className="dashboard-ai-search">
                                <div className="row">
                                    <div className="col-md-9 align-self-center">
                                        <AiSearch location="dashboard"/>
                                    </div>
                                    <div className="col-md-3 align-self-center">
                                        <div className="activate-ai">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setModalActivateAi(true)
                                            }}>Activate AI</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {user.userType !== 64 && user.userType !== undefined &&
                                <ProjectsAccordion/>
                            }
                            <CloudDrives callback={accordionCheck}/>
                            {user.userType !== 64 && user.userType !== undefined &&
                                <div className="new-pop-wrapper" id="drives-accordion-popup">
                                    <CloudAccounts open={amoveDrive === "true" || accounts === "true" ? 1 : 0} lastid={lastid} onboarding={amoveDrive === "true" || onboarding} callback={accordionCheck}/>
                                    <Tooltip
                                        placement="right"
                                        open={firstPopup}
                                        getPopupContainer={() => document.getElementById("drives-accordion-popup")}
                                        title={<div className="tooltip-new-popup">
                                            <a href="#" className="close-icon" onClick={(e) => {
                                                setFirstPopup(false)
                                                e.preventDefault()
                                            }}>
                                                <CloseOutlined/>
                                            </a>
                                            <span className="title">Amove Storage Now Available</span>

                                            <p>25GB is now available directly from your Amove Drive. <br/>
                                                1TB free for each user with an Amove Everywhere Subscription.
                                            </p>

                                            {amovePkg === "premium" &&
                                                <p>25GB is now available directly from your Amove Drive. <br/>
                                                    3TB free for each user with your Amove Premium subscription.</p>
                                            }
                                        </div>}>
                                    </Tooltip>
                                </div>
                            }
                            {user.userType !== 64 && user.userType !== undefined &&
                                <BackupInstance callback={accordionCheck}/>
                            }
                        </div>
                    }
                    {/*Agent Popup*/}
                    {(amoveDrive === "true" || showAgentOnce) &&
                        <Modal
                            open={showAgentOnce} onOk={OnboardingAgentOk} onCancel={OnboardingAgentOk}
                            centered
                            className="download-client-popup once-agent"
                            footer={null}
                            title={null}
                        >
                            <div className="cloud-drive-modal-wrapper new-on-boarding-popup">
                                <div className="row row-eq-height">
                                    <div className="col-md-4 ">
                                        <div className="left-side">
                                            <div className="logo-img-wrapper">
                                                <img src={pageLogo} alt="logo"/>
                                            </div>
                                            <div className="content-wrapper">
                                                <h3 className="title">Your 25 GB of Amove <br/> storage is now available.</h3>
                                                <h3>Let's download the Amove Desktop <br/> Client in order to gain instant access.</h3>
                                                <h3>To connect additional cloud <br/> accounts to Amove click <a href="#" onClick={() => {

                                                    setShowAgentOnce(false);
                                                    setShowAgentOnce(false);
                                                    setShowTourDashboard(false)
                                                    localStorage.setItem("dashboardTourStep", 0)
                                                    navigate("/add-cloud-account");
                                                }}>here</a>.</h3>
                                            </div>

                                            <div className="text-center launch-amove-btn">

                                                {config.environment === 'POC' && !showSingleButton &&
                                                    <>
                                                        {!isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-intel-POC downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-POC.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                        {isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-64bit-POC downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })
                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-POC.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                    </>
                                                }
                                                {config.environment !== 'POC' && !showSingleButton &&
                                                    <>
                                                        {!isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                        {isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })
                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="right-side">
                                            <img src={`${popupImage}`} alt="popupImage"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    }

                    {user.userType === 64 && !agentIsRunning && standardUserTour && !showLaunchPopup &&
                        <div className="standard-user-tour">
                            <div className="tour-overlay" onClick={(e) => {
                                e.preventDefault();
                                setStandardUserTour(false)
                            }}></div>
                            <div className={`tour-tooltip tour-create-drive tour-step-5 active`}>
                                <div className="tour-tooltip-wrapper">
                                    <a href="#" className="cross" onClick={(e) => {
                                        e.preventDefault();
                                        setStandardUserTour(false)
                                    }}>
                                        <img src={crossBalckIcon} alt="icon"/>
                                    </a>
                                    <h3>Access your drive</h3>
                                    <ul className="steps">
                                        <li className="active"><span className="dot"></span></li>
                                        <li className="active"><span className="dot"></span></li>
                                        <li className="active"><span className="dot"></span></li>
                                    </ul>
                                    <div className="launch-amove">
                                        <p className="p black bold">Instant Connectivity for Everyone</p>
                                        <p className="p black">Set up unlimited Connections, Drives, Syncs and manage
                                            all of your teams and content instantly, from anywhere.</p>

                                        <p className="p black bold">Download the Amove Desktop App</p>
                                        <p className="p black">If you have not installed the Amove app, please do so:</p>


                                        <div className="btn-wrapper">
                                            {config.environment === 'POC' && !showSingleButton &&
                                                <>
                                                    {!isWindows &&
                                                        <a href='#' onClick={(e) => {
                                                            e.preventDefault();
                                                            checkAgent(true)
                                                            setShowSingleButton(true)
                                                            sendDataToMixPanel('Amove-intel-POC downloaded.', user.username, {
                                                                'App': 'Web App'
                                                            })
                                                            setStandardUserTour(false)

                                                            // Create a temporary link element
                                                            const link = document.createElement('a');
                                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-POC.pkg";
                                                            link.download = "Amove-Mac-latest.pkg";
                                                            link.target = "_blank";

                                                            // Append the link to the body
                                                            document.body.appendChild(link);

                                                            // Trigger the download by clicking the link
                                                            link.click();

                                                            // Remove the link from the document
                                                            document.body.removeChild(link);
                                                        }} className="btn-tour orange">Download Amove</a>
                                                    }
                                                    {isWindows &&
                                                        <a href='#' onClick={(e) => {
                                                            e.preventDefault();
                                                            checkAgent(true)
                                                            setShowSingleButton(true)
                                                            setStandardUserTour(false)
                                                            sendDataToMixPanel('Amove-64bit-POC downloaded.', user.username, {
                                                                'App': 'Web App'
                                                            })

                                                            // Create a temporary link element
                                                            const link = document.createElement('a');
                                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-POC.exe";
                                                            link.download = "Amove-Mac-latest.pkg";
                                                            link.target = "_blank";

                                                            // Append the link to the body
                                                            document.body.appendChild(link);

                                                            // Trigger the download by clicking the link
                                                            link.click();

                                                            // Remove the link from the document
                                                            document.body.removeChild(link);
                                                        }} className="btn-tour orange">Download Amove</a>
                                                    }
                                                </>
                                            }
                                            {config.environment !== 'POC' && !showSingleButton &&
                                                <>
                                                    {!isWindows &&
                                                        <a
                                                            href="#"
                                                            className="btn-tour fix-width orange"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true);
                                                                setShowSingleButton(true);
                                                                setStandardUserTour(false);
                                                                sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                });

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }}
                                                        >
                                                            Download Amove
                                                        </a>
                                                    }
                                                    {isWindows &&
                                                        <a
                                                            href="#"
                                                            className="btn-tour fix-width orange"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true);
                                                                setShowSingleButton(true);
                                                                setStandardUserTour(false);
                                                                setShowDrivePopup2(true);
                                                                sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                });

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }}
                                                        >
                                                            Download Amove
                                                        </a>
                                                    }
                                                </>
                                            }
                                        </div>

                                        <p className="p black bold">Launch the Amove Desktop App</p>
                                        <p className="p black">If you already have the app installed, simply launch it:</p>

                                        <div className="btn-wrapper">
                                            <a href='#' onClick={(e) => {
                                                e.preventDefault();
                                                sendDataToMixPanel('Launch the Amove Desktop App', user.username, {
                                                    'App': 'Web App'
                                                })
                                                checkAgent(true)
                                                window.location.href = `amoveapp://open?token=${token}`;

                                            }} className={`btn-tour`}>Launch Amove</a>
                                        </div>
                                    </div>
                                    {/*<div className="amove-connectivity">
                        <p className="p black bold">Instant Connectivity for Everyone</p>
                        <p className="p black">Setup unlimited Connetions, Drives, Projects
                            and manage ALL of your teams and content instantly, from anywhere.</p>
                    </div>*/}
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </div>
            {user.userType !== 64 && !aISearchSection && user.userType !== undefined && showStartedBtn && !syncOpen && !accountsOpen && !drivesOpen && !showTourDashboard && cloudAccounts.length > 0 &&
                <a href="#" className="getting-started-link btn-style small orange" onClick={startTourAgain}>Guide</a>
            }
        </>
    )
}

export default Dashboard;

