import React, {useEffect, useState} from "react";
import {Dropdown, Tabs} from "antd";
import pageLogo from "../../assets/images/page-logo.svg";
import MonthlySubscription from "../../components/Subscription/MonthlySubscription";
import YearlySubscription from "../../components/Subscription/YearlySubscription";
import {useAuth} from "../../context/AuthContext";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import pageLogoWhite from "../../assets/images/logo-dark.svg";
import config from "../../config";

//Live
/*  "MonthlyPlanId": "price_1OLqEEFIS02f0YTqR9Cto5lH",
      "YearlyPlanId": "price_1OLqEaFIS02f0YTqVYnH0A4u"*/


//App 2
/*  "MonthlyPlanId": "price_1OIwwkGjtdaDcihGf2vWHtl2",
      "YearlyPlanId": "price_1OIwxsGjtdaDcihGfBFQlFbn"*/

const TabelAmoveEverywhere = [
    {
        label: "Monthly",
        key: "1",
        children: <MonthlySubscription logo={pageLogo} price="15" priceId={config.EverywhereMonthlyPlanId}/>,
    },
    {
        label: "Annual",
        key: "2",
        children: <YearlySubscription logo={pageLogo} price="10" priceId={config.EverywhereYearlyPlanId}/>,
    },
];
const TabelAmovePremium = [
    {
        label: "Monthly",
        key: "1",
        children: <MonthlySubscription logo={pageLogo} price="30" priceId={config.PremiumMonthlyPlanId}/>,
    },
    {
        label: "Annual",
        key: "2",
        children: <YearlySubscription logo={pageLogo} price="20" priceId={config.PremiumYearlyPlanId}/>,
    },
];
const SubscriptionPlan = () => {

    const {userStatus, user, isDark, logout} = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const subscriptionStatus = searchParams.get('subscription');
    const [items, setItems] = useState([]);

    if (subscriptionStatus === 'success') {
        navigate("/");
    }
    useEffect(() => {
        if (!user.owner) {
            navigate("/billing-status");
        }
        var itemsMenu = [

            {
                key: "0",
                label: (
                    <Link
                        className={userStatus.status === "unpaid" ? "disabled" : ""}
                        to="/account/"
                    >
                        Account
                    </Link>
                ),
            },
            {
                key: "3",
                label: (
                    <a
                        href="#"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Sign out
                    </a>
                ),
            }
        ];
        if (user.owner) {
            itemsMenu.splice(1, 0,
                {
                    key: "1",
                    label: (
                        <Link
                            to="/billing-status/"
                        >
                            Billing
                        </Link>
                    ),
                }
            )
        }
        setItems(itemsMenu)
    }, [userStatus, user]);
    return (
        <div className="login subscription subscription-page-wrapper">
            <div className="subscription-header">
                <div className="row">
                    <div className="col-md-6 col-4 align-self-center">
                        {userStatus.status !== 'unpaid' &&
                            <div className="subscription-back">
                                <Link to="/">
                                    <i className="fa-solid fa-arrow-left"></i>
                                    <span className="visible">Amove, Inc</span>
                                    <span className="hover">Back</span>
                                </Link>
                            </div>
                        }
                    </div>
                    <div className="col-md-6 col-8 align-self-center">
                        <ul className="dashboard-options subscribe-page">
                            <li>
                                <div className="user-account-dropdown">
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        placement="bottomRight"
                                        arrow
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                        {/*<span className="letter-wrapper">{userName}</span>*/}
                                        <div className="user-account">
                                            <span>{user.username}</span>
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="#000" aria-hidden="true">
                                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                            </svg>
                                        </div>
                                    </Dropdown>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="login-wrapper subscription-wrapper">
                <div className="logo">
                    <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                </div>
                <div className="tabs-wrapper">
                    <div className="tabs-pricing-wrapper">
                        <div className="pricing-wrapper">
                            <h3>Click for Everyone</h3>
                            <Tabs
                                defaultActiveKey="2"
                                type="card"
                                items={TabelAmoveEverywhere}
                                className="antdTabs"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default SubscriptionPlan;
