import React, {useEffect, useState} from "react";
import {notification, Progress, Tooltip,} from "antd";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import info from "../assets/images/info.svg";
import AgentOffPopup from "../components/AgentOffPopup";
import SettingHeader from "../components/Setting/SettingHeader";

const ActivityPage = () => {
    const {
        activityDataDrives,
        activityDataBackups,
        activityDataDirects,
        setactivityDrives,
        setactivityBackups,
        setactivityDirects,
        agentIsRunning,
        prettySize,
        setError499,
        setPopup499,
        isWindows,
        clearActivityData
    } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const {getData, logout, setPopup500} = useAuth();
    const [cloudDrives, setCloudDrives] = useState([]);
    const [backupInstance, setBackupInstance] = useState([]);
    const [directs, setDirects] = useState([]);

    function toggleClass(index) {
        var data = [...cloudDrives]
        for (var i = 0; i < data.length; i++) {
            if (index !== i) {
                data[i].active = false;
            }
        }
        if (data[index].active) {
            data[index].active = false;
        } else {
            data[index].active = true;
        }
        setCloudDrives(data);
        var data1 = [...backupInstance]
        for (var i1 = 0; i1 < data1.length; i1++) {
            data1[i1].active = false;
        }
        setBackupInstance(data1);
    }

    function toggleClassBackup(index) {
        var data1 = [...cloudDrives]
        for (var i1 = 0; i1 < data1.length; i1++) {
            data1[i1].active = false;
        }
        setCloudDrives(data1);
        var data = [...backupInstance]
        for (var i = 0; i < data.length; i++) {
            if (index !== i) {
                data[i].active = false;
            }
        }
        if (data[index].active) {
            data[index].active = false;
        } else {
            data[index].active = true;
        }
        setBackupInstance(data);
    }

    function getFilename(fullPath) {
        var parts = fullPath.split(/[/\\]/);
        return parts.pop();
    }


    useEffect(() => {

        var aDirects = [...activityDataDirects]

        for (var i = 0; i < aDirects.length; i++) {
            aDirects[i].active = false;
        }
        setDirects(aDirects);

        if (cloudDrives.length === 0) {
            getData('/Management/clouddrive')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    for (var i = 0; i < data.length; i++) {
                        data[i].active = false;
                    }
                    setCloudDrives(data);
                });
        }
        if (backupInstance.length === 0) {
            getData('/Management/cloudbackup')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    for (var i = 0; i < data.length; i++) {
                        data[i].active = false;
                    }
                    setBackupInstance(data);
                });
        }
    }, []);

    /*   useEffect(() => {
           console.log(activityDataDrives)
       }, [activityDataDrives]);*/

    function getPercent(b, s) {
        return Math.ceil(((b / s) * 100));
    }


    return (
        <> {contextHolder}
            <div id="main-content" className={`activity-page`}>
                <div className="container-fluid mb-4">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>

                    </div>
                    <h2 className="small-title">Activity Report
                        <a href="#" className="btn-style small-btn blue float-right" onClick={(e) => {
                            e.preventDefault();
                            clearActivityData()
                        }}>Clear Activity</a></h2>
                </div>
                <div className={`${!agentIsRunning ? "disabled" : ""}`}>
                    {activityDataDrives.length > 0 && cloudDrives.length > 0 && (
                        <div className="container-fluid mb-4">
                            <div className="custom-table">
                                <div className="custom-table-header-wrapper">
                                    <div className="custom-table-header">
                                        <div className="table-header-item">Drive</div>
                                        <div className="table-header-item text-center">Bucket Name</div>
                                    </div>
                                </div>
                                {cloudDrives.map((item, i) => (
                                    <>
                                        {activityDataDrives.filter(data => (data.name === item.name && data.storageName === item.storageName)).length > 0 &&
                                            <div className={`custom-table-wrapper `}>
                                                <div className={`custom-table-row ${item.active ? 'active' : ''}`} onClick={() => {
                                                    toggleClass(i)
                                                }} role="button">
                                                    <div className="table-item">{item.name}</div>
                                                    <div className="table-item text-center">{item.storageName}</div>
                                                </div>
                                                {activityDataDrives.filter(data => (data.name === item.name && data.storageName === item.storageName)).map(filteredActivityData => (
                                                    <div className={`activity-inner-table ${item.active ? 'active' : ''}`}>
                                                        <table className="table ">
                                                            <thead>
                                                            <tr>
                                                                <th>File Path</th>
                                                                <th>Upload/Download</th>
                                                                <th>Size</th>
                                                                <th>Status</th>
                                                                <th>Progress</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {filteredActivityData.files.map(file => (
                                                                <tr key={file.id}>
                                                                    {isWindows &&
                                                                        <td>{file.path.replace(item.localPath + "\\", "")}</td>
                                                                    }
                                                                    {!isWindows &&
                                                                        <td>{file.path.replace(item.localPath + "/", "")}</td>
                                                                    }

                                                                    {file.transferMode === 1 &&
                                                                        <td><ArrowUpOutlined/></td>
                                                                    }
                                                                    {file.transferMode === 2 &&
                                                                        <td><ArrowDownOutlined/></td>
                                                                    }
                                                                    {file.transferMode === 3 &&
                                                                        <td className="text-center"><DeleteOutlined/></td>
                                                                    }
                                                                    <td>{prettySize(file.size)}</td>
                                                                    {file.status === 1 &&
                                                                        <td>Start Transfer</td>
                                                                    }
                                                                    {file.status === 2 &&
                                                                        <td>In Progress</td>
                                                                    }
                                                                    {file.status === 3 &&
                                                                        <td>Completed</td>
                                                                    }
                                                                    {file.status === 4 &&
                                                                        <td className="info-wrapper error-info-wrapper">Failed
                                                                            <Tooltip
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                placement="topLeft"
                                                                                title={file.error}
                                                                            >
                                                                                <img src={info} alt="Info"/>
                                                                            </Tooltip>
                                                                        </td>
                                                                    }

                                                                    <td>
                                                                        {file.size > 0 &&
                                                                            <Progress percent={getPercent(file.bytes, file.size)} status={file.status === 4 ? 'exception' : ''}/>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                    {activityDataBackups.length > 0 && backupInstance.length > 0 && (
                        <div className="container-fluid">
                            <div className="custom-table">
                                <div className="custom-table-header-wrapper">
                                    <div className="custom-table-header">
                                        <div className="table-header-item">Backup Path</div>
                                        <div className="table-header-item text-center">Bucket Name</div>
                                    </div>
                                </div>
                                {backupInstance.map((item, i) => (
                                    <>
                                        {activityDataBackups.filter(data => (data.name === item.name && data.storageName === item.storageName)).length > 0 &&
                                            <div className={`custom-table-wrapper `}>
                                                <div className={`custom-table-row ${item.active ? 'active' : ''}`} onClick={() => {
                                                    toggleClassBackup(i)
                                                }} role="button">
                                                    <div className="table-item">{item.name}</div>
                                                    <div className="table-item text-center">{item.storageName}</div>
                                                </div>
                                                {activityDataBackups.filter(data => (data.name === item.name && data.storageName === item.storageName)).map(filteredActivityData => (
                                                    <div className={`activity-inner-table ${item.active ? 'active' : ''}`}>
                                                        <table className="table ">
                                                            <thead>
                                                            <tr>
                                                                <th>File Path</th>
                                                                <th>Upload/Download</th>
                                                                <th>Size</th>
                                                                <th>Status</th>
                                                                <th>Progress</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {filteredActivityData.files.map(file => (
                                                                <tr key={file.id}>
                                                                    <td>{file.path}</td>
                                                                    {file.transferMode === 1 &&
                                                                        <td><ArrowUpOutlined/></td>
                                                                    }
                                                                    {file.transferMode === 2 &&
                                                                        <td><ArrowDownOutlined/></td>
                                                                    }
                                                                    {file.transferMode === 3 &&
                                                                        <td className="text-center"><DeleteOutlined/></td>
                                                                    }
                                                                    <td>{prettySize(file.size)}</td>
                                                                    {file.status === 1 &&
                                                                        <td>Start Transfer</td>
                                                                    }
                                                                    {file.status === 2 &&
                                                                        <td>In Progress</td>
                                                                    }
                                                                    {file.status === 3 &&
                                                                        <td>Completed</td>
                                                                    }
                                                                    {file.status === 4 &&
                                                                        <td>Failed</td>
                                                                    }

                                                                    <td>
                                                                        {file.size > 0 &&
                                                                            <Progress percent={getPercent(file.bytes, file.size)} status={file.status === 4 ? 'exception' : ''}/>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                    {activityDataDirects.length > 0 && (
                        <div className="container-fluid mb-4">
                            <div className="custom-table">
                                <div className="custom-table-header-wrapper">
                                    <div className="custom-table-header">
                                        <div className="table-header-item">Files Uploading</div>
                                    </div>
                                </div>
                                <div className={`custom-table-wrapper files-upload-table`}>
                                    <div className={`activity-inner-table active`}>
                                        <table className="table ">
                                            <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Bucket</th>
                                                <th>Upload</th>
                                                <th>Size</th>
                                                <th>Status</th>
                                                <th>Progress</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {directs.map((item, i) => (
                                                <>
                                                    {item.files.map(file => (
                                                        <tr key={file.id}>
                                                            <td><span className="ellipses-add" title={getFilename(file.path)}>{getFilename(file.path)}</span></td>
                                                            <td className="no-wrappe">{item.name}</td>
                                                            {file.transferMode === 1 &&
                                                                <td><ArrowUpOutlined/></td>
                                                            }
                                                            {file.transferMode === 2 &&
                                                                <td><ArrowDownOutlined/></td>
                                                            }
                                                            {file.transferMode === 3 &&
                                                                <td className="text-center"><DeleteOutlined/></td>
                                                            }
                                                            <td className="no-wrappe">{prettySize(file.size)}</td>
                                                            {file.status === 1 &&
                                                                <td>Start Transfer</td>
                                                            }
                                                            {file.status === 2 &&
                                                                <td>In Progress</td>
                                                            }
                                                            {file.status === 3 &&
                                                                <td>Completed</td>
                                                            }
                                                            {file.status === 4 &&
                                                                <td>Failed</td>
                                                            }
                                                            <td>
                                                                {file.size > 0 &&
                                                                    <Progress percent={getPercent(file.bytes, file.size)} status={file.status === 4 ? 'exception' : ''}/>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <AgentOffPopup/>
        </>
    )
}

export default ActivityPage;

