import React, {useEffect, useRef, useState} from "react";
import {notification} from "antd";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import PageLoading from "../components/PageLoading";
import config from "../config";

const SSOCallBack = () => {
        const {
            isAuthenticated,
            setJWTToken,
            setShowHeaderAndSidebar,
            setShowSidebar,
        } = useAuth();
        const dataFetchedRef = useRef(false);
        const navigate = useNavigate();
        const controller = useRef(null);
        const [api, contextHolder] = notification.useNotification();
        const [loading, setLoading] = useState(false);

        async function postData(url = "", data = {}) {
            controller.current = new AbortController();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                signal: controller.current.signal,
            });
            return response;
        }

        useEffect(() => {
                const url = window.location.search;
                const urlParams = new URLSearchParams(window.location.search);
                const authorizationCode = urlParams.get('code')
                const identifier = urlParams.get('state')
                const jwt = urlParams.get('jwt')
                setLoading(true);
                if (dataFetchedRef.current) return;
                dataFetchedRef.current = true;
                if(jwt !== null){
                   // sendDataToMixPanel("Login With Saml Success")
                    setJWTToken(jwt);
                }else{
                    postData(config.apiURLWeb + "/api/sso/authenticate", {
                        identifier: identifier,
                        authorizationCode: authorizationCode,
                        callbackUrl: config.callbackURL + "/ssocallback"
                    }).then((res) => Promise.all([res.status, res.text()]))
                        .then(([status, textData]) => {
                            if (status === 499) {
                                navigate("/sso-sign-in?error=Authorization code is expired" );
                            }
                            if (status === 400) {
                                navigate("/sso-sign-in?error=" + textData);
                            }
                            if (status === 200) {
                               // sendDataToMixPanel("Login With Microsoft Success")
                                setJWTToken(textData);
                            }
                            setLoading(false);
                        })
                }
            }, []
        )
        useEffect(() => {
            if (isAuthenticated) {
                navigate("/dashboard");
                setShowHeaderAndSidebar(true);
                setShowSidebar(true);
            }
        }, [isAuthenticated]);

        return (
            <>
                {contextHolder}
                <PageLoading></PageLoading>
            </>
        );
    }
;

export default SSOCallBack;
