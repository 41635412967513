import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {LoadingOutlined} from '@ant-design/icons';
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal} from 'antd';
import {useAuth} from "../context/AuthContext";

import searchIcon from "../assets/images/ai-search/search-icon.svg";
import orderByIcon from "../assets/images/ai-search/order-by-icon.svg";
import infoIcon from "../assets/images/ai-search/info-icon.svg";
import verticleDotsIcon from "../assets/images/ai-search/vertical-dot-icon.svg";
import previewIcon from "../assets/images/ai-search/preview-icon.svg";
import renameIcon from "../assets/images/ai-search/rename-icon.svg";
import editIcon from "../assets/images/ai-search/edit-icon.svg";
import copyIcon from "../assets/images/ai-search/copy-icon.svg";
import moveIcon from "../assets/images/ai-search/move-icon.svg";
import showDetailIcon from "../assets/images/ai-search/show-detail-icon.svg";
import showActivityIcon from "../assets/images/ai-search/show-activity-icon.svg";
import downloadIcon from "../assets/images/ai-search/download-icon.svg";
import deleteIcon from "../assets/images/ai-search/delete-icon.svg";
import rowIcon from "../assets/images/ai-search/row-icon.svg";
import gridIcon from "../assets/images/ai-search/grid-icon.svg";
import crossBlackIcon from "../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../assets/images/ai-search/info-white-icon.svg";
import fileIcon from "../assets/images/ai-search/file-icon.svg";
import fileUserImg1 from "../assets/images/ai-search/file-user-img-1.png";
import fileUserImg2 from "../assets/images/ai-search/file-user-img-2.png";
import fileUserImg3 from "../assets/images/ai-search/file-user-img-3.png";
import fileUserImg4 from "../assets/images/ai-search/file-user-img-4.png";
import fileUserImg5 from "../assets/images/ai-search/file-user-img-5.png";
import backArrowIcon from "../assets/images/icons/back-arrow-black-icon.svg";
import aiCopyIcon from "../assets/images/icons/ai-copy-icon.svg";
import clipsIcon from "../assets/images/ai-search/clips-icon.svg";
import taggingIcon from "../assets/images/ai-search/tagging-icon.svg";
import fileInfoIcon from "../assets/images/ai-search/file-info-icon.svg";
import commnetIcon from "../assets/images/ai-search/commnet-icon.svg";
import videoImg from "../assets/images/ai-search/video-plyer-img.png";
import playIcon from "../assets/images/ai-search/play-circle-icon.svg";
import globeIcon from "../assets/images/ai-search/globe-gray-icon.svg";
import rightDrawerIcon from "../assets/images/ai-search/open-drawer-right-icon.svg";
import plusWhiteIcon from "../assets/images/ai-search/plus-white-icon.svg";
import playBlackIcon from "../assets/images/ai-search/play-black-icon.svg";
import trashBlackIcon from "../assets/images/ai-search/trash-black-icon.svg";
import downloadBlackIcon from "../assets/images/ai-search/download-black-icon.svg";
import editBlackIcon from "../assets/images/ai-search/edit-black-icon.svg";

import identifiedImg1 from "../assets/images/ai-search/identified-object-img-1.png";
import identifiedImg2 from "../assets/images/ai-search/identified-object-img-2.png";
import identifiedImg3 from "../assets/images/ai-search/identified-object-img-3.png";
import identifiedImg4 from "../assets/images/ai-search/identified-object-img-4.png";
import identifiedImg5 from "../assets/images/ai-search/identified-object-img-5.png";
import identifiedImg6 from "../assets/images/ai-search/identified-object-img-6.png";
import identifiedImg7 from "../assets/images/ai-search/identified-object-img-7.png";
import identifiedImg8 from "../assets/images/ai-search/identified-object-img-8.png";
import identifiedImg9 from "../assets/images/ai-search/identified-object-img-9.png";
import identifiedImg10 from "../assets/images/ai-search/identified-object-img-10.png";
import identifiedImg11 from "../assets/images/ai-search/identified-object-img-11.png";

import crossRedCircleIcon from "../assets/images/ai-search/cross-red-circle-icon.svg";
import userImg1 from "../assets/images/ai-search/comment-user-img.png";
import AiSearch from "../components/AiSearch/AiSearch";
import VideoDetail from "../components/AiSearch/VideoDetail";
import PhotoDetail from "../components/AiSearch/PhotoDetail";
import AudioDetail from "../components/AiSearch/AudioDetail";

const {RangePicker} = DatePicker;
const SearchResult = () => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults
    } = useAuth();
    const [gridType, setGridType] = useState('grid');
    const [fileSidebar, setFileSidebar] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [showPhotoPreview, setShowPhotoPreview] = useState(false);
    const [showAudioPreview, setShowAudioPreview] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);
    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);

    const onOpenSidebar = () => {
        setFileSidebar(!fileSidebar)
    }

    const callbackVideoPreview = () => {
        setShowVideoPreview(false);
    }

    const callbackPhotoPreview = () => {
        setShowPhotoPreview(false);
    }
    const callbackAudioPreview = () => {
        setShowAudioPreview(false);
    }
    const onSelectFile = (values) => {
        setCheckedValues(document.querySelectorAll('input[name="selectedFiles"]:checked').length);
        console.log(document.querySelectorAll('input[name="selectedFiles"]:checked').length)
    };

    return (
        <>
            <VideoDetail open={showVideoPreview} callback={callbackVideoPreview}/>
            {showPhotoPreview &&
                <PhotoDetail open={showPhotoPreview} callback={callbackPhotoPreview}/>
            }
            <AudioDetail open={showAudioPreview} callback={callbackAudioPreview}/>
            <div className={`file-name-sidebar ${fileSidebar ? 'active' : ''}`}>
                <div className="file-name-wrapper">
                    <div className="title-wrapper">
                        <h3 className="h3">File Name</h3>
                        <a href="#" className="cross-icon" onClick={(e) => {
                            e.preventDefault();
                            setFileSidebar(false)
                        }}><img src={crossBlackIcon} alt="icon"/></a>
                    </div>
                    <div className="file-name-body">
                        <ul className="btn-list">
                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                            <li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>
                        </ul>
                        <div className="access-wrapper">
                            <div className="access-header">
                                <p className="p bold title">Who has access</p>
                                <p className="small orange">Manage access</p>
                            </div>
                            <ul className="users">
                                <li><a href="#"><img src={fileUserImg1} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg2} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg3} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg4} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg5} alt="img"/></a></li>
                                <li><a href="#"><span className="count">+1</span></a></li>
                            </ul>
                            <p className="small">Owned by Patrick Amove. Shared with xxx, xxxx, xxxx, xxxx, and 1 other.</p>
                        </div>
                        <div className="information-wrapper">
                            <p className="p bold title">General information</p>
                            <ul className="info">
                                <li>
                                    <p className="small semi">Size:</p>
                                    <p className="small">500 bytes</p>
                                </li>
                                <li>
                                    <p className="small semi">Cloud:</p>
                                    <p className="small">AWS</p>
                                </li>
                                <li>
                                    <p className="small semi">Bucket:</p>
                                    <p className="small">Bucket name</p>
                                </li>
                                <li>
                                    <p className="small semi">Created:</p>
                                    <p className="small">may 11th, 2023 at 09:14</p>
                                </li>
                                <li>
                                    <p className="small semi">Modified:</p>
                                    <p className="small">24 feb 2024 at 13:45 by Barry Allen</p>
                                </li>
                            </ul>
                        </div>
                        <div className="tags-wrapper">
                            <p className="p bold title">Auto-tags</p>
                            <ul className="tags">
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                                <li><a href="#">Quam</a></li>
                            </ul>
                        </div>
                        <div className="tags-wrapper">
                            <p className="p bold title">Tags</p>
                            <div className="form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Enter your email"/>
                                </div>
                            </div>
                        </div>
                        <div className="tags-wrapper">
                            <p className="p bold title">Comments</p>
                            <div className="form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Add some comments"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="main-content">
                <div className="container-fluid">
                    <div className="search-result-main">
                        <div className="search-result-wrapper">
                            <AiSearch location="results"/>
                            <div className="search-result-header">
                                <div className="row">
                                    <div className="col-md-3 align-self-center">
                                        <h3 className="h3 title">Search results</h3>
                                    </div>
                                    <div className="col-md-9 align-self-center">
                                        <ul className="options">
                                            <li>
                                                <div className="select-card">
                                                    <p>Group by</p>
                                                    <div className="select-wrapper">
                                                        <Select
                                                            defaultValue="Modification date"
                                                            options={[
                                                                {value: 'None', label: 'None'},
                                                                {value: 'Modification date', label: 'Modification date'},
                                                                {value: 'Name', label: 'Name'},
                                                                {value: 'Type', label: 'Type'},
                                                                {value: 'Size', label: 'Size'},
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="select-card">
                                                    <p>Order by</p>
                                                    <div className="select-wrapper">
                                                        <img src={orderByIcon} className="icon" alt="icon"/>
                                                        <Select
                                                            className="icon-select"
                                                            defaultValue="Modification date"
                                                            options={[
                                                                {value: 'None', label: 'None'},
                                                                {value: 'Modification date', label: 'Modification date'},
                                                                {value: 'Name', label: 'Name'},
                                                                {value: 'Type', label: 'Type'},
                                                                {value: 'Size', label: 'Size'},
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            {checkedValues === 1 &&
                                                <li><a href="#" onClick={onOpenSidebar}><img src={infoIcon} alt="icon"/></a></li>
                                            }
                                            <li>
                                                <div className="ai-tooltip-v1">
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="click"
                                                        placement="bottomLeft"
                                                        visible={tooltipVisible}
                                                        onVisibleChange={(visible) => setTooltipVisible(visible)}
                                                        title={
                                                            <div className="popup-options">
                                                                <p className="title">Design</p>
                                                                <ul className="view-list">
                                                                    <li><a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setGridType('row');
                                                                        setTooltipVisible(false);
                                                                    }}><img src={rowIcon} alt="icon"/>Rows</a></li>
                                                                    <li><a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setGridType('grid');
                                                                        setTooltipVisible(false);
                                                                    }}><img src={gridIcon} alt="icon"/>Grid</a></li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    >
                                                        <a href="#"><img src={gridType === 'row' ? gridIcon : rowIcon} alt="icon"/></a>
                                                    </Tooltip>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {(gridType === 'grid') &&
                                <div className="search-result-body grid-body">
                                    <div className="result-body-card">
                                        <h3 className="title fk-font">Last Week</h3>
                                        <div className="result-card-wrapper">
                                                {searchResults?.data?.length > 0 && searchResults?.data?.map((item, index) => (
                                                    <div className="result-card-main">
                                                        <input type="checkbox" value={item.id} name="selectedFiles" onChange={onSelectFile} id={`result-card-${index}`}/>
                                                        <label htmlFor={`result-card-${index}`} className="result-card">
                                                            <div className="result-options">
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">{item.type}</p>
                                                                                <ul className="view-list">
                                                                                    <li><a href="#" onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        if (item.type === "video") {
                                                                                            setShowVideoPreview(true)
                                                                                        }
                                                                                        if (item.type === "image") {
                                                                                            setShowPhotoPreview(true);
                                                                                        }
                                                                                        if (item.type === "audio") {
                                                                                            setShowAudioPreview(true);
                                                                                        }
                                                                                    }}><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className=""><a href="#"  onClick={onOpenSidebar}><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                            <div className="img-wrapper">
                                                                <img src={item.thumbnail} alt={item.name}/>
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <p>{item.name}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                ))
                                                }
                                        </div>
                                    </div>
                                    {/*   <div className="result-body-card">
                                        <h3 className="title fk-font">Last Month</h3>
                                        <div className="result-card-wrapper">

                                            <div className="result-card-main">
                                                <input type="checkbox" id="result-card-13"/>
                                                <label htmlFor="result-card-13" className="result-card">
                                                    <div className="result-options">
                                                        <div className="ai-tooltip-v1">
                                                            <Tooltip
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                trigger="click"
                                                                placement="bottomLeft"
                                                                title={
                                                                    <div className="popup-options">
                                                                        <p className="title">Design</p>
                                                                        <ul className="view-list">
                                                                            <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            >
                                                                <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="img-wrapper">
                                                        <img src={resultImg13} alt="img"/>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <p>Name of the file .jpg</p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="result-card-main">
                                                <input type="checkbox" id="result-card-14"/>
                                                <label htmlFor="result-card-14" className="result-card">
                                                    <div className="result-options">
                                                        <div className="ai-tooltip-v1">
                                                            <Tooltip
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                trigger="click"
                                                                placement="bottomLeft"
                                                                title={
                                                                    <div className="popup-options">
                                                                        <p className="title">Design</p>
                                                                        <ul className="view-list">
                                                                            <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            >
                                                                <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="img-wrapper">
                                                        <img src={resultImg14} alt="img"/>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <p>Name of the file .jpg</p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="result-card-main">
                                                <input type="checkbox" id="result-card-15"/>
                                                <label htmlFor="result-card-15" className="result-card video-card">
                                                    <div className="result-options">
                                                        <div className="ai-tooltip-v1">
                                                            <Tooltip
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                trigger="click"
                                                                placement="bottomLeft"
                                                                title={
                                                                    <div className="popup-options">
                                                                        <p className="title">Design</p>
                                                                        <ul className="view-list">
                                                                            <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            >
                                                                <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="img-wrapper">
                                                        <img src={resultImg15} alt="img"/>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <p>Name of the file .jpg</p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="result-card-main">
                                                <input type="checkbox" id="result-card-16"/>
                                                <label htmlFor="result-card-16" className="result-card video-card">
                                                    <div className="result-options">
                                                        <div className="ai-tooltip-v1">
                                                            <Tooltip
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                trigger="click"
                                                                placement="bottomLeft"
                                                                title={
                                                                    <div className="popup-options">
                                                                        <p className="title">Design</p>
                                                                        <ul className="view-list">
                                                                            <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            >
                                                                <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="img-wrapper">
                                                        <img src={resultImg16} alt="img"/>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <p>Name of the file .jpg</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            }
                            {(gridType === 'row') &&
                                <div className="search-result-body row-body">
                                    <div className={`result-table-main ${fileSidebar ? 'sidebar-opened' : ''}`}>
                                        <h3 className="title">Last Week</h3>
                                        {searchResults?.data?.length > 0 &&
                                            <div className="table-wrapper">
                                                <div className="table-header grid-4">
                                                    <p>Name</p>
                                                    <p>Type</p>
                                                    <p>File size</p>
                                                    <p></p>
                                                </div>
                                                <div className="table-body position-relative">
                                                    {searchResults.data.map((item, index) => (
                                                        <div className="table-row grid-4">
                                                            <div className="table-item">
                                                                <div className="name-wrapper">
                                                                    <img src={item.thumbnail} className="bucket-icon" alt={item.name}/>
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </div>

                                                            <div className="table-item">
                                                                <p>{item.type}</p>
                                                            </div>
                                                            <div className="table-item">
                                                                <p>10 MB</p>
                                                            </div>
                                                            <div className="table-item">
                                                                <ul className="options">
                                                                    <li>
                                                                        <div className="ai-tooltip-v1">
                                                                            <Tooltip
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                trigger="click"
                                                                                placement="bottomLeft"
                                                                                title={
                                                                                    <div className="popup-options">
                                                                                        <p className="title">Design</p>
                                                                                        <ul className="view-list">
                                                                                            <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                            <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="result-table-main">
                                        <h3 className="title">Last Mont</h3>
                                        <div className="table-wrapper">
                                            <div className="table-header grid-4">
                                                <p>Name</p>
                                                <p>Type</p>
                                                <p>File size</p>
                                                <p></p>
                                            </div>
                                            <div className="table-body position-relative">
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchResult;



