import React, {useEffect, useRef, useState} from "react";
import {notification} from "antd";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import PageLoading from "../components/PageLoading";
import config from "../config";
import warning from "../assets/images/icons/warning-sign.svg";

const OneDriveCallBack = () => {
        const {
            getDataWithTokenPOST,
            logout,
            setPopup500
        } = useAuth();
        const dataFetchedRef = useRef(false);
        const navigate = useNavigate();
        const controller = useRef(null);
        const [api, contextHolder] = notification.useNotification();

        const [loading, setLoading] = useState(false);

        useEffect(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const AUTHORIZATION_CODE = urlParams.get('code')
                const YOUR_UNIQUE_STATE = urlParams.get('state')
                const cloudName = urlParams.get('cloudName')
                var redirectURL = config.callbackURL + "/onedrive";

                setLoading(true);

                if (dataFetchedRef.current) return;
                dataFetchedRef.current = true;
                getDataWithTokenPOST("/api/desktop/onedrive_authorization_callback", {
                    "code": AUTHORIZATION_CODE,
                    "state": YOUR_UNIQUE_STATE,
                    "redirectURL": redirectURL
                })
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499 || status === 400) {
                            var msg = "";
                            for (var i = 0; i < data.errors.DEFAULT.length; i++) {
                                msg += data.errors.DEFAULT + " ";
                            }
                            setLoading(false);
                            api.open({
                                message: 'Error',
                                description: msg,
                                icon: <img src={warning} alt="warning"/>,
                                duration: 3,
                            });

                        } else {
                            navigate("/?accounts=true&lastid=" + data.id)
                        }
                        setLoading(false);
                    })
            }, []
        )

        return (
            <>
                {contextHolder}
                <PageLoading></PageLoading>
            </>
        );
    }
;

export default OneDriveCallBack;
