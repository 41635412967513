import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import pageLogo from "../assets/images/page-logo.svg";
import circleCheckIcon from "../assets/images/icons/circle-check-icon.svg";

import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";
const EmailActivation = () => {
    const {
        sendDataToMixPanel,
        setJWTToken,
        user,
        sendDataToWebsocket
    } = useAuth();

    const navigate = useNavigate();
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 84,
            }}
            spin
        />
    );

    const [tokenResults, setTokenResults] = useState({
        token: null,
        requestToken: null,
    });
    const [acountStatus, setAcountStatus] = useState(false);
    const [loadingSpin, setLoadingSpin] = useState(false);

    const getTokenQuery = () => {
        const url = window.location.search;
        const token = url.split("token=")[1];

        setTokenResults((prev) => ({
            ...prev,
            token: token,
        }));
        return token;
    };

    const requestToken = () => {
        setLoadingSpin(true)
        axios
            .post(config.auth + "/api/authentication/request_token")
            .then((response) =>
                setTokenResults((prev) => ({...prev, requestToken: response.data}))
            )
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getTokenQuery();
        requestToken();
    }, []);


    async function postData(url = '', data) {
        try {
            const response = await fetch(config.apiURLWeb + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                        "X-App-Origin": "WebApp"
                },
                body: JSON.stringify(data)
            });
            sendDataToWebsocket(config.apiURLWeb + url, "POST")
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (tokenResults.token && tokenResults.requestToken) {
            postData('/api/desktop/confirm_signup', tokenResults)
                .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
                    console.log(res.status)
                    console.log(data)
                if (res.status === 200) {
                    console.log(res.status)
                    console.log(data)
                    setJWTToken(data);
                    navigate("/onboarding")
                }
                if (res.status === 400 || res.status === 404) {
                    navigate("/sign-in?verified=failed")
                }
            });
        }
    }, [tokenResults]);

    useEffect(() => {
        if (acountStatus) {
            setLoadingSpin(false);
        }
    }, [acountStatus])


    return (
        <>
            <div></div>
        </>
    );
};

export default EmailActivation;



