import {Button} from 'antd'
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../context/AuthContext';
import config from "../../config";

const MonthlySubscription = ({logo, price, priceId}) => {
    const {logout, postDataWebNoResponse, setPopup500, sendDataToMixPanel, user, setError499, setPopup499} = useAuth();
    const [loading, setLoading] = useState(false);
    const handleSubscribe = () => {
        setLoading(true);
        postDataWebNoResponse('/api/desktop/billing_subscribe', {"baseAddress": config.callbackURL + "/subscribe", "priceId": priceId})
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                if (status === 200) {

                    sendDataToMixPanel('User billing monthly subscription called', user.username, {
                        'App': 'Web App'
                    })
                    window.open(
                        response,
                        '_self'
                    );
                }
                setLoading(false)
            })
    }
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="subscription-body">
            <div className="price">
                <p className='mb-0'>Includes 3 Users, 1TB Global Storage</p>
                <p className='mb-0'>and all features in <a href='https://amove.io/pricing/' className='sub-link' target='_blank'>Click for Everyone</a></p>
                <p>33% Discount for Annual Plan</p>
                <div className="d-flex align-items-center mt-3 mb-2">
                    <strong>${price}</strong>
                    <span>Month <br/> Billed Monthly</span>
                </div>
                <Button className="btn-style full subscribe_btn" loading={loading} disabled={loading} onClick={handleSubscribe}>Subscribe</Button>
            </div>
        </div>
    )
}
export default MonthlySubscription
