import React, {useEffect, useState, useRef} from "react";
import {Modal, Select, Button, notification, Radio, Tooltip, Slider, Col, Row, Space, InputNumber, Popconfirm} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import uploadIcon from "../assets/images/icons/file-upload-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {useNavigate} from "react-router-dom";
import AgentOffPopup from "../components/AgentOffPopup";

const SetupNFS = ({type}) => {
    const {
        cloudAccounts,
        isWindows,
        getData,
        logout,
        getIcon,
        setDefaultOpenConnection,
        defaultOpenConnection,
        user,
        sendDataToMixPanel,
        agentIsRunning,
        preSelectedNFSSMB,
        setPreSelectedNFSSMB,
        getDataMethodGet,
        postDataWeb,
        postDataWebResponseText,
        setPopup500,
        postDataNoResponse,
        postDataNewFolder,
        setError499,
        setPopup499
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [availableLocalDrives, setAvailableLocalDrives] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [showNFSNameError, setShowNFSNameError] = useState(false);
    const [isActive, setActive] = useState(null);
    const [yourNFSName, setYourNFSName] = useState("");
    const [selectedConnection, setSelectedConnection] = useState("");
    const [selectedAvailableDrive, setSelectedAvailableDrive] = useState("");
    const [selectedVersion, setSelectedVersion] = useState("");
    const [yourNFSShare, setYourNFSShare] = useState("");
    const [SMBUserName, setSMBUserName] = useState("");
    const [SMBPassword, setSMBPassword] = useState("");
    const [SMBDomain, setSMBDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedPath, setSelectedPath] = useState("");
    const [open, setOpen] = useState(false);
    const [previousPath, setPreviousPath] = useState("");
    const [localDir, setLocalDir] = useState([]);
    const [localDrives, setLocalDrives] = useState([]);
    const [step, setStep] = useState(1);
    const [folderName, setFolderName] = useState("");
    const [NFSs, setNFSs] = useState([]);
    const [errorText, setErrorText] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState("0");
    const [showError, setShowError] = useState(false);


    const onChangeAuthenticated = (value) => {
        setIsAuthenticated(value);
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setSelectedConnection(preSelectedNFSSMB)
        setSelectedAvailableDrive('')
        setStep(2)
        loadNFS();
        getAvailableDrives();
        if (!isWindows) {
            setIsAuthenticated("1")
        }

    }, []);

    useEffect(() => {
        setSelectedAvailableDrive("")
    }, [type]);

    function loadNFS(refresh = false) {
        getData('/Management/nfsclient')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setNFSs(response)
            });
    }


    const newFolderName = (event) => {
        const folderName = event.target.value;
        setFolderName(folderName);
    }


    function goBack() {
        var val = ""
        if (isWindows) {
            if (previousPath.lastIndexOf("\\") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\") + 1);
            }
        } else {
            if (previousPath.lastIndexOf("/") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("/"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("/") + 1);
            }
        }
        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setPreviousPath(val)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    function getAvailableDrives() {
        getDataMethodGet('/Management/list_available_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAvailableLocalDrives(response)
            })
    }

    function getDirectories(e) {
        setSelectedPath(e.target.value)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setPreviousPath(e.target.value)
                setLocalDir(response)
            })
    }

    function changeDir(e) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        setSelectedPath(val)
        if (e.detail == 2) {
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setPreviousPath(val)
                    setLocalDir(response)
                    const elements = document.querySelectorAll('.directory-list .form-group')
                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                })
        }
    }

    const onChangeAvailableDrive = (value) => {
        setSelectedAvailableDrive(value)
    };
    const onChangeSelectedVersion = (value) => {
        setSelectedVersion(value)
    };
    const NFSName = (event) => {
        const name = event.target.value;
        setYourNFSName(name)
        setShowNFSNameError(false)
        if (name.length > 1) {
            var result1 = NFSs.filter(item => item.name.toLowerCase() === name.toLowerCase());
            if (result1.length > 0) {
                setActive(false);
                setShowNFSNameError(true)
            } else {
                setShowNFSNameError(false)
                setActive(true);
                if (selectedPath !== '') {
                    setStep(4)
                }
            }
        } else {
            setActive(false);
            setShowNFSNameError(false)
        }
    }
    const NFSShareChange = (event) => {
        setYourNFSShare(event.target.value)
    }
    const SMBUserNameChange = (event) => {
        setSMBUserName(event.target.value)
    }
    const SMBPasswordChange = (event) => {
        setSMBPassword(event.target.value)
    }
    const SMBDomainChange = (event) => {
        setSMBDomain(event.target.value)
    }
    const setupSMB = () => {

        setShowError(false);

        //  setLoading(true)
        var dataToPost = {
            "name": yourNFSName,
            "smbShare": yourNFSShare,
            "version": "2",
            "localPath": selectedAvailableDrive,
            "active": true,
            "isAuthenticated": isAuthenticated !== "0",
            "username": SMBUserName,
            "password": SMBPassword,
            "domain": SMBDomain

        }
        if (!isWindows) {
            dataToPost.localPath = selectedPath
        }

        postDataNoResponse('/Management/smbclient', dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 499) {
                    var d = data;
                    if (d.type === "AUTH") {
                        setErrorText(d.errors.AUTH[0]);
                    }
                    if (d.type === "INACTIVE_USER") {
                        setErrorText(d.errors.INACTIVE_USER[0]);
                    }

                    if (d.type === "SESSION") {
                        setErrorText(d.errors.SESSION[0]);
                    }

                    if (d.type === "SESSION_MAXOUT") {
                        setErrorText(d.errors.SESSION_MAXOUT[0]);
                    }

                    if (d.type === "DUPLICATE") {
                        setErrorText(d.errors.DUPLICATE[0]);
                    }

                    if (d.type === "COGNITO") {
                        setErrorText(d.errors.COGNITO[0]);
                    }

                    if (d.type === "INVALID_EMAIL") {
                        setErrorText(d.errors.INVALID_EMAIL[0]);
                    }
                    if (d.type === "INTERNAL_ERROR") {
                        setErrorText(d.errors.INTERNAL_ERROR[0]);
                    }
                    setLoading(false);
                    setShowError(true);
                } else {
                    api.open({
                        message: yourNFSName + ' SMB has been successfully set up.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });

                    sendDataToMixPanel('SMB has been successfully set up.', user.username, {
                        'App': 'Desktop App',
                        'SMB Name': yourNFSName
                    })

                    setTimeout(function () {
                        setDefaultOpenConnection(true)
                        setLoading(false)
                    }, 3000)
                }
                setLoading(false);
            });
    }
    const setupNFS = () => {

        setShowError(false);
        setLoading(true)
        var dataToPost = {
            "name": yourNFSName,
            "nfsShare": yourNFSShare,
            "version": selectedVersion,
            "localPath": selectedAvailableDrive,
            "active": true
        }
        if (!isWindows) {
            dataToPost.localPath = selectedPath
        }
        postDataNoResponse('/Management/nfsclient', dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 499) {
                    var d = data;
                    if (d.type === "AUTH") {
                        setErrorText(d.errors.AUTH[0]);
                    }
                    if (d.type === "INACTIVE_USER") {
                        setErrorText(d.errors.INACTIVE_USER[0]);
                    }

                    if (d.type === "SESSION") {
                        setErrorText(d.errors.SESSION[0]);
                    }

                    if (d.type === "SESSION_MAXOUT") {
                        setErrorText(d.errors.SESSION_MAXOUT[0]);
                    }

                    if (d.type === "DUPLICATE") {
                        setErrorText(d.errors.DUPLICATE[0]);
                    }

                    if (d.type === "COGNITO") {
                        setErrorText(d.errors.COGNITO[0]);
                    }

                    if (d.type === "INVALID_EMAIL") {
                        setErrorText(d.errors.INVALID_EMAIL[0]);
                    }
                    if (d.type === "INTERNAL_ERROR") {
                        setErrorText(d.errors.INTERNAL_ERROR[0]);
                    }
                    setLoading(false);
                    setShowError(true);
                } else {
                    api.open({
                        message: yourNFSName + ' NFS has been successfully set up.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });

                    sendDataToMixPanel('NFS has been successfully set up.', user.username, {
                        'App': 'Desktop App',
                        'NFS Name': yourNFSName
                    })

                    setTimeout(function () {
                        setDefaultOpenConnection(true)
                        setLoading(false)
                    }, 3000)
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        if (defaultOpenConnection) {
            navigate("/dashboard");
        }
    }, [defaultOpenConnection]);

    const confirm = (e) => {
        if (folderName !== '') {
            postDataNewFolder('/Management/make_directory', {
                "directoryName": folderName,
                "path": previousPath
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    sendDataToMixPanel('New Folder Created.', user.username, {
                        'App': 'Web App',
                        'Folder Name':folderName
                    })
                    getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(previousPath))
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }
                            setPreviousPath(previousPath)
                            setLocalDir(response)
                            const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                            elements.forEach((element) => {
                                element.classList.remove('active');
                            });

                        })
                });
            setFolderName('');
        }
    };
    const showModal = () => {
        setOpen(true);
        if (selectedPath !== '') {
            var p = selectedPath.substring(0, selectedPath.lastIndexOf("\\"));
            if (!isWindows) {
                p = selectedPath.substring(0, selectedPath.lastIndexOf("/"))
            }
            setPreviousPath(p)
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(p))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    setTimeout(function () {
                        const elements = document.querySelectorAll('.directory-list .form-group')
                        elements.forEach((element) => {
                            element.classList.remove('active');
                            var fName = '';
                            if (isWindows) {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                            } else {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                            }
                            if (element.innerText === fName) {
                                element.classList.add('active');
                            }
                        });
                    }, 200)
                })
        } else {
            var dirListPath = '/';
            if (isWindows) {
                setPreviousPath(localDrives[0])
                dirListPath = encodeURIComponent(localDrives[0]);
            } else {
                setPreviousPath('/');
            }
            getDataMethodGet('/Management/list_directories?path=' + dirListPath)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                })
        }
    };

    // run code for dirHandle
    const handleOk = () => {
        setOpen(false);
        setStep(4);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const selectConnection = (event) => {
        event.preventDefault();
        const id = event.target.value;
        const index = event.target.getAttribute('data-index');
        const logo = event.target.getAttribute('data-name');
        setSelectedConnection(logo)
        setSelectedAvailableDrive('')
        setStep(2)
    }

    const changeConnection = (event) => {
        event.preventDefault();
        setSelectedAvailableDrive('')
        setYourNFSName('')
        setYourNFSShare('')
        setSelectedVersion('')
        setSelectedPath('')
        setActive(null)

        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setStep(1)
    };
    return (
        <>
            {contextHolder}
            <div className={`${!agentIsRunning ? "disabled" : ""}`}>
                {type === "NFS" &&
                    <>
                        <div className="field-wrapper nfs">
                            <div className={`form-group ${isActive ? "help-circle" : null}`}>
                                <input type="text" className="form-control" value={yourNFSName} placeholder="Give it a name" onChange={NFSName}/>
                            </div>
                            {showNFSNameError &&
                                <span className="red-error cloud-name">
                                        That NFS name has already been used.
                                    </span>
                            }
                            {isWindows &&
                                <>
                                    <div className={`${!isActive ? 'disabled' : ''}`}>
                                        <div className={`form-group  ${selectedAvailableDrive !== '' ? "selected-select" : ""}`}>
                                            <Select
                                                className='d-block remove-ant-select-arrow'
                                                placeholder="Select drive"
                                                optionFilterProp="children"
                                                onChange={onChangeAvailableDrive}
                                                listHeight={160}
                                                // value={selectedAvailableDrive}
                                                options={availableLocalDrives.map((item) => ({
                                                    label: item,
                                                    value: item,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {!isWindows &&
                                <>
                                    <div className={`form-group ${!isActive ? "disabled" : ''}`}>
                                        {selectedPath === '' &&
                                            <div className="file-upload-btn" onClick={showModal}>
                                                <div className="img-wrapper">
                                                    <img src={`${uploadIcon}`} alt="logo" className="icon"/>
                                                </div>
                                                <div className="content-wrapper">
                                                    Select folder
                                                </div>
                                            </div>
                                        }
                                        {selectedPath !== '' &&
                                            <div className="change-cloud-wrapper selected-path">
                                                <div className="choose-cloud-card" onClick={showModal}>
                                                    <div className="content-wrapper">
                                                        <p className="medium" title={selectedPath}>{selectedPath}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={` ${selectedPath === '' ? 'disabled' : ''}`}>


                                        <div className={`form-group  ${selectedVersion !== '' ? "selected-select" : ""}`}>
                                            <Select
                                                className='d-block remove-ant-select-arrow'
                                                placeholder="Select version"
                                                optionFilterProp="children"
                                                onChange={onChangeSelectedVersion}
                                                listHeight={160}
                                                options={[
                                                    {value: '3', label: '3'},
                                                    {value: '4', label: '4'},
                                                    {value: '4.1', label: '4.1'},
                                                    {value: '4.2', label: '4.2'}
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className={`${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedVersion === '') ? 'disabled' : ''}`}>
                                <div className="account-detail mounting radio-button">
                                    <div className={`form-group ${yourNFSShare !== '' ? "help-circle" : null}`}>
                                        <input type="text" className="form-control" value={yourNFSShare} placeholder="192.168.0.1:/nfs_share" onChange={NFSShareChange}/>
                                    </div>
                                </div>
                            </div>
                            {showError && (
                                <div className="form-group">
                                    <span className="red-error">{errorText}</span>
                                </div>
                            )}
                        </div>
                        <div className={`form-group submit ${!isActive || step === 1 || yourNFSShare === '' || (isWindows && selectedAvailableDrive === '') || (!isWindows && selectedVersion === '') || (!isWindows && selectedPath === '') ? 'disabled' : ''}`}>
                            <Button className="btn-style small" loading={loading} onClick={setupNFS}>Connect</Button>
                        </div>
                    </>
                }

                {type === "SMB" &&
                    <>
                        <div className="field-wrapper nfs">
                            <div className={`${showNFSNameError ? "has-error" : null}`}>
                                <div className={`form-group ${isActive ? "help-circle" : null}`}>
                                    <input type="text" className="form-control" value={yourNFSName} placeholder="Give it a name" onChange={NFSName}/>
                                </div>
                                {showNFSNameError &&
                                    <span className="red-error cloud-name">
                                                                That SMB name has already been used.
                                                            </span>
                                }
                            </div>
                            {isWindows &&
                                <>
                                    <div className={`${!isActive ? 'disabled' : ''}`}>
                                        <div className={`form-group  ${selectedAvailableDrive !== '' ? "selected-select" : ""}`}>
                                            <Select
                                                className='d-block remove-ant-select-arrow'
                                                placeholder="Select drive"
                                                optionFilterProp="children"
                                                onChange={onChangeAvailableDrive}
                                                listHeight={160}
                                                // value={selectedAvailableDrive}
                                                options={availableLocalDrives.map((item) => ({
                                                    label: item,
                                                    value: item,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {!isWindows &&
                                <>
                                    <div className={`form-group ${!isActive ? "disabled" : ''}`}>
                                        {selectedPath === '' &&
                                            <div className="file-upload-btn" onClick={showModal}>
                                                <div className="img-wrapper">
                                                    <img src={`${uploadIcon}`} alt="logo" className="icon"/>
                                                </div>
                                                <div className="content-wrapper">
                                                    Select folder
                                                </div>
                                            </div>
                                        }
                                        {selectedPath !== '' &&
                                            <div className="change-cloud-wrapper selected-path">
                                                <div className="choose-cloud-card" onClick={showModal}>
                                                    <div className="content-wrapper">
                                                        <p className="medium" title={selectedPath}>{selectedPath}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }


                            <div className={` ${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') ? 'disabled' : ''}`}>
                                <div className={`form-group  ${yourNFSShare !== '' ? "help-circle" : null}`}>
                                    <input type="text" className="form-control" placeholder={isWindows ? "\\\\server-name\\share-name" : "//Server_IP/Share_Name"} value={yourNFSShare} onChange={NFSShareChange}/>
                                </div>
                            </div>
                            {isWindows &&
                                <div className={` ${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') ? 'disabled' : ''}`}>
                                    <div className="form-group">
                                        <p className="medium">Authenticated</p>
                                        <div className="account-detail mounting radio-button">
                                            <Radio.Group onChange={(e) => {
                                                onChangeAuthenticated(e.target.value);
                                            }} value={isAuthenticated}>
                                                <Radio value="0">Anonymous</Radio>
                                                <Radio value="1">Authenticated</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>
                            }

                            {isAuthenticated === "1" &&
                                <>

                                    <div className={`${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') ? 'disabled' : ''}`}>
                                        <div className={`form-group  ${SMBUserName !== '' ? "help-circle" : null}`}>
                                            <input type="text" className="form-control" placeholder="Username" value={SMBUserName} onChange={SMBUserNameChange}/>
                                        </div>
                                    </div>

                                    <div className={`${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') || SMBUserName === "" ? 'disabled' : ''}`}>

                                        <div className={`form-group  ${SMBPassword !== '' ? "help-circle" : null}`}>
                                            <input type="password" className="form-control" placeholder="Password" value={SMBPassword} onChange={SMBPasswordChange}/>
                                        </div>
                                    </div>
                                    <div className={`${(isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') || SMBPassword === "" ? 'disabled' : ''}`}>
                                        <div className={`form-group ${SMBDomain !== '' ? "help-circle" : null}`}>
                                            <input type="text" className="form-control" placeholder="Domain" value={SMBDomain} onChange={SMBDomainChange}/>
                                        </div>
                                    </div>
                                </>
                            }
                            {showError && (
                                <span className="red-error">{errorText}</span>
                            )}
                        </div>
                        <div className={`form-group submit  ${!isActive || step === 1 || yourNFSShare === '' || (isWindows && selectedAvailableDrive === '') || (!isWindows && selectedPath === '') || (isAuthenticated === "1" && (SMBUserName === '' || SMBPassword === '')) ? 'disabled' : ''}`}>
                            <Button className="btn-style small" loading={loading} onClick={setupSMB}>Connect</Button>
                        </div>
                    </>
                }

            </div>
            <Modal
                open={open}
                title="Title"
                onOk={handleOk}
                onCancel={handleCancel}
                className={`file-path-modal file-path-modal-wrapper ${!isWindows ? "mac-os" : ""}`}
                footer={[
                    <Popconfirm
                        title="Create a folder"
                        description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                        onConfirm={confirm}
                        cancelButtonProps={false}
                        icon={false}
                        okText="Create"
                        placement="topLeft"
                        getPopupContainer={trigger => trigger.parentNode}
                        className={`new-folder-wrapper ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}
                    >
                        <button className={`btn-style float-start  ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}>
                            New Folder
                        </button>
                    </Popconfirm>,
                    <button className="btn-style" onClick={handleOk}>
                        Select Folder
                    </button>,
                    <button className="btn-style margin-left" onClick={handleCancel}>
                        Cancel
                    </button>
                ]}
            >
                <div className="form dir-path-wrapper">
                    <div className="form-group choose-your-backup-title">
                        <h3>
                            <button className={`btn-style  ${selectedPath.length < 4 ? "disabled" : ""}`} type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                            Choose folder
                        </h3>
                    </div>
                </div>
                <div className="modal-body-wrapper">
                    <div className={`select-drive-wrapper  ${!isWindows ? "mac-os" : ""}`}>
                        <div className="row">
                            {isWindows &&
                                <div className="col-md-3">
                                    <p className="medium">Drive</p>
                                    <div className="form">

                                        {localDrives.length > 0 &&
                                            localDrives.map((item, i) => (
                                                <div className="form-group" key={i}>
                                                    <input type="radio" id={`select-drive-` + i} value={item} name="select-drive" onChange={getDirectories}/>
                                                    <label htmlFor={`select-drive-` + i}>
                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-9">
                                <div className="form">
                                    <div className={`account-detail radio radio-button  ${isWindows ? "windows-dir-wrapper" : ""}`}>
                                        <div className={`final-selected-cloud directory-list  ${isWindows ? "windows-dir-list-wrapper" : ""}`}>
                                            {localDir.length > 0 &&
                                                localDir.map((item, i) => (
                                                    <div className="form-group">
                                                        <label data-value={item} title={item.substring(item.lastIndexOf("\\") + 1, item.length)} className="account-card" key={i} onClick={changeDir}>
                                                            {isWindows &&
                                                                item.substring(item.lastIndexOf("\\") + 1, item.length)
                                                            }
                                                            {!isWindows &&
                                                                item.substring(item.lastIndexOf("/") + 1, item.length)
                                                            }
                                                            <i key={i} className='d-none'></i>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            {localDir.length < 1 && selectedPath !== "" &&
                                                <h3>There is no sub directory</h3>
                                            }
                                        </div>
                                    </div>

                                    <div className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Path:</div>
                                            <div className="col-9">
                                                <div className="folder-name" title={selectedPath}>
                                                    {selectedPath}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Folder:</div>
                                            <div className="col-9">
                                                <div className="folder-name">
                                                    {isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                                                    }
                                                    {!isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <AgentOffPopup/>
        </>
    )
}

export default SetupNFS;

