import React, {useEffect, useState} from "react";
import AddMaterials from "../components/AddMaterials";
import {Button, notification} from "antd";
import warning from "../assets/images/icons/warning-sign.svg";
import searchWhiteIcon from "../assets/images/icons/search-white-icon.svg";
import {useAuth} from "../context/AuthContext";

const Materials = () => {
    const {
        getDataWithToken, logout, getFormatedDate, getDataWithTokenPOST, token, setPopup500, setError499, setPopup499
    } = useAuth();
    const [showMaterial, setShowMaterial] = useState(false);
    const [materialsData, setMaterialsData] = useState([]);
    const [page, setPage] = useState("1");
    const [search, setSearch] = useState("");
    const [itemsToCompare, setItemsToCompare] = useState([]);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        if (token !== "" && token !== undefined) {
            loadMaterials();
        }
    }, [token]);

    function loadMaterials() {
        var url = '/api/desktop/get_materials?page=' + page;
        if (search !== "") {
            url += '&title=' + search;
        }
        getDataWithToken(url)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setMaterialsData(data.data)
                }
            });
    }

    const enterKeyup = (event) => {
        if (event.key === "Enter") {
            loadMaterials()
        }
    };

    const setItemToCompare = (item) => {
        if (itemsToCompare.length < 2) {
            setItemsToCompare([...itemsToCompare, item]);
        }
    };
    const removeItemFromCompare = (id) => {
        let newArr = [...itemsToCompare];
        for (var i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr.splice(i, 1);
            }
        }
        setItemsToCompare(newArr);
    };
    const compare = () => {
        if (itemsToCompare.length === 2) {
            var dataToPost = {
                "matrialId1": itemsToCompare[0].id,
                "matrialId2": itemsToCompare[1].id
            }
            getDataWithTokenPOST("/api/desktop/compare_materials", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 499) {
                        var msg = "";
                        for (var i = 0; i < data.errors.DEFAULT.length; i++) {
                            msg += data.errors.DEFAULT + " ";
                        }
                        setLoading(false);

                        api.open({
                            message: msg,
                            icon: <img src={warning} alt="warning"/>,
                            duration: 5,
                        });
                    } else {

                    }
                }
            )

        }
    };

    /*const onClearSearch = () =>{
        setSearch("")
        setTimeout(function() {
            loadMaterials()
        }, 1000);
    }*/
    const onSearchMaterial = () => {
        loadMaterials()
    }
    return (
        <>{contextHolder}
            <div id="main-content" className="dashboard-new-page">
                <div className="container-fluid">
                    {/*<h3 className="mb-4">Materials</h3>*/}
                    {/* <ul className="match">
                        <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                        <li><NavLink to="/materials">Materials</NavLink></li>
                        <li><NavLink to="/match">Match</NavLink></li>
                    </ul>*/}
                    <div className="match-heading">
                        <div className="row">
                            <div className="col-md-6 col-4 align-self-center">
                                <h3>Materials</h3>
                            </div>
                            <div className="col-md-6 col-8 align-self-center text-end">
                                <a href="#" className="btn-style transparent" onClick={(e) => {
                                    e.preventDefault();
                                    setShowMaterial(true)
                                }}>Add New Material</a>
                            </div>
                        </div>
                    </div>
                    <AddMaterials showAddMaterial={showMaterial} setShowMaterial={setShowMaterial} load={loadMaterials}/>
                    {!showMaterial &&
                        <div className="search-material-wrapper">
                            <div className="match-wrapper">
                                <div className="match-search-header">
                                    <div className="row">
                                        <div className="col-lg-7 col-md-12 align-self-center">
                                            <div className="compare-items">
                                                {itemsToCompare.length > 0 &&
                                                    <ul className="match">
                                                        {itemsToCompare.map((item) => (
                                                            <li>{item.title} <a href="#" className="cross" onClick={() => {
                                                                removeItemFromCompare(item.id)
                                                            }}>X</a></li>
                                                        ))
                                                        }
                                                    </ul>
                                                }
                                                {itemsToCompare.length === 2 &&
                                                    <Button loading={loading} className="btn-style transparent" onClick={compare}>Compare</Button>

                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-12 align-self-center">
                                            <div className="form">
                                                <div className="form-group">
                                                    {/*<a href="#" className="clear-search" onClick={onClearSearch}>Clear search</a>*/}
                                                </div>
                                                <div className="form-group search-group material-page">
                                                    <input type="text" placeholder="Search..." className="form-control" value={search} onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }} onKeyUp={(e) => {
                                                        enterKeyup(e)
                                                    }}/>
                                                    <a href="#" className="btn-style" onClick={onSearchMaterial}>
                                                        <img src={searchWhiteIcon} alt="icon"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-main-wrapper">
                                    <div className="different-table-wrapper material-table-wrapper">
                                        <div className="table-header">
                                            <p>Title</p>
                                            <p>Detected Duration</p>
                                            <p>Created At</p>
                                            <p></p>
                                        </div>
                                        <div className="table-body-wrapper">
                                            {
                                                materialsData.map((item, index) => (
                                                    <div className="table-body">
                                                        <div className="color-wrapper">
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <div className="center">
                                                            <p>{item.detectedDuration}</p>
                                                        </div>
                                                        <div className="end">
                                                            <p>{getFormatedDate(item.createdAt)}</p>
                                                        </div>
                                                        <div className="end">
                                                            <a href="#" className={`${itemsToCompare.includes(item) || itemsToCompare.length === 2 ? "disabled" : ""}`} onClick={() => {
                                                                setItemToCompare(item)
                                                            }}>Add to compare</a>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="pagination-wrapper d-none">
                                <li><span>Next</span></li>
                                <li>1</li>
                                <li>2</li>
                                <li>3</li>
                                <li><span>Prev</span></li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Materials;

