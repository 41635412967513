import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Checkbox, Modal, notification, Select, Tooltip, Radio} from 'antd';
import {useAuth} from "../context/AuthContext";
import pageLogo from "../assets/images/logo.svg";
import awsLogo from "../assets/images/icons/onboard-aws-icon.svg";
import AmoveStorage from "../components/SetupAmoveStorage";
import warning from "../assets/images/icons/warning-sign.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const Onboarding = () => {
    const {
        getDataWithToken,
        cloudAccounts,
        isCMDDone,
        userStatus,
        setHideBTNs,
        getDataWithTokenPOST,
        setShowAgentOnce,
        setPopup500,
        setPopupSupport,
        logout,
        setEndpoint,
        setShowPopupSupport,
        setShowTourDashboard,
        sendDataToMixPanel,
        user,
        setError499,
        setPopup499,
        isDark
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [connectedCloudDrives, setConnectedCloudDrives] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [options, setOptions] = useState(0);
    const [step, setStep] = useState(1);
    const [errorText, setErrorText] = useState('');
    const [regions, setRegions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState("Select a Region");
    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        localStorage.setItem('onboarding', true);
        setHideBTNs(true)
    }, []);
    useEffect(() => {
        setTimeout(function () {
            setShowMessage(true);
        }, 500)
    }, [cloudAccounts]);
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getDataWithToken('/api/cloudaccount/idrive_regions')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setRegions(response)
            });
    }, []);

    const onChange = async (value) => {
        setSelectedRegions(value)
    };

    const setUpNewAmoveStorage = () => {
        var dataToPost = {
            "cloudAccountName": "Amove",
            "bucketName": "amovebucket",
            "shared": false
        }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 499) {
                    if (response.type === 'DEFAULT') {
                        setErrorText(response.errors.DEFAULT[0])
                    }
                    setLoading(false);
                } else {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    setLoading(false);
                    localStorage.setItem("dashboardTourStep", 0)
                    setShowTourDashboard(false)
                    setShowAgentOnce(true)

                    sendDataToMixPanel('Log settings updated successfully.', user.username, {
                        'App': 'Web App'
                    })
                    navigate(`/dashboard?amove=true`);
                }
            })

    };

    const setUpNewIDrive = () => {
        var dataToPost = {
            "userEmail": "",
            "cloudAccountName": "Amove",
            "bucketTitle": "amovebucket",
            "region": selectedRegions,
            "defaultPassword": "",
            "shared": false
        }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/idrive_add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    if (response.type === 'DEFAULT') {
                        setErrorText(response.errors.DEFAULT[0])
                    }
                    setLoading(false);
                } else {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('New Amove Storage Created.', user.username, {
                        'App': 'Web App'
                    })
                    setLoading(false);
                    navigate(`/dashboard?amove=true`);
                }
            })

    };

    return (
        <>
            {contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    {(userStatus.status === "trial" || userStatus.status === "active") &&
                        <div className="main-content-body home">
                            <div className="onboarding-screen-main">
                                {step === 1111 &&
                                    <div className="onboarding-step onboarding-step-1">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                        </div>
                                        <h2>Welcome to <strong>Click</strong></h2>
                                        <h3 className="mb-32">Get Started with your <strong> 15-Day Free Trial</strong></h3>
                                        <div className="pricing-wrapper">
                                            <div className="pricing-card">
                                                <p className="p bold title">Click for Everyone</p>
                                                <p className="discount"></p>
                                                <p className="p bold price">Features Include:</p>
                                                <ul className="feature">
                                                    <li>3 Admin Users</li>
                                                    <li>1 TB of Storage</li>
                                                    <li>Instant Collaboration</li>
                                                    <li>3 Cloud Connections <br/> (Bring Your Own Storage)</li>
                                                    <li>Unlimited Cloud Drives</li>
                                                    <li>Unlimited Backups to Any Cloud</li>
                                                    <li>Unlimited transfers between Cloud Drives</li>
                                                    <li>High Speed File Access & Share</li>
                                                    <li>Sync between any points</li>
                                                    <li>File management</li>
                                                    <li>Cloudflyer Access <br/> (Migrations between clouds)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="btn-footer">
                                            <a href="#" className="btn-style" onClick={(e) => {
                                                e.preventDefault();
                                                setStep(2)
                                            }}>Let's Get Started</a>
                                        </div>
                                    </div>
                                }
                                {step === 1 &&
                                    <div className="onboarding-step onboarding-step-2">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                        </div>
                                        <h2>Welcome to <strong>Click</strong></h2>
                                        <h3 className="mb-32">Let's Start Your 15 Day Trial with your Storage</h3>
                                        {/*<p className="gray mb-32">In this step, you'll set up your storage
                                            preferences to ensure your cloud <br/> management experience
                                            is tailored to your needs.</p>*/}
                                        <div className="choose-cloud-wrapper">
                                            <Radio.Group onChange={(e) => {
                                                setOptions(e.target.value)
                                            }}>
                                                <Radio value={1}>
                                                    <p className="cloud-name text-center">Create Storage</p>
                                                    <div className="cloud-card">
                                                        <div className="title-wrapper">
                                                            <p className="title p bold">Create Amove <br/>
                                                                Global Storage</p>
                                                            <span className="circle"></span>
                                                        </div>
                                                        <p className="small">Instantly set up Amove Storage and <br/>
                                                            get started with 25GB Free.</p>
                                                    </div>
                                                </Radio>
                                                <Radio value={2}>
                                                    <p className="cloud-name text-center">Add Existing Storage</p>
                                                    <div className="cloud-card">
                                                        <div className="title-wrapper">
                                                            <p className="title p bold">Bring Your <br/>
                                                                Own Storage</p>
                                                            <span className="circle"></span>
                                                        </div>
                                                        <p className="small">Connect to your existing cloud account. <br/>
                                                            Choose from over 50 providers.</p>
                                                    </div>
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                        <div className="btn-footer">
                                            <Button loading={loading} className={`btn-style ${options === 0 ? "disabled" : ""}`} onClick={(e) => {
                                                e.preventDefault();
                                                if (options === 1) {
                                                    setErrorText('')
                                                    setStep(2)
                                                } else if (options === 2) {
                                                    sendDataToMixPanel('Connect to your existing cloud account From Onboarding.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    navigate("/add-cloud-account?hide=true&startTour=true");
                                                }
                                            }}>Continue</Button>
                                        </div>
                                    </div>
                                }
                                {step === 2 &&
                                    <div className="onboarding-step onboarding-step-3">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                            <strong>Click</strong>
                                        </div>
                                        <h2>Choose an <strong>Amove Global Storage Region</strong>  <br/>
                                            and experience amazing performance.</h2>
                                        <p className="gray mb-32">You will have the option to choose multiple
                                            regions after getting started.</p>
                                        <div className="choose-region-wrapper">
                                            <div className="region-card">
                                                <p className="small">Choose Your Region</p>
                                                <Select
                                                    className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={onChange}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder="Select a Region"
                                                    value={selectedRegions}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    options={regions.map((item) => ({
                                                        label: item.name, value: item.code,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="btn-footer grid-two">
                                            <Button className={`btn-style transparent`} onClick={(e) => {
                                                e.preventDefault();
                                                setErrorText('')
                                                setOptions(0)
                                                setStep(1)
                                            }}>Back</Button>
                                            <Button loading={loading} className={`btn-style ${selectedRegions === "" || selectedRegions === "Select a Region" ? "disabled" : ""}`} onClick={(e) => {
                                                e.preventDefault();
                                                setUpNewIDrive();
                                            }}>Continue</Button>
                                        </div>
                                        <div className="provisioning-wrapper">
                                            {loading &&
                                                <>
                                                    <div className={`loader`}></div>
                                                    <span className="loading-text">Amove Global Storage is provisioning.</span>
                                                </>
                                            }

                                            {!loading &&
                                                <div className="red-error fix-3-lines-desktop" title={errorText}>{errorText}</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {/*<div className="row justify-content-center d-none">
                            {step === 1 &&
                                    <div className="create-team col-lg-6 dashboard-amove-storage  step-n-1">
                                        <img src={pageLogo} alt="logo"/>
                                        <h2>Let's start with your storage.</h2>

                                        <Radio.Group onChange={(e) => {
                                            setOptions(e.target.value)
                                        }}>
                                            <Radio value={1}>
                                                <div className="box">
                                                    <h3 className="title">Create Amove Storage</h3>
                                                    <p>Instantly set up Amove Storage and <br/>
                                                        get started with 25GB Free.</p>
                                                </div>
                                            </Radio>
                                            <Radio value={2}>
                                                <div className="box">
                                                    <h3 className="title">Add Existing Storage</h3>
                                                    <p>Connect to your existing cloud account. <br/>
                                                        Choose from over 50 providers.</p>
                                                </div>
                                            </Radio>
                                        </Radio.Group>
                                        <div className="row">
                                            <div className="col-4 text-start">
                                                <Button loading={loading} className={`btn-style blue ${options === 0 ? "disabled" : ""}`} onClick={(e) => {
                                                    e.preventDefault();
                                                    if (options === 1) {
                                                        setErrorText('')
                                                        setStep(2)
                                                    } else if (options === 2) {
                                                        sendDataToMixPanel('Connect to your existing cloud account From Onboarding.', user.username, {
                                                            'App': 'Web App'
                                                        })
                                                        navigate("/add-cloud-account?hide=true&startTour=true");
                                                    }
                                                }}>Continue</Button></div>
                                            <div className="col-8">
                                                <div className="red-error">{errorText}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {step === 2 &&
                                    <div className="create-team amove-region-popup col-lg-6 dashboard-amove-storage  step-n-1">
                                        <div className="row">
                                            <div className="col-md-3"><img src={pageLogo} alt="logo"/></div>
                                            <div className="col-md-9">

                                                <h2 className="new-title">Choose a storage region nearest <br/>
                                                    your team for best performance.</h2>
                                                <h3 className="new-title">Choose a storage region nearest <br/>
                                                   your team for best performance</h3>
                                            </div>
                                        </div>
                                        <Select
                                            className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            placeholder="Select a Region"
                                            value={selectedRegions}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={regions.map((item) => ({
                                                label: item.name, value: item.code,
                                            }))}
                                        />
                                        <div className="row">
                                            <div className="col-6 text-start two-buttons">
                                                <Button className={`btn-style blue`} onClick={(e) => {
                                                    e.preventDefault();
                                                    setErrorText('')
                                                    setOptions(0)
                                                    setStep(1)
                                                }}>Back</Button>
                                                <Button loading={loading} className={`btn-style blue ${selectedRegions === "" || selectedRegions === "Select a Region" ? "disabled" : ""}`} onClick={(e) => {
                                                    e.preventDefault();
                                                    setUpNewIDrive();
                                                }}>Continue</Button>
                                            </div>
                                            <div className="col-6">
                                                {loading &&
                                                    <>
                                                        <div className={`loader`}></div>
                                                        <span className="loading-text">Amove Global Storage is provisioning.</span>
                                                    </>
                                                }

                                                {!loading &&
                                                    <div className="red-error fix-3-lines-desktop" title={errorText}>{errorText}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>*/}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Onboarding;

