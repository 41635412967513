import React, {useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import pageLogo from "../assets/images/page-logo.svg"
import {Button, notification} from 'antd';
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import config from "../config";

const MFAVerificationCode = ({length = 6, onComplete}) => {
    const {session, setJWTToken, setIsAuthenticated, setError499, setPopup499, clearLoginSession, logout, isDark, getToken, token,setPopup500, sendDataToWebsocketLogin} = useAuth();
    const navigate = useNavigate();
    const controller = useRef(null);

    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState(false);
    const [loginDone, setLoginDone] = useState(false);
    const [lastRemoved, setLastRemoved] = useState(true);
    const [code, setCode] = useState([...Array(length)].map(() => ""));
    const inputs = useRef([]);

    const processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num)) return;
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
    };

    async function postData(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response;
    }

    const onKeyUp = (e, slot) => {
        if(slot === 5 && e.keyCode === 8 && !lastRemoved){
            setLastRemoved(true)
        }else if (e.keyCode === 8 && !code[slot] && slot !== 0 && lastRemoved) {
            const newCode = [...code];
            newCode[slot - 1] = "";
            setCode(newCode);
            inputs.current[slot - 1].focus();
        }
        if(slot === 5 && e.keyCode !== 8 && lastRemoved){
            setLastRemoved(false)
        }
    };


    function verifyCode() {
        if (code.join("").length > 0) {
            setLoading(true);
            getToken(config.auth + "/api/authentication/request_token")
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, token]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(token)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                postData(config.auth + '/api/authentication/mfa_respond', {
                    "requestToken": token,
                    "userCode": code.join(""),
                    "session": session
                })
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (status === 499) {
                            setLoading(false);
                            api.open({
                                message: 'Error',
                                description: "MFA code is expired, please try again.",
                                icon: <img src={warning} alt="warning"/>,
                                duration: 5,
                                placement: "top"
                            });

                            return;
                        }
                        if (status === 400) {
                            api.open({
                                message: 'Error',
                                description:
                                    `Incorrect MFA Code.`,
                                icon: <img src={warning} alt="warning"/>,
                                duration: 5,
                                placement: "top"
                            });
                            setLoading(false);
                        } else if (data.jwtToken !== undefined) {
                            clearLoginSession()
                            sendDataToWebsocketLogin(config.auth + "/api/authentication/login", "POST", data.jwtToken)
                            setJWTToken(data.jwtToken)
                            setIsAuthenticated(true);
                            setLoginDone(true);
                            navigate("/")
                        } else {
                            clearLoginSession()
                            setIsAuthenticated(false);
                            navigate("/sign-in")
                        }
                    });
            });
        } else {
            api.open({
                message: 'Error',
                description:
                    `Please Enter MFA Code.`,
                icon: <img src={warning} alt="warning"/>,
                duration: 5,
                placement: "top"
            });
        }
        setLoading(false);
    }

    return (
        <>
            {contextHolder}
            <div className="login email-verification">
                {!loginDone &&
                    <div className="login-wrapper">
                        <div className="text-center">
                            <a href="/" className="logo">
                                <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                            </a>
                        </div>
                        <div className="form">
                            <h2 className="h2">Enter 6-Digit Code</h2>
                            <p className="p">Enter an MFA code to complete sign-in.</p>
                            <div className="form-group">
                                <div className="code-input">
                                    {code.map((num, idx) => {
                                        return (
                                            <input
                                                key={idx}
                                                type="text"
                                                inputMode="numeric"
                                                maxLength={1}
                                                value={num}
                                                autoFocus={!code[0].length && idx === 0}
                                                readOnly={loading}
                                                onChange={e => processInput(e, idx)}
                                                onKeyUp={e => onKeyUp(e, idx)}
                                                ref={ref => inputs.current.push(ref)}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="form-group submit">
                                <Button className="btn-style full" onClick={verifyCode} loading={loading}>Verify code</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    )
}

export default MFAVerificationCode;

